import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoController, StoStore } from '../../mobx';

import Styled from './StoControlButtons.styles';
import { useStoControls } from './hooks';

const StoControlButtons: FC = () => {
  const store = useStore(StoStore);
  const controller = useStore(StoController);

  const [isFetching, setIsFetching] = useState(false);

  const controls = useStoControls();

  const handleButtonClick = () => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    if (store.isCreateMode) {
      controller
        .createSto()
        .then(STO => controls.createStoSuccessHandler(STO.id))
        .catch(() => controls.createStoErrorHandler())
        .finally(() => {
          setIsFetching(false);
        });
    }

    if (store.selectedSto?.id) {
      controller
        .updateSto()
        .then(() => controls.updateStoSuccessHandler())
        .catch(() => controls.updateStoErrorHandler())
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const primaryButtonText = useMemo(() => {
    if (store.isCreateMode) {
      return 'Создать СТО';
    }

    return 'Сохранить';
  }, [store.isCreateMode]);

  const isBlocked = useMemo(() => {
    if (store.isCreateMode) {
      return !store.isCreateStoFormValid;
    }

    if (store?.hasChanges) {
      return !store.isEditStoFormValid;
    }

    return false;
  }, [
    store.isCreateMode,
    store.isCreateStoFormValid,
    store.isEditStoFormValid,
    store?.updatedStoData,
    store?.createStoData,
  ]);

  const isHideButton = useMemo(() => {
    return store?.isViewOnly;
  }, [store?.isCreateMode, store?.isViewOnly]);

  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper $isHidden={isHideButton}>
        <Button
          color={'primary'}
          type={'button'}
          onClick={handleButtonClick}
          disabled={isBlocked}
          dataTestId={
            store.isCreateMode ? 'fullscreen-create-sto-button' : 'fullscreen-save-sto-button'
          }
        >
          {primaryButtonText}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

StoControlButtons.displayName = 'StoControlButtons';

export default observer(StoControlButtons);
