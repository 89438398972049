// шаг `/sto` идёт от родительского роутера
enum EStoRoute {
  Listing = '/',

  StoItem = '/:stoId',

  // Вкладки
  StoItemCreate = '/create',
  StoItemSummary = '/:stoId/summary',
  StoItemFieldList = '/:stoId/fields',

  StoItemOperationList = '/:stoId/operations',
  StoItemOperation = '/:stoId/operations/:operationId',

  StoItemChecklist = '/:stoId/operations/:operationId/:checklistId',
}

export default EStoRoute;
