import { makeAutoObservable, reaction } from 'mobx';
import moment from 'moment';

import { provide } from '../../shared/utils/IoC';
import { SeasonModel } from '../../../api/models/season.model';
import type { Option } from '../../shared/components/SeasonAccordeon/SeasonAccordeon';
import 'moment/locale/ru';

@provide.singleton()
export class SeasonsStore {
  currentSeasons: Array<SeasonModel> = [];

  selectedSeason = '';

  loading = false;

  private _isLoaded = false;

  constructor() {
    moment.locale('ru');
    makeAutoObservable(this);
  }

  get selectedSeassonData(): SeasonModel | null {
    const selectedSeassonData = this.currentSeasons.find(
      seasson => String(seasson.year) === this.selectedSeason
    );

    return selectedSeassonData || null;
  }

  get seasonsOptions(): Array<Option> {
    return this.currentSeasons.map(s => {
      const startDateAlias = moment(s.startDate, 'YYYY-MM-DD').format('DD MMM');
      const endDateAlias = moment(s.endDate, 'YYYY-MM-DD').format('DD MMM');
      return {
        value: s.year.toString(),
        label: s.label,
        description: `${startDateAlias} - ${endDateAlias}`,
      };
    });
  }

  get isLoaded() {
    return this._isLoaded;
  }

  waitSeasons = (timeoutMs = 3000) => {
    return new Promise((res, rej) => {
      if (this.selectedSeason) {
        return res(this.selectedSeason);
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      const timerId = setTimeout(() => rej(null), timeoutMs);

      reaction(
        () => this.selectedSeason,
        value => {
          clearTimeout(timerId);
          res(value);
        }
      );
    });
  };

  getSeasonDataByYear = (): SeasonModel =>
    this.currentSeasons.filter(season => season.year === Number(this.selectedSeason))?.[0];

  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  setCurrentSeasons = (seasonList: SeasonModel[]): void => {
    this.currentSeasons = seasonList;
  };

  setSelectedSeason = (season: string): void => {
    this.selectedSeason = season;
  };

  setIsLoaded = (state: boolean) => {
    this._isLoaded = state;
  };

  clearSelectedSeason = (): void => {
    this.selectedSeason = '';
  };

  clearCurrentSeasons = (): void => {
    this.currentSeasons = [];
  };

  clearLoadingState = () => {
    this._isLoaded = false;
    this.loading = false;
  };

  clear = (): void => {
    this.clearSelectedSeason();
    this.clearCurrentSeasons();
    this.clearLoadingState();
  };
}
