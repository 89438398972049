import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { TableFiltersBuilder } from '../../../../../shared/features/TableFiltersBuilder';
import { TasksController } from '../../controllers/tasks.controller';
import { OperationsStore } from '../../../operations/stores/operations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { TableFiltersBuilderController as FiltersBuilderController } from '../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import { ISelectOption } from '../../../../../../types/selectOption';
import { TasksListController } from '../TasksList/mobx/controllers';
import { useTaskDataConsistency, useTasksParams } from '../../hooks';
import NO_CULTURE_SELECT_OPTION from '../../../../../shared/utils/constants/selectOptions/NoCultureSelectOption/NoCultureSelectOption';
import { FieldsStore } from '../../../fields/mobx';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';

import { TasksFiltersController } from './mobx/controllers';
import { TasksFiltersHeader as Header } from './containers/header';

const TasksFilters: FC = () => {
  const operationsStore = useStore(OperationsStore);
  const seasonsStore = useStore(SeasonsStore);
  const checkAccessStore = useStore(CheckAccessStore);
  const profileStore = useStore(ProfileStore);
  const fieldsStore = useStore(FieldsStore);

  const tasksController = useStore(TasksController);
  const filtersController = useStore(TasksFiltersController);
  const tableFiltersBuilderController = useStore(FiltersBuilderController);
  const tasksListController = useStore(TasksListController);

  const params = useTasksParams();

  const { isFieldsLoaded, isOrganizationLoaded, isSeasonLoaded } = useTaskDataConsistency({
    consistencyKey: 'tasks',
  });

  const isDataLoading = seasonsStore.loading || fieldsStore.isLoading;
  const isDataLoaded = !isDataLoading && isFieldsLoaded && isOrganizationLoaded && isSeasonLoaded;

  const [assigneeOptionList, setAssigneeOptionList] = useState<ISelectOption[]>([]);

  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.Tasks,
    ContextualPaths.TasksHeaderTitle
  );

  const hasWorkWithTasksPermission = checkAccessStore.getAccessRuleValue(
    SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS
  );

  const hasManageTasksPermission = checkAccessStore.getAccessRuleValue(
    SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS
  );

  const appliedFilters = tableFiltersBuilderController.getAppliedFilters('tasks') || {};

  useEffect(() => {
    (async () => {
      const createdOptionList = await filtersController.createAssigneeSelectOptionList(
        params.orgId
      );

      setAssigneeOptionList(createdOptionList);
    })();
  }, [params.orgId]);

  useEffect(() => {
    (async () => {
      // ВАЖНО! Инициализация фильтров должна происходить лишь один раз.
      // Это очень дорогостоящая операция.
      // В данном случае фильтры инициализируются столько раз, сколько запросов.

      // tableFiltersBuilderController.clearStore('tasks');

      await operationsStore.fetchCulturesList();
      await operationsStore.fetchOperationTypeDictionaryByCultureId('', true);

      filtersController.initiateFilters(ContextualHelpIcon);
      filtersController.addStatusSelectOptionList();
    })();
  }, [isDataLoaded, isDataLoading]);

  useEffect(() => {
    return () => {
      tableFiltersBuilderController.clearStore('tasks');
    };
  }, []);

  useEffect(() => {
    if (!appliedFilters || isEmpty(appliedFilters)) {
      return;
    }

    tasksListController.fetchTaskList(appliedFilters);
  }, [JSON.stringify(appliedFilters), seasonsStore.selectedSeason]);

  useEffect(() => {
    const cultureSelectOptionList = operationsStore.fieldsWithoutCulturesCount
      ? [NO_CULTURE_SELECT_OPTION, ...tasksController.culturesList]
      : tasksController.culturesList;

    tableFiltersBuilderController.addSelectOptionList(
      'tasks',
      'cultureId',
      cultureSelectOptionList
    );
  }, [
    seasonsStore.selectedSeason,
    operationsStore.OperationCulturesInFields,
    operationsStore.fieldsWithoutCulturesCount,
  ]);

  useEffect(() => {
    tableFiltersBuilderController.addSelectOptionList(
      'tasks',
      'fieldId',
      tasksController.fieldsList
    );
  }, [tasksController.fieldsList]);

  useEffect(() => {
    tableFiltersBuilderController.addSelectOptionList(
      'tasks',
      'operationTypeId',
      tasksController.operationsTypesForFilter
    );
  }, [tasksController.operationsTypesForFilter]);

  useEffect(() => {
    const noAssigneeOption = { label: 'Не назначено', value: 'noAssignee' };

    if (hasManageTasksPermission) {
      const formattedOptionList: ISelectOption[] = [noAssigneeOption, ...assigneeOptionList];

      tableFiltersBuilderController.addSelectOptionList('tasks', 'assigneeId', formattedOptionList);

      return;
    }

    if (hasWorkWithTasksPermission) {
      const formattedOptionList: ISelectOption[] = [
        noAssigneeOption,
        { label: profileStore.fullName, value: profileStore.user.id },
      ];

      tableFiltersBuilderController.addSelectOptionList('tasks', 'assigneeId', formattedOptionList);
    }
  }, [assigneeOptionList, hasManageTasksPermission, hasWorkWithTasksPermission]);

  return <TableFiltersBuilder builderId={'tasks'} />;
};

TasksFilters.displayName = 'TasksFilters';

export default observer(TasksFilters);
