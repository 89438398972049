import React, { FC, MouseEvent, useMemo, memo, useCallback } from 'react';
import moment from 'moment/moment';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { EStoStatus, ISto } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';

import defaultCultureIcon from './assets/icons/defaultCulture.svg';
import Styled from './StoCard.styles';

interface IProps {
  sto: ISto;
  onClick: (id: string) => void;
}

const StoCard: FC<IProps> = ({ sto, onClick }) => {
  const { id, name, culture, createDate, status, cultureZones, totalCultureZones } = sto;

  const { ref: nameRef, isShowTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>();

  const fieldsDescription = useMemo(() => {
    const cultureZonesNumber = cultureZones.length;

    return `Поля: ${cultureZonesNumber} из ${totalCultureZones}`;
  }, [cultureZones, totalCultureZones]);

  const formattedStatus = useMemo(() => {
    const formattedCreatingDate = createDate ? moment(createDate).format('DD.MM.YYYY') : '—';

    switch (status) {
      case EStoStatus.Draft:
        return `Черновик от ${formattedCreatingDate}`;

      case EStoStatus.Active:
        return `Версия от ${formattedCreatingDate}`;

      case EStoStatus.Inactive:
        return `Изменено ${formattedCreatingDate}`;

      default:
        return '';
    }
  }, [status]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      onClick(id);
    },
    [onClick]
  );

  const getDataTestId = useDataTestIdV2('sto__list__sto-card');

  return (
    <Styled.Wrapper onClick={handleClick} {...getDataTestId()}>
      <Styled.Header {...getDataTestId('header')}>
        <AutoTooltip content={name} disabled={!isShowNameTooltip}>
          <Styled.Name ref={nameRef} {...getDataTestId('name')}>
            {name}
          </Styled.Name>
        </AutoTooltip>

        <Styled.CultureIcon
          src={culture?.picLink?.downloadUrl ?? defaultCultureIcon}
          alt={culture.name}
          {...getDataTestId('culture-icon')}
        />
      </Styled.Header>

      <Styled.Content {...getDataTestId('content')}>
        <Styled.CultureName {...getDataTestId('culture-name')}>{culture.name}</Styled.CultureName>

        <Styled.FieldsDescription {...getDataTestId('fields')}>
          {fieldsDescription}
        </Styled.FieldsDescription>
      </Styled.Content>

      <Styled.Footer {...getDataTestId('footer')}>
        <Styled.Status $status={status} {...getDataTestId('status')}>
          {formattedStatus}
        </Styled.Status>

        <Styled.DownloadIcon {...getDataTestId('download-icon')} />
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

StoCard.displayName = 'StoCard';

export default memo(StoCard);
