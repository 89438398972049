import { useCallback } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { MapDrawerController } from '../../../../../../../shared/features/map/modules';
import { BaseMarker } from '../../../../../../../shared/features/map/utils/MapElements';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { InspectionPointsStore } from '../../mobx/stores';
import { useMapActions } from '../../../../../../../shared/features/map/hooks';
import { IMapMarkerElement } from '../../../../../../../shared/features/map/models';
import { CultureZone, Field } from '../../../../../../../../api/models/field.model';
import { InspectionPointsElementHelpers as ElementHelpers } from '../../utils/helpers';
import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { InspectionPointsController } from '../../mobx/controllers';
import { TInspectionPointsZoneElement as TZoneElement } from '../../models';
import { EDIT_TASK_MAP_POINT_MODAL_ID } from '../../../../modals/editTaskMapPointModal/editTaskMapPointModal';
import { useChecklistInstanceActions } from '../../../../../operationsAndTasks/components/instance/ListOfChecklistInstance/components/ChecklistInstance/hooks';
import { ChecklistInstancesStore } from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { NO_CHECKLIST_FOUND_MODAL_ID } from '../../../../modals/noChecklistFoundModal';
import { getInspectionMarkerConfig } from '../../utils/helpers/mapElementHelpers/markers';

const { createZoneConfig, getPointElementListToChangeStyle } = ElementHelpers;

// Отображает КЗ на карте.
type TDisplayZone = (
  zone: CultureZone | Field,
  options?: {
    isAllowedToAddPoints?: boolean;
  }
) => TZoneElement;

// Отображает точки на карте.
type TDisplayPointList = (
  pointList: IDrawChecklistInstance[],
  options?: {
    isCleanDisplay?: boolean;
    isReorder?: boolean;
  }
) => void;

type TCreateMarkerHandler = (
  marker: BaseMarker,
  instanceTypeListToCreate?: ECheckListInstanceType[]
) => IMapMarkerElement | false;

const useInspectionPointsActionsHook = (): {
  displayZone: TDisplayZone;
  displayPointList: TDisplayPointList;
  cleanMap;
  createMarkerHandler;
} => {
  const { pointElementList, setZoneElement, setPointElementList } = useStore(InspectionPointsStore);
  const { delDrawInst, setDrawInst } = useStore(ChecklistInstancesStore);
  const { changePointElementList, removePointElementList, createMarker } = useStore(
    InspectionPointsController
  );

  const mapDrawerController = useStore(MapDrawerController);

  const { mapActions, polygonActions, markerActions } = useMapActions();

  const { updateDrawInst } = useChecklistInstanceActions();
  const { openModalByModalId, closeModal } = useModal();

  const displayZone = useCallback<TDisplayZone | null>((zone, options) => {
    if (!zone) {
      polygonActions.removeMany([], { isRemoveAll: true });
      markerActions.removeMany([], { isRemoveAll: true });

      mapDrawerController.disableDrawMarker();
      mapDrawerController.setMarkerDrawConfig({ bounds: null });

      return null;
    }

    // Процесс отрисовки культурных зон на карте.

    markerActions.removeMany([], { isRemoveAll: true });
    polygonActions.removeMany([], { isRemoveAll: true });

    const polygonConfig = createZoneConfig(zone, {
      isAllowedToCreateMarkers: options?.isAllowedToAddPoints,
    });
    const element = polygonActions.display(polygonConfig);

    if (!element) {
      return null;
    }

    mapDrawerController.setMarkerDrawConfig({ bounds: element.polygon });

    if (options?.isAllowedToAddPoints) {
      mapDrawerController.enableDrawMarker();
    } else {
      mapDrawerController.disableDrawMarker();
    }

    mapActions.centerOnBounds(element.polygon, {
      paddingBottomRight: [64, 64],
      paddingTopLeft: [64, 8],
    });

    setZoneElement(element);

    return element;
  }, []);

  const displayPointList = useCallback<TDisplayPointList>(
    (_pointList, options) => {
      const pointList = _pointList;

      // Если это первая отрисовка элементов, то не делаем никаких проверок, а просто рисуем точки.
      if (options?.isCleanDisplay || !pointElementList.length) {
        const markers = pointList.map(getInspectionMarkerConfig);

        const markerElementList = markerActions.displayMany(markers, {
          isClearPreviousList: true,
        });

        setPointElementList(markerElementList);
      } else {
        // После изменения исходного массива с точками, проверяем, какие элементы были удалены или изменены.

        // Проверяем на удаление.
        const pointListToDelete = pointElementList.filter(
          ({ element }) => !pointList.some(point => point.id === element.id)
        );

        if (pointListToDelete.length) {
          markerActions.removeMany(pointListToDelete.map(item => item.marker.id));
          removePointElementList(pointListToDelete.map(({ element }) => element.id));
        }

        // Проверяем на изменение.
        const pointListToChangeStyle = getPointElementListToChangeStyle(
          pointElementList,
          pointList
        );

        if (pointListToChangeStyle) {
          pointListToChangeStyle.forEach(({ marker, element }) => {
            marker.setStyle({ html: String(element.positionNumber) });
          });

          changePointElementList(pointListToChangeStyle);
        }
      }
    },
    [pointElementList]
  );

  const createMarkerHandler: TCreateMarkerHandler = useCallback(
    (marker: BaseMarker, instanceTypeListToCreate) => {
      const instance = createMarker(marker);
      if (!instance) {
        return;
      }

      if (!instanceTypeListToCreate?.length) {
        openModalByModalId(NO_CHECKLIST_FOUND_MODAL_ID, {}, () => {
          delDrawInst(instance.element.id);
          removePointElementList([instance.element.id]);
        });

        return false;
      }

      if (instanceTypeListToCreate?.length > 1) {
        const { positionNumber, instance: innerInstance } = instance.element;

        openModalByModalId(
          EDIT_TASK_MAP_POINT_MODAL_ID,
          {
            instanceTypeListToCreate,
            isNewPoint: true,
            coordinates: innerInstance.planCoords.geometry.coordinates,
            positionNumber,
          },
          data => {
            updateDrawInst(instance.element, data);

            closeModal();
          },
          () => {
            delDrawInst(instance.element.id);
            removePointElementList([instance.element.id]);
          }
        );
      } else if (typeof instance === 'object' && instanceTypeListToCreate?.length) {
        const [availableType] = instanceTypeListToCreate;

        if (availableType === ECheckListInstanceType.Machinery) {
          instance.element.isMachinery = true;
        } else if (availableType === ECheckListInstanceType.Field) {
          instance.element.isField = true;
        }

        instance.element.instance.type = availableType;

        setDrawInst(instance.element);

        return instance;
      } else {
        delDrawInst(instance.element.id);
        removePointElementList([instance.element.id]);
        return false;
      }
    },
    []
  );

  const cleanMap = () => {
    polygonActions.removeMany([], { isRemoveAll: true });
    markerActions.removeMany([], { isRemoveAll: true });
  };

  return {
    displayZone,
    displayPointList,
    cleanMap,
    createMarkerHandler,
  };
};

export default useInspectionPointsActionsHook;
