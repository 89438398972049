import L, { LatLngBounds } from 'leaflet';

import { normalizeCoordsForLeaflet } from './index';

/**
 * Вычилсяет границы по координатам
 */
function getBoundsByCoordinates(coordinates: any): LatLngBounds | null {
  if (!coordinates) {
    return null;
  }

  const normalizedCoords = normalizeCoordsForLeaflet(coordinates);

  return L.polygon(normalizedCoords)?.getBounds() ?? null;
}

export default getBoundsByCoordinates;
