import { observer } from 'mobx-react';
import { FC, useMemo, useEffect, useCallback } from 'react';
import { ButtonLink, useContextualHelpActions } from '@farmlink/farmik-ui';

import { Map } from '../../../../../shared/features/map/components';
import { useMapEvents } from '../../../../../shared/features/map/hooks';
import { useStore } from '../../../../../shared/utils/IoC';
import { ListOfChecklistInstance } from '../../../operationsAndTasks/components/instance';
import { useTasksRouteActions } from '../../hooks';
import InstanceListActualityService from '../../../operationsAndTasks/services/instanceList/instanceListActuality.service';
import { ECheckListInstanceType } from '../../../../../../api/models/checklist/instance/checklist.instance.model';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../shared/hooks';

import { useInspectionPointsActionsHook } from './hooks';
import Styled from './InspectionPoints.styles';
import { InspectionPointsStore } from './mobx/stores';
import { InspectionPointsController } from './mobx/controllers';
import './assets/styles/points/points.css';

interface IProps {
  mode: 'checklists' | 'tasks';
  onAddPointClick?: () => void;
  instanceTypeListToCreate?: ECheckListInstanceType[];
  isAllowedToShowAddButton?: boolean;
  isAllowedToCreateMarkers?: boolean;
  isDisplayTitleActionButton?: boolean;
  isHideMap?: boolean;
}

const InspectionPoints: FC<IProps> = ({
  mode,
  instanceTypeListToCreate,
  isDisplayTitleActionButton,
  isAllowedToCreateMarkers,
  isHideMap,
}) => {
  const { pointElementList, clearStore, setIsInspectionPointsChanged } = useStore(
    InspectionPointsStore
  );
  const { getNewMarkerOnClick, createMarker } = useStore(InspectionPointsController);
  const { clearService } = useStore(InstanceListActualityService);

  const { goToMapCreatePoint } = useTasksRouteActions();
  const { createMarkerHandler } = useInspectionPointsActionsHook();

  const { onCreateMarkerByClick } = useMapEvents();

  // const markerHandler: IMapCreateMarkerHandler = useCallback(
  //   (marker: IMapMarker) => {
  //     setIsInspectionPointsChanged(true);
  //     return createMarkerHandler(marker, instanceTypeListToCreate);
  //   },
  //   [instanceTypeListToCreate]
  // );

  const sortedPointList = useMemo(
    () =>
      pointElementList.sort((a, b) => {
        return a.element.positionNumber - b.element.positionNumber;
      }),
    [pointElementList]
  );

  useEffect(() => {
    onCreateMarkerByClick(marker => {
      if (mode === 'checklists') {
        createMarker(marker);
        return;
      }

      setIsInspectionPointsChanged(true);
      createMarkerHandler(marker, instanceTypeListToCreate);
    });
  }, [instanceTypeListToCreate, mode]);

  useEffect(() => {
    return () => {
      clearService();
      clearStore();
    };
  }, []);

  usePageContextualHelp(EContextualParentPath.TasksTaskMap);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.TasksTaskMap,
    ContextualPaths.TasksTaskMapTitle
  );

  if (mode === 'checklists') {
    return (
      <Styled.MapWrapper $isHidden={isHideMap}>
        <Map
          globalConfig={{
            mapKey: 'taskInspectionPointsMap',
            markersConfig: {
              getNewMarkerOnClick,
            },
          }}
          style={{ borderRadius: '16px' }}
        />
      </Styled.MapWrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.TitleWrapper>
          <Styled.Title>Точки осмотра</Styled.Title>
          {ContextualHelpIcon}
        </Styled.TitleWrapper>
        {isDisplayTitleActionButton && (
          <ButtonLink
            onClick={() => {
              goToMapCreatePoint();
            }}
            color="accent"
          >
            + Добавить или изменить точки
          </ButtonLink>
        )}
      </Styled.Header>

      <Styled.MapWrapper $marginTop={'24px'}>
        <Map
          globalConfig={{
            mapKey: 'taskInspectionPointsMap',
            markersConfig: {
              getNewMarkerOnClick,
            },
            fieldIndicesOptions: {},
          }}
          style={{ borderRadius: '16px' }}
        />
      </Styled.MapWrapper>

      {sortedPointList?.length ? (
        <Styled.InstanceListWrapper>
          <ListOfChecklistInstance
            mode={'fullscreen'}
            variant="fullscreen"
            instList={sortedPointList.map(({ element }) => element)}
            type={'point'}
            title={null}
            isChangesCanBeUndone
          />
        </Styled.InstanceListWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

InspectionPoints.displayName = 'InspectionPoints';

export default observer(InspectionPoints);
