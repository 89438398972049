import { area } from '@turf/turf';

import toTurfPolygon from './toTurfPolygon';

/**
 * Вычисляет площать полигона в гектарах
 */
function calculateArea(leafletPolygon: L.Polygon) {
  const polygon = toTurfPolygon(leafletPolygon);

  return area(polygon) / 10000;
}

export default calculateArea;
