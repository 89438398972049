import { useModal, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { List } from 'react-virtualized';

import { Colors } from '../../../../../shared/constans/colors';
import { useStore } from '../../../../../shared/utils/IoC';
import { CreationFieldItem } from '../../components/CreationgFieldItem/CreationFieldItem';
import { CreateFieldsController } from '../../mobx/controllers';
import { CreateFieldsStore } from '../../mobx/stores';
import AddPointSvg from '../../static/map-add-entity-mock.svg';
import {
  FIELDS_CREATE_MODALS_UNIQUE_KEY,
  fieldsCreateModalConfigList,
} from '../../modals/create/configs/fieldsCreateModalsConfig';
import { FieldsListWrapper } from '../fieldsListWrapper/fields.list.wrapper';

import {
  AddFirstPointInformation,
  InformationDescription,
  InformationImage,
  InformationTitle,
} from './style';
import { FooterContainer } from './components';

export const FieldsAdd = observer(() => {
  // Контроллеры
  const createFieldsController = useStore(CreateFieldsController);

  // Сторы
  const createFieldsStore = useStore(CreateFieldsStore);

  const { setSidebarDisabled } = useSidebar();

  const { registerModalList } = useModal();

  useEffect(() => {
    setSidebarDisabled(true);
    createFieldsController.initialize();

    return () => {
      createFieldsController.destroy();
      setSidebarDisabled(false);
    };
  }, []);

  useEffect(() => {
    registerModalList(fieldsCreateModalConfigList, FIELDS_CREATE_MODALS_UNIQUE_KEY);
  }, []);

  const { createdFieldsList } = createFieldsStore;

  const rowRender = ({ index, key, style }) => {
    const field = createdFieldsList[index];
    return (
      <div style={style} key={key}>
        <CreationFieldItem
          key={field.id}
          area={field.areaInHectare}
          name={field.name}
          onNameChange={value => createFieldsController.changeFieldName(field.id, value)}
          onDelete={() => createFieldsController.deleteField(field.id)}
          data-test-id={'fields-add-props'}
        />
      </div>
    );
  };

  const listHeight =
    createdFieldsList.length < 3 ? createdFieldsList.length * 92 : window?.innerHeight - 167;
  return (
    <FieldsListWrapper
      title="Добавление полей"
      footer={<FooterContainer />}
      dataTestId="fields-add-section"
    >
      <>
        {Boolean(createdFieldsList.length) && (
          <List
            rowCount={createdFieldsList.length}
            width={284}
            height={listHeight}
            rowHeight={92}
            row
            rowRenderer={rowRender}
            noRowsRenderer={() => <></>}
            scrollToAlignment="center"
          />
        )}

        {createFieldsStore.createdFieldsList.length < 3 ? (
          <AddFirstPointInformation>
            {!createFieldsStore.hasCreatedField && (
              <>
                <InformationImage src={AddPointSvg} />
                <InformationTitle data-test-id={'fields-add-polygon-points'}>
                  Проставьте точки контура поля на карте
                </InformationTitle>
                <InformationDescription data-test-id={'fields-add-info'}>
                  После замыкания контура можно будет добавить еще поля
                </InformationDescription>
              </>
            )}

            {createFieldsStore.hasCreatedField && (
              <InformationDescription data-test-id={'fields-add-info'} $color={Colors.darkGray}>
                Продолжайте рисовать новые поля в нужном количестве
              </InformationDescription>
            )}
          </AddFirstPointInformation>
        ) : null}
      </>
    </FieldsListWrapper>
  );
});
