import L, { LatLngBounds, LatLngExpression, PolylineOptions } from 'leaflet';
import { cloneDeep } from 'lodash';

import { normalizeCoordsForLeaflet } from '../../helpers';

type TCoordinates = LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][];

abstract class AbstractPolygon extends L.Polygon {
  public id: number;

  protected initialOptions: PolylineOptions;

  public initialBounds: LatLngBounds | null = null;

  constructor(coordinates: TCoordinates, options?: PolylineOptions) {
    const normalizedCoords = normalizeCoordsForLeaflet(coordinates);

    super(normalizedCoords, options);

    this.id = L.Util.stamp(this);
    this.initialOptions = cloneDeep(options);
    this.initialBounds = this.getBounds();
  }
}

export default AbstractPolygon;
