export const modalActionsData: {
  [x: string]: { name: string; color: 'default' | 'secondary' | 'primary' };
} = {
  cancel: {
    name: 'Нет, отменить',
    color: 'default',
  },
  accept: {
    name: 'Да, удалить',
    color: 'secondary',
  },
};

export const MAX_FILED_NAME_LENGTH = 18;
