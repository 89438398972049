import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { OperationsStore } from '../../stores/operations.store';
import { OperationsUIStore } from '../../stores/operations.ui.store';
import { FieldsStore } from '../../../fields/mobx';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { TasksController } from '../../controllers/tasks.controller';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';

import { Field, FieldImg, FieldName, FieldTasks, FieldArea, FieldInfo } from './style';

type Props = {
  field: OperationField;
  isActive: boolean;
};

export const OperationFieldsListItem: FC<Props> = observer(({ field, isActive }) => {
  const organizationsStore = useStore(OrganizationsStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const store = useStore(OperationsStore);
  const uiStore = useStore(OperationsUIStore);
  const fieldsStore = useStore(FieldsStore);
  const tasksController = useStore(TasksController);

  const handleFieldClick = async (fieldId: string) => {
    uiStore.selectMarkId(fieldId);
    store.selectOperationFieldId(fieldId);
    tasksController.clear();
    tasksController.resetPageSettings();
    await tasksController.fetchTasks({
      organizationId: organizationsStore.selectedOrganizationId,
      seassonCultureId: seasonCultureStore.selectedCultureId,
      operationId: store.selectedOperationId,
      fieldId: store.selectedOperationFieldId,
      noCulture: seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
    });
  };

  const selectedField = useMemo(() => {
    return fieldsStore.getFieldById(field?.fieldId);
  }, [field?.fieldId]);

  return (
    <Field
      onClick={handleFieldClick.bind(this, field.fieldId)}
      data-test-id={'operation-field-item'}
      isActive={isActive}
    >
      <FieldImg src={selectedField?.icon?.downloadUrl} data-test-id={'operation-field-item-img'} />
      <FieldInfo>
        <FieldName data-test-id={'operation-field-item-name'}>{field.fieldName}</FieldName>
        <FieldTasks data-test-id={'operation-field-item-tasks'}>{field.tasks} задач</FieldTasks>
      </FieldInfo>
      <FieldArea data-test-id={'operation-field-item-area'}>
        {`${selectedField?.area ? toFixedWithCeilBackEnd(selectedField?.area) : '0'} га`}
      </FieldArea>
    </Field>
  );
});
