import { intersect } from '@turf/turf';
import _ from 'lodash';

import { BasePolygon } from '../../../../shared/features/map/utils/MapElements';

const getIntersectingLayers = (
  comparableLayer: BasePolygon,
  layerList: BasePolygon[]
): BasePolygon[] => {
  const collectionOfIntersectingLayers: Map<number, BasePolygon> = new Map<number, BasePolygon>();

  const comparableLayerId = comparableLayer.id;
  const comparableLayerGeoJSON = comparableLayer.toGeoJSON();

  layerList.forEach(layer => {
    const layerGeoJSON = layer.toGeoJSON();

    const isExactlyThisLayer = comparableLayerId === layer.id;
    if (isExactlyThisLayer) {
      return;
    }

    const haveEqualGeometry = _.isEqual(comparableLayerGeoJSON?.geometry, layerGeoJSON?.geometry);

    if (haveEqualGeometry) {
      collectionOfIntersectingLayers.set(comparableLayerId, comparableLayer);
      collectionOfIntersectingLayers.set(layer.id, layer);
    }

    if (intersect(comparableLayerGeoJSON, layerGeoJSON)) {
      collectionOfIntersectingLayers.set(comparableLayerId, comparableLayer);
      collectionOfIntersectingLayers.set(layer.id, layer);
    }
  });

  return Array.from(collectionOfIntersectingLayers.values());
};

export default getIntersectingLayers;
