import { Field } from '../../../../../../api/models/field.model';
import { MapCoreController } from '../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { PopupPages } from '../../../../constants/popup.pages';
import { EFieldsMode } from '../../../../modules/fields/constants';
import { FieldsUIStore, SharedFieldsStore } from '../../../../modules/fields/mobx';
import { COMMON_OPTIONS_SET, SELECTED_FIELD_OPTIONS_SET } from '../../../TopPanelControls/utils';
import { CultureZoneFillStrategy } from '../../modules/CultureZonesFill/mobx';
import IndicesFillStrategy from '../../modules/IndicesFill/mobx/strategy/IndicesFill.strategy';
import { FieldFillStore } from '../index';

@provide.transient()
class FieldFillController {
  @lazyInject(FieldFillStore)
  private store: FieldFillStore;

  @lazyInject(SharedFieldsStore)
  private sharedFieldsStore: SharedFieldsStore;

  @lazyInject(FieldsUIStore)
  private fieldsUIStore: FieldsUIStore;

  @lazyInject(MapCoreController)
  protected mapCoreController: MapCoreController;

  /**
   * Устанавливает стратегию заполнения поля.
   * Перед установкой выполняет сброс предыдущей стратегии.
   */
  public setFillStrategy(value: EFieldFill, field?: Field) {
    const activeField = field ?? this.sharedFieldsStore.selectedField;
    const activeLayer = this.mapCoreController.getMapLayerById(activeField?.polyId);

    if (this.store.strategy) {
      this.store.strategy.reset();
    }

    if (value === EFieldFill.Ndvi || value === EFieldFill.Msavi) {
      this.store.strategy = new IndicesFillStrategy(value, activeField?.id, activeLayer);
      return;
    }

    if (value === EFieldFill.Cultures) {
      this.store.strategy = new CultureZoneFillStrategy(activeLayer);
      return;
    }

    this.store.strategy = null;
    this.store.fieldFillValue = EFieldFill.None;
    activeLayer?.openTooltip();
  }

  public getFillOptionsSet() {
    const { selectedField } = this.sharedFieldsStore;

    if (this.fieldsUIStore.isCultureZonePopupOpen) {
      return [];
    }

    return selectedField ? SELECTED_FIELD_OPTIONS_SET : COMMON_OPTIONS_SET;
  }

  public showTooltipAccordion() {
    const isCultureZoneStrategy = this.store.strategy instanceof CultureZoneFillStrategy;
    const isEmptyStrategy = !this.store.strategy;

    const modesToSkipTooltip = [
      EFieldsMode.EDIT,
      EFieldsMode.FIELD_SEASONS,
      EFieldsMode.FIELD_CULTURE_ZONES,
      EFieldsMode.FIELD_CULTURE_ZONE_EDIT,
    ];

    const isNotInSkippedMode = !modesToSkipTooltip.includes(this.sharedFieldsStore.fieldsMode);

    return (isCultureZoneStrategy || isEmptyStrategy) && isNotInSkippedMode;
  }
}

export default FieldFillController;
