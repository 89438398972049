import { observer } from 'mobx-react';
import React from 'react';

import { GoogleSearch } from '../../../shared/components/GoogleSearch';
import { useStore } from '../../../shared/utils/IoC';
import { FieldsFacadeController } from '../../modules/fields/mobx';
import { FieldFillControls } from '../FieldFillControls';
import { FieldFillStore } from '../FieldFillControls/mobx';

import Styled from './style';
import { VALUES_TO_HIDE_THE_SEARCH_BAR } from './utils';

export const TopPanelControls = observer(() => {
  // Контроллеры
  const fieldsFacadeController = useStore(FieldsFacadeController);

  // Сторы
  const fieldFillStore = useStore(FieldFillStore);

  const showGoogleSearch = () => {
    const { selectedField } = fieldsFacadeController;
    const isHideValue = VALUES_TO_HIDE_THE_SEARCH_BAR.includes(fieldFillStore.fieldFillValue);

    return !(selectedField && isHideValue);
  };

  return (
    <Styled.ControlPanelWrapper>
      {showGoogleSearch() && (
        <Styled.ControlPanelItem isMarginRight>
          <GoogleSearch />
        </Styled.ControlPanelItem>
      )}

      <FieldFillControls />
    </Styled.ControlPanelWrapper>
  );
});

// import { Accordeon } from '@farmlink/farmik-ui';
// import { observer } from 'mobx-react';
// import React, { FC, useEffect, useRef, useState } from 'react';
// import useOnclickOutside from 'react-cool-onclickoutside';
//
// import { GoogleSearch } from '../../../shared/components/GoogleSearch';
// import { MapController } from '../../../shared/features/map/mobx';
// import { useStore } from '../../../shared/utils/IoC';
// import { CultureFillEnum } from '../../constants/culture.fill.enum';
// import { LabelFieldFillEnum } from '../../constants/label.fill.enum';
// import { FieldsFacadeController } from '../../modules/fields/mobx/controllers';
// import { Timeline } from '../Timeline/Timeline';
//
// import { TopPanelControlsController, TopPanelControlsStore } from './mobx';
// import { AccordeonWrapper, ControlPanelItem, ControlPanelWrapper } from './style';
// import { LABEL_FIELD_FILL_OPTIONS_SET } from './utils';
//
// export const TopPanelControls: FC = observer(() => {
//   // Контроллеры
//   const topPanelControlsController = useStore(TopPanelControlsController);
//   const fieldsFacadeController = useStore(FieldsFacadeController);
//   const mapController = useStore(MapController);
//
//   // Сторы
//   const topPanelControlsStore = useStore(TopPanelControlsStore);
//
//   const [openArr, setOpenArr] = useState([false, false]);
//
//   const accWrapper = useRef(null);
//
//   const accordeonWrapperRef = useOnclickOutside(() => {
//     setOpenArr([false, false]);
//   });
//
//   useEffect(() => {
//     const { selectedField, prevSelectedField, displayedFields } = fieldsFacadeController;
//     const { fieldFillType } = topPanelControlsStore;
//
//     let cultureZonesIds = [];
//
//     const hasSelectedField = Boolean(selectedField);
//     const hasPrevSelectedField = Boolean(prevSelectedField);
//     const hasDisplayedFields = Boolean(displayedFields.length);
//
//     if (hasSelectedField && !hasPrevSelectedField) {
//       topPanelControlsController.setFieldFill(CultureFillEnum.Ndvi);
//       return;
//     }
//
//     // Выбрано поле, также выбрано заполнение поля
//     if (hasSelectedField && fieldFillType !== CultureFillEnum.None) {
//       topPanelControlsController.setFieldFill(fieldFillType);
//       return;
//     }
//
//     // Отсутсвует выбранное поле и есть поля на карте
//     if (hasDisplayedFields) {
//       cultureZonesIds = topPanelControlsController.setFieldFill(CultureFillEnum.Fill);
//       return;
//     }
//
//     return () => {
//       cultureZonesIds?.forEach(id => {
//         mapController.polygon.actions.remove(id);
//       });
//     };
//   }, [fieldsFacadeController.selectedField, fieldsFacadeController.displayedFields]);
//
//   return (
//     <ControlPanelWrapper>
//       {topPanelControlsController.showGoogleSearchPanel() && (
//         <ControlPanelItem isMarginRight>
//           <GoogleSearch />
//         </ControlPanelItem>
//       )}
//
//       {topPanelControlsController.showCultureFillPanel() && (
//         <ControlPanelItem ref={accordeonWrapperRef}>
//           <AccordeonWrapper ref={accWrapper}>
//             <Accordeon
//               onOpen={() => {
//                 setOpenArr([true, false]);
//               }}
//               onHover={() => 1}
//               onChange={value => {
//                 topPanelControlsController.setFieldFill(value as CultureFillEnum);
//               }}
//               onMouseOut={() => 1}
//               options={topPanelControlsController.getCultureFillOptionsSet()}
//               placeholder={'Выберите заливку'}
//               isOpen={openArr[0]}
//               dataTestId={'fill-switcher'}
//               value={topPanelControlsStore.fieldFillType}
//             />
//           </AccordeonWrapper>
//           {topPanelControlsController.showTooltipLabelPanel() && (
//             <AccordeonWrapper ref={accWrapper}>
//               <Accordeon
//                 onOpen={() => {
//                   setOpenArr([false, true]);
//                 }}
//                 onHover={() => 1}
//                 onChange={(value: LabelFieldFillEnum) => {
//                   topPanelControlsController.selectLabelFieldFillValue(value);
//                 }}
//                 onMouseOut={() => 1}
//                 options={LABEL_FIELD_FILL_OPTIONS_SET}
//                 placeholder={'Выберите подпись'}
//                 isOpen={openArr[1]}
//                 dataTestId={'label-field-fill-switcher'}
//                 value={topPanelControlsStore.fieldTooltipType}
//               />
//             </AccordeonWrapper>
//           )}
//         </ControlPanelItem>
//       )}
//
//       {topPanelControlsController.showTimeLine() && (
//         <ControlPanelItem fullWidth isMarginRight>
//           <Timeline />
//         </ControlPanelItem>
//       )}
//     </ControlPanelWrapper>
//   );
// });
