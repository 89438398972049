import { isEmpty, omit } from 'lodash';
import { AxiosError } from 'axios';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { TaskStore } from '../../stores';
import {
  FieldsService,
  OperationsService,
  SeasonsService,
  TasksService,
} from '../../../../../../shared/mobx/services/as-fields';
import { OperationCulture } from '../../../../../../../api/models/operations/operation.culture';
import {
  createCultureSelectOptionList,
  createOperationSelectOptionList,
  createUserSelectOptionList,
} from '../../../../../../shared/utils/helpers/selectOptions';
import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { TGetOperationListReq } from '../../../../../../../api/endpoints/operations/operations.get';
import { OrganizationsService } from '../../../../../../shared/mobx/services/da-profile';
import { TypeUser } from '../../../../../../../api/models/user.model';
import { ChecklistInstancesStore } from '../../../../operationsAndTasks/stores/checklist.instances.store';
import { CultureZone, Field } from '../../../../../../../api/models/field.model';
import { GetFieldByIdReq } from '../../../../../../../api/endpoints/fields/get.field.by.id';
import {
  ETaskAction,
  ETaskStatus,
  ITask,
  ITaskCreate,
  ITaskItem,
} from '../../../../../../../api/models/as-fields/task/task.model';
import { TSetStatusReq } from '../../../../../../../api/endpoints/tasks/set.status';
import { ICultureZoneExtendedByField } from '../../../models';
import useInspectionPointsActionsHook from '../../../components/InspectionPoints/hooks/useInspectionPointsActions/useInspectionPointsActions.hook';
import { PermissionsBuilderController as PermissionsController } from '../../../../../../shared/features/PermissionsBuilder/mobx/controllers';
import TaskValidatingService from '../../../services/task.validatingService';
import { OperationsStore } from '../../../../operations/stores/operations.store';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { SeasonsController } from '../../../../../controllers/seasons/seasons.controller';
import { ISelectOption } from '../../../../../../../types/selectOption';
import { TaskChangesService, TaskCreateService, TaskDefaultValuesService } from '../../services';
import { createZoneName, toDouble } from '../../stores/TaskStore/Task.store';
import { TableBuilderController } from '../../../../../../shared/features/TableBuilder/mobx/controllers';
import { ETasksTableBuilderId } from '../../../utils/constants';
import { NO_CULTURE_SELECT_OPTION } from '../../../../../../shared/utils/constants/selectOptions';
import { TypeApiRequest } from '../../../../../../shared/utils/axios2';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';

@provide.transient()
class TaskController {
  @lazyInject(TaskCreateService)
  protected taskCreateService: TaskCreateService;

  @lazyInject(TaskDefaultValuesService)
  protected defaultValuesService: TaskDefaultValuesService;

  @lazyInject(TaskChangesService)
  protected taskChangesService: TaskChangesService;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  @lazyInject(ChecklistInstancesStore)
  protected checklistsStore: ChecklistInstancesStore;

  @lazyInject(OperationsStore)
  protected organizationsStore: OperationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(OperationsService)
  protected operationsService: OperationsService;

  @lazyInject(SeasonsService)
  protected seasonsService: SeasonsService;

  @lazyInject(OrganizationsService)
  protected organizationsService: OrganizationsService;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  @lazyInject(PermissionsController)
  protected permissionsController: PermissionsController<ETaskAction>;

  @lazyInject(TaskValidatingService)
  taskValidatingService: TaskValidatingService;

  @lazyInject(SeasonsController)
  protected seasonsController: SeasonsController;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  get fieldDefaultValueList() {
    return this.defaultValuesService.fieldDefaultValueList;
  }

  get planStartDateDefaultValue() {
    return this.defaultValuesService.planStartDate;
  }

  get planEndDateDefaultValue() {
    return this.defaultValuesService.planEndDate;
  }

  get dateRangeOptions() {
    return this.defaultValuesService.dateRangeOptions;
  }

  fetchTask = async (
    taskId: string,
    options?: { isUpdatePermissions?: boolean }
  ): Promise<ITask> => {
    const { setSelectedTask } = this.taskStore;
    const { getTask } = this.tasksService;

    const task = await getTask({ id: taskId });

    if (task) {
      setSelectedTask(task);

      if (options?.isUpdatePermissions) {
        // Добавляем экшены в стор для экшенов.
        this.permissionsController.addPermissionList(task.id, task.availableActions, {
          isClearPreviousList: true,
        });
      }

      return task;
    }
  };

  fetchCultureZoneList = async (
    payload: TypeApiRequest<'getCultureZoneList'>
  ): Promise<CultureZone[]> => {
    const response = await this.fieldsService.getCultureZoneList(payload);

    if (response?.content) {
      return response.content;
    }

    return [];
  };

  fetchOperationCultureList = async (
    year: number,
    organizationId: string
  ): Promise<OperationCulture[]> => {
    const formattedOrganizationId = organizationId === 'my' ? undefined : organizationId;

    const response = await this.seasonsService.getCultureList({
      year,
      organizationId: formattedOrganizationId,
    });

    if (response?.cultureAndVarietyList) {
      this.taskStore.setOperationCultureList(response.cultureAndVarietyList);

      const cultureList = response.cultureAndVarietyList.map(({ culture }) => culture);
      const cultureOptionList = createCultureSelectOptionList(cultureList as any);

      const optionList = response?.fieldsWithoutCulturesCount
        ? [NO_CULTURE_SELECT_OPTION, ...cultureOptionList]
        : cultureOptionList;

      this.taskStore.setCultureOptionList(optionList);

      return response.cultureAndVarietyList;
    } else {
      return [];
    }
  };

  fetchOperationList = async (payload: TGetOperationListReq): Promise<Operation[]> => {
    const { setOperationList, setOperationOptionList } = this.taskStore;
    const { getOperationList } = this.operationsService;

    const operationList = await getOperationList({ ...payload, page: 0, size: 2000 });

    if (operationList) {
      setOperationOptionList(
        createOperationSelectOptionList(operationList, { isAddInitialModel: true })
      );
      setOperationList(operationList);
    } else {
      return [];
    }
  };

  fetchUserList = async (organizationId: string): Promise<TypeUser[]> => {
    if (organizationId === 'my') {
      return [];
    }

    const userList = await this.organizationsService.getUserList({
      organizationID: organizationId,
      status: 'ACTIVE',
    });

    if (userList) {
      this.taskStore.setUserList(userList);
      this.taskStore.setUserOptionList(createUserSelectOptionList(userList));

      return userList;
    } else {
      return [];
    }
  };

  fetchField = async (payload: GetFieldByIdReq): Promise<Field> => {
    const { setSelectedField } = this.taskStore;
    const { getField } = this.fieldsService;

    const field = await getField(payload);

    if (field) {
      setSelectedField(field);

      return field;
    }
  };

  fetchTaskData = (
    taskId: string,
    options: {
      displayZone: ReturnType<typeof useInspectionPointsActionsHook>['displayZone'];
      isEditMode?: boolean;
    }
  ): Promise<void> => {
    return this.fetchTask(taskId)
      .then(async fetchedTask => {
        // Добавляем экшены в стор для экшенов.
        this.permissionsController.addPermissionList(fetchedTask.id, fetchedTask.availableActions, {
          isClearPreviousList: true,
        });

        await Promise.all([
          this.fetchField({
            fieldId: fetchedTask.field.id,
            seasonYear: fetchedTask.operationInfo.seasonYear,
          }),
          this.fetchOperationCultureList(
            fetchedTask.operationInfo.seasonYear,
            fetchedTask.organizationId
          ),
          this.fetchUserList(fetchedTask.organizationId),
          this.fetchOperationList({
            seasonYear: fetchedTask.operationInfo.seasonYear,
            organizationId: fetchedTask.organizationId,
            cultureId: fetchedTask?.culture?.id,
          }),
          this.fetchTaskEventList(fetchedTask.id),
        ]);

        // В целях обратной совместимости. Ранее созданные задачи привязывались к полю, а не к КЗ.
        const selectedZone = fetchedTask?.cultureZone ?? fetchedTask?.field;

        if (selectedZone) {
          options.displayZone(selectedZone, {
            isAllowedToAddPoints: options?.isEditMode,
          });
        }
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  fetchDataToCreateTask = async (organizationId: string): Promise<void> => {
    if (!this.seasonsStore.selectedSeason) {
      await this.seasonsController.fetchMySeasons();
    }

    this.createInitialTaskData();

    await Promise.all([
      this.fetchOperationCultureList(Number(this.seasonsStore.selectedSeason), organizationId),
      this.fetchUserList(organizationId),
    ]);
  };

  createListOfZoneExtendedByField = async (
    payload: TypeApiRequest<'getCultureZoneList'>
  ): Promise<ICultureZoneExtendedByField[]> => {
    const zoneList = await this.fetchCultureZoneList(payload);

    const extendedZoneList = await Promise.all(
      zoneList.map(zone => this.createZoneExtendedByField(zone))
    );

    this.taskStore.setListOfZoneExtendedByField(extendedZoneList, { isClearPreviousList: true });

    const fieldOptionList = this.createFieldOptionList(extendedZoneList);

    this.taskStore.setFieldOptionList(fieldOptionList);

    return extendedZoneList;
  };

  createFieldOptionList = (extendedZoneList: ICultureZoneExtendedByField[]): ISelectOption[] => {
    return extendedZoneList.map<ISelectOption>(zone => {
      const selectedField = zone?.field;
      const fieldName = createZoneName(selectedField?.name, selectedField?.area);

      if (zone?.name) {
        return {
          value: zone.id,
          label: `${fieldName}. ${zone.name}. ${toDouble(zone.area)} га`,
        };
      }

      return {
        value: zone.id,
        label: `${fieldName}. ${toDouble(zone.area)} га`,
      };
    });
  };

  createZoneExtendedByField = async (zone: CultureZone): Promise<ICultureZoneExtendedByField> => {
    const { getField } = this.fieldsService;

    const fetchedField = await getField({ fieldId: zone.fieldId, seasonYear: zone.seasonYear });

    return {
      ...zone,
      field: fetchedField,
    };
  };

  createTask = async (organizationId: string): Promise<ITask> => {
    const payload = omit(this.taskStore.taskCreate, ['cultureId', 'bindingDate']);

    if (organizationId === 'my') {
      payload.assigneeId = this.profileStore.user.id;
    }

    if (payload?.assigneeId === 'noAssignee') {
      delete payload.assigneeId;
    }

    if (payload?.comment) {
      payload.comment = payload.comment.trim();
    }

    const createdTask = await this.tasksService.createTask(payload);

    if (createdTask) {
      return createdTask;
    }
  };

  createTaskList = async (): Promise<ITask[]> => {
    const payload = omit(this.taskStore.taskCreate, [
      'cultureId',
      'cultureZoneId',
      'fieldId',
      'bindingDate',
    ]);

    if (payload?.assigneeId === 'noAssignee') {
      delete payload.assigneeId;
    }

    const createdTaskList = await this.tasksService.createTaskList(payload);

    if (createdTaskList?.length) {
      this.taskStore.clearIsFormChanged();
      this.taskStore.setCreatedTaskList(createdTaskList);
      this.taskStore.setTasksCreationSteps('view-created');

      this.tableBuilderController.addElementList(
        ETasksTableBuilderId.CreatedTasks,
        createdTaskList,
        'id',
        { isClearPreviousList: true }
      );

      return createdTaskList;
    }
  };

  updateTask = async (): Promise<boolean> => {
    const { selectedTask, taskUpdate, setSelectedTask } = this.taskStore;
    const { updateTask } = this.tasksService;

    if (isEmpty(taskUpdate)) {
      return true;
    }

    const validatedTaskUpdate = this.taskValidatingService.prepareTaskFields(taskUpdate);

    const updatedTask = await updateTask({ id: selectedTask.id, ...validatedTaskUpdate });

    if (updatedTask) {
      setSelectedTask(updatedTask);
      this.fetchTaskEventList(updatedTask.id);

      return true;
    }
  };

  sendTaskStatus = async (payload: TSetStatusReq): Promise<boolean> => {
    const { setStatus } = this.tasksService;
    const { changeSelectedTask } = this.taskStore;

    const isSuccess = await setStatus(payload);

    if (isSuccess) {
      const task = await this.fetchTask(payload.taskId, { isUpdatePermissions: true });
      changeSelectedTask(task);
      this.fetchTaskEventList(task.id);

      return true;
    }
  };

  createInitialTaskData = (): void => {
    const initialTaskCreate: ITaskCreate = {
      planStartDate: '',
      planEndDate: '',
      comment: '',
      status: ETaskStatus.New,
      assigneeId: '',
      operationId: '',
      cultureZoneId: '',
      ...(this.taskStore.taskCreate ?? {}),
    };

    this.taskStore.setTaskCreate(initialTaskCreate);
  };

  changeCulture = (cultureId: string): void => {
    const data: Partial<ITaskCreate> = {
      cultureId,
      operationId: '',
      planStartDate: null,
      planEndDate: null,
    };

    if (this.taskStore.isCreateOne) {
      data.cultureZoneId = '';
    }

    if (this.taskStore.isCreateMultiple) {
      data.taskItems = [];
    }

    this.taskChangesService.changeTaskCreateData(data);
  };

  fetchOperationListAfterCultureChange = async (organizationId: string): Promise<void> => {
    const cultureId = this.taskStore.taskCreate?.cultureId;
    const formattedOrganizationId = organizationId === 'my' ? '' : organizationId;

    const payload: TypeApiRequest<'getOperations'> = {
      organizationId: formattedOrganizationId,
      seasonYear: Number(this.seasonsStore.selectedSeason),
    };

    if (cultureId === NO_CULTURE_SELECT_OPTION.value) {
      payload.noCulture = true;
    } else {
      payload.cultureId = this.taskStore.taskCreate?.cultureId;
    }

    await this.fetchOperationList(payload);
  };

  changeOperation = (operationId: string): void => {
    const selectedOperation = this.taskStore.getOperationOption(operationId)?.initialModel;
    const planStartDate = this.defaultValuesService.getPresetPlanStartDate(selectedOperation);

    const data: Partial<ITaskCreate> = {
      operationId,
      planStartDate,
      planEndDate: planStartDate,
    };

    if (this.taskStore.isCreateOne) {
      data.cultureZoneId = '';
    }

    if (this.taskStore.isCreateMultiple) {
      data.taskItems = [];
    }

    this.taskChangesService.changeTaskCreateData(data);
  };

  createListOfZoneExtendedByFieldAfterOperationChange = async (
    organizationId: string
  ): Promise<void> => {
    const operationId = this.taskStore.taskCreate?.operationId;
    const formattedOrganizationId = organizationId === 'my' ? '' : organizationId;

    const payload: TypeApiRequest<'getCultureZoneList'> = {
      organizationId: formattedOrganizationId,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      operationId,
      byTasks: false,
      withGeometry: true,
    };

    if (this.taskStore.taskCreate.cultureId === NO_CULTURE_SELECT_OPTION.value) {
      payload.noCulture = true;
    } else {
      payload.cultureId = this.taskStore.taskCreate.cultureId;
    }

    await this.createListOfZoneExtendedByField(payload);
  };

  changeField = (cultureZoneId: string): void => {
    const zoneExtendedByField = this.taskStore.getZoneExtendedByField(cultureZoneId);

    this.taskChangesService.changeTaskCreateData({
      cultureZoneId,
      fieldId: zoneExtendedByField?.fieldId ?? '',
    });
  };

  changeFieldList = (selectedFieldList: ISelectOption[]): void => {
    const taskItemList = selectedFieldList.map<ITaskItem>(({ value }) => ({
      fieldId: this.taskStore.getZoneExtendedByField(value)?.fieldId,
      cultureZoneId: value,
    }));

    if (this.taskStore.isCreateOne || this.taskStore.isCreateMultiple) {
      this.taskChangesService.changeTaskCreateData({ taskItems: taskItemList });
    }
  };

  changeAssignee = (assigneeId: string): void => {
    if (this.taskStore.isEditMode) {
      this.taskStore.setTaskUpdateData({ assigneeId });
    }

    if (this.taskStore.isCreateOne || this.taskStore.isCreateMultiple) {
      this.taskChangesService.changeTaskCreateData({ assigneeId });
    }
  };

  changePlanStartDate: TaskChangesService['changePlanStartDate'] = planStartDate => {
    this.taskChangesService.changePlanStartDate(planStartDate);
  };

  changePlanEndDate: TaskChangesService['changePlanEndDate'] = planEndDate => {
    this.taskChangesService.changePlanEndDate(planEndDate);
  };

  changeComment = (comment: string): void => {
    if (this.taskStore.isEditMode) {
      this.taskStore.setTaskUpdateData({ comment });
    }

    if (this.taskStore.isCreateOne || this.taskStore.isCreateMultiple) {
      this.taskChangesService.changeTaskCreateData({ comment });
    }
  };

  createPreviewTasks = (): void => {
    this.taskCreateService.addPreviewTasks();
    this.taskStore.setTasksCreationSteps('preview');
  };

  initiatePreviewTasksTable = (): void => {
    this.taskCreateService.addPreviewTableConfig();
  };

  initiateCreatedTasksTable = (): void => {
    this.taskCreateService.addCreatedTasksTableConfig();
  };

  fetchTaskEventList = async (taskId: string) => {
    const eventList = await this.tasksService.getTaskEventList({ id: taskId });

    this.taskStore.setTaskEventList(eventList);

    return eventList;
  };

  clearTaskStore = (): void => {
    this.taskStore.clearTaskStore();
  };
}

export default TaskController;
