import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { BasePolygon } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapMarkerEventsService from '../../MapMarker/services/MapMarkerEvents.service';
import MapMarkerStore from '../../MapMarker/stores/MapMarker.store';
import MapPolygonService from '../../MapPolygon/services/MapPolygon.service';
import MapPolygonStore from '../../MapPolygon/stores/MapPolygon.store';

import MapDrawerEventsService from './MapDrawerEvents.service';

@provide.transient()
class MapDrawerService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  @lazyInject(MapMarkerStore)
  private markerStore: MapMarkerStore;

  @lazyInject(MapPolygonService)
  private polygonService: MapPolygonService;

  @lazyInject(MapDrawerEventsService)
  private drawerEventsService: MapDrawerEventsService;

  @lazyInject(MapMarkerEventsService)
  private markerEventsService: MapMarkerEventsService;

  public enableDrawPolygon(options?: L.PM.DrawModeOptions): void {
    // Не включаем, если включен режим рисования маркеров
    if (this.markerStore.isDrawEnabled) {
      return;
    }

    if (!this.coreStore.instance?.pm) {
      return;
    }

    this.coreStore.instance.pm.enableDraw('Polygon', options);

    this.polygonStore.isDrawEnabled = true;
  }

  public disableDrawPolygon(): void {
    if (!this.coreStore.instance?.pm) {
      return;
    }

    this.coreStore.instance.pm.disableDraw();
    this.polygonStore.isDrawEnabled = false;
  }

  public toggleEditPolygon(
    polygon: BasePolygon | undefined,
    action: 'enable' | 'disable',
    options?: L.PM.EditModeOptions
  ): void {
    if (!polygon) {
      return;
    }

    if (action === 'enable') {
      polygon.setGeomanModule(true);
      polygon.pm?.enable({
        allowSelfIntersection: false,
        preventMarkerRemoval: true,
        snappable: true,
        ...options,
      });

      this.drawerEventsService.registerEditPolygonEvents(polygon);

      return;
    }

    polygon.pm?.disable();
    polygon.setGeomanModule(false);

    this.drawerEventsService.unregisterEditPolygonEvents(polygon);
  }

  public resetDrawPolygon(): void {
    const drawnLayers = this.coreStore.instance?.pm.getGeomanDrawLayers() as L.Layer[];

    // @ts-ignore
    const ids = drawnLayers.map(item => item._leaflet_id);
    this.polygonService.removeManyByIds(ids);

    this.enableDrawPolygon();
  }

  public enableDrawMarker(): void {
    // Не включать, если включен режим рисования полигонов
    if (this.polygonStore.isDrawEnabled) {
      return;
    }

    this.markerEventsService.register();
    this.markerStore.isDrawEnabled = true;
  }

  public disableDrawMarker(): void {
    this.markerEventsService.unregister();
    this.markerStore.isDrawEnabled = false;
  }
}

export default MapDrawerService;
