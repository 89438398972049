import { Dropdown, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';

import { TDropdownConfig } from '../../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import {
  ChecklistInstancesStore,
  EChecklistMode,
} from '../../../../../stores/checklist.instances.store';
import { useTasksParams } from '../../../../../../tasks/hooks';
import { TaskStore } from '../../../../../../tasks/mobx/stores';
import { ChecklistsStore } from '../../../../../../tasks/modules/Checklists/mobx/stores';
import { Container } from '../../../../../../../../shared/features/UI/Container';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import { warnBeforePhenophaseChangesConfigModalConfig } from './modals';

const Intensity: FC = () => {
  const getDataTestId = useDataTestIdV2('checklists__phenophase-selection');

  const { selectedTask } = useStore(TaskStore);
  const { mode } = useStore(ChecklistsStore);
  const { selectedInstance, selectedIntensity } = useStore(ChecklistInstancesStore);

  const {
    intensityOptionList,
    intensityDefaultValue,
    fetchIntensityByTaskId,
    selectIntensity,
    getTypeForChecklist,
  } = useStore(ChecklistInstancesController);

  const { orgId, taskId } = useTasksParams();
  const { registerModalList, openModalByModalId, closeModal } = useModal();

  const [isSuccessfulResult, setIsSuccessfulResult] = useState<boolean | null>(null);

  useEffect(() => {
    registerModalList([warnBeforePhenophaseChangesConfigModalConfig], 'intensity-selection');
  }, []);

  useEffect(() => {
    (async () => {
      if (!selectedTask?.intensityRequired) return;

      await fetchIntensityByTaskId(taskId);
    })();
  }, []);

  const handleIntensityChange: TDropdownConfig['field']['onChange'] = intensityId => {
    if (selectedIntensity) {
      setIsSuccessfulResult(null);

      const handleSuccess = async (): Promise<void> => {
        await selectIntensity({
          organizationId: orgId,
          intensityId,
          type: getTypeForChecklist(selectedInstance),
        });

        setIsSuccessfulResult(true);
      };

      const handleDeny = (): void => {
        closeModal();
        setIsSuccessfulResult(false);
      };

      openModalByModalId('warnBeforePhenophaseChanges', null, handleSuccess, handleDeny);
    } else {
      selectIntensity({
        organizationId: orgId,
        intensityId,
        type: getTypeForChecklist(selectedInstance),
      });
    }
  };

  const dropdownConfig: TDropdownConfig = {
    field: {
      onChange: handleIntensityChange,
      defaultValue: intensityDefaultValue,
      placeholder: 'Выберите фенофазу',
      icons: {
        clear: {},
      },
    },
    body: {
      optionList: intensityOptionList,
    },
    visual: {
      label: 'Фенофаза',
      isBlocked: mode === EChecklistMode.View,
    },
    validation: {
      modal: {
        isShowWarning: Boolean(selectedIntensity),
        modalResult: isSuccessfulResult,
      },
    },
    other: {
      dataTestId: getDataTestId('dropdown')['data-test-id'],
    },
  };

  return (
    <>
      {selectedTask?.intensityRequired && (
        <Container header={{ title: 'Фенофаза' }} dataTestId={getDataTestId()['data-test-id']}>
          <Dropdown config={dropdownConfig} />
        </Container>
      )}
    </>
  );
};

export default observer(Intensity);
