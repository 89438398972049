import { findIndex, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import { List } from 'react-virtualized';

import { Field } from '../../../../../../api/models/field.model';
import { DisplayFieldsController } from '../../mobx/controllers';
import { FieldsStore, FieldsUIStore, SharedFieldsStore } from '../../mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { FieldItem } from '../../components/FieldItem/FieldItem';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import {
  fieldsImportModalConfigList,
  FIELDS_IMPORT_MODALS_UNIQUE_KEY,
} from '../../modals/import/configs/fieldsImportModalsConfig';
import { FieldsListWrapper } from '../fieldsListWrapper/fields.list.wrapper';
import {
  FIELDS_EDIT_MODALS_UNIQUE_KEY,
  fieldsEditModalConfigList,
} from '../../modals/edit/configs/fieldsEditModalsConfig';
import { PopupPages } from '../../../../constants/popup.pages';

import { FooterContainer, NoItems, NoSeasons } from './components';

export const FieldsListing: FC<{ visible?: boolean }> = observer(({ visible = true }) => {
  // Контроллеры
  const displayFieldsController = useStore(DisplayFieldsController);

  // Сторы
  const organizationsStore = useStore(OrganizationsStore);
  const seasons = useStore(SeasonsStore);
  const fieldsStore = useStore(FieldsStore);
  const sharedFieldsStore = useStore(SharedFieldsStore);
  const fieldsUIStore = useStore(FieldsUIStore);

  const fieldListClickDetected = useRef<boolean>(false);

  const { registerModalList } = useModal();

  const hasSelectedSeason = Boolean(seasons.selectedSeason);

  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);

  const scrollToActive = () => {
    const index = findIndex(sortedFieldListByName, { id: sharedFieldsStore.selectedField.id });
    setSelectedFieldIndex(index);
  };

  const sortedFieldListByName = useMemo(() => {
    return sortBy(fieldsStore.fieldsList, f => f.name?.toLowerCase());
  }, [fieldsStore.fieldsList]);

  const isSelectedField = useCallback(
    (field: Field) => {
      return field.id === sharedFieldsStore.selectedField?.id;
    },
    [sharedFieldsStore.selectedField?.id]
  );

  useEffect(() => {
    if (sharedFieldsStore.selectedField) {
      if (!fieldListClickDetected.current) scrollToActive();
      if (fieldListClickDetected.current) fieldListClickDetected.current = false;
    }
  }, [sharedFieldsStore.selectedField?.id]);

  useEffect(() => {
    if (!seasons.selectedSeason || !organizationsStore.selectedOrganizationId) {
      return;
    }

    void displayFieldsController.initialize().then(() => {
      if (sharedFieldsStore.selectedField) {
        onSelectField(sharedFieldsStore.selectedField);
      }
    });

    return () => {
      displayFieldsController.destroy();
    };
  }, [seasons.selectedSeason, organizationsStore.selectedOrganizationId]);

  useEffect(() => {
    registerModalList(fieldsEditModalConfigList, FIELDS_EDIT_MODALS_UNIQUE_KEY);
    registerModalList(fieldsImportModalConfigList, FIELDS_IMPORT_MODALS_UNIQUE_KEY);
  }, []);

  const onSelectField = field => {
    fieldListClickDetected.current = true;
    displayFieldsController.selectField(field);
    // временный фикс, проверить после ухода на отдельный роут для сезонов
    if (fieldsUIStore.isSeasonsPopupOpen) {
      setTimeout(() => {
        fieldsUIStore.popupPageState = PopupPages.Seasons;
      });
    }
  };
  const rowRender = ({ index, key, style }) => {
    const field = sortedFieldListByName[index];
    return (
      <div style={style} key={key}>
        <FieldItem
          className={isSelectedField(field) && 'active'}
          dataTestId={`fields-list-section-item-${index}`}
          key={key}
          field={field}
          isActive={isSelectedField(field)}
          isLast={index === fieldsStore.fieldsCount - 1}
          onClick={() => onSelectField(field)}
        />
      </div>
    );
  };

  return (
    <>
      <FieldsListWrapper
        footer={<FooterContainer />}
        visible={visible}
        title="Поля"
        dataTestId="fields-list-section"
      >
        {hasSelectedSeason ? (
          <List
            rowCount={sortedFieldListByName.length}
            width={284}
            height={window?.innerHeight - 167}
            rowHeight={80}
            row
            rowRenderer={rowRender}
            noRowsRenderer={() => <NoItems />}
            scrollToIndex={selectedFieldIndex}
            scrollToAlignment="center"
          />
        ) : (
          <NoSeasons />
        )}
      </FieldsListWrapper>
    </>
  );
});
