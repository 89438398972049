import { Field } from '../../../../../../../api/models/field.model';
import { MapEventBus } from '../../../../../../shared/features/map/modules/MapCore';
import {
  MapDrawerController,
  MapPolygonController,
} from '../../../../../../shared/features/map/modules';
import {
  BasePolygon,
  FieldLayerGroup,
} from '../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../../../../constants/FieldTooltip.enum';
import { PopupPages } from '../../../../../constants/popup.pages';
import { EFieldsMode } from '../../../constants';
import drawOptions from '../../../utils/constants/DrawPolygonOptions.constant';
import { FIELD_POLYGON_OPTIONS } from '../../../utils/constants/PolygonOptions.constant';
import CreateFieldsService from '../../services/CreateFieldsService/CreateFields.service';
import { CreateFieldsStore } from '../../stores';
import { ECurrDrawPolygonStatus } from '../../stores/CreateFieldsStore/CreateFields.store';
import AbstractFieldsController from '../AbstractFieldsController/AbstractFields.controller';
import { PolygonValidator } from '../../../../../../shared/features/map/utils/validators';

@provide.singleton()
class CreateFieldsController extends AbstractFieldsController {
  @lazyInject(CreateFieldsStore)
  private store: CreateFieldsStore;

  @lazyInject(CreateFieldsService)
  private service: CreateFieldsService;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  @lazyInject(MapPolygonController)
  private mapPolygonController: MapPolygonController;

  public async initialize() {
    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.service.fetchFieldsList();

    // Инициализируем карту. Делаем связь полигон - поле
    await this.buildMap(fieldsList, this.getLayerGroupConfig);

    // Убираем заливку культруных зон и тултипы
    this.fieldFillController.setFillStrategy(EFieldFill.None);
    this.fieldTooltipController.setTooltipContent(EFieldTooltip.None);

    // Подготовка карты. Включаем рисование
    this.mapDrawerController.enableDrawPolygon(drawOptions);

    // Временно
    this.UIStore.popupPageState = PopupPages.None;
    this.sharedFieldsStore.fieldsMode = EFieldsMode.CREATE;
  }

  public destroy() {
    super.destroy();

    this.mapDrawerController.resetDrawPolygon();

    // удаляет поля из Store
    this.store.clearCreatedFieldsById();

    this.store.drawStatus = ECurrDrawPolygonStatus.FINISHED;
  }

  public changeFieldName(fieldId: number, value: string) {
    console.log(value);
    const field = this.store.getCreatedFieldById(fieldId);
    // field.name = value;
    const changedField = { ...field, name: value };
    this.store.updateFiled(changedField);
    field.polygon.setTooltipContent(value);
  }

  public submitFields() {
    const { createdFieldsList } = this.store;

    return this.service.submitFields(createdFieldsList).then(createdFieldsIds => {
      this.sharedFieldsStore.fieldToCenter = createdFieldsIds?.[0];
    });
  }

  public deleteField(fieldId: number): void {
    const field = this.store.getCreatedFieldById(fieldId);

    this.mapPolygonController.remove(field?.polygon.id, { revalidateIntersections: true });
    this.store.deleteCreatedFieldById(fieldId);

    this.checkErrors();
  }

  public removeUnfinishedContour() {
    if (this.store.isFinishedPolygon) {
      return;
    }

    this.mapDrawerController.resetDrawPolygon();
    this.store.drawStatus = ECurrDrawPolygonStatus.FINISHED;
  }

  // Расширяет базовый метод
  protected getLayerGroupConfig(field: Field) {
    const baseConfig = super.getLayerGroupConfig(field);

    baseConfig.layerGroup = new FieldLayerGroup(field, {
      optimization: { isClusterable: false, isRenderViewport: true },
      fieldOptions: FIELD_POLYGON_OPTIONS.creating,
    });

    return baseConfig;
  }

  protected registerMapEvents() {
    const listener = MapEventBus.on('draw.polygon.create', this.handleCreatePolygon);
    const listener1 = MapEventBus.on('draw.polygon.edit', this.handleEditPolygon);
    const listener2 = MapEventBus.on('draw.polygon.vertex.add', this.handleAddVertex);

    this.mapEventsListenersList.push(listener, listener1, listener2);
  }

  private handleCreatePolygon = (polygon: BasePolygon) => {
    const newField = this.store.setCreatedField(polygon);

    polygon.setTooltipContent(newField.name);
    this.validatePolygon(polygon);

    this.store.drawStatus = ECurrDrawPolygonStatus.FINISHED;
  };

  private handleEditPolygon = (polygon: BasePolygon) => {
    const creatableField = this.store.getCreatedFieldById(polygon.id);
    if (!creatableField) {
      return;
    }

    creatableField.areaInHectare = polygon.getInfo().area;

    polygon.rerenderTooltip();

    this.validatePolygon(polygon);
  };

  private handleAddVertex = e => {
    if (!e.options.draggable) {
      this.store.drawStatus = ECurrDrawPolygonStatus.UNFINISHED;
    }
  };

  private validatePolygon(polygon: BasePolygon): void {
    const allPolygons = [...this.fieldsPolygonsList, ...this.store.createdFieldsPolygonsList];
    const polygonValidator = new PolygonValidator(polygon);

    polygonValidator
      .checkIntersections(allPolygons)
      .checkIsAreaTooBig()
      .changeStyles(FIELD_POLYGON_OPTIONS.error);

    this.checkErrors();
  }

  private checkErrors() {
    const errorPolygons = this.store.createdFieldsPolygonsList.filter(
      polygon => polygon.errors.size
    );
    this.store.hasErrors = Boolean(errorPolygons.length);
  }
}

export default CreateFieldsController;
