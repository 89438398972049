import { ButtonLink } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Field } from '../../../../api/models/field.model';
import { useStore } from '../../../shared/utils/IoC';
import { toFixedWithCeil } from '../../../shared/utils/toFixedWithCeil';
import { PopupPages } from '../../constants/popup.pages';
import { FieldsRoute } from '../../modules/fields/fields.route';
import { FieldsUIStore } from '../../modules/fields/mobx';
import { SharedFieldsStore } from '../../modules/fields/mobx/stores';
import { getUniqueCulturesNameList } from '../../modules/fields/utils';
import { OrganizationsStore } from '../../stores/organizations.store';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../AccessRulesWrapper/ControlAccessRulesWrapper';

import {
  Content,
  FieldCulturePic,
  FieldCultures,
  FieldCulturesWrapper,
  FieldInfoWrapper,
  FieldSquare,
  Label,
} from './style';
import { getCultures } from './utils';

export const FieldInfo: FC = observer(() => {
  const fieldsUIStore = useStore(FieldsUIStore);
  const organizationsStore = useStore(OrganizationsStore);
  const sharedFieldsStore = useStore(SharedFieldsStore);

  const navigate = useNavigate();

  const [field, setField] = useState<Field>();
  const [cultures, setCultures] = useState<string>('');
  const [culturesPic, setCulturesPic] = useState<string>('');
  const [showFieldNameTip, setShowFieldNameTip] = useState<boolean>();

  const fieldName = useRef(null);

  const isEllipsisActive = (e: HTMLDivElement): void => {
    setShowFieldNameTip(() => {
      return e.offsetWidth < e.scrollWidth;
    });
  };

  const fieldEditRoute = useMemo(
    () =>
      generatePath(FieldsRoute.Edit, {
        orgId: organizationsStore.selectedOrganizationId,
        fieldId: sharedFieldsStore.selectedField?.id ?? '',
      }),
    [organizationsStore.selectedOrganizationId, sharedFieldsStore.selectedField?.id]
  );

  const handleFieldEdit = () => {
    fieldsUIStore.showFullWeather = false;
    navigate(fieldEditRoute);
  };

  const handleOpenSeasonsHistory = () => {
    fieldsUIStore.popupPageState = PopupPages.Seasons;
  };

  useEffect(() => {
    const { selectedField } = sharedFieldsStore;

    if (selectedField) {
      setField(selectedField);
    }

    const { culturesPic: culturesPicTmp } = getCultures(selectedField);
    const culturesList = getUniqueCulturesNameList(selectedField?.cultureZones);

    setCultures(culturesList.length > 0 ? culturesList.join(', ') : 'Нет культуры');
    setCulturesPic(culturesPicTmp);
  }, [sharedFieldsStore.selectedField]);

  return (
    <FieldInfoWrapper data-test-id="widget-field-info">
      <Label
        fieldname={(field && field.name) || ''}
        showtip={showFieldNameTip}
        onMouseOver={() => {
          isEllipsisActive(fieldName.current);
        }}
        onMouseOut={() => {
          setShowFieldNameTip(false);
        }}
      >
        <p data-test-id="widget-field-info-name" ref={fieldName}>
          {field && field.name}
        </p>
      </Label>

      <Content>
        <FieldSquare data-test-id="widget-field-info-square">
          {field && `${toFixedWithCeil(field.area) || 0} га`}
        </FieldSquare>

        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.editField">
          <ButtonLink color={'accent'} onClick={handleFieldEdit} dataTestId={'edit-field-area'}>
            Редактировать поле
          </ButtonLink>
        </ControlAccessRulesWrapper>

        <FieldCulturesWrapper>
          <FieldCultures>{cultures}</FieldCultures>
        </FieldCulturesWrapper>

        <ButtonLink
          color={'accent'}
          onClick={handleOpenSeasonsHistory}
          dataTestId={'edit-field-seazons'}
        >
          История посевов
        </ButtonLink>
      </Content>
      {culturesPic && <FieldCulturePic src={culturesPic} />}
    </FieldInfoWrapper>
  );
});
