import { Listener } from 'eventemitter2';

import { Field } from '../../../../../../../api/models/field.model';
import {
  IMapLayerGroupConfig,
  IMapLayerGroupSelectOptions,
} from '../../../../../../shared/features/map/models';
import {
  MapCoreController,
  MapLayerGroupController,
  MapLayerGroupStore,
} from '../../../../../../shared/features/map/modules';
import { getBoundsByCoordinates } from '../../../../../../shared/features/map/utils/helpers';
import {
  BaseLayerGroup,
  FieldLayerGroup,
} from '../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import {
  FieldFillController,
  FieldFillStore,
  FieldTooltipController,
} from '../../../../../components/FieldFillControls/mobx';
import { EFieldFill } from '../../../../../constants/FieldFill.enum';
import { FieldsStore, SharedFieldsStore, FieldsUIStore } from '../../stores';

type TGetLayerGroupConfigFn = (field: Field) => IMapLayerGroupConfig;

@provide.singleton()
abstract class AbstractFieldsController {
  @lazyInject(SharedFieldsStore)
  protected sharedFieldsStore: SharedFieldsStore;

  @lazyInject(FieldsUIStore)
  protected UIStore: FieldsUIStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(FieldFillStore)
  protected fieldFillStore: FieldFillStore;

  @lazyInject(MapLayerGroupStore)
  protected mapLayerGroupStore: MapLayerGroupStore;

  @lazyInject(MapCoreController)
  protected mapCoreController: MapCoreController;

  @lazyInject(MapLayerGroupController)
  protected mapLayerGroupController: MapLayerGroupController;

  @lazyInject(FieldFillController)
  protected fieldFillController: FieldFillController;

  @lazyInject(FieldTooltipController)
  protected fieldTooltipController: FieldTooltipController;

  // Хранит подписки на события карты. Нужен для отписки от событий по завершению работы мода
  protected mapEventsListenersList: Listener[] = [];

  abstract initialize(...args: unknown[]): void;

  protected abstract registerMapEvents(): void;

  /**
   * Инициализирует карту. Состоит из след. шагов:
   * 1. Формирование списка с конфигурацией полигонов
   * 2. Очистка карты
   * 3. Добавление слоев! на карту
   * 4. Создание связи поле-слой. (id слоя сетается в модель поля)
   * 5. Регистрация событий карты
   */
  protected async buildMap(fieldsList: Field[], getLayerGroupConfigFn: TGetLayerGroupConfigFn) {
    console.time('Build Map');
    this.sharedFieldsStore.isBuildingMap = true;
    const configsList = fieldsList.map(getLayerGroupConfigFn);

    this.mapCoreController.clear();

    const elements = await this.mapLayerGroupController.displayManyAsync(configsList);

    this.fieldsStore.attachMapElements(elements);

    this.registerMapEvents();

    this.sharedFieldsStore.isBuildingMap = false;
    console.timeEnd('Build Map');

    return elements;
  }

  /**
   * Отписывается от событий карты
   */
  public destroy(options?: { keepSelected: boolean }) {
    this.mapEventsListenersList.forEach(listener => listener.off());
    this.sharedFieldsStore.selectedField = null;
  
    if (!options?.keepSelected) {
      this.sharedFieldsStore.selectedField = null;
    }
    this.sharedFieldsStore.fieldsMode = null;
  }

  /**
   * Базовый метод по выбору поля. Состоит из след. шагов:
   * 1. Выбор слоя на карте (автоматически центрирует)
   * 2. Запись поля в стор
   * 3. Доп. действия (работа с UIStore)
   * 4. Заливка полигона
   */
  public selectField(field: Field, options?: IMapLayerGroupSelectOptions) {
    // Необходимо для того, чтобы не терялся polyid.
    const fieldWithPolyId = this.fieldsStore.getFieldById(field?.id);
    const layerGroup = this.getLayerGroupByField(fieldWithPolyId);

    this.mapLayerGroupController.select(layerGroup, options);
    this.sharedFieldsStore.selectedField = fieldWithPolyId;
    this.sharedFieldsStore.fieldToCenter = fieldWithPolyId;

    if (this.UIStore.showFullWeather) {
      this.UIStore.showFullWeather = false;
    }

    if (!options?.skipFillAfterSelect) {
      this.fillLayerAfterSelect(fieldWithPolyId);
    }
  }

  /**
   * Базовый метод по отмене выделенного слоя
   */
  public deselectField() {
    this.mapLayerGroupController.deselect();
    this.sharedFieldsStore.selectedField = null;
    this.sharedFieldsStore.fieldToCenter = null;
    this.fieldFillController.setFillStrategy(EFieldFill.Cultures);
  }

  /**
   * Возвращает слой карты для переданной модели поля
   */
  protected getLayerGroupByField(field: Field | null): BaseLayerGroup | undefined {
    // Необходимо для того чтобы не терялся polyid.
    const originalField = this.fieldsStore.getFieldById(field?.id);

    return this.mapLayerGroupStore.getLayerGroup(originalField?.polyId);
  }

  /**
   * Возвращает модель поля для переданного слоя карты
   */
  protected getFieldByLayerGroup(layerGroup: BaseLayerGroup): Field | null {
    return this.fieldsStore.fieldsList.find(({ polyId }) => polyId === layerGroup.id) ?? null;
  }

  /**
   * Центрирует карту на границы поля
   */
  protected centerMapToFieldBounds(field: Field) {
    const bounds = getBoundsByCoordinates(field?.geometry?.coordinates);
    this.mapCoreController.centerOnBounds({ getBounds: () => bounds });
  }

  // Базовый метод получения конфигурации группы слоев
  protected getLayerGroupConfig(field: Field, ...args: unknown[]): IMapLayerGroupConfig {
    return {
      element: field,
      layerGroup: new FieldLayerGroup(field),
      options: { isAllowToClick: true },
    };
  }

  protected get fieldsPolygonsList() {
    return this.mapLayerGroupStore.layerGroupsMainPolygons;
  }

  /**
   * Возвращает поле для центрирования. Поле должно быть в списке загруженных с бэка полей
   */
  protected get fieldToCenter() {
    const { fieldToCenter } = this.sharedFieldsStore;

    if (typeof fieldToCenter === 'string') {
      return this.fieldsStore.getFieldById(fieldToCenter);
    }

    return this.fieldsStore.getFieldById(fieldToCenter?.id);
  }

  private fillLayerAfterSelect(field: Field): void {
    const { prevSelectedField } = this.sharedFieldsStore;
    const { fieldFillValue } = this.fieldFillStore;

    // Поле выбрано в первый раз
    if (!prevSelectedField) {
      this.fieldFillController.setFillStrategy(EFieldFill.Ndvi, field);
      return;
    }

    // Выбрано поле и его заливка (переход с одного поля на другое)
    if (fieldFillValue) {
      this.fieldFillController.setFillStrategy(fieldFillValue, field);
    }
  }
}

export default AbstractFieldsController;
