import * as L from 'leaflet';

export default {
  continueDrawing: false,
  allowSelfIntersection: false,
  hintlineStyle: {
    fillColor: '#fff',
    fillOpacity: 0,
    color: '#F29993',
    dashArray: [5, 5],
  },
  snappable: true,
  snapDistance: 3,
  pathOptions: {
    fillColor: '#fff9e8',
    color: '#F29993',
    fillOpacity: 0,
  },
  markerStyle: {
    // @ts-ignore
    color: '#F29993',
  },
  templineStyle: {
    fillColor: '#fff',
    fillOpacity: 0,
    color: '#F29993',
    dashArray: [5, 5],
  },
} as L.PM.DrawModeOptions;
