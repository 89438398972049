import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../shared/utils/IoC';
import { TableFiltersBuilder } from '../../../../../shared/features/TableFiltersBuilder';
import { ETableFiltersBuilderId } from '../../../../constants/configs/TableFiltersBuilderId';
import { useTableFilters } from '../../../../../shared/features/TableFiltersBuilder/hooks';
import { useStoParams } from '../../hooks';
import { SeasonsStore } from '../../../../stores/seasons.store';
import EStoRoute from '../../routes/sto.routes';
import { StoListController } from '../StoListContainer/mobx/controllers';

import { STOFiltersController } from './mobx/controllers';

const STOFiltersContainer: FC = () => {
  const { selectedSeason } = useStore(SeasonsStore);
  const { initiateFilters, addOptions } = useStore(STOFiltersController);
  const { fetchStoList } = useStore(StoListController);

  const { orgId } = useStoParams();

  const navigate = useNavigate();

  const handleActionButtonClick = useCallback(() => {
    navigate(`..${EStoRoute.StoItemCreate}`);
  }, []);

  useEffect(() => {
    initiateFilters({ onActionButtonClick: handleActionButtonClick });
  }, []);

  useEffect(() => {
    (async () => {
      if (!selectedSeason) return;

      await addOptions();
      await fetchStoList();
    })();
  }, [selectedSeason, orgId]);

  useTableFilters(ETableFiltersBuilderId.Sto, () => fetchStoList());

  return <TableFiltersBuilder builderId={ETableFiltersBuilderId.Sto} />;
};

STOFiltersContainer.displayName = 'STOFiltersContainer';

export default observer(STOFiltersContainer);
