import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import { STO_ACCESS_ACTION_LIST } from '../../../shared/constants/access-rules-action';
import { ApplicationRoutes } from '../../../routes';
import { OrganizationsStore } from '../../stores/organizations.store';
import { SpinnerLoader } from '../../../shared/components/loaders';

import EStoRoute from './routes/sto.routes';
import { STOListingContainer as StoListing } from './containers';
import { StoContainer } from './modules/Sto';
import { useStoParams } from './hooks';
import { StoBaseController } from './mobx';

const StoRouter = () => {
  const controller = useStore(StoBaseController);
  const { currentOrganization } = useStore(OrganizationsStore);
  const { orgId } = useStoParams();

  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    if (!currentOrganization) {
      return;
    }

    controller.fetchStoActionList(orgId).then(data => {
      const stoOrgDisabled = !currentOrganization.isSTOEnabled;
      const noViewActions = !data?.some(
        item => Boolean(item.available) && (STO_ACCESS_ACTION_LIST as string[]).includes(item.code)
      );

      setHasAccess(!(stoOrgDisabled || noViewActions));
    });
  }, [currentOrganization, orgId]);

  if (hasAccess === null) {
    return <SpinnerLoader />;
  }

  if (hasAccess === false) {
    return <Navigate to={ApplicationRoutes.PageForbidden} />;
  } else {
    return (
      <Routes>
        <Route path={EStoRoute.Listing} element={<StoListing />} />

        <Route path={EStoRoute.StoItem} element={<StoContainer />} />
        <Route path={EStoRoute.StoItemSummary} element={<StoContainer />} />
        <Route path={EStoRoute.StoItemFieldList} element={<StoContainer />} />
        <Route path={EStoRoute.StoItemOperationList} element={<StoContainer />} />
      </Routes>
    );
  }
};

export default observer(StoRouter);
