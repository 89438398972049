import { Field } from '../../../../../../../api/models/field.model';
import { MapEventBus } from '../../../../../../shared/features/map/modules/MapCore';
import { MapDrawerController } from '../../../../../../shared/features/map/modules';
import {
  BasePolygon,
  FieldLayerGroup,
} from '../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../../../../constants/FieldTooltip.enum';
import { PopupPages } from '../../../../../constants/popup.pages';
import { EFieldsMode } from '../../../constants';
import { FIELD_POLYGON_OPTIONS } from '../../../utils/constants/PolygonOptions.constant';
import { EditFieldsService } from '../../services';
import { EditFieldsStore } from '../../stores';
import AbstractFieldsController from '../AbstractFieldsController/AbstractFields.controller';
import { PolygonValidator } from '../../../../../../shared/features/map/utils/validators';

@provide.singleton()
class EditFieldController extends AbstractFieldsController {
  @lazyInject(EditFieldsStore)
  private store: EditFieldsStore;

  @lazyInject(EditFieldsService)
  private service: EditFieldsService;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  public async initialize(editableFieldId: string) {
    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.service.fetchFieldsList();
    if (!this.fieldsStore.hasField(editableFieldId)) {
      return;
    }

    // Подготовка карты. Центрируем
    this.centerMapToFieldBounds(this.fieldsStore.getFieldById(editableFieldId));

    // Инициализируем карту. Делаем связь слой - поле
    await this.buildMap(fieldsList, field => {
      return this.getLayerGroupConfig(field, field.id === editableFieldId);
    });

    // Получаем редактируемый полигон и включаем ему режим редактирования
    const editableField = this.fieldsStore.getFieldById(editableFieldId);
    const fieldPolygon = this.getLayerGroupByField(editableField)?.getMainPolygon();

    this.mapDrawerController.enableEditPolygon(fieldPolygon);

    // Выбираем поле
    this.selectField(editableField, { skipCenter: true, skipFillAfterSelect: true });

    // Заливаем редактируемый слой культурными зонами и устанавливаем ему тултип
    this.fieldFillController.setFillStrategy(EFieldFill.Cultures, editableField);
    this.fieldTooltipController.setTooltipContent(EFieldTooltip.Name, editableField);

    // Сетаем редактируемое поле в стор, для отображения в левом меню
    this.store.setEditableField(editableField, fieldPolygon.getInfo());

    // Временно
    this.UIStore.popupPageState = PopupPages.None;
    this.sharedFieldsStore.fieldsMode = EFieldsMode.EDIT;
  }

  public destroy() {
    super.destroy({ keepSelected: true });

    const { editableField } = this.store;
    const layerGroup = this.getLayerGroupByField(editableField);

    if (layerGroup) {
      const fieldPolygon = layerGroup?.getMainPolygon();
      this.mapDrawerController.disableEditPolygon(fieldPolygon);
    }

    this.store.clear();
  }

  public deleteField(field: Field, fromSeason?: number): Promise<Field> {
    return this.service.deleteField(field.id, fromSeason).then(response => {
      this.sharedFieldsStore.fieldToCenter = null;

      return response;
    });
  }

  public submitField() {
    const { editableField } = this.store;

    const layerGroup = this.getLayerGroupByField(editableField);
    const fieldPolygon = layerGroup?.getMainPolygon();

    if (!fieldPolygon) {
      return;
    }

    const promise = this.service.submitField(editableField, fieldPolygon);

    return promise.then(() => {
      // return this.fieldsStore.updateCultureZones(editableFieldWithGeoJSON, seasonYear);
      return true;
    });
  }

  public changeFieldName(value: string) {
    this.store.changeEditableFieldName(value);

    const layerGroup = this.getLayerGroupByField(this.store.editableField);
    layerGroup.setTooltipContent(value);
  }

  // Расширяет базовый метод
  protected getLayerGroupConfig(field: Field, isEditablePolygon: boolean) {
    const config = super.getLayerGroupConfig(field);

    config.layerGroup = new FieldLayerGroup(field, {
      optimization: false,
      selectedStyle: FIELD_POLYGON_OPTIONS.display,
      fieldOptions: isEditablePolygon ? FIELD_POLYGON_OPTIONS.display : FIELD_POLYGON_OPTIONS.edit,
    });

    return config;
  }

  protected registerMapEvents() {
    const listener = MapEventBus.on('draw.polygon.edit', this.handlePolygonEdit);

    this.mapEventsListenersList.push(listener);
  }

  private handlePolygonEdit = (polygon: BasePolygon) => {
    if (this.store.isEditableFieldHasCultures) {
      const isValid = this.service.isPolygonCultureZonesValid(polygon);
      this.store.isCultureZoneOutOfField = !isValid;
    }

    this.store.updateFieldData(polygon.getInfo());

    const layerGroup = this.getLayerGroupByField(this.store.editableField);
    layerGroup.redrawTooltip();

    this.validatePolygon(polygon);
  };

  private validatePolygon(polygon: BasePolygon): void {
    const polygonValidator = new PolygonValidator(polygon);
    const instance = polygonValidator
      .checkIntersections(this.fieldsPolygonsList)
      .checkIsAreaTooBig();

    instance.changeStyles(FIELD_POLYGON_OPTIONS.error);
    this.store.hasError = Boolean(instance.getErrors().length);
  }
}

export default EditFieldController;
