import styled, { css } from 'styled-components';

import StyledStoCard from '../StoCard/StoCard.styles';

const getGridTemplateColumns = (cardsNumber: number) => {
  const GAP = '16px';
  const gapsNumber = cardsNumber - 1;

  return css`
    grid-template-columns: repeat(
      ${cardsNumber},
      calc((100% / ${cardsNumber}) - ((${GAP} * ${gapsNumber}) / ${cardsNumber}))
    );
  `;
};

const getCardMaxWidth = (value: string) => {
  return css`
    ${StyledStoCard.Wrapper} {
      max-width: ${value};
    }
  `;
};

const Wrapper = styled.div`
  width: 100%;

  position: relative;

  display: grid;
  align-items: center;
  grid-gap: 16px;

  @media only screen and (min-width: 3840px) {
    ${getGridTemplateColumns(10)};
  }

  @media only screen and (min-width: 2560px) and (max-width: 3839px) {
    ${getGridTemplateColumns(7)};
    ${getCardMaxWidth('518.13px')};
  }

  @media only screen and (min-width: 1920px) and (max-width: 2559px) {
    ${getGridTemplateColumns(5)};
    ${getCardMaxWidth('475.8px')};
  }

  @media only screen and (min-width: 1367px) and (max-width: 1919px) {
    ${getGridTemplateColumns(4)};
    ${getCardMaxWidth('438.75px')};
  }

  @media only screen and (width: 1366px) {
    ${getGridTemplateColumns(4)};
    ${getCardMaxWidth('300.5px')};
  }

  @media only screen and (min-width: 1025px) and (max-width: 1365px) {
    ${getGridTemplateColumns(3)};
    ${getCardMaxWidth('405.66px')};
  }

  @media only screen and (max-width: 1024px) {
    ${getGridTemplateColumns(3)};
    ${getCardMaxWidth('293.3px')};
  }
`;

const StyledStoList = {
  Wrapper,
};

export default StyledStoList;
