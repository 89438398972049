import { ENotificationType, useNotificator } from '@farmlink/farmik-ui';

import { useStoNavigation } from '../../../../../../hooks';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../../shared/utils/helpers/getNotificationScheme';
import { NOTIFICATION_MESSAGES } from '../../../../../../constants';

const useStoControls = () => {
  const { setNotification } = useNotificator();
  const navigation = useStoNavigation();

  const createStoSuccessHandler = (stoId: string) => {
    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, NOTIFICATION_MESSAGES.createStoSuccess, {
        type: ENotificationType.Success,
      })
    );

    navigation.navigateToSto(stoId, true);
  };

  const createStoErrorHandler = () => {
    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, NOTIFICATION_MESSAGES.createStoError, {
        type: ENotificationType.Warning,
      })
    );
  };

  const updateStoSuccessHandler = () => {
    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, NOTIFICATION_MESSAGES.updateStoSuccess, {
        type: ENotificationType.Success,
      })
    );
  };

  const updateStoErrorHandler = () => {
    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, NOTIFICATION_MESSAGES.updateStoError, {
        type: ENotificationType.Warning,
      })
    );
  };

  return {
    createStoSuccessHandler,
    createStoErrorHandler,
    updateStoSuccessHandler,
    updateStoErrorHandler,
  };
};

export default useStoControls;
