import { computed, makeObservable } from 'mobx';

import { Field } from '../../../../../../../api/models/field.model';
import { MapLayerGroupStore } from '../../../../../../shared/features/map/modules';
import MapPolygonStore from '../../../../../../shared/features/map/modules/MapPolygon/stores/MapPolygon.store';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import {
  CreateFieldsStore,
  DisplayFieldsStore,
  EditFieldsStore,
  FieldsStore,
  ImportFieldsStore,
  SharedFieldsStore,
} from '../../stores';

@provide.transient()
class FieldsFacadeController {
  @lazyInject(SharedFieldsStore)
  private sharedFieldsStore: SharedFieldsStore;

  @lazyInject(DisplayFieldsStore)
  private displayFieldsStore: DisplayFieldsStore;

  @lazyInject(CreateFieldsStore)
  private createFieldsStore: CreateFieldsStore;

  @lazyInject(EditFieldsStore)
  private editFieldsStore: EditFieldsStore;

  @lazyInject(FieldsStore)
  private fieldsStore: FieldsStore;

  @lazyInject(ImportFieldsStore)
  private importFieldsStore: ImportFieldsStore;

  @lazyInject(MapPolygonStore)
  private mapPolygonStore: MapPolygonStore;

  @lazyInject(MapLayerGroupStore)
  private mapLayerGroupStore: MapLayerGroupStore;

  constructor() {
    /**
     * Так как этот фасад может собирать информацию из разных сторов,
     * необходимо пометить его get методы как computed,
     * чтобы не вызывать многочисленные ререндеры в компонентах.
     * Возможно стоит заменить на makeAutoObservable
     */
    makeObservable(this, {
      activeMode: computed,
      selectedField: computed,
      prevSelectedField: computed,
      isShowFillControls: computed,
      hasDisplayedFields: computed,
    });
  }

  // Возвращает текущий мод для модуля полей
  public get activeMode() {
    return this.sharedFieldsStore.fieldsMode;
  }

  // Возвращает выбранное поле
  public get selectedField(): Field | null {
    return this.sharedFieldsStore.selectedField;
  }

  // Возвращает предыдущее выбранное поле
  public get prevSelectedField(): Field | null {
    return this.sharedFieldsStore.prevSelectedField;
  }

  /**
   * Указывает на то, может ли активный мод иметь логику заливки поля
   * Возможно в дубущем можно переделать под конфигруацию UI компонентов для конкретного мода
   */
  public get isShowFillControls(): boolean {
    const { fieldsMode } = this.sharedFieldsStore;

    // Если нет ни одного поля, то не показываем логику с заливкой
    if (!this.fieldsStore.hasFields) {
      return false;
    }

    if (this.sharedFieldsStore.isCultureZoneMode || this.sharedFieldsStore.isCultureZoneEditMode) {
      return false;
    }

    return true;
  }

  public get hasDisplayedFields() {
    const { hasPolygons } = this.mapPolygonStore;
    const { hasLayers } = this.mapLayerGroupStore;

    return this.fieldsStore.hasFields && (hasPolygons || hasLayers);
  }
}

export default FieldsFacadeController;
