import { AutoTooltip } from '@farmlink/farmik-ui';
import React, { useMemo } from 'react';

import { CultureLabel, CulturePlaceholder, FieldName, LabelsWrapper } from '../style';

import { MAX_FILED_NAME_LENGTH } from './constants';

type Props = {
  dataTestId: string;
  fieldName: string;
  cultures: string;
};

export const FiledNameAndCulture = ({ dataTestId, fieldName, cultures }: Props) => {
  const renderName = useMemo(() => {
    return fieldName?.length > MAX_FILED_NAME_LENGTH
      ? `${fieldName?.substr(0, MAX_FILED_NAME_LENGTH)}...`
      : fieldName;
  }, [fieldName]);

  const cultureLabel = useMemo(() => {
    return cultures?.length > MAX_FILED_NAME_LENGTH
      ? `${cultures.substr(0, MAX_FILED_NAME_LENGTH)}...`
      : cultures;
  }, [cultures]);

  return (
    <LabelsWrapper>
      <AutoTooltip content={fieldName} disabled={fieldName?.length <= MAX_FILED_NAME_LENGTH}>
        <FieldName data-test-id={`${dataTestId}-field-name`}>{renderName}</FieldName>
      </AutoTooltip>
      {cultures?.length ? (
        <AutoTooltip content={cultures} disabled={cultures?.length <= MAX_FILED_NAME_LENGTH}>
          <CultureLabel data-test-id={`${dataTestId}-culture-culture`}>{cultureLabel}</CultureLabel>
        </AutoTooltip>
      ) : (
        <CulturePlaceholder data-test-id={`${dataTestId}-culture-no-culture`}>
          Нет культуры
        </CulturePlaceholder>
      )}
    </LabelsWrapper>
  );
};
