import { observer } from 'mobx-react';
import { Colors, Icon } from '@farmlink/farmik-ui';

import { SharedFieldsStore } from '../../../dashboard/modules/fields/mobx';
import { MapLayerGroupStore, MapPolygonStore } from '../../../shared/features/map/modules';
import { useStore } from '../../../shared/utils/IoC';
import Styled from '../PointCoordinatesControls/PointCoordinatesControls.styles';
import EditCultureZoneController from '../../../dashboard/components/PopupSlider/components/CultureZone/controllers/EditCultureZone.controller';

import { usePointCoordinatesUpdate } from './hooks';

const PointCoordinatesControls = () => {
  const editCultureZoneController = useStore(EditCultureZoneController);
  const sharedFieldsStore = useStore(SharedFieldsStore);

  const mapLayerGroupStore = useStore(MapLayerGroupStore);
  const mapPolygonStore = useStore(MapPolygonStore);

  const getActivePolygons = () => {
    if (sharedFieldsStore.isEditMode || sharedFieldsStore.isCreateMode) {
      return {
        selectedPolygon: mapLayerGroupStore.selectedLayerGroup?.getMainPolygon(),
        polygonsList: mapLayerGroupStore.layerGroupsList.map(group => group.getMainPolygon()),
      };
    }

    return {
      selectedPolygon: mapPolygonStore.selectedPolygon,
      polygonsList: mapPolygonStore.polygonsList,
    };
  };

  const {
    expandChangeCord,
    isDisplayCoordinatesBlock,
    isExpanded,
    newCoords,
    shrinkChangeCord,
    coordinates,
    updateCord,
    onChangeCoordinates,
    error,
    isExpandDisabled,
  } = usePointCoordinatesUpdate({ editCultureZoneController, ...getActivePolygons() });

  if (!isDisplayCoordinatesBlock || isExpandDisabled) {
    return null;
  }

  return (
    <Styled.BottomBackground>
      <Styled.Container onSubmit={updateCord}>
        <Styled.CoordinateDisplay value={coordinates} blocked />
        {isExpanded ? (
          <>
            <Styled.CoordinateDisplay
              value={newCoords}
              onChange={onChangeCoordinates}
              $isError={Boolean(error?.length)}
            />

            <Styled.IconButton
              onClick={shrinkChangeCord}
              size={40}
              $padding={12}
              type="button"
              customIcon={
                <Styled.IconWrapper $hoverColor={Colors.lightPink}>
                  <Icon icon="close" size={16} fill={Colors.accentPink} />
                </Styled.IconWrapper>
              }
            />
            <Styled.IconButton
              size={40}
              $padding={12}
              type="submit"
              customIcon={
                <Styled.IconWrapper $hoverColor={Colors.lightGreen}>
                  <Styled.CheckIcon />
                </Styled.IconWrapper>
              }
            />
          </>
        ) : (
          <Styled.IconButton
            onClick={expandChangeCord}
            size={40}
            $padding={12}
            type="button"
            $isDisabled={isExpandDisabled}
            customIcon={
              <Styled.IconWrapper>
                <Icon icon="coordinates" size={16} />
              </Styled.IconWrapper>
            }
          />
        )}
      </Styled.Container>
    </Styled.BottomBackground>
  );
};

export default observer(PointCoordinatesControls);
