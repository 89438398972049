import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import { Field } from '../../../../../../../api/models/field.model';
import { IMapPolygonInfo } from '../../../../../../shared/features/map/models';
import { provide } from '../../../../../../shared/utils/IoC';

@provide.singleton()
class EditFieldsStore {
  private _editableField: Field | null = null;

  private _initialEditableField: Field | null = null;

  private _isCultureZoneOutOfField: boolean | null = null;

  private _hasError = false;

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get editableField(): Field | null {
    return this._editableField;
  }

  get isFieldChanged() {
    const editableField = { ...this._editableField };
    const initialEditableField = { ...this._initialEditableField };

    delete editableField.areaInHectare;
    delete initialEditableField.areaInHectare;

    return !_.isEqual(initialEditableField, editableField);
  }

  get isEditableFieldHasCultures() {
    return Boolean(this.editableField?.cultureZones.length);
  }

  get isAreaTooBig() {
    return this._editableField?.areaInHectare > 1000;
  }

  get hasError() {
    return this._hasError;
  }

  set hasError(value) {
    this._hasError = value;
  }
  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get isCultureZoneOutOfField() {
    return this._isCultureZoneOutOfField;
  }

  set isCultureZoneOutOfField(value) {
    this._isCultureZoneOutOfField = value;
  }

  setEditableField(field: Field, polygonInfo: IMapPolygonInfo) {
    runInAction(() => {
      this.clear();

      this._editableField = { ...field };
      this._initialEditableField = { ...field };

      this._editableField.areaInHectare = polygonInfo.area;
    });
  }

  changeEditableFieldName(name: string) {
    this._editableField.name = name;
  }

  updateFieldData(info: IMapPolygonInfo) {
    runInAction(() => {
      this._editableField.areaInHectare = info.area;
      this._editableField.geometry = info.coordinates;
    });
  }

  clear() {
    this._editableField = null;
    this._initialEditableField = null;
    this._isCultureZoneOutOfField = null;
  }
}

export default EditFieldsStore;
