import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import { PopupPages } from '../../constants/popup.pages';
import { FieldsUIStore } from '../../modules/fields/mobx';

import { PSlider } from './style';
import { Main } from './components/Main/Main';
import { CultureZone } from './components/CultureZone/CultureZone';
import { Seasons } from './components/Seasons/Seasons';

export const PopupSlider: FC = observer(() => {
  const fieldsUiStore = useStore(FieldsUIStore);

  const { popupPageState } = fieldsUiStore;

  const renderContent = useCallback(() => {
    const variants = {
      [PopupPages.None]: null,
      [PopupPages.Main]: <Main />,
      [PopupPages.Seasons]: <Seasons />,
      [PopupPages.CultureZone]: <CultureZone />,
    };

    return variants[popupPageState];
  }, [popupPageState]);

  return (
    <PSlider visibility={popupPageState !== PopupPages.None} type={popupPageState}>
      {renderContent()}
    </PSlider>
  );
});
