import { ICreateSto, IUpdateSto } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { ERequestStatus } from '../../../../../../../shared/constants/requests';
import { StoService } from '../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { TypeApiRequest } from '../../../../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { EStoTab, initiateStoOptions } from '../../models/StoCommon';
import { StoFieldsTabStore } from '../../modules/FieldsTab/mobx/stores';
import { StoOptionService, StoTabService } from '../services';
import { StoStore } from '../stores';

@provide.transient()
class StoController {
  @lazyInject(StoStore)
  protected store: StoStore;

  @lazyInject(StoTabService)
  protected stoTabService: StoTabService;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(StoFieldsTabStore)
  protected stoFieldsTabStore: StoFieldsTabStore;

  @lazyInject(StoOptionService)
  protected optionService: StoOptionService;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  initiateSto = async (options: initiateStoOptions) => {
    try {
      if (options.isCreation) {
        const tab = this.stoTabService.calculateStoItemTab(options.isCreation);

        this.store.setIsCreateMode(true);

        this.stoTabService.calculateStoItemTabConfigByParams(tab, options.isCreation);

        this.optionService.fetchCultureList();

        return Promise.resolve();
      }

      if (options.stoId) {
        await this.fetchSto(options.stoId, true);

        const tab = this.stoTabService.calculateStoItemTab();

        this.stoTabService.calculateStoItemTabConfigByParams(tab);

        this.checkEditStoUserInputValidity();

        return Promise.resolve();
      }
    } catch (error) {
      Promise.reject(new Error('STO_INIT_ERROR'));
    }
  };

  /**
   * Функция вычисления текущего активного таба
   * @returns ESTOItemTab
   */
  calculateCurrentTab = (): EStoTab => {
    return this.stoTabService.calculateStoItemTab();
  };

  updateStoCreationData = (data: Partial<ICreateSto>) => {
    this.store.updateCreateStoData(data);
    this.store.setHasChanges(true);

    this.checkCreationStoUserInputValidity();
  };

  updateStoEditData = (data: Partial<IUpdateSto>) => {
    this.store.setUpdatedSto(data);
    this.store.setHasChanges(true);

    this.checkEditStoUserInputValidity();
  };

  fetchSto = (stoId: string, includeDrafts?: boolean, asOnDate?: string) => {
    this.store.setRequestStoStatus(ERequestStatus.Pending);

    this.stoService
      .getSto({ id: stoId, includeDrafts, asOnDate })
      .then(sto => {
        this.store.setSelectedSto(sto);

        this.store.setRequestStoStatus(ERequestStatus.Fulfilled);
      })
      .catch(e => {
        this.store.setRequestStoStatus(ERequestStatus.Rejected);
      });
  };

  changeStoTab = (tab: EStoTab) => {
    this.stoTabService.calculateStoItemTabConfigByParams(tab);

    this.store.setStoTab(tab);
  };

  checkCreationStoUserInputValidity = () => {
    const data = this.store.createStoData;

    const isNameValid = Boolean(data?.name?.length) && data?.name?.length <= 50;

    const isCultureValid = Boolean(data?.cultureId?.length);

    this.store.setIsCreateStoFormValid(isNameValid && isCultureValid);
  };

  checkEditStoUserInputValidity = () => {
    // Если изменения ещё не вносились - валидируем стандартную модель СТО
    const data = this.store.hasChanges ? this.store.updatedStoData : this.store.selectedSto;

    const isNameValid = Boolean(data?.name?.length) && data?.name?.length <= 50;

    this.store.setIsEditStoFormValid(isNameValid);
  };

  updateCreateStoName = (name: string) => {
    this.updateStoCreationData({ name });
  };

  updateCrateStoCulture = (cultureId: string) => {
    this.updateStoCreationData({ cultureId });
  };

  createSto = () => {
    return this.stoService
      .createSto({
        cultureId: this.store.createStoData.cultureId,
        name: this.store.createStoData.name,
        isDefault: false,
        organizationId: this.organizationsStore?.currentOrganization.organizationId,
        seasonYear: Number(this.seasonsStore?.selectedSeason),
      })
      .then(sto => {
        this.store.setHasChanges(false);
        this.store.clearCreationMode();

        this.store.setSelectedSto(sto);

        return sto;
      });
  };

  updateSto = () => {
    const payload: TypeApiRequest<'updateSto'> = {
      id: this.store.selectedSto.id,
      ...this.store.updatedStoData,
    };

    if (this.store.StoTab === EStoTab.Fields) {
      payload.cultureZones = Array.from(this.stoFieldsTabStore.selectedFieldIdSet.values());
    }

    return this.stoService.updateSto(payload).then(sto => {
      this.store.setHasChanges(false);

      this.store.setSelectedSto(sto);
      this.store.clearUpdatedSto();

      return sto;
    });
  };

  updateStoName = (name: string) => {
    this.updateStoEditData({ name });
  };

  clearEditOnLeaveSummaryTabTab = () => {
    this.store.clearUpdatedSto();
    this.store.clearIsEditStoFromValid();

    this.checkEditStoUserInputValidity();
  };
}

export default StoController;
