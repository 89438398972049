import { observer } from 'mobx-react';
import { FC } from 'react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoFieldsTabStore } from '../../mobx/stores';
import { ScrollableContainer } from '../../../../../../../../../shared/containers';

import { FieldsTabListingItem, NoFieldsPlaceholder } from './components';

const FieldsTabListing: FC<{ isBlocked?: boolean }> = ({ isBlocked }) => {
  const store = useStore(StoFieldsTabStore);

  const displayList = store?.potentialCultureZoneListToDisplay;

  const getIsLast = (index: number) => displayList?.length - 1 === index;

  const renderRow = (fieldId: string, index: number) => (
    <FieldsTabListingItem isBlocked={isBlocked} fieldId={fieldId} isLast={getIsLast(index)} />
  );

  if (displayList?.length === 0 && store.czIdToPotentialCultureZones?.size > 0) {
    return <NoFieldsPlaceholder />;
  }

  return (
    <ScrollableContainer maxHeight="469px" dataTestId="sto-field-list">
      {displayList.map((item, index) => renderRow(item.id, index))}
    </ScrollableContainer>
  );
};

export default observer(FieldsTabListing);
