import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import { SwiperSlider } from '../../../shared/components/SwiperSlider/SwiperSlider';
import { FieldsStore } from '../../modules/fields/mobx';
import { FieldsFacadeController } from '../../modules/fields/mobx/controllers';

import { CulturesListWrapper, CultureWrapper, CultureColor, CultureLabel } from './style';

export const AvailableCultures: FC = observer(() => {
  const fieldsStore = useStore(FieldsStore);

  const [cultures, setCultures] = useState([]);

  useEffect(() => {
    const tmpCultures = [];
    const checkNames = [];

    const allCultureZonesList = fieldsStore.fieldsList
      .flatMap(field => field.cultureZones)
      .filter(Boolean);

    allCultureZonesList.forEach(({ culture }) => {
      if (culture?.name && !checkNames.includes(culture?.name)) {
        checkNames.push(culture.name);
        tmpCultures.push(culture);
      }
    });

    // for (const [key, value] of fieldsStore.idToFields) {
    //   if (value.cultureZones?.length)
    //     value.cultureZones.forEach(zone => {
    //       const { culture } = zone;
    //
    //       if (culture?.name && !checkNames.includes(culture?.name)) {
    //         checkNames.push(culture.name);
    //         tmpCultures.push(culture);
    //       }
    //     });
    // }
    setCultures(tmpCultures.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [fieldsStore.fieldsList.length]);

  if (!cultures.length) {
    return null;
  }

  return (
    <CulturesListWrapper data-test-id="map-legend-culture-wrapper">
      <SwiperSlider>
        {cultures.map((c, i) => (
          <CultureWrapper data-test-id={`map-legend-culture-item-${i}`} key={c.id}>
            <CultureColor color={c.attrs.assistanceColorLegend} />
            <CultureLabel data-test-id={`map-legend-culture-item-${i}-name`}>{c.name}</CultureLabel>
          </CultureWrapper>
        ))}
      </SwiperSlider>
    </CulturesListWrapper>
  );
});
