import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { TablePlugBuilder } from '../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestId } from '../../../../../../../../shared/features/utils/hooks/locators';
import { FileSvg } from '../../../../../../../../shared/assets/images/asComponent';
import { CREATE_SEASON_MODAL_ID } from '../../../../../../seasons/modals/CreateSeasonModal/CreateSeasonModal.config';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import { FieldsStore } from '../../../../../../fields/mobx';
import { FieldsRoute } from '../../../../../../fields/fields.route';

import Styled from './TaskListNoSeasonPlug.styles';

const TaskListNoSeasonPlug: FC = () => {
  const seasons = useStore(SeasonsStore);
  const fields = useStore(FieldsStore);

  const { openModalByModalId } = useModal();
  const { orgId } = useParams<{ orgId: string }>();
  const navigate = useNavigate();

  const isNoSeason = !seasons.selectedSeason || seasons.selectedSeason === '';
  const isNoFields = !fields.hasFields;

  const handleButtonClick = useCallback(() => {
    if (isNoSeason) {
      return openModalByModalId(CREATE_SEASON_MODAL_ID);
    }

    if (isNoFields) {
      navigate(generatePath(FieldsRoute.Add, { orgId }));
    }
  }, [isNoSeason, isNoFields]);

  const getDataTestId = useDataTestId('tasks-table');

  const noDataPlugDataTestId = getDataTestId('no-season-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: <FileSvg /> },
        content: {
          title: 'Сначала добавьте сезон и поля',
          description: 'После этого вы сможете запланировать задачи на каждое поле',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('crete-season-plug-button')['data-test-id']}
            >
              Добавить
            </Styled.Button>
          ),
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

TaskListNoSeasonPlug.displayName = 'TaskListNoSeasonPlug';

export default observer(TaskListNoSeasonPlug);
