import { EFieldFill } from '../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../constants/FieldTooltip.enum';
import { PopupPages } from '../../constants/popup.pages';

export const VALUES_TO_HIDE_THE_SEARCH_BAR: EFieldFill[] = [
  EFieldFill.Msavi,
  EFieldFill.Ndvi,
  EFieldFill.Cultures,
];

export const VALUES_TO_SHOW_THE_CULTURE_FILL: PopupPages[] = [PopupPages.None, PopupPages.Main];

export interface IOption<L, V> {
  label: L;
  value: V;
}

export const COMMON_OPTIONS_SET: IOption<string, EFieldFill>[] = [
  { label: 'Культуры', value: EFieldFill.Cultures },
  { label: 'Без заливки', value: EFieldFill.None },
];

export const INDEXES_OPTIONS_SET: IOption<string, EFieldFill>[] = [
  { label: 'Индекс MSAVI', value: EFieldFill.Msavi },
  { label: 'Индекс NDVI', value: EFieldFill.Ndvi },
];

export const SELECTED_FIELD_OPTIONS_SET: IOption<string, EFieldFill>[] = [
  { label: 'Индекс MSAVI', value: EFieldFill.Msavi },
  { label: 'Индекс NDVI', value: EFieldFill.Ndvi },
  { label: 'Культуры', value: EFieldFill.Cultures },
  { label: 'Без заливки', value: EFieldFill.None },
];

export const LABEL_FIELD_FILL_OPTIONS_SET: IOption<string, EFieldTooltip>[] = [
  { label: 'Площадь', value: EFieldTooltip.Area },
  { label: 'Название', value: EFieldTooltip.Name },
  { label: 'Культура', value: EFieldTooltip.Culture },
  { label: 'Без подписи', value: EFieldTooltip.None },
];
