import { EStoAction } from '../../../../../../../api/models/as-fields/STO/STO.model';
import { CheckAccessStore } from '../../../../../../authorization/stores/checkAccess.store';
import { PermissionsBuilderController } from '../../../../../../shared/features/PermissionsBuilder/mobx/controllers';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';

@provide.transient()
class StoBaseController {
  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  @lazyInject(PermissionsBuilderController)
  protected permissionsController: PermissionsBuilderController<EStoAction>;

  fetchStoActionList = async (orgId: string) => {
    try {
      const actionList = await this.checkAccessStore.getAccessRulesByCodePrefix(orgId, 'sto');

      this.permissionsController.addPermissionList(
        'sto',
        actionList
          .filter(item => Boolean(item?.available))
          ?.flatMap(item => item.code as EStoAction),
        {
          isClearPreviousList: true,
        }
      );

      return actionList;
    } catch (error) {
      console.log(error);
    }
  };
}

export default StoBaseController;
