import { booleanPointInPolygon } from '@turf/turf';
import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { toTurfPolygon } from '../../../utils/helpers';
import { MapEventBus } from '../../MapCore';
import { MapMarkerValidationService } from '../../../mobx/services/MapMarkerValidationService';
import { BasePolygon, BaseLayerGroup } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapLayerGroupStore from '../stores/MapLayerGroup.store';

@provide.transient()
class MapLayerGroupEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapLayerGroupStore)
  private layerGroupStore: MapLayerGroupStore;

  @lazyInject(MapMarkerValidationService)
  private markerValidationService: MapMarkerValidationService;

  public registerClickEvent(layer: BaseLayerGroup) {
    if (!this.coreStore.instance) {
      return;
    }

    layer.on('click', this.handleClickLayerGroup);
  }

  public handleClickLayerGroup = (event: L.LeafletMouseEvent) => {
    this.coreStore.preventMapClick = true;

    const layerGroup = event.target as BaseLayerGroup;
    const mainPolygon = layerGroup?.getMainPolygon();
    const clickedPolygon = event.layer as BasePolygon;

    const { selectedLayerGroup } = this.layerGroupStore;

    if (!layerGroup || !clickedPolygon || !mainPolygon) {
      console.warn('Недостатчно данных для обработки клика на группу слоев');
      return;
    }

    if (layerGroup.id === selectedLayerGroup?.id) {
      return;
    }

    MapEventBus.emit('layerGroup.click', {
      layerGroup,
      clickedPolygon,
      mainPolygon,
    });
  };

  /**
   * Вызывает событие отмены выделения слоя при клике на область карты без слоев
   */
  public handleDeselectLayerGroup = (event: L.LeafletMouseEvent) => {
    if (!this.layerGroupStore.selectedLayerGroup) {
      return;
    }

    const isInsideLayerGroup = this.layerGroupStore.layerGroupsList.some(layerGroup => {
      const point = [event.latlng.lng, event.latlng.lat];
      const polygon = toTurfPolygon(layerGroup.getMainPolygon());

      return booleanPointInPolygon(point, polygon);
    });

    if (!isInsideLayerGroup) {
      MapEventBus.emit('layerGroup.cancelSelected');
    }
  };
}

export default MapLayerGroupEventsService;
