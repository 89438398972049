import { Field } from '../../../../../../../../api/models/field.model';
import { IMapLayerGroupConfig } from '../../../../../../../shared/features/map/models';
import { FieldLayerGroup } from '../../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { EFieldsMode } from '../../../../../../modules/fields/constants';
import { AbstractFieldsController } from '../../../../../../modules/fields/mobx/controllers';
import { FIELD_POLYGON_OPTIONS } from '../../../../../../modules/fields/utils/constants/PolygonOptions.constant';
import { FieldSeasonsStore } from '../../../../../../stores/field.seasons.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import FieldSeasonsService from '../services/FieldSeasons.service';

@provide.transient()
class FieldSeasonsController extends AbstractFieldsController {
  @lazyInject(FieldSeasonsStore)
  private store: FieldSeasonsStore;

  @lazyInject(FieldSeasonsService)
  private service: FieldSeasonsService;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  public async initialize(): Promise<void> {
    this.sharedFieldsStore.fieldsMode = EFieldsMode.FIELD_SEASONS;

    const { selectedField } = this.sharedFieldsStore;
    const { selectedSeason } = this.seasonsStore;

    if (!selectedField || !selectedSeason) {
      return;
    }

    this.store.clear();
    await this.service.fetchAllFieldSeasons(selectedField);

    this.renderFieldBySeason(selectedSeason);
  }

  public renderFieldBySeason(seasonYear: string | number): void {
    const selectedSeason = this.store.yearToField.get(Number(seasonYear));
    if (!selectedSeason) {
      return;
    }

    this.mapCoreController.clear();

    const field = selectedSeason?.fieldVersion;

    const config = this.getLayerGroupConfig(field);
    const mapElement = this.mapLayerGroupController.display(config);
    this.fieldsStore.attachMapElements([mapElement]);

    this.selectField(field, { skipFillAfterSelect: true });

    this.fieldFillController.setFillStrategy(EFieldFill.Cultures, field);
  }

  public async copyFieldFromSeason(toSeasonYear: number): Promise<void> {
    const { selectedSeasonYearToCopy, yearsToFieldIdToCopy } = this.store;
    const fieldId = yearsToFieldIdToCopy.get(selectedSeasonYearToCopy).fieldVersion.id;

    await this.service.copyFieldFromSeason(fieldId, selectedSeasonYearToCopy, toSeasonYear);

    await this.initialize();
  }

  protected getLayerGroupConfig(field: Field): IMapLayerGroupConfig {
    const config = super.getLayerGroupConfig(field);
    const hasCultures = Boolean(field?.cultureZones?.length);

    config.layerGroup = new FieldLayerGroup(field, {
      selectedStyle: hasCultures
        ? FIELD_POLYGON_OPTIONS.displayTransparent
        : FIELD_POLYGON_OPTIONS.selected,
    });

    return config;
  }

  protected registerMapEvents = () => null;
}

export default FieldSeasonsController;
