import { PolylineOptions } from 'leaflet';

import { CultureZone } from '../../../../../../../api/models/field.model';
import { CULTURE_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';

import BasePolygon from './BasePolygon';

type TGetOptionsProp = PolylineOptions | ((value: CultureZone) => PolylineOptions);

class CultureZonePolygon extends BasePolygon {
  constructor(cultureZone: CultureZone, options: TGetOptionsProp) {
    const opts = getPolygonOptions(cultureZone, options);

    super(cultureZone.geometry.coordinates, opts);
  }
}

export default CultureZonePolygon;

function getPolygonOptions(zone: CultureZone, prop?: TGetOptionsProp): PolylineOptions {
  if (typeof prop === 'function') {
    return prop(zone);
  }

  if (typeof prop === 'object') {
    return prop;
  }

  const getStyle = CULTURE_POLYGON_OPTIONS.cultureZone;

  return getStyle(zone.culture?.attrs?.assistanceColorLegend);
}
