import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ImageOverlay } from 'leaflet';
import moment from 'moment';

import { useStore } from '../../../../../../utils/IoC';
import { SeasonsStore } from '../../../../../../../dashboard/stores/seasons.store';
import { TaskStore } from '../../../../../../../dashboard/modules/tasks/mobx/stores';
import { InspectionPointsStore } from '../../../../../../../dashboard/modules/tasks/components/InspectionPoints/mobx/stores';
import { EFieldFill } from '../../../../../../../dashboard/constants/FieldFill.enum';
import { EIndexType, IndexModel } from '../../../../../../../../api/models/indices.model';
import { useIndicesDateSelect } from '../../../../hooks';
import { useDefaultIndex } from '../../hooks';
import { InspectionPointsElementStyles as zoneStyle } from '../../../../../../../dashboard/modules/tasks/components/InspectionPoints/utils/constants';

import { Calendar, FillSelector, TimeLineSkeleton, Timeline } from './components';
import { IndicesController, IndicesStore } from './mobx';
import IndicesService from './mobx/services/Indices.serivices';

const Indices = () => {
  const { selectedSeason } = useStore(SeasonsStore);
  const { selectedField, selectedTask } = useStore(TaskStore);
  const { zoneElement } = useStore(InspectionPointsStore);
  const {
    clearStore,
    currentFillMode,
    getIndicesListByFieldId,
    getIndicesYearListByFieldId,
    currentYear,
    setCurrentYear,
    selectedIndex,
    setSelectedIndex,
    selectedIndexIndex,
    clearSelectedIndex,
  } = useStore(IndicesStore);
  const { createImageOverlay } = useStore(IndicesService);

  const { getClosestDateIndex } = useIndicesDateSelect();

  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [currentOverlay, setCurrentOverlay] = useState<ImageOverlay>(null);
  const planDate = moment(selectedTask?.planEndDate);

  const { fetchIndices } = useStore(IndicesController);

  const yearList = getIndicesYearListByFieldId(selectedField?.id);
  const indicesList = getIndicesListByFieldId(selectedField?.id);

  const isDaySelectionAvailable = currentFillMode !== EFieldFill.None;

  const { findDefaultIndex } = useDefaultIndex({
    fieldId: selectedField.id,
    fillMode: currentFillMode,
    fieldSeason: selectedTask.operationInfo.seasonYear,
    status: [EIndexType.Completed],
    targetDate: planDate,
  });

  useEffect(() => {
    setCurrentYear(selectedTask.operationInfo.seasonYear);

    return () => {
      clearStore();
    };
  }, []);

  const selectIndex = (index: IndexModel, arrayIndex: number) => {
    if (index) {
      currentOverlay?.getElement().remove();

      setSelectedIndex(index, arrayIndex);

      const overlay = createImageOverlay(
        zoneElement?.polygon?.id,
        index.visImage,
        selectedField?.id,
        selectedField.geometry.coordinates
      );

      setCurrentOverlay(overlay);
    }
  };

  useEffect(() => {
    if (currentFillMode === EFieldFill.Ndvi) {
      setIsLoading(true);

      findDefaultIndex(planDate.get('year'))
        .then(({ index, arrayIndex }) => {
          selectIndex(index, arrayIndex);
        })
        .finally(() => {
          setIsLoading(false);
          setIsFirstLoading(false);
        });

      zoneElement?.polygon.setStyle(zoneStyle.zoneIndexNdvi);
    } else {
      currentOverlay?.remove();
      zoneElement?.polygon.setStyle(zoneStyle.zoneEdit);
    }
  }, [currentFillMode]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const indexList = await fetchIndices(
      selectedField.id,
      Number(selectedSeason),
      currentFillMode,
      [EIndexType.Completed],
      `${currentYear}-01-01`,
      `${currentYear}-12-31`
    );

    setIsLoading(false);

    return indexList;
  }, [selectedField?.id, currentFillMode, currentYear, isFirstLoading]);

  useEffect(() => {
    currentOverlay?.getElement().remove();

    if (currentFillMode && currentYear && selectedField?.id && !isFirstLoading) {
      if (currentFillMode !== EFieldFill.None) {
        fetchData().then(list => {
          const closestIndex = getClosestDateIndex(
            list,
            moment(selectedTask?.planEndDate).toDate()
          );

          const index = closestIndex?.index ?? 0;

          selectIndex(list[index], index);
        });
      }
    }
  }, [currentYear]);

  const dateToIndex = useMemo<Map<string, IndexModel & { index: number }>>(() => {
    const newMap = new Map();

    indicesList.forEach((item, i) => newMap.set(item.fromDate, { ...item, index: i }));
    return newMap;
  }, [indicesList]);

  const onYearChange = (year: number) => {
    setCurrentYear(year);
    clearSelectedIndex();
  };

  return (
    <>
      <FillSelector />
      {isDaySelectionAvailable ? (
        isLoading ? (
          <TimeLineSkeleton />
        ) : (
          <>
            <Timeline
              selectIndex={selectIndex}
              yearList={yearList}
              indicesList={indicesList}
              refetchData={fetchData}
              arrayIndex={selectedIndexIndex}
            />
            {yearList?.length ? (
              <Calendar
                value={selectedIndex?.fromDate}
                dateMap={dateToIndex}
                setIndex={selectIndex}
                onYearChange={onYearChange}
                availableYearList={yearList}
              />
            ) : null}
          </>
        )
      ) : null}
    </>
  );
};

export default observer(Indices);
