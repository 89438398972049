import { FC, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useParams, useNavigate, Outlet } from 'react-router';
import { observer } from 'mobx-react';

import { SeasonsController } from '../../controllers/seasons/seasons.controller';
import { useStore } from '../../../shared/utils/IoC';
import { ProfileStore } from '../profile/stores/ProfileStore';
import { ProfileController } from '../profile/controllers/profile.controller';
import { OrganizationsStore } from '../../stores/organizations.store';
import { EAppSettingsType } from '../../../..';
import {
  MainWrapper,
  MapWrapper,
  MapsControlsWrapper,
} from '../../components/DashboardLayout/style';
import { FullWeatherSlider } from '../../components/FullWeatherSlider';
import { HoleTooltip } from '../../components/HoleTooltip/HoleTooltip';
import { LegendControls } from '../../components/LegendControls/LegendControls';
import { PopupSlider } from '../../components/PopupSlider';
import { Map } from '../../../shared/features/map/components';
import PointCoordinatesControls from '../../../map/components/PointCoordinatesControls/PointCoordinatesControls';
import { CreateControls } from '../../components/CreateControls/CreateControls';
import { TopPanelControls } from '../../components/TopPanelControls';

import { FieldsRoute } from './fields.route';

import '../../../map/style.css';

export const FieldsRouter: FC<{ orgId?: string }> = observer(() => {
  const navigate = useNavigate();

  const { orgId, season, fieldId } = useParams<{
    orgId?: string;
    season?: string;
    fieldId?: string;
  }>();

  const { selectedOrganizationId } = useStore(OrganizationsStore);
  const { isLoadingBySeasonFieldUrl } = useStore(ProfileStore);

  const seasonsController = useStore(SeasonsController);

  const { setIsLoadingBySeasonFieldsUrl } = useStore(ProfileController);
  const profileController = useStore(ProfileController);

  useEffect(() => {
    (async () => {
      if (isLoadingBySeasonFieldUrl && selectedOrganizationId === orgId && season && fieldId) {
        await seasonsController.fetchMySeasons();
        await seasonsController.setSelectedSeason(season);

        profileController.setAppSettings(EAppSettingsType.Season)(season);

        navigate(generatePath(FieldsRoute.Listing, { orgId }));
        setIsLoadingBySeasonFieldsUrl(false);
      }
    })();
  }, [selectedOrganizationId]);

  return (
    <>
      <MainWrapper>
        {/* Outlet для левого меню в котором отображаются Fields*/}
        <Outlet />

        <MapsControlsWrapper>
          <FullWeatherSlider />
          <MapWrapper>
            <HoleTooltip />
            <TopPanelControls />
            <Map
              globalConfig={{
                mapKey: 'fields-map',
              }}
            />
            <CreateControls />
            <LegendControls />
            <PointCoordinatesControls />
          </MapWrapper>
          <PopupSlider />
        </MapsControlsWrapper>
      </MainWrapper>
    </>
  );
});
