import { Button, useModal } from '@farmlink/farmik-ui';
import React, { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ButtonWrapper } from '../../add/style';
import { ButtonLoader } from '../../../../../../shared/components/ButtonLoader/ButtonLoader';
import { useStore } from '../../../../../../shared/utils/IoC';
import { EditFieldsController, EditFieldsStore, FieldsStore } from '../../../mobx';
import { FieldsRoute } from '../../../fields.route';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { useWarningBeforeLeavingThePage } from '../../../../../../shared/hooks/useWarningBeforeLeavingThePage';
import useMapValidationHook from '../../../../../../shared/features/map/hooks/useMapValidation/useMapValidation.hook';
import { EFieldsEditModalName } from '../../../modals/edit/configs/fieldsEditModalsConfig';
import { FieldsErrors } from '../../../constants';

export const FooterContainer = observer(() => {
  const navigate = useNavigate();
  const editFieldsStore = useStore(EditFieldsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const fieldsStore = useStore(FieldsStore);

  const editFieldController = useStore(EditFieldsController);

  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();
  const { hasErrorsLayers } = useMapValidationHook();
  const { openModalByModalId } = useModal();

  const fieldListingRoute = useMemo(
    () => generatePath(FieldsRoute.Listing, { orgId: organizationsStore.selectedOrganizationId }),
    [organizationsStore.selectedOrganizationId]
  );

  const handleClose = () => {
    if (editFieldsStore.isFieldChanged) {
      showWarningBeforeLeavingThePage(() => {
        editFieldController.destroy();
        navigate(fieldListingRoute);
      });

      return;
    }

    editFieldController.destroy();
    navigate(fieldListingRoute);
  };

  const handleSave = async () => {
    if (!editFieldsStore.isFieldChanged) {
      return;
    }

    if (editFieldsStore.isAreaTooBig) {
      openModalByModalId(EFieldsEditModalName.OversizedAreaWarning);
      return;
    }

    if (editFieldsStore.hasError) {
      openModalByModalId(EFieldsEditModalName.IntersectionWarning);
      return;
    }

    if (editFieldsStore.isCultureZoneOutOfField) {
      openModalByModalId(EFieldsEditModalName.IntersectionWarning);
      return;
    }

    const promise = editFieldController.submitField();
    await promise
      .then(() => {
        editFieldController.destroy();
        navigate(fieldListingRoute);
      })
      .catch(err => {
        const msg = err.message;

        if (msg === FieldsErrors.Intersection || msg === FieldsErrors.CrossingGeometry) {
          openModalByModalId(EFieldsEditModalName.IntersectionWarning);
        }
      });
  };

  const canSave = !editFieldsStore.hasError && !editFieldsStore.isCultureZoneOutOfField;

  return (
    <>
      <ButtonWrapper>
        <Button
          type={'button'}
          color={'secondary'}
          onClick={handleClose}
          dataTestId={'fields-edit-cancel'}
        >
          Отменить
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonLoader
          isLoading={editFieldsStore.isLoading || fieldsStore.isLoading}
          onClick={handleSave}
          disabled={!canSave}
          data-test-id={'fields-edit-save'}
        >
          Сохранить
        </ButtonLoader>
      </ButtonWrapper>
    </>
  );
});
