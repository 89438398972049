import { MapEventBus } from '../../../../../../shared/features/map/modules/MapCore';
import { cultureZoneDrawModelOptions } from '../../../../../../shared/features/map/utils/constants/cultureZone';
import { BasePolygon } from '../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EFieldsMode } from '../../../../../modules/fields/constants';
import CreateCultureZoneService from '../services/CreateCultureZoneService';
import DrawerCultureZoneService from '../services/DrawerCultureZoneService';
import EditCultureZoneService from '../services/EditCultureZoneService';

import AbstractCultureZoneController from './AbstractCultureZone.controller';
import DisplayCultureZoneController from './DisplayCultureZone.controller';

@provide.singleton()
class CreateCultureZoneController extends AbstractCultureZoneController {
  @lazyInject(DisplayCultureZoneController)
  protected displayCZController: DisplayCultureZoneController;

  @lazyInject(CreateCultureZoneService)
  protected createCZService: CreateCultureZoneService;

  @lazyInject(DrawerCultureZoneService)
  protected drawerCZService: DrawerCultureZoneService;

  @lazyInject(EditCultureZoneService)
  protected editCZService: EditCultureZoneService;

  constructor() {
    super();
    // todo времненно проверить после рефактора кз
    // MapEventBus.on('draw.polygon.create', this.createCultureZoneEvent);
  }

  public startDrawZone = (): void => {
    this.mapDrawerController.enableDrawPolygon(cultureZoneDrawModelOptions);
    this.cultureZoneStore.setIsCultureZoneCreating(true);
    this.cultureZoneStore.setIsZoneNotificationWarning(true);
  };

  handleEditZone = (mapId: string | string[]) => {
    console.log('handleEditZone new');
    if (!Array.isArray(mapId)) {
      const model = this.cultureZoneStore.idToCultureZone.get(mapId);
      if (!model || this.cultureZoneStore.selectedCultureZoneModel) {
        return;
      }
      const selectedPolygon = this.mapPolygonStore.getPolygon(Number(model.polyId));
      if (!selectedPolygon) {
        return;
      }

      this.cultureZoneStore.setIsZoneNotificationWarning(true);
      this.cultureZoneStore.lastValidatedGeometry = model.geometry;
      this.cultureZoneStore.selectedCultureZoneModel = model;
      this.sharedFieldsStore.fieldsMode = EFieldsMode.FIELD_CULTURE_ZONE_EDIT;

      this.mapDrawerController.enableEditPolygon(selectedPolygon, {
        allowSelfIntersection: true,
      });
      selectedPolygon.on('pm:markerdragend', this.editCZService.handleChangeGeometry);
    }
  };

  public createCultureZoneEvent = (polygon: BasePolygon) => {
    // создание кз

    const { newZones, isPolygon } = this.createCZService.handleCreateCultureZone(polygon);
    if (!newZones.length) {
      return;
    }

    this.cultureZoneStore.idToCultureZone.clear();
    newZones.forEach(zone => {
      this.cultureZoneStore.idToCultureZone.set(zone.mapId, zone);
    });

    // создание дыр
    this.createHoles();

    // отрисовка кз
    this.displayCZController.renderCultureZones();
    if (isPolygon) {
      this.handleEditZone(newZones[0].mapId);
    } else {
      this.handleEditZone(newZones.flatMap(item => item[0].mapId));
    }
  };

  acceptTheCreatedNewZone = (): void => {
    console.log('acceptTheCreatedNewZone new');
    this.acceptTheCreatedNewCZ();
  };

  cancelTheCreatedNewZone = () => {
    this.cancelTheCreatedNewCZ();
  };
}

export default CreateCultureZoneController;
