import booleanIntersects from '@turf/boolean-intersects';

import { EPolygonErrorType } from '../../models/PolygonErrors/PolygonErrors.model';
import { BasePolygon } from '../MapElements';

import { hasIntersectionsError } from './polygonErrors';

interface IGetIntersections {
  /**
   * Список полигонов которые пересекаются с comparableLayer
   */
  intersectedList: Map<number, BasePolygon>;

  /**
   *   Список полигонов у которых были пересечения с comparableLayer, но после валидации они исчезли
   */
  notIntersectedList: Map<number, BasePolygon>;
}

function getIntersections(
  comparableLayer: BasePolygon,
  layerList: BasePolygon[]
): IGetIntersections {
  const result: IGetIntersections = {
    intersectedList: new Map<number, BasePolygon>(),
    notIntersectedList: new Map<number, BasePolygon>(),
  };

  const comparableLayerGeoJSON = comparableLayer.toGeoJSON();

  layerList.forEach(layer => {
    const layerGeoJSON = layer.toGeoJSON();
    if (comparableLayer.id === layer.id) {
      return;
    }

    const isIntersect = booleanIntersects(comparableLayerGeoJSON, layerGeoJSON);
    const isAlreadyIntersect = layer.intersections.has(comparableLayer.id);

    // Если поля пересекаются то сетаем в эти поля ссылки друг на друг
    if (isIntersect) {
      layer.intersections.set(comparableLayer.id, comparableLayer);
      comparableLayer.intersections.set(layer.id, layer);

      const error = hasIntersectionsError();
      layer.errors.set(error.type, error);
      comparableLayer.errors.set(error.type, error);

      // Если до этой проверки у полей не было пересечения
      if (!isAlreadyIntersect) {
        result.intersectedList.set(layer.id, layer);
      }
    } else {
      layer.intersections.delete(comparableLayer.id);
      comparableLayer.intersections.delete(layer.id);

      if (!layer.intersections.size) {
        layer.errors.delete(EPolygonErrorType.Intersection);
      }

      // Если у полей до этого были пересечения, а также у полигона больше нет ошибок.
      if (isAlreadyIntersect && !layer.hasErrors) {
        result.notIntersectedList.set(layer.id, layer);
      }
    }
  });

  if (comparableLayer.intersections.size) {
    result.intersectedList.set(comparableLayer.id, comparableLayer);
  }

  if (!comparableLayer.intersections.size) {
    comparableLayer.errors.delete(EPolygonErrorType.Intersection);
  }

  if (!comparableLayer.hasErrors) {
    result.notIntersectedList.set(comparableLayer.id, comparableLayer);
  }

  return result;
}

export default getIntersections;
