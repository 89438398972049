import { provide } from '../../../../../utils/IoC';
import { hasTooBigAreaInHectares } from '../../../../../../map/utils/helpers';

@provide.transient()
class MapPolygonValidationService {
  // размер поля
  checkPolygonArea = (layer: any): boolean => {
    if (!layer) {
      return false;
    }

    // Проверяем размер поля
    return hasTooBigAreaInHectares(layer);
  };
}

export default MapPolygonValidationService;
