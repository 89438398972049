import React, { useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { DialogModal } from '../../../../../../../components/DialogModal/DialogModal';
import { useStore } from '../../../../../../shared/utils/IoC';
import { DisplayFieldsController } from '../../../mobx';
import { SeasonsStore } from '../../../../../stores/seasons.store';

import { modalActionsData } from './constants';

export const DeleteFieldModal = () => {
  const { getModalPayload, closeModal } = useModal();
  const displayFieldsController = useStore(DisplayFieldsController);
  const seasons = useStore(SeasonsStore);
  const [isDeleteFieldFromAllSeasons, setIsDeleteFieldFromAllSeasons] = useState(false);

  const handleDeleteField = event => {
    event.stopPropagation();
    const { field } = getModalPayload();
    const seasonYearForDelete = isDeleteFieldFromAllSeasons ? null : Number(seasons.selectedSeason);
    displayFieldsController.deleteField(field, seasonYearForDelete);
    closeModal();
  };

  const handleClose = () => {
    closeModal();
  };

  const checkbox = {
    name: 'Удалить это поле из всех сезонов',
    handler: () => setIsDeleteFieldFromAllSeasons(!isDeleteFieldFromAllSeasons),
    checked: isDeleteFieldFromAllSeasons,
  };

  return (
    <DialogModal
      status={'warning'}
      title={'Вы уверены, что хотите удалить поле? Поле будет удалено из текущего сезона'}
      cancel={{
        ...modalActionsData.cancel,
        handler: handleClose,
      }}
      accept={{
        ...modalActionsData.accept,
        handler: handleDeleteField,
      }}
      onClose={handleClose}
      checkbox={checkbox}
    />
  );
};
