export enum EPolygonErrorType {
  InvalidGeometry = 'INVALID_GEOMETRY',
  Intersection = 'INTERSECTION',
  SmallArea = 'SMALL_AREA',
  LargeArea = 'LARGE_AREA',
}

export interface IMapPolygonError {
  type: EPolygonErrorType;
  message: string;
}

export type TGetPolygonError = (message?: string) => IMapPolygonError;
