// @ts-nocheck
/* eslint-disable */

import L from 'leaflet';

import 'Leaflet.Deflate';

import { isInViewport } from '../helpers';
import OptimizedLayerGroup from '../MapElements/layerGroups/OptimizedLayerGroup';

interface IDeflatedLayer extends OptimizedLayerGroup {
  marker: L.Marker;
  zoomThreshold: number;
  zoomState: number;
  _isHidden: boolean;
}

/**
 * Данное расширение над плагином L.Deflate позволяет использовать кластеризацию слоев а также рендер по viewport.
 * Для того чтобы можно было воспользоваться данным слоем, необходимо добавлять в него !только слои которые наследуются от класса OptimizedLayerGroup.
 * OptimizedLayerGroup содержит в себе опции, которые позволяют отключать некоторые функции оптимизации.
 *
 * Запуск механизма оптимизации происходит после срабатывания евента 'moveend' на карте.
 * После срабаывания евента каждый слой рендерится согласно viewport(если включено),
 * а также проверяется на возможность преобразования в маркер с последющей кластеризацией.
 *
 * Все методы представленные в данном классе переопределяют методы класса L.Deflate,
 * исключением являются методы "_optimize", "_optimizeAll", "_toggleLayerToMarker", "_renderByViewport"
 */
L.Deflate.include({
  /**
   * Слой который хранит НЕ кластеризованные слои.
   */
  _conditionalLayer: L.featureGroup([], { pmIgnore: true }),

  /**
   * Данный слой содержит маркеры полигонов/cлоев после их скрытия.
   * Можно передать слой кластеризации (L.markerCluster()) чтобы получить кластеризацию маркеров.
   * Слой инициализируется самим L.Deflate плагином.
   */
  // _featureLayer: L.markerCuster()

  addLayer(layer: IDeflatedLayer) {
    if (!(layer instanceof OptimizedLayerGroup)) {
      console.warn('Слой не наследуется от "OptimizedLayerGroup". Оптимизация невозможна');
      return;
    }

    if (this._map) {
      this.prepLayer(layer);

      this._optimize(layer, this._map.getZoom());
    } else {
      this._needsPrepping.push(layer);
    }
    this._layers[this.getLayerId(layer)] = layer;
  },

  removeLayer(layer: IDeflatedLayer) {
    const layerId = layer in this._layers ? layer : this.getLayerId(layer);

    this._conditionalLayer.removeLayer(this._layers[layerId]);
    if (this._layers[layerId].marker) {
      this._featureLayer.removeLayer(this._layers[layerId].marker);
    }

    delete this._layers[layerId];

    const layerIndex = this._needsPrepping.indexOf(this._layers[layerId]);
    if (layerIndex !== -1) {
      this._needsPrepping.splice(layerIndex, 1);
    }
  },

  clearLayers() {
    this._conditionalLayer.clearLayers();
    this._featureLayer.clearLayers();
    this._layers = [];
  },

  onAdd(map: L.Map) {
    this._conditionalLayer.addTo(map);
    this._featureLayer.addTo(map);

    this._map.on('moveend', this._optimizeAll, this);

    for (let i = 0, len = this._needsPrepping.length; i < len; i += 1) {
      this.addLayer(this._needsPrepping[i]);
    }
    this._needsPrepping = [];
  },

  onRemove(map: L.Map) {
    map.removeLayer(this._conditionalLayer);
    map.removeLayer(this._featureLayer);

    this._map.off('moveend', this._optimizeAll, this);
  },

  _optimize(layer: IDeflatedLayer, endZoom: number) {
    if (!layer || !endZoom) {
      return;
    }

    // Рендерим слой если все оптимизации вылючены. Пропускаем остальную логику
    if (!layer.isClusterable && !layer.isRenderViewport) {
      this._conditionalLayer.addLayer(layer);

      return;
    }

    /**
     * Если опция включена, производим рендер по viewport.
     * Если после рендера полигон был удален, то пропускаем дальнейшую кластеризацию
     */
    const isRendered = this._renderByViewport(layer);
    if (!isRendered) {
      return;
    }

    this._toggleLayerToMarker(layer, endZoom);
  },

  _optimizeAll() {
    const endZoom = this._map.getZoom();

    this.eachLayer(function (layer: IDeflatedLayer) {
      this._optimize(layer, endZoom);
    }, this);
  },

  _toggleLayerToMarker(layer: IDeflatedLayer, endZoom: number): void {
    if (!layer.isClusterable || !layer.marker || endZoom === layer.zoomState) {
      return;
    }

    const showMarker = endZoom <= layer.zoomThreshold;

    if (showMarker && !layer._isHidden && !layer.skipClustering) {
      this._conditionalLayer.removeLayer(layer);
      this._featureLayer.addLayer(layer.marker);
      layer._isHidden = true;
    }

    if (!showMarker && layer._isHidden) {
      this._featureLayer.removeLayer(layer.marker);
      this._conditionalLayer.addLayer(layer);
      layer._isHidden = false;
    }

    layer.zoomState = endZoom;
  },

  _renderByViewport(layer: IDeflatedLayer): boolean {
    if (!layer.isRenderViewport) {
      return true;
    }

    const isLayerInViewport = isInViewport(this._map, layer);
    const isClustered = layer._isHidden;

    // Если элемент вне границ карты, то просто удаляем его
    if (!isLayerInViewport) {
      this._conditionalLayer.removeLayer(layer);
    }

    if (isLayerInViewport && !isClustered) {
      this._conditionalLayer.addLayer(layer);
    }

    return isLayerInViewport;
  },
});
