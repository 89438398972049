import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../shared/utils/IoC';
import { PopupPages } from '../../../../../constants/popup.pages';

@provide.singleton()
class FieldsUIStore {
  private _showFullWeather = false;

  // Хранит состояние нижнего попапа на карте
  private _popupPageState: PopupPages = PopupPages.None;

  constructor() {
    makeAutoObservable(this);
  }

  get showFullWeather() {
    return this._showFullWeather;
  }

  set showFullWeather(value: boolean) {
    this._showFullWeather = value;
  }

  get popupPageState() {
    return this._popupPageState;
  }

  set popupPageState(value: PopupPages) {
    this._popupPageState = value;
  }

  get isSeasonsPopupOpen() {
    return this.popupPageState === PopupPages.Seasons;
  }

  get isCultureZonePopupOpen() {
    return this.popupPageState === PopupPages.CultureZone;
  }

  get isMainPopupOpen() {
    return this.popupPageState === PopupPages.Main;
  }
}

export default FieldsUIStore;
