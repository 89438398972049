import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { MapEventBus } from '../index';
import { MapMarkerValidationService } from '../../../mobx/services/MapMarkerValidationService';
import MapLayerGroupEventsService from '../../MapLayerGroup/services/MapLayerGroupEvents.service';
import MapPolygonEventsService from '../../MapPolygon/services/MapPolygonEvents.service';
import MapCoreStore from '../stores/MapCore.store';

@provide.transient()
class MapCoreEventsService {
  @lazyInject(MapCoreStore)
  private store: MapCoreStore;

  @lazyInject(MapLayerGroupEventsService)
  private layerGroupEventsService: MapLayerGroupEventsService;

  @lazyInject(MapPolygonEventsService)
  private polygonEventsService: MapPolygonEventsService;

  @lazyInject(MapMarkerValidationService)
  private markerValidationService: MapMarkerValidationService;

  public registerGlobalEvents() {
    if (!this.store.instance) {
      return;
    }

    this.store.instance.on('click', this.handleMapClick);

    this.registerResizeObserver();
  }

  public unregisterGlobalEvents() {
    const mapDiv = document.getElementById(this.store.globalConfig.mapKey);

    if (mapDiv) {
      this.store.resizeObserver.unobserve(mapDiv);
    }
  }

  /**
   * Изменяет размер leaflet карты при изменении размера родительского элемента
   */
  private registerResizeObserver() {
    if (!this.store.globalConfig?.mapKey) {
      return;
    }

    const mapDiv = document.getElementById(this.store.globalConfig.mapKey);
    if (!mapDiv) {
      return;
    }

    this.store.resizeObserver = new ResizeObserver(() => {
      this.store.instance?.invalidateSize();
    });
    this.store.resizeObserver.observe(mapDiv);
  }

  private handleMapClick = (event: L.LeafletMouseEvent) => {
    if (this.store.preventMapClick) {
      this.store.preventMapClick = false;
      return;
    }

    MapEventBus.emit('map.click', event);

    this.layerGroupEventsService.handleDeselectLayerGroup(event);
    this.polygonEventsService.handleDeselectPolygon(event);
  };
}

export default MapCoreEventsService;
