import { ReactNode } from 'react';

import { Header, Label, StyledLabelWrapper, Wrapper, Footer, ListWrapper } from './style';

type Props = {
  title: string;
  footer: ReactNode;
  dataTestId: string;
  visible?: boolean;
  expanded?: boolean;
  children: ReactNode;
};
export const FieldsListWrapper = ({
  title,
  footer,
  dataTestId,
  visible,
  expanded,
  children,
}: Props) => {
  return (
    <Wrapper visible={visible} $expanded={expanded} data-test-id={dataTestId}>
      <Header>
        <StyledLabelWrapper>
          <Label data-test-id={`${dataTestId}-label`}>{title}</Label>
        </StyledLabelWrapper>
      </Header>
      <ListWrapper data-test-id={'fields-list-section-wrapper'}>{children}</ListWrapper>
      <Footer>{footer}</Footer>
    </Wrapper>
  );
};
