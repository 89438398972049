import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../../../constants/FieldTooltip.enum';
import { IFillStrategy } from '../../models';

@provide.singleton()
class FieldFillStore {
  private _fieldFillValue: EFieldFill = EFieldFill.None;

  private _tooltipLabelValue: EFieldTooltip = EFieldTooltip.Name;

  private _strategy: IFillStrategy | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Выбранный вариант заполнения полигонов. <br/>(Бывший fieldsStore.showCulturesFillPanel)
  get fieldFillValue() {
    return this._fieldFillValue;
  }

  set fieldFillValue(value) {
    this._fieldFillValue = value;
  }

  get strategy() {
    return this._strategy;
  }

  set strategy(value) {
    this._strategy = value;
  }

  // Указывает какой контент рендерить в тултипе полигона. <br/>(Бывший fieldsStore.showLabelFieldFill)
  get tooltipLabelValue() {
    return this._tooltipLabelValue;
  }

  set tooltipLabelValue(value) {
    this._tooltipLabelValue = value;
  }

  get isSelectedIndexFill() {
    return [EFieldFill.Ndvi, EFieldFill.Msavi].includes(this.fieldFillValue);
  }
}

export default FieldFillStore;
