import { DivIcon, LatLngExpression, MarkerOptions } from 'leaflet';

import BaseInspectionPointMarker from './BaseInspectionPointMarker';

class PlanInspectionPointMarker extends BaseInspectionPointMarker {
  constructor(
    latlng: LatLngExpression,
    position: number,
    isCompleted = false,
    options?: MarkerOptions
  ) {
    super(latlng, { pmIgnore: true, ...options });

    const icon = this.getPointIcon(position, isCompleted);
    this.setIcon(icon);
  }

  protected getPointIcon(position: number, isCompleted = false) {
    const isCompletedClass = `inspection-point_${isCompleted ? 'completed' : 'uncompleted'}`;

    return new DivIcon({
      html: String(position),
      className: `inspection-point inspection-point_plan ${isCompletedClass}`,
    });
  }
}

export default PlanInspectionPointMarker;
