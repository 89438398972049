import { ReactNode } from 'react';

export enum EStoTab {
  Summary = 'Summary',
  Fields = 'Fields',
  Operations = 'Operations',
}

export interface IStoFullscreenConfig {
  goBackText: string;
  titleText: string;
  dataTestIdPrefix: string;
  fullscreenContent: ReactNode;
  titleAdditionalElement?: ReactNode;
}

export interface initiateStoOptions {
  isCreation?: boolean;
  stoId?: string;
}
