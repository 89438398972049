import { provide } from '../../../../../../../../shared/utils/IoC';
import {
  ITableFiltersBuilderConfig as IBuilderConfig,
  ITableFiltersBuilderHeaderConfig as IHeaderConfig,
} from '../../../../../../../../shared/features/TableFiltersBuilder/models/configs';
import { ISTOFilters as IFilters, TSTOFiltersFilterConfig as TFilterConfig } from '../../../models';
import { ETableFiltersBuilderId } from '../../../../../../../constants/configs/TableFiltersBuilderId';

@provide.transient()
class STOFiltersConfigsService {
  public createConfig = (config: { onActionButtonClick: () => void }): IBuilderConfig<IFilters> => {
    const header = this.createHeader({
      onActionButtonClick: config.onActionButtonClick,
    });

    const filterList = this.createFilterList();

    return {
      id: ETableFiltersBuilderId.Sto,
      headerConfig: header,
      filterConfigList: filterList,
    };
  };

  protected createHeader = (config: { onActionButtonClick: () => void }): IHeaderConfig => {
    return {
      title: 'Стандарты техопераций',
      iconsConfig: {
        isShowToggleFilters: true,
      },
      actionButtonConfig: {
        autoRenderConfig: {
          title: 'Создать СТО',
          onClick: config.onActionButtonClick,
        },
      },
    };
  };

  protected createFilterList = (): TFilterConfig[] => {
    return [
      this.createCultureFilter(),
      this.createCultureZoneFilter(),
      this.createActiveOnlyFilter(),
    ];
  };

  protected createCultureFilter = (): TFilterConfig => {
    return {
      id: 'cultureIds',
      name: 'Культура',
      type: 'select',
    };
  };

  protected createCultureZoneFilter = (): TFilterConfig => {
    return {
      id: 'cultureZones',
      name: 'Поля',
      type: 'select',
    };
  };

  protected createActiveOnlyFilter = (): TFilterConfig => {
    return {
      id: 'activeOnly',
      name: 'Только активные',
      type: 'boolean',
      defaultValue: {
        type: 'boolean',
        filterId: 'activeOnly',
        booleanValue: true,
        isDefault: true,
      },
    };
  };
}

export default STOFiltersConfigsService;
