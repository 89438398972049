import { makeAutoObservable } from 'mobx';

import { BasePolygon } from '../../../../../../shared/features/map/utils/MapElements';
import { provide } from '../../../../../../shared/utils/IoC';
import { toFixedWithCeilBackEnd } from '../../../../../../shared/utils/toFixedWithCeil';

export enum ECurrDrawPolygonStatus {
  UNFINISHED,
  FINISHED,
}

export type TCreationField = {
  id: number;
  name: string;
  longLatGeometry: Array<Array<number>>;
  areaInHectare: number;
  polygon: BasePolygon;
};

@provide.singleton()
class CreateFieldsStore {
  // Коллекция созданных полей которые отображаются в правом сайдбаре
  private _createdFieldsById = new Map<number, TCreationField>();

  private _isLoading = false;

  private _hasErrors = false;

  /**
   * Хранит статус текущего рисуемного полигона.
   * Если полигон замкнут, то status = FINISHED
   * Если полигон не замкнут, то статус = UNFINISHED
   * @private
   */
  private _drawStatus = ECurrDrawPolygonStatus.FINISHED;

  constructor() {
    makeAutoObservable(this);
  }

  get createdFieldsList() {
    return Array.from(this._createdFieldsById.values());
  }

  get createdFieldsPolygonsList() {
    return this.createdFieldsList.map(field => field.polygon);
  }

  set hasErrors(value) {
    this._hasErrors = value;
  }

  get hasErrors() {
    return this._hasErrors;
  }

  get isFinishedPolygon() {
    return this._drawStatus === ECurrDrawPolygonStatus.FINISHED;
  }

  get isAreaTooBig() {
    return this.createdFieldsList.some(field => field.areaInHectare > 1000);
  }

  get hasCreatedField() {
    return Boolean(this._createdFieldsById.size);
  }

  set drawStatus(status: ECurrDrawPolygonStatus) {
    this._drawStatus = status;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  getCreatedFieldById = (fieldId: number) => {
    return this._createdFieldsById.get(fieldId);
  };

  updateFiled = filed => {
    this._createdFieldsById.set(filed.id, filed);
  };
  
  setCreatedField = (polygon: BasePolygon): TCreationField => {
    const polygonInfo = polygon.getInfo();

    const newField: TCreationField = {
      id: polygon.id,
      name: `Поле ${toFixedWithCeilBackEnd(polygonInfo.area)} га`,
      areaInHectare: parseFloat(polygonInfo.area.toFixed(7)),
      longLatGeometry: (polygonInfo.coordinates as unknown) as number[][],
      polygon,
    };

    this._createdFieldsById.set(polygon.id, newField);

    return newField;
  };

  deleteCreatedFieldById = (fieldId: number) => {
    this._createdFieldsById.delete(fieldId);
  };

  clearCreatedFieldsById = () => {
    this._createdFieldsById.clear();
  };
}

export default CreateFieldsStore;
