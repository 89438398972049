enum EFieldsMode {
  DISPLAY = 'display',
  CREATE = 'create',
  EDIT = 'edit',

  IMPORT = 'import',

  FIELD_SEASONS = 'field_seasons',

  FIELD_CULTURE_ZONES = 'field_culture_zones',
  FIELD_CULTURE_ZONE_EDIT = 'field_culture_zone_edit',
}

export default EFieldsMode;
