import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Widget } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoStore } from '../../mobx';

import { FieldsTabListing, FieldsTabSearch } from './components';
import { StoFieldsTabController } from './mobx/controllers';
import { StoFieldsTabStore } from './mobx/stores';

const StoFieldsTab: FC = () => {
  const { selectedSto, isViewOnly } = useStore(StoStore);
  const { clearStoFieldsTabStore } = useStore(StoFieldsTabStore);
  const controller = useStore(StoFieldsTabController);

  const isBlocked = isViewOnly;

  useEffect(() => {
    return () => {
      clearStoFieldsTabStore();
    };
  }, []);

  useEffect(() => {
    if (!selectedSto?.id) {
      return;
    }

    controller.fetchFieldList();
  }, [selectedSto?.id]);

  return (
    <Widget
      displayTitle
      title={`Поля: ${selectedSto ? selectedSto?.culture?.name?.toLowerCase?.() : 'культура'}`}
      dataTestId="sto-fields-tab"
    >
      <FieldsTabSearch isBlocked={isBlocked} />
      <FieldsTabListing isBlocked={isBlocked} />
    </Widget>
  );
};

export default observer(StoFieldsTab);
