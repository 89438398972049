import { makeAutoObservable } from 'mobx';

import { Field } from '../../../../../../../api/models/field.model';
import { provide } from '../../../../../../shared/utils/IoC';
import { EFieldsMode } from '../../../constants';

/**
 * Содержит общие переменные, которые используются во всех модах работы карты
 */
@provide.singleton()
class SharedFieldsStore {
  private _fieldsMode: EFieldsMode | null = null;

  private _selectedField: Field | null = null;

  private _fieldToCenter: (Field | string) | null = null;

  private _prevSelectedField: Field | null = null;

  private _isBuildingMap = false;

  // Костыль для КЗ
  public skipInit: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Хранит активный режим работы модуля полей.
   */
  get fieldsMode() {
    return this._fieldsMode;
  }

  set fieldsMode(mode) {
    this._fieldsMode = mode;
  }

  /**
   * Хранит текущее выбранное поле (для любого мода)
   */
  get selectedField() {
    return this._selectedField;
  }

  set selectedField(value) {
    this._prevSelectedField = this._selectedField ? { ...this._selectedField } : null;
    this._selectedField = value;
  }

  /**
   * Хранит поле(или id поля) к которому нужно центрировать. Используется при переходе между режимами
   */
  get fieldToCenter() {
    return this._fieldToCenter;
  }

  set fieldToCenter(value) {
    this._fieldToCenter = value;
  }

  get prevSelectedField() {
    return this._prevSelectedField;
  }

  get isBuildingMap() {
    return this._isBuildingMap;
  }

  set isBuildingMap(value) {
    this._isBuildingMap = value;
  }

  get isDisplayMode() {
    return this._fieldsMode === EFieldsMode.DISPLAY;
  }

  get isCreateMode() {
    return this._fieldsMode === EFieldsMode.CREATE;
  }

  get isImportMode() {
    return this._fieldsMode === EFieldsMode.IMPORT;
  }

  get isEditMode() {
    return this._fieldsMode === EFieldsMode.EDIT;
  }

  get isCultureZoneMode() {
    return this._fieldsMode === EFieldsMode.FIELD_CULTURE_ZONES;
  }

  get isCultureZoneEditMode() {
    return this._fieldsMode === EFieldsMode.FIELD_CULTURE_ZONE_EDIT;
  }
}

export default SharedFieldsStore;
