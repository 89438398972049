import { generatePath, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { ButtonWrapper } from '../style';
import { ButtonLoader } from '../../../../../../shared/components/ButtonLoader/ButtonLoader';
import { useStore } from '../../../../../../shared/utils/IoC';
import { CreateFieldsController, CreateFieldsStore, FieldsStore } from '../../../mobx';
import { FieldsRoute } from '../../../fields.route';
import { useWarningBeforeLeavingThePage } from '../../../../../../shared/hooks/useWarningBeforeLeavingThePage';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { EFieldsCreateModalName } from '../../../modals/create/configs/fieldsCreateModalsConfig';
import { FieldsErrors } from '../../../constants';

export const FooterContainer = observer(() => {
  const navigate = useNavigate();

  const createFieldsController = useStore(CreateFieldsController);

  const createFieldsStore = useStore(CreateFieldsStore);
  const fieldsStore = useStore(FieldsStore);
  const organizationsStore = useStore(OrganizationsStore);

  const {
    hasCreatedField,
    isAreaTooBig,
    isFinishedPolygon,
    isLoading,
    hasErrors,
  } = createFieldsStore;
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();
  const { openModalByModalId } = useModal();
  const fieldsListingRoute = useMemo(
    () =>
      generatePath(FieldsRoute.Listing, {
        orgId: organizationsStore.selectedOrganizationId,
      }),
    [organizationsStore.selectedOrganizationId]
  );

  const handleClose = () => {
    if (hasCreatedField) {
      showWarningBeforeLeavingThePage(() => {
        navigate(fieldsListingRoute);
      });

      return;
    }

    navigate(fieldsListingRoute);
  };

  const handleSave = async () => {
    if (isAreaTooBig) {
      openModalByModalId(EFieldsCreateModalName.OversizedAreaWarning);
      return;
    }

    if (!isFinishedPolygon) {
      openModalByModalId(EFieldsCreateModalName.UnfinishedContourWarning);
      return;
    }

    if (hasErrors) {
      openModalByModalId(EFieldsCreateModalName.IntersectionWarning);
      return;
    }

    await createFieldsController
      .submitFields()
      .then(() => {
        navigate(fieldsListingRoute);
      })
      .catch(err => {
        const { message } = err;

        if (message === FieldsErrors.Intersection || message === FieldsErrors.CrossingGeometry) {
          openModalByModalId(EFieldsCreateModalName.IntersectionWarning);
        }
      });
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          type={'button'}
          color={'default'}
          onClick={handleClose}
          dataTestId={'fields-add-cancel'}
        >
          Отменить
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonLoader
          isLoading={isLoading || fieldsStore.isLoading}
          onClick={handleSave}
          disabled={!hasCreatedField || hasErrors}
          data-test-id={'fields-add-save'}
        >
          Сохранить
        </ButtonLoader>
      </ButtonWrapper>
    </>
  );
});
