import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useModal } from '@farmlink/farmik-ui';
import _, { findIndex } from 'lodash';
import { List } from 'react-virtualized';

import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { FieldsRoute } from '../../fields.route';
import { FieldsStore } from '../../mobx';
import { ImportFieldsController } from '../../mobx/controllers';
import { ImportFieldsStore } from '../../mobx/stores';
import { EFieldsImportModalName } from '../../utils/constants/import';
import { EditableFieldItem, FieldErrorNotification } from '../../components';
import { Field } from '../../../../../../api/models/field.model';
import { TChangeImportedFieldReq } from '../../../../../../api/api';
import { SessionStore } from '../../../../../authorization/stores/session.store';
import { FieldsListWrapper } from '../fieldsListWrapper/fields.list.wrapper';

import Styled from './FieldsImportContainer.styles';
import { FooterContainer } from './components';

const FieldsImportContainer: FC = observer(() => {
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);

  const navigate = useNavigate();

  const importFieldsController = useStore(ImportFieldsController);

  const sessionStore = useStore(SessionStore);
  const organizationsStore = useStore(OrganizationsStore);
  const fieldsImportStore = useStore(ImportFieldsStore);
  const fieldsStore = useStore(FieldsStore);

  const {
    editableField,
    focusTargetId,
    saveErrorModal,
    setIsFieldFocused,
    setIsWaitingForEditRes,
    clearSaveErrorModal,
  } = fieldsImportStore;

  const { changeFieldName, deleteImportedField, changeImportedField } = importFieldsController;

  const { closeModal, openModalByModalId } = useModal();

  const importedFieldNameRef = useRef<HTMLInputElement>(null);

  const fieldsListingRoute = useMemo<string>(() => {
    return generatePath(FieldsRoute.Listing, {
      orgId: organizationsStore.selectedOrganizationId,
    });
  }, [organizationsStore.selectedOrganizationId]);

  useEffect(() => {
    if (!focusTargetId) {
      return;
    }

    if (importedFieldNameRef?.current?.id === `field-card-id-${focusTargetId}`) {
      importedFieldNameRef.current.focus();

      setIsFieldFocused(false);
    }
  }, [focusTargetId]);

  const throttledRequestToChangeField = useRef(
    _.throttle((changedData: TChangeImportedFieldReq) => changeImportedField(changedData), 3000)
  );

  useEffect(() => {
    if (editableField) {
      const { id, name } = editableField;

      const changedData: TChangeImportedFieldReq = { id, name };

      throttledRequestToChangeField.current(changedData);
    }
  }, [editableField]);

  // TODO: Подумать над тем, стоит ли переносить логику валидации с бэка на фронт
  useEffect(() => {
    if (editableField && editableField.geometry) {
      const changedData: TChangeImportedFieldReq = {
        id: editableField.id,
        geoJson: {
          type: 'Feature',
          geometry: editableField.geometry,
        },
      };

      throttledRequestToChangeField.current(changedData);
    }
  }, [editableField?.areaInHectare]);

  useEffect(() => {
    importFieldsController.initialize();
    closeModal();

    return () => {
      importFieldsController.destroy();
    };
  }, []);

  useEffect(() => {
    if (saveErrorModal === EFieldsImportModalName.FileImport) {
      openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: true });
      clearSaveErrorModal();

      navigate(fieldsListingRoute);
      return;
    }

    if (saveErrorModal === EFieldsImportModalName.NotAuthorized) {
      sessionStore.logout();
      return;
    }

    if (saveErrorModal) {
      openModalByModalId(saveErrorModal);
      clearSaveErrorModal();
    }
  }, [saveErrorModal]);

  const handleDeleteImportedField = useCallback(async (field: Field): Promise<void> => {
    await deleteImportedField(field);
  }, []);

  const handleFieldNameChange = useCallback((field: Field, value: string): void => {
    changeFieldName(field, value);
  }, []);

  const totalCountOfIncorrectFields = useMemo<number>(() => {
    return fieldsStore.fieldsList.reduce<Field[]>((incorrectFieldList, field) => {
      if (field?.error) {
        incorrectFieldList.push(field);
      }

      return incorrectFieldList;
    }, [])?.length;
  }, [fieldsStore.fieldsList]);

  const displayedImportedFiledList = useMemo<Field[]>(() => {
    const filteredFields = fieldsStore.fieldsList.filter(field => field?.isImported);

    return _.sortBy(filteredFields, ['errorType', 'area']);
  }, [fieldsStore.fieldsList]);

  const scrollToActive = () => {
    const index = findIndex(displayedImportedFiledList, { id: editableField.id });
    setSelectedFieldIndex(index);
  };

  useEffect(() => {
    if (editableField) {
      scrollToActive();
    }
  }, [editableField?.id]);

  const rowRender = ({ index, key, style }) => {
    const field = displayedImportedFiledList[index];
    return (
      <div style={style} key={key}>
        <EditableFieldItem
          key={field?.id}
          field={field}
          focusTargetId={focusTargetId}
          isSelected={field?.id === editableField?.id}
          onDelete={handleDeleteImportedField}
          onNameChange={handleFieldNameChange}
          onCardClick={() => {
            importFieldsController.selectField(field);
          }}
          ref={importedFieldNameRef}
        />
      </div>
    );
  };

  return (
    <>
      <FieldsListWrapper
        title="Добавление полей"
        footer={<FooterContainer />}
        dataTestId="fields-import-section"
      >
        <>
          {totalCountOfIncorrectFields ? (
            <Styled.ErrorNotificationWrapper>
              <FieldErrorNotification totalErrorCount={totalCountOfIncorrectFields} />
            </Styled.ErrorNotificationWrapper>
          ) : null}
          <List
            rowCount={displayedImportedFiledList.length}
            width={284}
            height={window?.innerHeight - 203}
            rowHeight={92}
            row
            rowRenderer={rowRender}
            scrollToIndex={selectedFieldIndex}
            scrollToAlignment="center"
          />
        </>
      </FieldsListWrapper>
    </>
  );
});

export default FieldsImportContainer;
