import { polygon as turfPolygon } from '@turf/turf';
import { Feature, Polygon } from 'geojson';

import { BasePolygon } from '../MapElements';

import normalizeCoordsForTurf from './normalizeCoordsForTurf';

function toTurfPolygon(leafletPolygon: BasePolygon | L.Polygon): Feature<Polygon> {
  const { coordinates } = leafletPolygon.toGeoJSON().geometry;

  return turfPolygon(normalizeCoordsForTurf(coordinates));
}

export default toTurfPolygon;
