import { BasePolygon } from '../MapElements';

const isBigAreaInHectares = (polygon: BasePolygon): boolean => {
  if (!polygon || typeof polygon?.getInfo !== 'function') {
    return false;
  }

  const areaInHectares = polygon.getInfo().area;

  return areaInHectares > 1000;
};

export default isBigAreaInHectares;
