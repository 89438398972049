import { FIELD_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';
import { lazyInject, provide } from '../../../../../utils/IoC';
import { getIntersections } from '../../../utils/helpers';
import { BasePolygon } from '../../../utils/MapElements';
import { MapValidationStore } from '../../stores';
import { MapValidationService } from '../../services/MapValidationService';

@provide.transient()
class MapValidationController {
  @lazyInject(MapValidationStore)
  private _mapValidationStore: MapValidationStore;

  @lazyInject(MapValidationService)
  private _mapValidation: MapValidationService;

  // public validate(validators: ['bigarea', 'intersection']) {
  //   const errors = [];
  //
  //   if (validators[0]) {
  //     this.checkIntersectionsInList();
  //   }
  //
  //   if (validators[1]) {
  //     this.checkArea();
  //   }
  //
  //   return [];
  // }

  /**
   * Проверяет что переданный полигон имеет пересечения с полигонами из аргумента list.
   * Если пересечения есть, то устанавливает ошибку всем полигонам с пересечениями.
   * Если пересечений нет (но они были перед проверкой), то выводит полигон из состояния ошибки
   */
  public checkIntersectionsInList(polygon: BasePolygon, list: BasePolygon[]) {
    const { intersectedList, notIntersectedList } = getIntersections(polygon, list);

    intersectedList.forEach(element => {
      element.setStyle(FIELD_POLYGON_OPTIONS.error);
    });

    notIntersectedList.forEach(element => {
      element.setInitialStyle();
    });
  }

  public checkAllIntersections(polygonsList: BasePolygon[]) {
    polygonsList.forEach(polygon => {
      this.checkIntersectionsInList(polygon, polygonsList);
    });
  }

  setIncorrectFields = (collection: Set<number>) => {
    this._mapValidationStore.setCollectionOfTempIncorrectPolygonId(collection);
  };

  validateLayers = (
    listOfPolygons: BasePolygon[],
    polygon: BasePolygon | null,
    editedPolygonsById: BasePolygon[]
  ) => {
    const {
      hasIntersections,
      hasPatchedLayers,
      errorInCurrentField,
    } = this._mapValidation.checkForIntersectionsLayer(listOfPolygons, polygon, editedPolygonsById);

    console.log(hasIntersections, hasPatchedLayers, errorInCurrentField);
    if (hasPatchedLayers) {
      this.changeColorOfPatchedLayers([polygon, ...editedPolygonsById]);
    }

    if (hasIntersections) {
      this._mapValidationStore.listOfPrevIncorrectPolygons.forEach(p => {
        this.showErrorInPolygon(p);
      });
    }
    return { errorInCurrentField };
  };

  changeColorOfPatchedLayers = (editedPolygonsById: BasePolygon[]): void => {
    this._mapValidationStore.listOfPatchedPolygons.forEach(polygon => {
      polygon.setStyle(FIELD_POLYGON_OPTIONS.edit);
    });

    editedPolygonsById.forEach(polygon => {
      polygon?.setStyle(FIELD_POLYGON_OPTIONS.display);
    });
  };

  showErrorInPolygon = (polygon: BasePolygon) => {
    polygon?.setStyle(FIELD_POLYGON_OPTIONS.error);
  };
}

export default MapValidationController;
