import React, { FC, useState } from 'react';
import { usePortals } from 'react-portal-hook';
import { useModal } from '@farmlink/farmik-ui';

import { Field } from '../../../../../../api/models/field.model';
import { useWarningBeforeLeavingThePage } from '../../../../../shared/hooks/useWarningBeforeLeavingThePage';
import { useStore } from '../../../../../shared/utils/IoC';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { UiStore } from '../../../../stores/ui.store';
import { DisplayFieldsController, FieldsUIStore, SharedFieldsStore } from '../../mobx';
import { ReactComponent as MenuSvg } from '../../static/menu.svg';
import { getUniqueCulturesNameList } from '../../utils';
import { EFieldsEditModalName } from '../../modals/edit/configs/fieldsEditModalsConfig';

import { ContextMenu, FiledNameAndCulture } from './components';
import {
  ActiveLine,
  AreaLabel,
  Content,
  ContentInformation,
  Line,
  MapImage,
  RightButton,
  RightColumn,
  Wrapper,
} from './style';

type FieldItem = {
  isActive?: boolean;
  onClick: (id: string) => void;
  field: Field;
  isLast?: boolean;
  dataTestId: string;
  deleteField?: (fieldId: string, seasonYear?: number | null) => void;
  className?: string;
};

export const FieldItem: FC<FieldItem> = ({
  isActive,
  isLast,
  onClick,
  field,
  dataTestId,
  className,
}) => {
  const portalManager = usePortals();
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();
  const { openModalByModalId } = useModal();

  const displayFieldsController = useStore(DisplayFieldsController);

  const uiStore = useStore(UiStore);
  const fieldsUiStore = useStore(FieldsUIStore);
  const sharedFieldsStore = useStore(SharedFieldsStore);

  let ContextMenuXPosition = 0;
  let ContextMenuYPosition = 0;
  const [isCardFocused, setIsCardFocused] = useState<boolean>(false);

  const handleMenuClick = event => {
    event.stopPropagation();

    setIsCardFocused(true);

    if (uiStore.isTipOpen) {
      uiStore.closeTip();
      uiStore.closePortal();
      setIsCardFocused(false);
    } else {
      portalManager.open(
        portal => {
          uiStore.setPortal(portal);
          return renderContextMenu(portal.close);
        },
        {
          appendTo: uiStore.tipContainerRef,
        }
      );

      const bounds = event.target.getBoundingClientRect();
      ContextMenuXPosition = bounds.x;
      ContextMenuYPosition = bounds.y;
      uiStore.openTip(bounds.x + 35, bounds.y - 10);
    }
  };

  const openDeleteModal = () => {
    openModalByModalId(EFieldsEditModalName.DeleteFieldWarning, { field });
  };

  const renderContextMenu = closeMenu => {
    return (
      <ContextMenu
        positions={{ x: ContextMenuXPosition, y: ContextMenuYPosition }}
        closeMenu={closeMenu}
        onClick={onClick}
        dataTestId={dataTestId}
        showModal={fieldsUiStore.isCultureZonePopupOpen}
        setIsDeleteModalOpen={openDeleteModal}
        filedId={field.id}
        setIsCardFocused={setIsCardFocused}
      />
    );
  };

  const onFieldClick = () => {
    if (fieldsUiStore.isCultureZonePopupOpen && field.id !== sharedFieldsStore.selectedField?.id) {
      showWarningBeforeLeavingThePage(() => {
        displayFieldsController.initialize().then(() => {
          onClick(field.id);
        });
      });
    } else {
      fieldsUiStore.showFullWeather = false;
      onClick(field.id);
    }
  };

  const culturesString = getUniqueCulturesNameList(field.cultureZones).join(', ');
  return (
    <Wrapper
      onClick={onFieldClick}
      isActive={isActive}
      isCardFocused={isCardFocused}
      className={className || ''}
      data-test-id={'fields-list-item'}
    >
      {isActive && <ActiveLine />}
      <Content>
        <ContentInformation isEdit={false}>
          <MapImage
            imgUrl={field?.icon?.downloadUrl || ''}
            data-test-id={`${dataTestId}-map-photo`}
          />
          <FiledNameAndCulture
            dataTestId={dataTestId}
            fieldName={field.name}
            cultures={culturesString}
          />
          <RightColumn>
            <AreaLabel data-test-id={`${dataTestId}-area`}>
              {field.area ? toFixedWithCeilBackEnd(field.area) : '0'} га
            </AreaLabel>
            <RightButton className="fieldMenuButton">
              <MenuSvg onClick={handleMenuClick} data-test-id={`${dataTestId}-menu-button`} />
            </RightButton>
          </RightColumn>
        </ContentInformation>
      </Content>
      {!isLast ? <Line /> : null}
    </Wrapper>
  );
};
