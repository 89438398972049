import { Field } from '../../../../../../../api/models/field.model';
import { IMapLayerGroupClickPayload } from '../../../../../../shared/features/map/models';
import {
  MapCoreStore,
  MapMarkerController,
  MapEventBus,
} from '../../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../../../../constants/FieldTooltip.enum';
import { PopupPages } from '../../../../../constants/popup.pages';
import { EFieldsMode } from '../../../constants';
import { FieldsService } from '../../services';
import AbstractFieldsController from '../AbstractFieldsController/AbstractFields.controller';

@provide.singleton()
class DisplayFieldsController extends AbstractFieldsController {
  @lazyInject(FieldsService)
  private service: FieldsService;

  @lazyInject(MapCoreStore)
  private mapCoreStore: MapCoreStore;

  @lazyInject(MapMarkerController)
  protected mapMarkerController: MapMarkerController;

  public async initialize() {
    // Костыль для работы КЗ
    if (this.sharedFieldsStore.skipInit) {
      this.sharedFieldsStore.skipInit = false;
      return;
    }

    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.service.fetchFieldsList();

    // чистим карту если нет полей
    if (!fieldsList.length) {
      this.mapCoreController.clear();
      this.UIStore.popupPageState = PopupPages.None;
      this.sharedFieldsStore.fieldsMode = EFieldsMode.DISPLAY;
      return;
    }
    // Подготовка карты. Центрирование
    this.centerMapToFieldBounds(this.fieldToCenter ?? fieldsList[0]);

    // Инициализируем карту. Делаем связь слой - поле
    await this.buildMap(fieldsList, this.getLayerGroupConfig);

    // Выполняем заливку слоев культурными зонами и устанавливаем тултипы для слоев
    this.fieldFillController.setFillStrategy(EFieldFill.Cultures);
    this.fieldTooltipController.setTooltipContent(EFieldTooltip.Name);

    // Временно
    this.UIStore.popupPageState = PopupPages.None;
    this.sharedFieldsStore.fieldsMode = EFieldsMode.DISPLAY;
  }

  public destroy() {
    super.destroy();
  }

  public selectField(field: Field | null) {
    if (!field) {
      return;
    }

    // Временно
    if (this.UIStore.isSeasonsPopupOpen) {
      this.sharedFieldsStore.selectedField = field;
      return;
    }

    // Временно #2
    if (this.UIStore.isCultureZonePopupOpen) {
      super.selectField(field);
      this.sharedFieldsStore.fieldsMode = EFieldsMode.FIELD_CULTURE_ZONES;
      return;
    }

    this.UIStore.popupPageState = PopupPages.Main;
    super.selectField(field);
  }

  public deselectField() {
    super.deselectField();
    this.UIStore.popupPageState = PopupPages.None;
    this.sharedFieldsStore.fieldsMode = EFieldsMode.DISPLAY;
  }

  public deleteField(field: Field, fromSeason?: number) {
    const promise = this.service.deleteField(field.id, fromSeason);

    promise
      .then(() => {
        this.deleteFieldFromMap(field);
        this.fieldsStore.deleteFieldById(field.id);
      })
      .finally(() => {
        if (field.id === this.sharedFieldsStore.selectedField?.id) {
          this.deselectField();
          this.initialize();
        }
      });
  }

  protected registerMapEvents() {
    const listener1 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);
    const listener2 = MapEventBus.on('layerGroup.cancelSelected', this.handleLayerGroupDeselect);

    this.mapEventsListenersList.push(listener1, listener2);
  }

  protected handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const fieldOfLayerGroup = this.getFieldByLayerGroup(payload.layerGroup);

    this.selectField(fieldOfLayerGroup);
  };

  protected handleLayerGroupDeselect = () => {
    // Временно
    if (this.UIStore.isSeasonsPopupOpen || this.UIStore.isCultureZonePopupOpen) {
      return;
    }

    this.deselectField();
  };

  private deleteFieldFromMap(field: Field) {
    const layerGroup = this.getLayerGroupByField(field);
    // todo временный фикс, проверить после рефактора кз
    if (
      this.UIStore.isCultureZonePopupOpen &&
      field.id === this.sharedFieldsStore.selectedField.id
    ) {
      this.initialize();
    }
    if (layerGroup) {
      this.mapLayerGroupController.remove(layerGroup.id);
    }
    const fieldToCenter = this.sharedFieldsStore.fieldToCenter as Field;
    if (field.id === fieldToCenter?.id) {
      this.sharedFieldsStore.fieldToCenter = null;
    }
  }
}

export default DisplayFieldsController;
