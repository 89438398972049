import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const warnBeforeChecklistChangesModalConfig: TModalConfig = {
  name: 'warnBeforeChecklistChanges',
  title: 'Если изменить тип чек-листа, внесенные ранее данные будут потеряны',
  type: EModalType.Warning_Before_Living,
  id: 'warnBeforePhenophaseChanges',
  dataTestId: 'warn-before-checklist-changes',
  denyButton: {
    title: 'Не менять',
    color: 'default',
  },
  successButton: {
    title: 'Изменить',
    color: 'secondary',
  },
  styledProps: {
    $size: EModalSize.Medium,
  },
};

export default warnBeforeChecklistChangesModalConfig;
