import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, useModal } from '@farmlink/farmik-ui';

import { Container } from '../../../../../../../../../shared/features/UI/Container';
import { TDropdownConfig } from '../../../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { ChecklistInstancesStore } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../../../../../modules/Checklists/mobx/stores';
import { ChecklistInstancesController } from '../../../../../../../operationsAndTasks/controllers/checklist.instances.controller';
import { TaskStore } from '../../../../../../mobx/stores';
import { ECheckListInstanceType } from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ISelectOption } from '../../../../../../../../../../types/selectOption';
import { IGetChecklist } from '../../../../../../../../../../api/models/checklist/checklist.model';
import { createChecklistSelectOption } from '../../../../../../../../../shared/utils/helpers/selectOptions';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import { warnBeforeChecklistChangesModalConfig } from './modals';

const ChecklistSelection: FC = () => {
  const getDataTestId = useDataTestIdV2('checklists__checklist-selection');

  const { selectedTask } = useStore(TaskStore);
  const { selectedChecklist } = useStore(ChecklistsStore);
  const { selectedInstance, selectedIntensity, checklistOptionList, defaultChecklist } = useStore(
    ChecklistInstancesStore
  );

  const { selectChecklist } = useStore(ChecklistInstancesController);

  const { registerModalList, openModalByModalId, closeModal } = useModal();

  const [isSuccessfulResult, setIsSuccessfulResult] = useState<boolean | null>(null);

  useEffect(() => {
    registerModalList([warnBeforeChecklistChangesModalConfig], 'checklist-selection');
  }, []);

  const defaultValue = useMemo<ISelectOption<IGetChecklist>>(() => {
    if (!defaultChecklist) return;

    return createChecklistSelectOption(defaultChecklist);
  }, [defaultChecklist]);

  const handleChecklistChange: TDropdownConfig['field']['onChange'] = checklistId => {
    if (selectedChecklist) {
      setIsSuccessfulResult(null);

      const handleSuccess = (): void => {
        selectChecklist(checklistId);
        setIsSuccessfulResult(true);
      };

      const handleDeny = (): void => {
        closeModal();
        setIsSuccessfulResult(false);
      };

      openModalByModalId('warnBeforePhenophaseChanges', null, handleSuccess, handleDeny);
    } else {
      selectChecklist(checklistId);
    }
  };

  const dropdownConfig: TDropdownConfig = {
    field: {
      onChange: handleChecklistChange,
      defaultValue,
      icons: {
        clear: {},
      },
      placeholder: 'Выберите тип-чек листа',
    },
    body: {
      optionList: checklistOptionList.filter(o => o.initialModel.id !== defaultChecklist?.id),
    },
    visual: {
      label: 'Тип чек-листа',
    },
    validation: {
      modal: {
        isShowWarning: Boolean(selectedChecklist),
        modalResult: isSuccessfulResult,
      },
    },
    other: {
      dataTestId: getDataTestId('dropdown')['data-test-id'],
    },
  };

  const isMachineryInstanceSelected = selectedInstance?.type === ECheckListInstanceType.Machinery;

  if (!isMachineryInstanceSelected && selectedTask?.intensityRequired && !selectedIntensity) {
    return <></>;
  }

  if (checklistOptionList.length <= 1) {
    return <></>;
  }

  return (
    <Container header={{ title: 'Тип чек-листа' }} dataTestId={getDataTestId()['data-test-id']}>
      <Dropdown config={dropdownConfig} />
    </Container>
  );
};

ChecklistSelection.displayName = 'ChecklistSelection';

export default observer(ChecklistSelection);
