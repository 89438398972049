import { v4 as createUUID } from 'uuid';
import { sortBy } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  ChecklistsAttrOptionsService as AttrOptionsService,
  ChecklistsAttrsDependencyService as AttrsDependencyService,
  ChecklistsAttrsDisplayService,
  ChecklistsAttrsFormulasService as AttrsFormulasService,
  ChecklistsAttrValuesService as AttrValuesService,
  ChecklistsValidationsService,
} from '../../services';
import { IGetChecklistStage as IStage } from '../../../../../../../../../api/models/checklist/stage/checklist.stage.model';
import { ChecklistsStore } from '../../stores';
import { ChecklistsService } from '../../../../../../../../shared/mobx/services/as-fields';
import {
  IChecklistsAttrToDraw as IAttrToDraw,
  IChecklistsFormulaResult as IFormulaResult,
  IChecklistsNestedInstanceToDraw as INestedInstanceToDraw,
} from '../../../models';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
} from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  ChecklistsBooleanAttrsService,
  ChecklistsChecklistAttrsService as ChecklistAttrsService,
  ChecklistsDateAttrsService,
  ChecklistsDictionaryAttrsService,
  ChecklistsDoubleAttrsService,
  ChecklistsEnumAttrsService,
  ChecklistsFileAttrsService,
  ChecklistsIntegerAttrsService,
  ChecklistsLongStringAttrsService,
  ChecklistsStringAttrsService,
  ChecklistsUserDictionaryAttrsService,
} from '../../services/attributes';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { DictionaryService } from '../../../../../../../../shared/mobx/services/da-dictionary';
import { createChecklistsAttributeId as createAttrId } from '../../../helpers';
import { IDependencyRecordForRequest } from '../../../../../../../../../api/endpoints/dictionary/get.dictionaryList.byRemoteName';
import {
  TChecklistsDictionarySelectOption as TDictionarySelectOption,
  TChecklistsEnumSelectOption,
} from '../../../types';
import { ChecklistInstancesStore } from '../../../../../../operationsAndTasks/stores/checklist.instances.store';

@provide.transient()
class ChecklistsController {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistInstancesStore)
  protected instancesStore: ChecklistInstancesStore;

  @lazyInject(ChecklistsService)
  protected checklistsService: ChecklistsService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(ChecklistsAttrsDisplayService)
  protected attrsDisplayService: ChecklistsAttrsDisplayService;

  @lazyInject(AttrOptionsService)
  protected attrOptionsService: AttrOptionsService;

  @lazyInject(AttrValuesService)
  protected attrValuesService: AttrValuesService;

  @lazyInject(AttrsFormulasService)
  protected attrsFormulasService: AttrsFormulasService;

  @lazyInject(AttrsDependencyService)
  protected attrsDependencyService: AttrsDependencyService;

  @lazyInject(ChecklistsValidationsService)
  protected validationsService: ChecklistsValidationsService;

  @lazyInject(ChecklistsBooleanAttrsService)
  protected booleanAttrsService: ChecklistsBooleanAttrsService;

  @lazyInject(ChecklistsIntegerAttrsService)
  protected integerAttrsService: ChecklistsIntegerAttrsService;

  @lazyInject(ChecklistsDoubleAttrsService)
  protected doubleAttrsService: ChecklistsDoubleAttrsService;

  @lazyInject(ChecklistsStringAttrsService)
  protected stringAttrsService: ChecklistsStringAttrsService;

  @lazyInject(ChecklistsDictionaryAttrsService)
  protected dictionaryAttrsService: ChecklistsDictionaryAttrsService;

  @lazyInject(ChecklistsEnumAttrsService)
  protected enumAttrsService: ChecklistsEnumAttrsService;

  @lazyInject(ChecklistsUserDictionaryAttrsService)
  protected userDictionaryAttrsService: ChecklistsUserDictionaryAttrsService;

  @lazyInject(ChecklistsFileAttrsService)
  protected fileAttrsService: ChecklistsFileAttrsService;

  @lazyInject(ChecklistAttrsService)
  protected checklistAttrsService: ChecklistAttrsService;

  @lazyInject(ChecklistsLongStringAttrsService)
  protected longStringAttrsService: ChecklistsLongStringAttrsService;

  @lazyInject(ChecklistsDateAttrsService)
  protected dateAttrsService: ChecklistsDateAttrsService;

  fetchDictionaryList = async (
    groupId: string,
    id: string,
    payload: TypeApiRequest<'getDictionaryListByRemoteName'>,
    options?: {
      isAllowAutocomplete?: boolean;
    }
  ): Promise<void> => {
    const response = await this.dictionaryService.getDictionaryEntityList(payload);

    if (!response?.content) return;

    const optionList = response.content.map<TDictionarySelectOption>(el => ({
      label: el.name,
      value: el.id,
      initialModel: el,
    }));

    this.attrOptionsService.setSelectOptionList(EAttrType.DictionaryLink, groupId, id, optionList);

    const attrToDraw = this.checklistsStore.getAttrToDraw(groupId, id);

    if (!attrToDraw) return;
    if (!options?.isAllowAutocomplete) return;

    this.attrValuesService.changeAttrValue(EAttrType.DictionaryLink, groupId, {
      checkListAttributeId: id,
      dictionaryValueList: optionList?.[0] ? [optionList[0].value] : [],
    });

    this.attrOptionsService.setSelectedSelectOptionList(
      EAttrType.DictionaryLink,
      groupId,
      id,
      optionList?.[0] ? [optionList[0]] : []
    );
  };

  fetchEnumList = async (
    groupId: string,
    id: string,
    payload: TypeApiRequest<'getChecklistEnumListByAttributeId'>,
    options?: {
      isAllowAutocomplete?: boolean;
    }
  ): Promise<TChecklistsEnumSelectOption[]> => {
    const enumList = await this.checklistsService.getEnumList(payload);

    if (!enumList) return;

    const optionList = enumList.map<TChecklistsEnumSelectOption>(el => ({
      label: el.value,
      value: el.id,
      initialModel: el,
    }));

    this.attrOptionsService.setSelectOptionList(
      EAttrType.UserDictionaryLink,
      groupId,
      id,
      optionList
    );

    const attrToDraw = this.checklistsStore.getAttrToDraw(groupId, id);

    if (!attrToDraw) return;
    if (!options?.isAllowAutocomplete) return;

    this.attrValuesService.changeAttrValue(EAttrType.Enum, groupId, {
      checkListAttributeId: id,
      enumValues: optionList?.[0] ? [optionList[0].value] : [],
    });

    this.attrOptionsService.setSelectedSelectOptionList(
      EAttrType.Enum,
      groupId,
      id,
      optionList?.[0] ? [optionList[0]] : []
    );

    return optionList;
  };

  fetchUserDictionaryList = async (
    groupId: string,
    id: string,
    payload: TypeApiRequest<'getChecklistUserDictionaryListByAttributeId'>
  ): Promise<void> => {
    const response = await this.checklistsService.getUserDictionaryList(payload);

    if (response?.content) {
      const optionList = response.content.map<ISelectOption>(el => ({
        label: el.value,
        value: el.id,
      }));

      const storedValueList = this.userDictionaryAttrsService.getStoredValueList(id);
      const storedOptionList = storedValueList.map<ISelectOption>(storedValue => ({
        label: storedValue.value,
        value: storedValue.id,
      }));

      this.attrOptionsService.setSelectOptionList(EAttrType.UserDictionaryLink, groupId, id, [
        ...optionList,
        ...storedOptionList,
      ]);
    }
  };

  fetchAttrListByPublicId = async (
    payload: TypeApiRequest<'getChecklistAttributeByCheckListPublicId'>
  ): Promise<IAttribute[]> => {
    const response = await this.checklistsService.getAttributeListByPublicId(payload);

    if (response?.content) {
      return response.content;
    } else {
      return [];
    }
  };

  getEnumDependency = (groupId: string, id: string): IFormulaResult => {
    return this.attrsDependencyService.getEnumDependency(groupId, id);
  };

  getDictionaryDependency = (
    groupId: string,
    id: string
  ): IFormulaResult<IDependencyRecordForRequest[]> => {
    return this.attrsDependencyService.getDictionaryDependency(groupId, id);
  };

  addSelectedSelectOptionList: AttrOptionsService['setSelectedSelectOptionList'] = (
    type,
    groupId,
    id,
    selectOptionList,
    options?: {
      isDisplayParent?: boolean;
      remoteName?: string;
    }
  ) => {
    if (options?.isDisplayParent && options?.remoteName) {
      Promise.all(
        selectOptionList.map(async el => {
          if (Boolean(el.initialModel?.crumbs)) {
            return el;
          } else {
            const breadcrumbs = await this.fetchDictionaryItemBreadcrumbs(
              options.remoteName,
              el.value
            );

            return { ...el, initialModel: { ...el?.initialModel, crumbs: breadcrumbs.crumbs } };
          }
        })
      ).then(data => {
        this.attrOptionsService.setSelectedSelectOptionList(type, groupId, id, data);
      });
    }

    this.attrOptionsService.setSelectedSelectOptionList(type, groupId, id, selectOptionList);
  };

  getInstanceTitles: ChecklistAttrsService['getInstanceTitles'] = instanceId => {
    return this.checklistAttrsService.getInstanceTitles(instanceId);
  };

  fetchStageList = async (): Promise<IStage[]> => {
    if (!this.checklistsStore.selectedChecklist) return;

    const stageList = await this.checklistsService.getStageList({
      checklistId: this.checklistsStore.selectedChecklist.id,
    });

    if (!stageList) {
      return [];
    }

    return stageList;
  };

  createAttrToDrawListByStageId = async (taskId: string, stageId: string): Promise<boolean> => {
    const { selectedChecklist } = this.checklistsStore;
    const { selectedInstance } = this.instancesStore;

    const response = await this.checklistsService.getAttributeList({
      taskId,
      stageId,
      checkListId: selectedChecklist.id,
      size: 2000,
      activeOnly: true,
    });

    if (!response?.content) return false;

    const orderedAttrList = sortBy(response.content, 'order');

    this.attrsDisplayService.addAttrList(
      stageId,
      orderedAttrList,
      selectedInstance?.attributeValues ?? []
    );

    return true;
  };

  addInitialNestedInstanceList = (
    groupId: string,
    attrId: string,
    attrList: IAttribute[]
  ): void => {
    const attrValue = this.instancesStore.selectedInstance?.attributeValues?.find?.(
      ({ checkListAttributeId }) => checkListAttributeId === attrId
    );

    if (!attrValue) {
      return;
    }

    const instanceToDrawList: INestedInstanceToDraw[] = [];

    attrValue.checkListInstanceValue.forEach((instance, index) => {
      const instanceToDraw: INestedInstanceToDraw = {
        id: instance.id,
        attrId,
        order: index + 1,
        isOpen: false,
      };

      instanceToDrawList.push(instanceToDraw);

      this.attrsDisplayService.addAttrList(instance.id, attrList, instance.attributeValues, {
        isBlocked: true,
        nestedInstanceId: instance.id,
      });
    });

    this.checklistsStore.setNestedInstanceToDrawList(attrId, instanceToDrawList);
  };

  addTemplateNestedInstance = (groupId: string, attrId: string, attrList: IAttribute[]): void => {
    const instanceList = this.checklistsStore.getNestedInstanceToDrawListByAttrId(attrId);
    const lastInstanceOrder = instanceList[instanceList.length - 1]?.order ?? 0;

    const newInstance: INestedInstanceToDraw = {
      id: createUUID(),
      attrId,
      order: lastInstanceOrder + 1,
      isOpen: true,
      isTemplate: true,
    };

    this.attrsDisplayService.addAttrList(newInstance.id, attrList, [], {
      nestedInstanceId: newInstance.id,
    });
    this.checklistsStore.setNestedInstanceToDraw(attrId, newInstance);
  };

  editNestedInstance = (attrId: string, id: string): void => {
    const instanceList = this.checklistsStore.getNestedInstanceToDrawListByAttrId(attrId);

    const alreadyEditableInstance = instanceList.find(
      instance => instance.isEditing || instance.isTemplate
    );

    if (alreadyEditableInstance) {
      this.checklistsStore.clearAttrIdWithError();
      this.saveNestedInstance(attrId, alreadyEditableInstance.id);
      if (this.checklistsStore.attrIdWithError) return;
    }

    this.checklistsStore.updateNestedInstanceToDraw(attrId, id, { isOpen: true, isEditing: true });

    const attrToDrawList = this.checklistsStore.getAttrToDrawListByGroupId(id);

    attrToDrawList.forEach(attrToDraw => {
      this.checklistsStore.updateAttrToDraw(id, attrToDraw.id, { isBlocked: false });
    });
  };

  removeNestedInstance = (attrId: string, id: string): void => {
    this.checklistsStore.deleteNestedInstanceToDraw(attrId, id);
    this.checklistsStore.deleteAttrsToDrawByGroupId(id);
  };

  openNestedInstance = (attrId: string, id: string): void => {
    this.checklistsStore.updateNestedInstanceToDraw(attrId, id, { isOpen: true });
  };

  closeNestedInstance = (attrId: string, id: string): void => {
    this.checklistsStore.updateNestedInstanceToDraw(attrId, id, { isOpen: false });
  };

  saveNestedInstance = (attrId: string, id: string): void => {
    // Избавляемся от предыдущей валидации.
    this.checklistsStore.clearAttrIdWithError();

    const attrToDrawList = this.checklistsStore.getAttrToDrawListByGroupId(id);
    const attrToDrawListToValidate = attrToDrawList.filter(attrToDraw => attrToDraw.isVisible);
    const orderedList = sortBy(attrToDrawListToValidate, 'order');
    this.validationsService.validateValues(orderedList);

    // Если после валидации есть атрибут с ошибкой, останавливаем сохранение.
    if (this.checklistsStore.attrIdWithError) return;

    this.checklistsStore.updateNestedInstanceToDraw(attrId, id, {
      isOpen: false,
      isEditing: false,
      isTemplate: false,
    });

    attrToDrawList.forEach(attrToDraw => {
      this.checklistsStore.updateAttrToDraw(id, attrToDraw.id, { isBlocked: true });
    });
  };

  saveAttrValues = async (): Promise<boolean> => {
    // Избавляемся от предыдущей валидации.
    this.checklistsStore.clearAttrIdWithError();

    const attrToDrawListToValidate = this.checklistsStore.attrToDrawList.filter(
      attrToDraw => !attrToDraw.nestedInstanceId && attrToDraw.isVisible
    );

    const orderedList = sortBy(attrToDrawListToValidate, 'order');
    const valueList = this.validationsService.validateValues(orderedList);

    // Если после валидации есть атрибут с ошибкой, останавливаем сохранение.
    if (this.checklistsStore.attrIdWithError) return;

    const payload: TypeApiRequest<'postChecklistInstanceAttributeValues'> = {
      checkListId: this.checklistsStore.selectedChecklist.id,
      checkListInstanceId: this.instancesStore.selectedInstance.id,
      values: valueList,
      intensityId: this.instancesStore?.selectedIntensity?.id,
    };

    const response = await this.checklistsService.saveInstanceAttributeValues(payload);

    if (response) {
      // Удаляем значения пользовательских справочников из sessionStorage.
      this.userDictionaryAttrsService.clearStoredData();

      return true;
    }
  };

  changeAttrValue = <T extends EAttrType>(
    type: T,
    groupId: string,
    value: IAttrToDraw<T>['value']
  ): void => {
    const formattedAttrId = createAttrId(groupId, value.checkListAttributeId);
    this.checklistsStore.setLastEditedAttrId(formattedAttrId);

    this.attrValuesService.changeAttrValue(type, groupId, value);
  };

  calculateAttrValue = <T extends EAttrType>(
    type: T,
    groupId: string,
    attrId: string
  ): IFormulaResult => {
    switch (type) {
      case EAttrType.Int:
        return this.integerAttrsService.calculateValue(groupId, attrId);

      case EAttrType.Double:
        return this.doubleAttrsService.calculateValue(groupId, attrId);

      default:
    }
  };

  calculateAttrVisibility = (groupId: string, id: string): IFormulaResult => {
    return this.attrsFormulasService.calculateVisibility(groupId, id);
  };

  toggleAttrVisibility = (groupId: string, id: string, isVisible: boolean): void => {
    this.checklistsStore.updateAttrToDraw(groupId, id, { isVisible });
  };

  showCalculationError = (groupId: string, id: string): void => {
    this.checklistsStore.updateAttrToDrawValidationScheme(groupId, id, {
      errorTitle: 'Ошибка расчета',
      isShowError: true,
    });
  };

  hideCalculationError = (groupId: string, id: string): void => {
    this.checklistsStore.updateAttrToDrawValidationScheme(groupId, id, {
      isShowError: false,
    });
  };

  uploadFile: ChecklistsFileAttrsService['uploadFile'] = file => {
    return this.fileAttrsService.uploadFile(file);
  };

  changeAttrOptions: ChecklistsStore['updateAttrToDrawOptions'] = (groupId, id, payload) => {
    this.checklistsStore.updateAttrToDrawOptions(groupId, id, payload);
  };

  fetchDictionaryItemBreadcrumbs: ChecklistsService['fetchDictionaryItemBreadcrumbs'] = (
    remoteName,
    id
  ) => this.checklistsService.fetchDictionaryItemBreadcrumbs(remoteName, id);

  clearChecklistsStore = (): void => {
    this.checklistsStore.clearSelectedChecklist();

    this.checklistsStore.clearAttrsToDrawByIdByGroupId();
    this.checklistsStore.clearNestedInstancesToDrawByIdByAttrId();
    this.checklistsStore.clearAttrIdToGroupId();

    this.checklistsStore.clearAttrIdWithError();
    this.checklistsStore.clearLastEditedAttrId();
    this.checklistsStore.clearIsAttrIdWithErrorTargeted();
  };
}

export default ChecklistsController;
