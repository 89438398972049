import { useMatch, useNavigate } from 'react-router-dom';

import { useStoParams } from '../useStoParams';
import EStoRoute from '../../routes/sto.routes';

const useStoNavigation = () => {
  const { orgId } = useStoParams();
  const navigate = useNavigate();

  const basePath = `/${orgId}/sto`;

  const navigateToListing = () => {
    navigate(basePath, { replace: true });
  };

  const navigateToSto = (stoId, isReplacePath?: boolean) => {
    navigate(`${basePath}/${stoId}`, { replace: isReplacePath });
  };

  const isCreationPath = Boolean(useMatch(`${basePath}${EStoRoute.StoItemCreate}`));

  return {
    navigateToListing,
    navigateToSto,
    isCreationPath,
  };
};

export default useStoNavigation;
