import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { IMapMarkerElementConfig } from '../../../../../../../../../shared/features/map/models';

import BaseInspectionPointMarker from './BaseInspectionPointMarker';
import PlanInspectionPointMarker from './PlanInspectionPointMarker';
import ActualInspectionPointMarker from './ActualInspectionPointMarker';

type TInstance = IGetChecklistInstanceByTaskId | IPutChecklistInstance | undefined;
type TCoordsKey = 'actualCoords' | 'planCoords';

function extractCoords(instance: TInstance, key: TCoordsKey): [number, number] {
  const coordsFromGeometry = instance?.[key]?.geometry?.coordinates;
  const coords = instance?.[key]?.coordinates ?? coordsFromGeometry;

  if (!coords) {
    return [0, 0];
  }

  // Не совсем понятна логика такого решения. Может быть стоит изначально получать валидные координаты с бэка?
  return [coords[1], coords[0]];
}

function getInspectionMarker(point: IDrawChecklistInstance): BaseInspectionPointMarker {
  if (!point || !point.instance) {
    return new BaseInspectionPointMarker([0, 0]);
  }

  const { instance, positionNumber } = point;

  const actualCoords = extractCoords(instance, 'actualCoords');
  const planCoords = extractCoords(instance, 'planCoords');
  const isCompleted = (instance as IGetChecklistInstanceByTaskId)?.isComplete;

  switch (instance.type) {
    case ECheckListInstanceType.ActualPoint:
      return new ActualInspectionPointMarker(actualCoords, positionNumber, isCompleted);

    case ECheckListInstanceType.Field:
    case ECheckListInstanceType.Machinery:
    case ECheckListInstanceType.PlannedPoint:
      return new PlanInspectionPointMarker(planCoords, positionNumber, isCompleted);
    default:
      return new BaseInspectionPointMarker([0, 0]);
  }
}

function getInspectionMarkerConfig(point: IDrawChecklistInstance): IMapMarkerElementConfig {
  return {
    element: point,
    marker: getInspectionMarker(point),
  };
}

export default getInspectionMarkerConfig;
