export enum EContextualParentPath {
  Tasks = 'assistance/tasks',
  Operations = 'assistance/operations',
  TasksTaskCreateOne = 'assistance/tasks/task/create/one',
  TasksTaskCreateGroup = 'assistance/tasks/task/create/group',
  TasksTaskSummary = 'assistance/tasks/task/summary',
  TasksTaskMap = 'assistance/tasks/task/map',
  TasksTaskChecklists = 'assistance/tasks/task/checklists',
}

export const ContextualPaths = {
  TasksHeaderTitle: `${EContextualParentPath.Tasks}/header__title`,
  OperationsHeaderTitle: `${EContextualParentPath.Operations}/header__title`,
  TasksTaskCreateOneHeaderTitle: `${EContextualParentPath.TasksTaskCreateOne}/header__title`,
  TasksTaskCreateGroupHeaderTitle: `${EContextualParentPath.TasksTaskCreateGroup}/header__title`,
  TasksTaskSummaryTitleNew: `${EContextualParentPath.TasksTaskSummary}/summary__title-new`,
  TasksTaskSummaryTitleInWork: `${EContextualParentPath.TasksTaskSummary}/summary__title-inWork`,
  TasksTaskSummaryTitleCompleted: `${EContextualParentPath.TasksTaskSummary}/summary__title-completed`,
  TasksTaskSummaryTitleCanceled: `${EContextualParentPath.TasksTaskSummary}/summary__title-canceled`,
  TasksTaskMapTitle: `${EContextualParentPath.TasksTaskMap}/map__title`,
  TasksTaskChecklistsInstanceListTitle: `${EContextualParentPath.TasksTaskChecklists}/instance-list__title`,
};
