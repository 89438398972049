import {
  booleanContains,
  difference,
  intersect,
  multiPolygon,
  polygon as turfPolygon,
} from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import area from '@turf/area';
import moment from 'moment';
import { Feature, Polygon } from 'geojson';

import { MapDrawerController } from '../../../../../../shared/features/map/modules';
import { BasePolygon } from '../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { CultureZoneStore } from '../stores/culture.zone.store';
import { normalizeCoordsForTurf } from '../helpers';
import { CultureZoneModel } from '../../../../../../../api/models/culture.zone.model';
import { SeasonsStore } from '../../../../../stores/seasons.store';

@provide.transient()
class CreateCultureZoneService {
  @lazyInject(CultureZoneStore)
  cultureZoneStore: CultureZoneStore;

  @lazyInject(MapDrawerController)
  mapDrawerController: MapDrawerController;

  @lazyInject(SeasonsStore)
  seasonStore: SeasonsStore;

  handleCreateCultureZone = (polygon: BasePolygon): { newZones: any; isPolygon: boolean } => {
    const tempPolygon = polygon.toTurf();
    const fieldPolygon = turfPolygon(
      normalizeCoordsForTurf(this.cultureZoneStore.field.geometry.coordinates)
    );

    const intersectionOfNewPolyWithField = intersect(fieldPolygon, tempPolygon);
    if (!intersectionOfNewPolyWithField) {
      return { newZones: [], isPolygon: false };
    }

    this.mapDrawerController.disableDrawPolygon();

    if (intersectionOfNewPolyWithField.geometry.type === 'Polygon') {
      const newZones = this.createCultureZone(
        intersectionOfNewPolyWithField as Feature<Polygon>,
        tempPolygon
      );
      console.log('newZones', newZones);
      return { newZones, isPolygon: true };
    }

    const newZones: CultureZoneModel[][] = [];
    intersectionOfNewPolyWithField.geometry.coordinates.forEach(zonePolygon => {
      newZones.push(this.createCultureZone(turfPolygon(zonePolygon), tempPolygon));
    });
    console.log('newZones => tempZones', newZones);
    return { newZones, isPolygon: false };
  };

  createCultureZone = (zonePolygon: Feature<Polygon>, tempPolygon: Feature<Polygon>) => {
    const tempCultureZoneList: Array<CultureZoneModel> = [];
    // Add new zone to temp array

    const newZone: CultureZoneModel = this.getCultureZoneModel(zonePolygon);

    tempCultureZoneList.push(newZone);

    this.cultureZoneStore.cultureZones.forEach(zone => {
      const anotherZonePoly = turfPolygon(normalizeCoordsForTurf(zone.geometry.coordinates));

      const differenceOfAnotherZoneWithNew = difference(anotherZonePoly, zonePolygon);

      if (!differenceOfAnotherZoneWithNew) {
        tempCultureZoneList.push(newZone);
        return;
      }

      const isNewPolyInsideInExistingPoly = booleanContains(anotherZonePoly, tempPolygon);

      // If a new cultural zone divides another into two
      const isThroughField = differenceOfAnotherZoneWithNew.geometry.coordinates?.[0]?.length <= 2;

      if (isNewPolyInsideInExistingPoly || !isThroughField) {
        tempCultureZoneList.push(
          this.updateExistingZonesData(differenceOfAnotherZoneWithNew, zone)
        );
      } else {
        differenceOfAnotherZoneWithNew.geometry.coordinates.forEach(coords => {
          tempCultureZoneList.push({
            ...zone,
            culture: {
              id: zone.culture.id,
              query: zone.culture.query,
              name: zone.culture.name,
              attrs: {
                ...zone.culture.attrs,
              },
            },
            polyId: '',
            mapId: uuidv4(),
            area: area(turfPolygon(normalizeCoordsForTurf(coords))) / 10000,
            geometry: {
              coordinates: normalizeCoordsForTurf(coords),
              type: 'Polygon',
            },
            id: '',
          });
        });
      }
    });

    return tempCultureZoneList;
  };

  getCultureZoneModel = (zonePolygon: Feature<Polygon>) => {
    const season = this.seasonStore.getSeasonDataByYear();
    const newZoneMapId = uuidv4();
    const areaInHectars = area(zonePolygon) / 10000;
    return {
      culture: {
        attrs: {
          assistanceColorLegend: '',
        },
        id: '',
        name: '',
        query: '',
      },
      id: '',
      isHole: false,
      polyId: '',
      variety: '',
      geometry: zonePolygon.geometry,
      mapId: newZoneMapId,
      area: areaInHectars,
      harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    };
  };

  updateExistingZonesData = (
    differenceOfAnotherZoneWithNew: any,
    zone: CultureZoneModel
  ): CultureZoneModel => ({
    ...zone,
    culture: {
      id: zone.culture.id,
      query: zone.culture.query,
      name: zone.culture.name,
      attrs: {
        ...zone.culture.attrs,
      },
    },
    polyId: '',
    mapId: uuidv4(),
    area: area(multiPolygon([differenceOfAnotherZoneWithNew.geometry.coordinates])) / 10000,
    geometry: differenceOfAnotherZoneWithNew.geometry as any,
  });
}

export default CreateCultureZoneService;
