import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { toJS } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import DrawerCultureZoneService from '../services/DrawerCultureZoneService';
import EditCultureZoneService from '../services/EditCultureZoneService';
import { CultureZoneModel } from '../../../../../../../api/models/culture.zone.model';

import AbstractCultureZoneController from './AbstractCultureZone.controller';

@provide.singleton()
class DisplayCultureZoneController extends AbstractCultureZoneController {
  @lazyInject(DrawerCultureZoneService)
  protected drawerCZService: DrawerCultureZoneService;

  @lazyInject(EditCultureZoneService)
  protected editCZService: EditCultureZoneService;

  public initiateCulturePolygon = () => {
    const selectedFieldId = this.sharedFieldsStore.selectedField?.id;
    this.cultureZoneStore.field = this.fieldsStore.getFieldById(selectedFieldId);

    if (!this.cultureZoneStore.field) {
      return;
    }

    this.mapCoreController.clear();
    this.cultureZoneStore.idToCultureZone.clear();
    this.replicateCultureZoneData();
    const holes = this.drawerCZService.getHolesPolygons();
    holes?.forEach(hole => this.cultureZoneStore.idToCultureZone.set(hole.mapId, hole));
    this.renderCultureZones();
    this.centerMap();
    this.cultureZoneStore.setInitialIdToCultureZone();
  };

  replicateCultureZoneData = () => {
    if (!(this.cultureZoneStore.field && this.cultureZoneStore.field.cultureZones?.length > 0)) {
      return;
    }

    this.cultureZoneStore.field.cultureZones.forEach(zone => {
      const mapId = uuidv4();
      const model: CultureZoneModel = {
        culture: { ...zone.culture, query: zone.culture.name },
        id: zone.id,
        area: zone.area,
        sowingDate: moment(zone.sowingDate),
        harvestDate: moment(zone.harvestDate),
        geometry: zone.geometry,
        variety: zone.variety,
        mapId,
        experimentReady: zone.experimentReady,
      };
      this.cultureZoneStore.idToCultureZone.set(mapId, model);
    });
  };

  selectCulture = (mapId: string, v: string) => {
    console.log('selectCulture');
    const { idToCultureZone, mapIdToCultureList } = this.cultureZoneStore;
    const zone = idToCultureZone.get(mapId);

    if (!zone) {
      return;
    }
    console.log('new value', v);
    zone.culture.id = v;
    const cultureList = mapIdToCultureList.get(mapId);
    if (!cultureList || !cultureList.length) {
      return;
    }
    const cultures = cultureList.filter(culture => culture.id === v);
    console.log(toJS(cultureList), cultures);
    if (!Boolean(cultures.length)) {
      return;
    }

    zone.culture.name = cultures[0].name;
    zone.culture.attrs.assistanceColorLegend = cultures[0].attrs.assistanceColorLegend;
    idToCultureZone.set(mapId, zone);

    this.renderCultureZones();
  };
}

export default DisplayCultureZoneController;
