import { useModal } from '@farmlink/farmik-ui';
import { useCallback } from 'react';

import { createWarningBeforeLeavingThePage } from '../../configs/modals/global';
import { useBlocker } from '../useBlocker';

type ShowWarningBeforeLeavingThePage = (successHandler: () => void) => void;

interface Actions {
  showWarningBeforeLeavingThePage: ShowWarningBeforeLeavingThePage;
}

const useWarningBeforeLeavingThePage = (
  isBlockActivated?: boolean,
  whiteList?: string[],
  denyHandler?: () => void
): Actions => {
  const { registerModalList, openModalByModalId } = useModal();

  const blocker = useCallback((tx: any) => {
    const modalConfig = createWarningBeforeLeavingThePage(() => tx.retry(), denyHandler);

    registerModalList(modalConfig.configList, modalConfig.key);

    openModalByModalId(modalConfig.name);
  }, []);

  useBlocker(blocker, isBlockActivated, whiteList ?? []);

  const showWarningBeforeLeavingThePage = useCallback<ShowWarningBeforeLeavingThePage>(
    successHandler => {
      const modalConfig = createWarningBeforeLeavingThePage(successHandler);

      registerModalList(modalConfig.configList, modalConfig.key);

      openModalByModalId(modalConfig.name);
    },
    []
  );

  return {
    showWarningBeforeLeavingThePage,
  };
};

export default useWarningBeforeLeavingThePage;
