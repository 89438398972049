import { NoFieldsDescription, NoFieldsImage, NoFieldsTitle, NoFieldsWrapper } from '../style';
import MarkerSvg from '../../../../../../shared/static/map.mark.svg';

export const NoItems = () => {
  return (
    <NoFieldsWrapper data-test-id={'fields-list-no-fields'}>
      <NoFieldsImage src={MarkerSvg} />
      <NoFieldsTitle>У вас ещё нет полей</NoFieldsTitle>
      <NoFieldsDescription>
        Добавьте поля, чтобы получить доступ к снимкам вегетации и данным погоды
      </NoFieldsDescription>
    </NoFieldsWrapper>
  );
};
