import L, { Content, PathOptions, Tooltip } from 'leaflet';
import { cloneDeep } from 'lodash';

import { FIELD_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';
import { IMapPolygonError } from '../../../models';
import { EPolygonErrorType } from '../../../models/PolygonErrors/PolygonErrors.model';
import { BaseTooltipOptions } from '../../constants';
import { toTurfPolygon } from '../../helpers';
import calculateArea from '../../helpers/CalculateArea';
import { hasIntersectionsError } from '../../helpers/polygonErrors';

import AbstractPolygon from './AbstractPolygon';

class BasePolygon extends AbstractPolygon {
  public errors: Map<EPolygonErrorType, IMapPolygonError> = new Map<
    EPolygonErrorType,
    IMapPolygonError
  >();

  // Хранит ссылки на полигоны с которыми пересекается данный полигон
  public intersections: Map<number, BasePolygon> = new Map<number, BasePolygon>();

  public get hasErrors(): boolean {
    return Boolean(this.errors.size);
  }

  /**
   * Возвращает вспомогательную информацию о полигоне
   */
  public getInfo() {
    return {
      area: calculateArea(this),
      coordinates: this.toGeoJSON().geometry.coordinates,
    };
  }

  /**
   * Возвращает turf версию полигона
   */
  public toTurf() {
    return toTurfPolygon(this);
  }

  /**
   * Включает/Выключает геоман для полигона. По дефлоту у всех полигонов геоман выключен
   */
  public setGeomanModule(value: boolean) {
    this.options.pmIgnore = !value;
    L.PM.reInitLayer(this);
  }

  /**
   * Перерисовывает тултип
   */
  public rerenderTooltip() {
    const tooltip = cloneDeep(this.getTooltip());
    if (!tooltip) {
      return;
    }

    this.unbindTooltip();
    this.bindTooltip(tooltip.getContent(), tooltip.options);
  }

  public setTooltipContent(content: Content | Tooltip): this {
    if (!this.getTooltip()) {
      this.bindTooltip(content, BaseTooltipOptions);
      return;
    }

    return super.setTooltipContent(content);
  }

  public setInitialStyle(): void {
    this.setStyle(this.initialOptions);
  }

  public toggleErrorStyle(errorStyle?: PathOptions) {
    if (this.hasErrors) {
      this.setStyle(errorStyle ?? FIELD_POLYGON_OPTIONS.error);
    } else {
      this.setInitialStyle();
    }
  }

  public addIntersection(polygon: BasePolygon): void {
    const error = hasIntersectionsError();
    this.intersections.set(polygon.id, polygon);
    this.errors.set(error.type, error);
  }

  public deleteIntersection(polygonId: number): void {
    this.intersections.delete(polygonId);
    if (!this.intersections.size) {
      this.errors.delete(EPolygonErrorType.Intersection);
    }
  }
}

export default BasePolygon;
