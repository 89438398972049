import { FC, useMemo } from 'react';

import { IChecklistFileItem } from '../../../../../../../operationsAndTasks/stores/checklist.fileUploader.store';

import { MiniImg, MiniImgWrapper, OutContainerPlaceholder, StyledDeleteIcon } from './style';

interface IImageMiniRowProps {
  imgsArr: IChecklistFileItem[];
  isBlocked: boolean;
  onDeleteByIndex: (number) => () => void;
  setSelectedImgIndex: (number) => void;
  parentElem?: null | Element;
}

const MIN_ELEMENT_WIDTH = 385;
const WRAPER_ITEM_WIDTH = 48;
const MAX_ITEM_IN_ROW = 10;

export const ImageMiniRow: FC<IImageMiniRowProps> = ({
  imgsArr,
  isBlocked,
  onDeleteByIndex,
  setSelectedImgIndex,
  parentElem,
}) => {
  if (!parentElem) return null;

  const containerWidth = parseInt(getComputedStyle(parentElem)?.width, 10) - WRAPER_ITEM_WIDTH;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const maxCountForRendering = useMemo(() => {
    const pimaryValue =
      Math.trunc(
        (containerWidth || 0) <= MIN_ELEMENT_WIDTH
          ? MIN_ELEMENT_WIDTH / WRAPER_ITEM_WIDTH
          : containerWidth / WRAPER_ITEM_WIDTH
      ) -
      (imgsArr.length < 10 && !isBlocked ? 2 : 1) +
      1;

    const lessThenMaxValue = pimaryValue > MAX_ITEM_IN_ROW ? MAX_ITEM_IN_ROW : pimaryValue;

    return lessThenMaxValue;
  }, [imgsArr.length]);

  if (maxCountForRendering > 0) {
    return (
      <>
        {imgsArr.map((imgItem, index) => {
          if (!imgItem) return null;
          return (
            index < maxCountForRendering && (
              <MiniImgWrapper key={imgItem.id}>
                {!isBlocked && <StyledDeleteIcon onClick={onDeleteByIndex(index)} />}
                <MiniImg
                  src={imgItem?.downloadUrl ? imgItem?.downloadUrl : imgItem?.imagePreview}
                  onClick={() => setSelectedImgIndex(index)}
                />
              </MiniImgWrapper>
            )
          );
        })}
        {imgsArr?.length > maxCountForRendering && (
          <OutContainerPlaceholder onClick={() => setSelectedImgIndex(maxCountForRendering)}>
            +{imgsArr?.length - maxCountForRendering}
          </OutContainerPlaceholder>
        )}
      </>
    );
  }
  return null;
};
