import { useStore } from '../../../../utils/IoC';
import { MapValidationStore } from '../../mobx/stores';
import { MapValidationController } from '../../mobx';

const useMapValidationHook = () => {
  const { hasErrorsLayers } = useStore(MapValidationStore);
  const { validateLayers } = useStore(MapValidationController);

  return { hasErrorsLayers: hasErrorsLayers(), validateLayers };
};

export default useMapValidationHook;
