import { polygon as turfPolygon } from '@turf/helpers';
import { intersect } from '@turf/turf';
import _ from 'lodash';

import {
  MapCoreController,
  MapDrawerController,
  MapPolygonController,
  MapPolygonStore,
} from '../../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { FieldsStore, SharedFieldsStore } from '../../../../../modules/fields/mobx';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { CultureZoneStore } from '../stores/culture.zone.store';
import { FieldSeasonsStore } from '../../../../../stores/field.seasons.store';
import { CultureZoneModel } from '../../../../../../../api/models/culture.zone.model';
import EditCultureZoneService from '../services/EditCultureZoneService';
import DrawerCultureZoneService from '../services/DrawerCultureZoneService';

@provide.singleton()
abstract class AbstractCultureZoneController {
  @lazyInject(CultureZoneStore)
  protected cultureZoneStore: CultureZoneStore;

  @lazyInject(SharedFieldsStore)
  protected sharedFieldsStore: SharedFieldsStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(MapPolygonStore)
  protected mapPolygonStore: MapPolygonStore;

  @lazyInject(MapCoreController)
  protected mapCoreController: MapCoreController;

  @lazyInject(MapPolygonController)
  protected mapPolygonController: MapPolygonController;

  @lazyInject(MapDrawerController)
  protected mapDrawerController: MapDrawerController;

  @lazyInject(FieldSeasonsStore)
  protected fieldAndSeason: FieldSeasonsStore;

  @lazyInject(EditCultureZoneService)
  protected editCZService: EditCultureZoneService;

  @lazyInject(DrawerCultureZoneService)
  protected drawerCZService: DrawerCultureZoneService;

  getLayerOfSelectedCultureZone = (): any =>
    this.mapPolygonStore.getPolygon(Number(this.cultureZoneStore.selectedCultureZoneModel?.polyId));

  handleChangeGeometry = (event: any) => {
    this.editCZService.handleChangeGeometry(event);
  };

  disableChangeGeometryEvent = (isPmDisable?: boolean): void => {
    const layer = this.getLayerOfSelectedCultureZone();

    if (layer) {
      layer.off('pm:markerdragend', this.handleChangeGeometry);
      // layer.off('pm:vertexclick', throttle(this.mapStore.selectVertex, 300));
      // layer.off('pm:vertexadded', throttle(this.mapStore.selectVertex, 300));
      // layer.off('pm:markerdrag', throttle(this.mapStore.handleLayerMarkerDrag, 300));
      if (isPmDisable) {
        layer.pm.disable();
      }
    }
  };

  calculateHoles = () => {
    console.log('calculateHoles new');
    const newZonePoly = turfPolygon([this.cultureZoneStore.lastValidatedGeometry.coordinates[0]]);
    Array.from(this.cultureZoneStore.idToCultureZone.keys()).forEach((key, index) => {
      if (index > 0) {
        const zone = this.cultureZoneStore.idToCultureZone.get(key);
        const culturePoly = turfPolygon(zone.geometry.coordinates);
        const intersection = intersect(newZonePoly, culturePoly);
        if (intersection) {
          const zoneWithHole: CultureZoneModel = {
            ...zone,
            geometry: {
              ...zone.geometry,
              // @ts-ignore
              coordinates: [zone.geometry.coordinates[0], intersection.geometry.coordinates[0]],
            },
          };
          this.cultureZoneStore.setIdToCultureZone(key, zoneWithHole);
        }
      }
    });
  };

  renderCultureZones = () => {
    console.log('renderCultureZone new');
    this.mapCoreController.clear();

    this.drawerCZService.createCultureZoneBorder();

    this.cultureZoneStore.cultureZones.forEach(cz => {
      if (cz.isHole) {
        const holePolygon = this.drawerCZService.createHole(cz);
        this.cultureZoneStore.holePolyIdToMapId.set(holePolygon.id, cz.mapId);
        this.cultureZoneStore.idToCultureZone.get(cz.mapId).polyId = String(holePolygon.id);
        holePolygon.on('click', this.handleHoleClick);
        return;
      }
      const czPolygon = this.drawerCZService.createCultureZone(cz);
      cz.polyId = String(czPolygon.id);

      czPolygon.on('click', this.editCZService.handleExistingZoneClick);
    });
  };

  handleHoleClick = event => {
    const {
      isCultureZoneCreating,
      isCultureZoneEditing,
      setSelectedPolygonId,
      setTooltipPosition,
      setIsCreateNewZoneTooltipVisible,
    } = this.cultureZoneStore;
    if (isCultureZoneCreating || isCultureZoneEditing) {
      return;
    }
    const { target, containerPoint } = event;
    setSelectedPolygonId(target._leaflet_id);
    setTooltipPosition(containerPoint.x - 110, containerPoint.y - 80);
    setIsCreateNewZoneTooltipVisible(true);
  };

  createHoles = () => {
    const holes = this.drawerCZService.getHolesPolygons();
    holes?.forEach(hole => this.cultureZoneStore.idToCultureZone.set(hole.mapId, hole));
  };

  resetChangesOfSelectedZone = (): void => {
    console.log('resetChangesOfSelectedZone new');
    this.cultureZoneStore.setInitialGeometryOfSelectedZone(null);

    this.cultureZoneStore.setSelectedCultureZoneModel(null);
    this.cultureZoneStore.setLastValidateGeometry(null);

    this.createHoles();
    this.renderCultureZones();

    this.cultureZoneStore.setIsZoneNotificationWarning(false);
  };

  resetIdToCultureZone = (): void => {
    this.cultureZoneStore.clearIdToCultureZone();
    Array.from(this.cultureZoneStore.initialIdToCultureZone).forEach(([key, value]) => {
      this.cultureZoneStore.idToCultureZone.set(key, value);
    });
    this.cultureZoneStore.clearInitialIdToCultureZone();
  };

  cancelDrawing = () => {
    this.mapDrawerController.disableDrawPolygon();
  };

  acceptTheCreatedNewCZ = (): void => {
    console.log('acceptTheCreatedNewCZ new');
    this.cultureZoneStore.setIsCultureZoneCreating(false);
    this.cultureZoneStore.setIsZoneNotificationWarning(false);

    this.calculateHoles();

    this.cultureZoneStore.clearInitialIdToCultureZone();
    this.cancelDrawing();

    // this.fieldsStore.setFieldsMapMode(MapMode.Listing);
    // this.mapStore.clearVertexCoordinates();

    this.acceptEditingCZ();
  };

  acceptEditingCZ = (): void => {
    console.log('acceptEditingCZ new');
    this.disableChangeGeometryEvent(true);

    this.cultureZoneStore.setIsCultureZoneEditing(false);

    // this.fieldsStore.setFieldsMapMode(MapMode.Listing);
    // this.mapStore.clearVertexCoordinates();

    this.resetChangesOfSelectedZone();
  };

  cancelTheCreatedNewCZ = (): void => {
    console.log('cancelTheCreatedNewCZ new');
    const isEmptyInitialZonesList = !this.cultureZoneStore.initialCultureZonesList.length;
    this.cultureZoneStore.setIsCultureZoneCreating(false);
    this.cultureZoneStore.setIsZoneNotificationWarning(false);

    if (!isEmptyInitialZonesList) {
      this.resetIdToCultureZone();

      this.createHoles();
      this.renderCultureZones();
    } else {
      this.cancelDrawing();
    }

    // this.fieldsStore.setMapModeValue(MapMode.Listing);
    // this.mapStore.clearVertexCoordinates();

    this.cancelDrawing();
    this.cultureZoneStore.selectedCultureZoneModel = null;
  };

  cancelEditingCZ = (): void => {
    console.log('cancelEditingCZ new');

    this.disableChangeGeometryEvent(true);

    const selectedCultureZone = this.cultureZoneStore.selectedCultureZoneModel;

    const initialSelectedZoneModel = {
      ...selectedCultureZone,
      geometry: this.cultureZoneStore.initialGeometryOfSelectedZone || selectedCultureZone.geometry,
    };

    this.cultureZoneStore.setIdToCultureZone(selectedCultureZone.mapId, initialSelectedZoneModel);

    this.cultureZoneStore.setIsCultureZoneEditing(false);

    // this.fieldsStore.setMapModeValue(MapMode.Listing);
    // this.mapStore.clearVertexCoordinates();

    this.resetChangesOfSelectedZone();
  };

  isCultureZonesEdited = (): boolean => {
    return !_.isEqual(
      this.cultureZoneStore.initialIdToCultureZone,
      this.cultureZoneStore.idToCultureZone
    );
  };

  centerMap = () => {
    if (!this.cultureZoneStore.field) {
      return;
    }

    const polygon = this.mapPolygonStore.getPolygon(this.cultureZoneStore.field.polyId);

    this.mapCoreController.centerOnBounds(polygon);
    this.mapPolygonController.select(polygon, {
      style: {
        fillColor: '#ffffff',
        fillOpacity: 0.15,
        color: '#ffffff',
      },
    });
  };
}

export default AbstractCultureZoneController;
