import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router';
import { useContextualHelpActions, useNotificator } from '@farmlink/farmik-ui';

import { FieldsStore } from '../../../fields/mobx';
import { FieldsService } from '../../../fields/mobx/services';
import { OperationsList } from '../../components/OperationsList';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { TasksStore } from '../../stores/tasks.store';
import {
  Footer,
  Header,
  Label,
  Wrapper,
  OperationsListWrapper,
  TasksListWrapper,
  LabelWrapper,
} from '../../style';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { CultureList } from '../../components/CultureList';
import { TasksController } from '../../controllers/tasks.controller';
import { TasksList } from '../../components/TasksList/TasksList';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { ModalContainer } from '../../../../../modals/containers';
import { tasksModalUniqueKey } from '../../modals/modalsConfig';
import { EModalSize } from '../../../../../modals/components/Modal/styles';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { DashboardRoutes } from '../../../../dashboard.routes';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../shared/hooks';

export const OperationsListing = observer(() => {
  const store = useStore(OperationsStore);
  const seasonStore = useStore(SeasonsStore);
  const fieldsStore = useStore(FieldsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const navigate = useNavigate();

  const tasksController = useStore(TasksController);
  const tasksStore = useStore(TasksStore);

  const fieldsService = useStore(FieldsService);

  const notificator = useNotificator();

  const {
    accessRulesReady,
    getAccessRuleValue,
    loading: accessRulesLoading,
    accessRules,
  } = useStore(CheckAccessStore);

  const [showSpinner, setShowSpinner] = useState(true);

  usePageContextualHelp(EContextualParentPath.Operations);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.Operations,
    ContextualPaths.OperationsHeaderTitle
  );

  let prevTasksListLength = 0;

  const loadAllData = async () => {
    if (seasonStore.selectedSeason) {
      store.clearOperationsStore();
      seasonCultureStore.clearSeasonCultureStore();
      tasksStore.pageNumber = 0;
      tasksStore.totalPages = 0;
      tasksController.clear();
      await store.fetchCulturesList();
      await fieldsService.fetchFieldsList();

      if (store.OperationCulturesInFields.length || store.fieldsWithoutCulturesCount) {
        seasonCultureStore.setSelectedCultureId(
          store.fieldsWithoutCulturesCount
            ? 'emptyCulture'
            : store.OperationCulturesInFields[0]?.culture?.id
        );

        // Не выяснена причина использования. При переходе на новую ручку убрал.
        // await store.getOperationsType();
        store.resetPageNumberSettings();
        await store.getOperations();
        tasksController.resetPageSettings();
        await tasksController.fetchTasks({
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId: seasonCultureStore.selectedCultureId,
          noCulture: seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
        });
      }
    }
  };

  useEffect(() => {
    tasksStore.fromTasksPage = false;
    tasksStore.setFullScreenMode(null);
    notificator.clearAll();
  }, []);

  const isOrgMy = useMemo(() => organizationsStore.selectedOrganizationId === 'my', [
    organizationsStore.selectedOrganizationId,
  ]);

  useEffect(() => {
    if (isOrgMy || !accessRulesReady) return;
    const isAllowOperations = getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS}`);
    if (!isAllowOperations) {
      const url = generatePath(DashboardRoutes.Fields, {
        orgId: organizationsStore.selectedOrganizationId,
      });
      navigate(url);
    }
  }, [isOrgMy, accessRulesReady]);

  useEffect(() => {
    (async () => {
      await loadAllData();
    })();
  }, [seasonStore.selectedSeason]);

  useEffect(() => {
    setShowSpinner(tasksStore.totalPages > 0);
    prevTasksListLength = 0;
  }, [
    seasonCultureStore.selectedCultureId,
    store.selectedOperationId,
    store.selectedOperationFieldId,
  ]);

  const loadAdditionalTasks = async () => {
    tasksStore.pageNumber += 1;
    await tasksController.fetchTasks(
      {
        organizationId: organizationsStore.selectedOrganizationId,
        seassonCultureId: seasonCultureStore.selectedCultureId,
        operationId: store.selectedOperationId,
        fieldId: store.selectedOperationFieldId,
      },
      true
    );
  };

  return isOrgMy || (accessRules.length && !accessRulesLoading) ? (
    <Wrapper data-test-id={'operations-container-wrapper'}>
      <Header data-test-id={'operations-container-header'}>
        <LabelWrapper>
          <Label data-test-id={'operations-container-header-label'}>Операции по культурам</Label>
          {ContextualHelpIcon ? ContextualHelpIcon : null}
        </LabelWrapper>

        <CultureList
          cultures={Array.from(store.OperationCulturesInFields)}
          fieldsWithoutCulturesCount={store.fieldsWithoutCulturesCount}
        />
        <div />
      </Header>
      <Footer data-test-id={'operations-container-footer'}>
        {seasonStore.selectedSeason && fieldsStore.hasFields ? (
          <OperationsListWrapper id="OperationsListWrapper">
            <OperationsList />
          </OperationsListWrapper>
        ) : null}
        <TasksListWrapper>
          <TasksList />
          {/* prevTasksListLength !== tasksStore.tasksMap.size ? <Spinner /> : null */}
          {/* todo Если выбрать " Культура не указана" то слайдер не исчезает */}
          {/* {showSpinner ? <Spinner /> : null} */}
        </TasksListWrapper>
      </Footer>
      <ModalContainer uniqueKey={tasksModalUniqueKey} $size={EModalSize.Medium} />
    </Wrapper>
  ) : null;
});
