import { usePermissionsBuilderPermissions } from '../../../../../shared/features/PermissionsBuilder/hooks';
import { EStoAction } from '../../../../../../api/models/as-fields/STO/STO.model';
import { EStoAccessAction } from '../../../../../shared/constants/access-rules-action';

const useStoActionControl = () => {
  const permissions = usePermissionsBuilderPermissions<EStoAction>('sto');

  const isAllowToEdit = permissions
    .getPermissionList()
    .some(rule => rule === EStoAccessAction.Edit || rule === EStoAccessAction.Publish);

  return {
    isAllowToEdit,

    hasPermission: permissions.getPermission,
    getPermissionList: permissions.getPermissionList,
    clearPermissionsStore: permissions.clearStore,
  };
};

export default useStoActionControl;
