import {
  MapCoreController,
  MapDrawerController,
  MapPolygonController,
} from '../../../../../../shared/features/map/modules';
import MapPolygonStore from '../../../../../../shared/features/map/modules/MapPolygon/stores/MapPolygon.store';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EFieldsMode } from '../../../../../modules/fields/constants';
import { SharedFieldsStore } from '../../../../../modules/fields/mobx';
import { CULTURE_POLYGON_OPTIONS } from '../../../../../modules/fields/utils/constants/PolygonOptions.constant';
import { CultureZoneStore } from '../stores/culture.zone.store';

import ValidateCultureZoneService from './ValidateCultureZoneService';

@provide.transient()
class EditCultureZoneService {
  @lazyInject(CultureZoneStore)
  protected cultureZoneStore: CultureZoneStore;

  @lazyInject(SharedFieldsStore)
  private sharedFieldsStore: SharedFieldsStore;

  @lazyInject(MapPolygonStore)
  private mapPolygonStore: MapPolygonStore;

  @lazyInject(MapCoreController)
  private mapCoreController: MapCoreController;

  @lazyInject(MapPolygonController)
  private mapPolygonController: MapPolygonController;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  @lazyInject(ValidateCultureZoneService)
  protected validateCZService: ValidateCultureZoneService;

  handleChangeGeometry = (event: any) => {
    const { target } = event;
    const leafletId = target._leaflet_id;
    const geoJson = target.toGeoJSON();

    const { needUndoChanges } = this.validateCZService.validateEditCultureZone(geoJson, leafletId);
    if (needUndoChanges) {
      this.undoLastEditChange();
    }
  };

  undoLastEditChange = () => {
    const { culture, polyId } = this.cultureZoneStore.selectedCultureZoneModel;
    const polygonLayer = this.mapPolygonStore.getPolygon(Number(polyId));
    if (!polygonLayer) {
      return;
    }

    polygonLayer.remove();

    const { polygon: newPolygon } = this.mapPolygonController.display({
      element: null,
      coordinates: this.cultureZoneStore.lastValidatedGeometry.coordinates,
      options: {
        isAllowToSelectPolygon: true,
        style: {
          ...CULTURE_POLYGON_OPTIONS.cultureZone(culture?.attrs?.assistanceColorLegend),
          pmIgnore: false,
        },
      },
    });

    if (!newPolygon) {
      return;
    }

    this.cultureZoneStore.selectedCultureZoneModel.polyId = String(newPolygon.id);

    this.mapDrawerController.enableEditPolygon(newPolygon, {
      allowSelfIntersection: true,
    });
    newPolygon.on('pm:markerdragend', this.handleChangeGeometry);
  };

  handleExistingZoneClick = event => {
    console.log('handleExistingZoneClick new');
    const {
      idToCultureZone,
      selectedCultureZoneModel,
      isCultureZoneCreating,
      getMapIdByPolygonId,
      setInitialGeometryOfSelectedZone,
      setIsCultureZoneEditing,
      setIsZoneNotificationWarning,
      setLastValidateGeometry,
      setSelectedCultureZoneModel,
    } = this.cultureZoneStore;

    const { target } = event;

    const specificZoneMapId = getMapIdByPolygonId(target._leaflet_id);

    const zoneModel = idToCultureZone.get(specificZoneMapId);

    if (!zoneModel || selectedCultureZoneModel || isCultureZoneCreating) {
      return;
    }

    const selectedPolygon = this.mapPolygonStore.getPolygon(Number(target._leaflet_id));
    if (!selectedPolygon) {
      return;
    }

    setInitialGeometryOfSelectedZone(zoneModel.geometry);
    setIsCultureZoneEditing(true);
    setLastValidateGeometry(zoneModel.geometry);
    setSelectedCultureZoneModel(zoneModel);
    setIsZoneNotificationWarning(true);

    this.sharedFieldsStore.fieldsMode = EFieldsMode.FIELD_CULTURE_ZONE_EDIT;

    this.mapDrawerController.enableEditPolygon(selectedPolygon, {
      allowSelfIntersection: true,
    });
    this.mapPolygonController.select(selectedPolygon, {
      style: {},
      skipCenter: true,
    });

    selectedPolygon.on('pm:markerdragend', this.handleChangeGeometry);
  };
}

export default EditCultureZoneService;
