import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';

import { CreateFieldsController } from '../../../dashboard/modules/fields/mobx/controllers';
import {
  CreateFieldsStore,
  SharedFieldsStore,
} from '../../../dashboard/modules/fields/mobx/stores';
import { useStore } from '../../../shared/utils/IoC';

import { ReactComponent as BinSvg } from './../../static/bin.svg';
import { Control, TipTail, Tooltip, Wrapper } from './style';

export const CreateControls: FC = observer(() => {
  const createFieldsController = useStore(CreateFieldsController);

  const sharedFieldsStore = useStore(SharedFieldsStore);
  const createFieldsStore = useStore(CreateFieldsStore);

  const notificator = useNotificator();

  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const handleRemoveClick = () => {
    createFieldsController.removeUnfinishedContour();
  };

  useEffect(() => {
    if (createFieldsStore.isFinishedPolygon) {
      notificator.clearAll();
      return;
    }

    notificator.setNotification({
      message: 'Замкните контур, чтобы завершить создание поля',
      style: {
        type: ENotificationType.Info,
        height: ENotificationHeight.BIG,
        hideProgressBar: true,
        autoClose: 10000000,
        placement: 'top-right',
        theme: ENotificatorTheme.Light,
      },
    });
  }, [createFieldsStore.isFinishedPolygon]);

  if (!sharedFieldsStore.isCreateMode) {
    return null;
  }

  return (
    <Wrapper data-test-id="map-user-controls-trashcan">
      <Control
        disabled={createFieldsStore.isFinishedPolygon}
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseOut={() => setIsShowTooltip(false)}
        onClick={handleRemoveClick}
      >
        <BinSvg />
      </Control>
      {isShowTooltip && (
        <Tooltip>
          <p>Удалить незаконченный контур</p>
          <TipTail />
        </Tooltip>
      )}
    </Wrapper>
  );
});
