import { FC, memo, useMemo } from 'react';

import { ITablePlugBuilderAutoRenderConfig as IAutoRenderConfig } from '../../models/configs';
import { useDataTestId } from '../../../../../utils/hooks/locators';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as FieldIcon } from '../../assets/icons/field.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';

import Styled from './TablePlugBuilderAutoRender.styles';

const DEFAULT_DATA_TEST_ID = 'table-plug-body';

type TProps = { dataTestId?: string } & IAutoRenderConfig;

const TablePlugBuilderAutoRender: FC<TProps> = ({ dataTestId, header, content, footer }) => {
  const isCustomIcon = typeof header.icon !== 'string';

  const AvailableIcon = useMemo(() => {
    if (!header?.icon) return;

    switch (header.icon) {
      case 'search':
        return <SearchIcon />;

      case 'field':
        return <FieldIcon />;

      case 'calendar':
        return <CalendarIcon />;

      default:
    }
  }, [header?.icon]);

  const getDataTestId = useDataTestId(dataTestId ? `${dataTestId}-content` : DEFAULT_DATA_TEST_ID);

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {header ? (
        <Styled.Header {...getDataTestId('header')}>
          {isCustomIcon ? header.icon : AvailableIcon}
        </Styled.Header>
      ) : null}

      {content ? (
        <Styled.Content {...getDataTestId('content')}>
          <Styled.Title {...getDataTestId('title')}>{content.title}</Styled.Title>

          {content.description ? (
            <Styled.Description {...getDataTestId('description')}>
              {content.description}
            </Styled.Description>
          ) : null}
        </Styled.Content>
      ) : null}

      {footer ? footer.renderButton() : null}
    </Styled.Wrapper>
  );
};

TablePlugBuilderAutoRender.displayName = 'TablePlugBuilderAutoRender';

export default memo(TablePlugBuilderAutoRender);
