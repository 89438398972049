import { runInAction } from 'mobx';

import { Field } from '../../../../../../../../api/models/field.model';
import { Axios } from '../../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { FieldSeasonsStore } from '../../../../../../stores/field.seasons.store';

@provide.transient()
class FieldSeasonsService {
  @lazyInject(Axios)
  private axios: Axios;

  @lazyInject(FieldSeasonsStore)
  private store: FieldSeasonsStore;

  fetchAllFieldSeasons = async (field: Field) => {
    this.store.isLoading = true;

    const promise = this.axios.api.getFieldSeasonsById(
      { fieldId: field.id, includeAllSeasons: true },
      { omit: ['fieldId'] }
    );

    await promise
      .then(({ content }) => {
        content.forEach(obj => {
          this.store.yearToField.set(obj.season.year, obj);
        });
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  };

  fetchNonEmptyFieldSeasons = async (field: Field) => {
    this.store.isLoading = true;

    const promise = this.axios.api.getFieldSeasonsById({
      fieldId: field.id,
      includeAllSeasons: false,
    });

    await promise
      .then(({ content }) => {
        this.store.yearsToFieldIdToCopy.clear();

        runInAction(() => {
          content.forEach(obj => {
            this.store.yearsToFieldIdToCopy.set(obj.season.year, obj);
          });
        });
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  };

  copyFieldFromSeason = async (
    fieldId: string | number,
    fromSeasonYear: number,
    toSeasonYear: number
  ) => {
    const promise = this.axios.api.copyFieldById(
      {
        fieldId: String(fieldId),
        fromSeasonYear,
        toSeasonYear,
      },
      {
        omit: ['fieldId', 'fromSeasonYear', 'toSeasonYear'],
      }
    );

    await promise.catch(err => {
      throw new Error(err.response.data.error);
    });
  };
}

export default FieldSeasonsService;
