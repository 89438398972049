import {
  ENotificationHeight,
  ENotificationType,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useWarningBeforeDeleting } from '../../../../../shared/hooks';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { CreationFieldItem } from '../../components/CreationgFieldItem/CreationFieldItem';
import { FieldsRoute } from '../../fields.route';
import { EditFieldsController } from '../../mobx/controllers';
import { EditFieldsStore, FieldsStore } from '../../mobx/stores';
import {
  FIELDS_EDIT_MODALS_UNIQUE_KEY,
  fieldsEditModalConfigList,
} from '../../modals/edit/configs/fieldsEditModalsConfig';
import { FieldsListWrapper } from '../fieldsListWrapper/fields.list.wrapper';

import { FooterContainer } from './components';

export const FieldsEdit: FC = observer(() => {
  const { fieldId } = useParams<{ fieldId: string }>();
  const navigate = useNavigate();

  // Контроллеры
  const editFieldController = useStore(EditFieldsController);

  // Сторы
  const fieldsStore = useStore(FieldsStore);
  const editFieldsStore = useStore(EditFieldsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const { selectedSeason } = useStore(SeasonsStore);

  const { editableField } = editFieldsStore;

  const notificator = useNotificator();

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();
  const { registerModalList } = useModal();

  const fieldListingRoute = useMemo(
    () =>
      generatePath(FieldsRoute.Listing, {
        orgId: organizationsStore.selectedOrganizationId,
      }),
    [organizationsStore.selectedOrganizationId]
  );

  const handleNameChange = (v: string) => {
    editFieldController.changeFieldName(v);
  };

  const handleDelete = () => {
    showWarningBeforeDeleting(
      'Вы уверены, что хотите удалить поле? Данные поля будут потеряны',
      () => {
        const field = fieldsStore.getFieldById(fieldId);

        editFieldController.deleteField(field, null).then(() => {
          editFieldController.destroy();
          navigate(fieldListingRoute);
        });
      }
    );
  };

  useEffect(() => {
    if (!fieldId || !selectedSeason) {
      return;
    }

    void editFieldController.initialize(fieldId);

    return () => {
      editFieldController.destroy();
    };
  }, [fieldId, selectedSeason]);

  useEffect(() => {
    registerModalList(fieldsEditModalConfigList, FIELDS_EDIT_MODALS_UNIQUE_KEY);
  }, []);

  useEffect(() => {
    notificator.clearAll();

    const zoneError = editFieldsStore.isCultureZoneOutOfField;

    if (zoneError === null) {
      return;
    }

    notificator.setNotification({
      message: zoneError
        ? 'Культурная зона выходит за пределы контура поля'
        : 'Культурные зоны в пределах контура поля',
      style: {
        type: zoneError ? ENotificationType.Warning : ENotificationType.Success,
        height: ENotificationHeight.BIG,
        hideProgressBar: true,
        autoClose: 10000,
        placement: 'top-right',
      },
    });
  }, [editFieldsStore.isCultureZoneOutOfField]);

  return (
    <FieldsListWrapper
      expanded={true}
      title="Редактирование поля"
      dataTestId="fields-edit-section"
      footer={<FooterContainer />}
    >
      {editableField && (
        <CreationFieldItem
          key={editableField.id}
          area={editableField.areaInHectare ? editableField.areaInHectare : 0}
          name={editableField.name}
          onNameChange={handleNameChange}
          onDelete={handleDelete}
          image={editableField.icon ? editableField.icon.downloadUrl : ''}
          data-test-id={'fields-edit-props'}
        />
      )}
    </FieldsListWrapper>
  );
});
