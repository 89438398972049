import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TableFiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { ISTOFilters as IFilters } from '../../../../STOFiltersContainer/models';
import { ETableFiltersBuilderId } from '../../../../../../../constants/configs/TableFiltersBuilderId';
import { StoListStore } from '../../stores';
import { StoService } from '../../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { TableFiltersBuilderStore } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/stores';

@provide.transient()
class StoListController {
  @lazyInject(StoListStore)
  protected stoListStore: StoListStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  private organizationsStore: OrganizationsStore;

  @lazyInject(TableFiltersBuilderStore)
  protected tableFiltersBuilderStore: TableFiltersBuilderStore<IFilters>;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IFilters>;

  public get hasFields(): boolean {
    const optionList = this.tableFiltersBuilderStore.getOptionList(
      ETableFiltersBuilderId.Sto,
      'cultureZones'
    );

    return Boolean(optionList?.length);
  }

  public increaseStoListPage = (): void => {
    this.stoListStore.setCurrentPage(prevValue => prevValue + 1);
  };

  /**
   * Метод получения списка СТО согласно фильтрам.
   * @param isSavePagination - флаг, который говорит о том, сохраняем мы пагинацию или нет.
   * Когда активируется запрос после обновленных фильтров, то обнуляем пагинацию.
   */
  public fetchStoList = async (isSavePagination?: boolean): Promise<void> => {
    const appliedFilters = this.tableFiltersBuilderController.getAppliedFilters(
      ETableFiltersBuilderId.Sto
    );

    if (!isSavePagination) {
      this.stoListStore.setCurrentPage(0);
      this.stoListStore.setTotalPages(0);
    }

    const payload: TypeApiRequest<'getStoList'> = {
      ...appliedFilters,
      includeDrafts: true,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      organizationIds: [this.organizationsStore.selectedOrganizationId],
    };

    await this.stoService.getStoList(payload, {
      query: {
        page: isSavePagination ? this.stoListStore.currentPage : 0,
        size: 25,
        sort: 'status',
      },
      actions: {
        handleLoading: isLoading => {
          if (isLoading) this.showStoListLoader();
          else this.hideStoListLoader();
        },
        handleSuccess: response => {
          this.stoListStore.setTotalPages(response.totalPages);

          if (isSavePagination) {
            this.stoListStore.setStoList(prevStoList => [...prevStoList, ...response.content]);
          } else {
            this.stoListStore.setStoList(response.content);
          }
        },
      },
    });
  };

  public clearStoListStore = (): void => {
    this.stoListStore.clearIsFetchingStoList();
    this.stoListStore.clearCurrentPage();
    this.stoListStore.clearTotalPages();
    this.stoListStore.clearStoList();
  };

  protected showStoListLoader = (): void => {
    this.stoListStore.setIsFetchingStoList(true);
  };

  protected hideStoListLoader = (): void => {
    this.stoListStore.setIsFetchingStoList(false);
  };
}

export default StoListController;
