import styled from 'styled-components';

export const NoFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  margin-top: 120px;
`;

export const NoSeasonsWrapper = NoFieldsWrapper;

export const NoFieldsImage = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
`;

export const NoFieldsTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
`;

export const NoSeasonsTitle = NoFieldsTitle;

export const NoFieldsDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
`;

export const LoadMoreFieldsButton = styled.div`
  align-self: center;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.Colors.accentPositive};
  border: 1px solid ${({ theme }) => theme.Colors.borderDefault};
  border-left: none;
  border-width: 1px 0 1px 0;
`;

export const LoadMoreFieldsPreloader = styled.div`
  align-self: center;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.Colors.borderDefault};
  border: 1px solid ${({ theme }) => theme.Colors.borderDefault};
  border-width: 1px 0 1px 0;
`;

export const NoSeasonsDescription = NoFieldsDescription;
