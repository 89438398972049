import { CreateFieldModel } from '../../../../../../../api/models/create.field.model';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { CreateFieldsStore } from '../../stores';
import { TCreationField } from '../../stores/CreateFieldsStore/CreateFields.store';
import FieldsService from '../FieldsService/Fields.service';

@provide.transient()
class CreateFieldsService extends FieldsService {
  @lazyInject(CreateFieldsStore)
  private store: CreateFieldsStore;

  // Сохраняет созданные поля
  public submitFields(createdFields: TCreationField[]) {
    const fieldsForSave = this.creatableFieldsWithGeoJson(createdFields);

    this.store.isLoading = true;

    const promise = this.axios.api.createFields({
      organizationId: this.organizationStore.selectedOrganizationId,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      fields: fieldsForSave,
    });

    return promise
      .catch(err => {
        throw new Error(err.response.data.error);
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  private creatableFieldsWithGeoJson(createdFields: TCreationField[]): CreateFieldModel[] {
    return createdFields.map(field => {
      const geoJson = field.polygon.toGeoJSON();
      return {
        name: field.name,
        geoJson: {
          ...geoJson,
          geometry: {
            ...geoJson.geometry,
            coordinates: [
              geoJson.geometry.coordinates[0].map(position => [
                position[0].toFixed(19),
                position[1].toFixed(19),
              ]),
            ],
          },
        },
      };
    }) as CreateFieldModel[];
  }
}

export default CreateFieldsService;
