import { IPotentialCultureZone } from '../../../../models/as-fields/STO/potentialCultureZones.model';
import { IResponseList } from '../../../../models/common/response';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TRequest = { id: string; includeUnavailable?: boolean; page?: number; size?: number };

type TResponse = IResponseList<IPotentialCultureZone>;

export const getPotentialCZList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/sto/${id}/potentialCultureZones`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
