import { makeAutoObservable, runInAction } from 'mobx';

import { CheckAccessStore } from '../../../authorization/stores/checkAccess.store';
import { SessionStore } from '../../../authorization/stores/session.store';
import { ApplicationRoutes } from '../../../routes';
import { lazyInject, provide } from '../../../shared/utils/IoC';
import { PopupPages } from '../../constants/popup.pages';
import {
  DisplayFieldsController,
  FieldsStore,
  FieldsUIStore,
  SharedFieldsStore,
} from '../../modules/fields/mobx';
import { TaskEditController } from '../../modules/operations/controllers/task.edit.controller';
import { TasksEditStore } from '../../modules/operations/stores/task.edit.store';
import { ChecklistInstancesStore } from '../../modules/operationsAndTasks/stores/checklist.instances.store';
import { ProfileStore } from '../../modules/profile/stores/ProfileStore';
import { OrganizationsStore } from '../../stores/organizations.store';

import AvaPlaceholderSvg from './assets/images/avaPlaceholder.svg';

type TActiveDropdownUnion = null | 'seasons' | 'orgs';

@provide.singleton()
export class SidebarController {
  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  @lazyInject(FieldsStore)
  fieldsStore: FieldsStore;

  @lazyInject(FieldsUIStore)
  fieldsUiStore: FieldsUIStore;

  @lazyInject(SharedFieldsStore)
  sharedFieldsStore: SharedFieldsStore;

  @lazyInject(ProfileStore)
  profileStore: ProfileStore;

  @lazyInject(CheckAccessStore)
  checkAccessStore: CheckAccessStore;

  @lazyInject(OrganizationsStore)
  organizationsStore: OrganizationsStore;

  @lazyInject(TasksEditStore)
  taskEditStore: TasksEditStore;

  @lazyInject(TaskEditController)
  taskEditController: TaskEditController;

  @lazyInject(ChecklistInstancesStore)
  checklistInstancesStore: ChecklistInstancesStore;

  @lazyInject(DisplayFieldsController)
  displayFieldsController: DisplayFieldsController;

  private _activeDropdown: TActiveDropdownUnion = null;

  constructor() {
    makeAutoObservable(this);

    // @ts-ignore
    window.sc = this;
  }

  get activeDropdown() {
    return this._activeDropdown;
  }

  get accessRules(): string[] | null {
    if (this.organizationsStore.selectedOrganizationId === 'my') {
      return null;
    }

    const actions = this.checkAccessStore.accessRules
      .filter(item => item.available)
      .flatMap(item => item.code);
    return actions.length ? actions : null;
  }

  get isSidebarDisabled(): boolean {
    return (
      this.fieldsUiStore.isCultureZonePopupOpen ||
      this.fieldsUiStore.isSeasonsPopupOpen ||
      this.sharedFieldsStore.isEditMode ||
      this.sharedFieldsStore.isCreateMode ||
      this.sharedFieldsStore.isImportMode ||
      this.sharedFieldsStore.isCultureZoneEditMode ||
      this.sessionStore.isLoading ||
      this.profileStore.loading ||
      this.organizationsStore.loading ||
      this.fieldsStore.isLoading ||
      Boolean(Object.keys(this.taskEditStore.task).length)
    );
  }

  getUsername = (): string => {
    return this.profileStore.fullName;
  };

  getUserPic = (): string | null => {
    return this.profileStore?.user?.avatar?.downloadUrl || AvaPlaceholderSvg;
  };

  getAgroidLink = (): string => {
    return process.env?.NODE_ENV === 'development'
      ? ApplicationRoutes.ExternalAgroidProfileDev
      : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalAgroidProfile}`;
  };

  get isShowLeaveUnitModal() {
    return (
      this.fieldsUiStore.isCultureZonePopupOpen ||
      this.sharedFieldsStore.isEditMode ||
      this.sharedFieldsStore.isCreateMode ||
      this.sharedFieldsStore.isImportMode ||
      this.sharedFieldsStore.isCultureZoneEditMode ||
      this.checklistInstancesStore.hasPositionToInstanceChanged ||
      Boolean(this.taskEditStore.touchedFields.size)
    );
  }

  setActiveDropdown = (type: TActiveDropdownUnion): void => {
    runInAction(() => {
      this._activeDropdown = type;
    });
  };

  onLogoutClick = (): void => {
    this.fieldsUiStore.popupPageState = PopupPages.None;
    this.sessionStore.logout();
  };

  onProfileClick = (): void => {
    window.location.assign(this.getAgroidLink());
  };

  clearMainDashboardState = () => {
    this.fieldsUiStore.popupPageState = PopupPages.None;
    // this.uiStore.setPopupPageState(PopupPages.None);
    // this.fieldsStore.setMapModeValue(MapMode.Listing);
  };

  clearStatesOnMenuItemClick = () => {
    this.displayFieldsController.deselectField();
  };

  clearStateOnModalClick = () => {
    // this.fieldsController.resetSelectedField();
    this.clearMainDashboardState();
    this.checklistInstancesStore.setHasPositionToInstanceChanged(false);
    this.taskEditController.clearTouchedFields();
  };
}
