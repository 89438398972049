import { TypeApiRoute } from '../../../models/type.api.request';
import { IGetChecklist } from '../../../models/checklist/checklist.model';

type TRequest = {
  organizationId: string;
  operationTypeId?: string;
  cultureId?: string;
  intensityId?: string;
  type?: string;
};

type TResponse = IGetChecklist[];

export const getChecklistListByKeys: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/checklist/findByKeys`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
