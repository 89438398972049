import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils/IoC';
import { FieldSeasonsModel } from '../../../api/models/field.seasons.model';

@provide.singleton()
export class FieldSeasonsStore {
  yearToField: Map<number, FieldSeasonsModel> = new Map<number, FieldSeasonsModel>();
  yearsToFieldIdToCopy: Map<number, FieldSeasonsModel> = new Map<number, FieldSeasonsModel>();

  selectedSeasonYearToCopy: number = null;

  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get fields() {
    return Array.from(this.yearToField.values());
  }

  get yearsToCopy() {
    return Array.from(this.yearsToFieldIdToCopy.keys());
  }

  getYearToField = (year: number) => {
    return this.yearsToFieldIdToCopy.get(year);
  };

  selectSeasonYearToCopy = (seasonYear: number) => {
    this.selectedSeasonYearToCopy = seasonYear;
  };

  clear = () => {
    this.yearToField.clear();
    this.isLoading = true;
  };
}
