import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { IRequestStatusActions } from '../../../../../../api/models/common/request';

@provide.singleton()
class StoService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getStoList = async (
    payload: TypeApiRequest<'getStoList'>,
    config?: {
      query?: Pick<TypeApiRequest<'getStoList'>, 'page' | 'size' | 'sort'>;
      actions?: IRequestStatusActions<TypeApiResponse<'getStoList'>>;
    }
  ): Promise<TypeApiResponse<'getStoList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoList(payload, {
        omit: ['size', 'page'],
        query: config?.query,
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getSto = async (
    payload: TypeApiRequest<'getSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getSto'>>;
    }
  ): Promise<TypeApiResponse<'getSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getSto(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  createSto = async (
    payload: TypeApiRequest<'createSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'createSto'>>;
    }
  ): Promise<TypeApiResponse<'createSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.createSto(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  updateSto = async (
    payload: TypeApiRequest<'updateSto'>,
    config?: {
      query?: Pick<TypeApiRequest<'updateSto'>, 'id'>;
      actions?: IRequestStatusActions<TypeApiResponse<'updateSto'>>;
    }
  ): Promise<TypeApiResponse<'updateSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.updateSto(payload, {
        query: config?.query,
        omit: ['id'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  fetchPotentialCultureZoneList = async (
    payload: TypeApiRequest<'getPotentialCZList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getPotentialCZList'>>;
    }
  ): Promise<TypeApiResponse<'getPotentialCZList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getPotentialCZList(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };
}

export default StoService;
