import _ from 'lodash';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../stores';
import {
  EChecklistFormulaType as EFormulaType,
  TChecklistAttributeFormulaAttribute as TFormulaAttribute,
  TChecklistAttributeFormulaConstant as TFormulaConstant,
  TChecklistAttributeFormulaFunction as TFormulaFunction,
  TChecklistAttributeFormulaProperty as TFormulaProperty,
} from '../../../../../../../../../api/models/as-fields/checklists/attributes/formulas/ChecklistFormulas';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  TChecklistsBooleanAttrToDraw as TBooleanAttrToDraw,
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
  TChecklistsStringAttrToDraw as TStringAttrToDraw,
  TChecklistsDictionaryAttrToDraw as TDictionaryAttrToDraw,
  TChecklistsEnumAttrToDraw as TEnumAttrToDraw,
  TChecklistsUserDictionaryAttrToDraw as TUserDictionaryAttrToDraw,
  TChecklistsLongStingAttrToDraw as TLongStingAttrToDraw,
  TChecklistsDateAttrToDraw as TDateAttrToDraw,
  IChecklistsFormulaResult as IFormulaResult,
} from '../../../models';
import {
  EChecklistAttributeFormulaFunctionReturnType as EFormulaFunctionReturnType,
  EChecklistAttributeFormulaFunctionType as EFormulaFunctionType,
} from '../../../../../../../../../api/models/as-fields/checklists/attributes/formulas/functions/ChecklistAttributeFormulaFunction';
import { EChecklistAttributeFormulaConstantReturnType as EAttributeFormulaConstantReturnType } from '../../../../../../../../../api/models/as-fields/checklists/attributes/formulas/constants/ChecklistAttributeFormulaConstant';
import { EChecklistAttributeFormulaPropertyReturnType as EAttributeFormulaPropertyReturnType } from '../../../../../../../../../api/models/as-fields/checklists/attributes/formulas/properties/ChecklistAttributeFormulaProperty';

const CALC_FUNCTION_TYPE_LIST = [
  EFormulaFunctionType.AVG,
  EFormulaFunctionType.MULT,
  EFormulaFunctionType.ADD,
  EFormulaFunctionType.SUBTRACT,
  EFormulaFunctionType.DIVISION,
  EFormulaFunctionType.PERCENT,
];

@provide.transient()
class ChecklistsAttrsFormulasService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  calculateValue = (groupId: string, attrId: string): IFormulaResult => {
    const attrToDraw = this.checklistsStore.getAttrToDraw(groupId, attrId);
    const formulaFunction = attrToDraw.initialModel.calculate;

    const result = this.calculateValueFunction(groupId, formulaFunction);

    if (this.checkIfFormulaHasEmptyValue(result)) {
      return {
        ...result,
        value: '',
      };
    }

    switch (formulaFunction.returnType) {
      case EFormulaFunctionReturnType.Integer: {
        const numberValue = Number(result.value);

        return {
          ...result,
          value: !isNaN(numberValue) ? Math.round(numberValue) : '',
        };
      }

      case EFormulaFunctionReturnType.Double: {
        const numberValue = Number(result.value);
        const precision = attrToDraw.initialModel.attribute?.precision;

        return {
          ...result,
          value: !isNaN(numberValue) ? numberValue.toFixed(precision) : '',
        };
      }

      default:
    }
  };

  calculateVisibility = (groupId: string, attrId: string): IFormulaResult => {
    const formulaFunction = this.checklistsStore.getAttrToDraw(groupId, attrId).initialModel
      .visibility;

    const result = this.calculateVisibilityFunction(groupId, formulaFunction);

    return result;
  };

  protected getConstantValue = (formula: TFormulaConstant): IFormulaResult => {
    switch (formula.returnType) {
      case EAttributeFormulaConstantReturnType.Boolean:
        return {
          value: _.isString(formula.constantValue)
            ? formula.constantValue === 'true'
            : formula.constantValue,
          isNotEdited: true,
          isConstantResult: true,
        };

      case EAttributeFormulaConstantReturnType.Integer:
        return {
          value: Number(formula.constantValue),
          isNotEdited: true,
          isConstantResult: true,
        };

      case EAttributeFormulaConstantReturnType.Double:
        return {
          value: Number(formula.constantValue),
          isNotEdited: true,
          isConstantResult: true,
        };

      default:
        return {
          value: formula.constantValue,
          isNotEdited: true,
          isConstantResult: true,
        };
    }
  };

  protected getAttrValue = (groupId: string, formula: TFormulaAttribute): IFormulaResult => {
    const attrToDraw = this.checklistsStore.getAttrToDraw(groupId, formula.attrId);

    switch (attrToDraw?.initialModel?.attribute?.type) {
      case EAttrType.Boolean: {
        const { value } = attrToDraw as TBooleanAttrToDraw;

        return {
          value: value.booleanValue,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited,
        };
      }

      case EAttrType.Int: {
        const { value } = attrToDraw as TIntegerAttrToDraw;

        return {
          value: value.integerValue,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && this.checkIfValueIsEmpty(value),
        };
      }

      case EAttrType.Double: {
        const { value } = attrToDraw as TDoubleAttrToDraw;

        return {
          value: this.checkIfValueIsEmpty(value.doubleValue)
            ? value.doubleValue
            : Number(value.doubleValue),
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && this.checkIfValueIsEmpty(value),
        };
      }

      case EAttrType.String: {
        const { value } = attrToDraw as TStringAttrToDraw;

        return {
          value: value.stringValue,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && this.checkIfValueIsEmpty(value),
        };
      }

      case EAttrType.DictionaryLink: {
        const { value } = attrToDraw as TDictionaryAttrToDraw;

        return {
          value: value.dictionaryValueList?.[0],
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && !value.dictionaryValueList.length,
        };
      }

      case EAttrType.Enum: {
        const { options } = attrToDraw as TEnumAttrToDraw;
        const firstSelectedOption = options.selectedSelectOptionList?.[0];

        return {
          value: firstSelectedOption?.value,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && !options.selectedSelectOptionList?.length,
        };
      }

      case EAttrType.UserDictionaryLink: {
        const { value } = attrToDraw as TUserDictionaryAttrToDraw;

        return {
          value: value.userDictionaryValues?.[0]?.id,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && !value.userDictionaryValues.length,
        };
      }

      case EAttrType.LongString: {
        const { value } = attrToDraw as TLongStingAttrToDraw;

        return {
          value: value.longStringValue,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && this.checkIfValueIsEmpty(value),
        };
      }

      case EAttrType.Date: {
        const { value } = attrToDraw as TDateAttrToDraw;

        return {
          value: value.dateValue,
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && this.checkIfValueIsEmpty(value),
        };
      }

      default:
    }
  };

  protected getPropertyValue = (groupId: string, formula: TFormulaProperty): IFormulaResult => {
    const attrToDraw = this.checklistsStore.getAttrToDraw(groupId, formula.attrId);

    switch (attrToDraw?.initialModel?.attribute?.type) {
      case EAttrType.DictionaryLink: {
        const { options } = attrToDraw as TDictionaryAttrToDraw;
        const firstSelectedOption = options.selectedSelectOptionList?.[0];

        return {
          value: this.formatPropertyValue(firstSelectedOption?.initialModel, formula),
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && !options.selectedSelectOptionList?.length,
        };
      }

      case EAttrType.Enum: {
        const { options } = attrToDraw as TEnumAttrToDraw;
        const firstSelectedOption = options.selectedSelectOptionList?.[0];

        return {
          value: this.formatPropertyValue(firstSelectedOption?.initialModel, formula),
          isThisArgumentHidden: !attrToDraw.isVisible,
          isNotEdited: !attrToDraw.isEdited && !options.selectedSelectOptionList?.length,
        };
      }

      default:
    }
  };

  protected formatPropertyValue = (model: any, formula: TFormulaProperty) => {
    const pathList = formula.propertyName.split('|');

    const target = _.get(model, pathList);

    if (!target) return;

    switch (formula.returnType) {
      case EAttributeFormulaPropertyReturnType.Integer:
        return _.isNumber(target) ? target : Number(target);

      case EAttributeFormulaPropertyReturnType.Double:
        return target;

      case EAttributeFormulaPropertyReturnType.UUID:
        return target;

      case EAttributeFormulaPropertyReturnType.DATE:
        return target;

      case EAttributeFormulaPropertyReturnType.STRING:
        return target;

      default:
    }
  };

  protected calculateValueFunction = (
    groupId: string,
    formulaFunction: TFormulaFunction
  ): IFormulaResult => {
    switch (formulaFunction.functionType) {
      case EFormulaFunctionType.AVG:
        return this.calculateAverageFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.MULT:
        return this.calculateMultFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.ADD:
        return this.calculateAddFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.SUBTRACT:
        return this.calculateSubtractFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.DIVISION:
        return this.calculateDivisionFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.PERCENT:
        return this.calculatePercentFunction(groupId, formulaFunction.args);

      default:
        return {
          value: null,
        };
    }
  };

  protected calculateAverageFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const hasNotEditedValues = this.checkIfHasNotEditedValues(resultList);

    if (hasNotEditedValues) {
      return {
        value: null,
        isNotEdited: true,
      };
    }

    const hasAllEmptyValues = this.checkIfHasAllEmptyValues(resultList);

    if (hasAllEmptyValues) {
      return {
        value: null,
      };
    }

    const valuesToCalc = this.getValueListToCalc(resultList) as number[];

    if (valuesToCalc.length) {
      const [first, ...other] = valuesToCalc;

      const sumResult = other.reduce<number>(
        (result, value) => result + Number(value),
        Number(first)
      );

      return {
        value: sumResult / valuesToCalc.length,
      };
    }

    return {
      value: null,
    };
  };

  protected calculateMultFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const hasNotEditedValues = this.checkIfHasNotEditedValues(resultList);

    if (hasNotEditedValues) {
      return {
        value: null,
        isNotEdited: true,
      };
    }

    const hasAllEmptyValues = this.checkIfHasAllEmptyValues(resultList);

    if (hasAllEmptyValues) {
      return {
        value: null,
      };
    }

    const valuesToCalc = this.getValueListToCalc(resultList) as number[];

    if (valuesToCalc.length) {
      const [first, ...other] = valuesToCalc;

      return {
        value: other.reduce<number>((result, value) => result * Number(value), Number(first)),
      };
    }

    return {
      value: null,
    };
  };

  protected calculateAddFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const hasNotEditedValues = this.checkIfHasNotEditedValues(resultList);

    if (hasNotEditedValues) {
      return {
        value: null,
        isNotEdited: true,
      };
    }

    const hasAllEmptyValues = this.checkIfHasAllEmptyValues(resultList);

    if (hasAllEmptyValues) {
      return {
        value: null,
      };
    }

    const valuesToCalc = this.getValueListToCalc(resultList) as number[];

    if (valuesToCalc.length) {
      const [first, ...other] = valuesToCalc;

      return {
        value: other.reduce<number>((result, value) => result + Number(value), Number(first)),
      };
    }

    return {
      value: null,
    };
  };

  protected calculateSubtractFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const hasNotEditedValues = this.checkIfHasNotEditedValues(resultList);

    if (hasNotEditedValues) {
      return {
        value: null,
        isNotEdited: true,
      };
    }

    const hasAllEmptyValues = this.checkIfHasAllEmptyValues(resultList);

    if (hasAllEmptyValues) {
      return {
        value: null,
      };
    }

    const canBeUsedFirst = this.checkIfValueCanBeUsedForCalc(resultList?.[0]);

    if (!canBeUsedFirst) {
      return {
        value: null,
        hasCalculationError: true,
      };
    }

    const valuesToCalc = this.getValueListToCalc(resultList) as number[];

    if (valuesToCalc.length) {
      const [first, ...other] = valuesToCalc;

      return {
        value: other.reduce<number>((result, value) => result - Number(value), Number(first)),
      };
    }

    return {
      value: null,
    };
  };

  protected calculateDivisionFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;
    const hasNotEditedValues = this.checkIfHasNotEditedValues(resultList);

    if (hasNotEditedValues) {
      return {
        value: null,
        isNotEdited: true,
      };
    }

    const hasAllEmptyValues = this.checkIfHasAllEmptyValues(resultList);

    if (hasAllEmptyValues) {
      return {
        value: null,
      };
    }

    const canBeUsedFirst = this.checkIfValueCanBeUsedForCalc(firstResult);
    const canBeUsedSecond = this.checkIfValueCanBeUsedForCalc(secondResult);
    const isNotEqualToZero = this.checkIfValueIsNotEqualToZero(secondResult);

    if (canBeUsedFirst && canBeUsedSecond && isNotEqualToZero) {
      return {
        value: Number(firstResult?.value) / Number(secondResult?.value),
      };
    } else if (canBeUsedFirst && !isNotEqualToZero) {
      return {
        value: null,
        hasCalculationError: true,
      };
    } else {
      return {
        value: null,
      };
    }
  };

  protected calculatePercentFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;
    const hasNotEditedValues = this.checkIfHasNotEditedValues(resultList);

    if (hasNotEditedValues) {
      return {
        value: null,
        isNotEdited: true,
      };
    }

    const hasAllEmptyValues = this.checkIfHasAllEmptyValues(resultList);

    if (hasAllEmptyValues) {
      return {
        value: null,
      };
    }
    const canBeUsedFirst = this.checkIfValueCanBeUsedForCalc(firstResult);
    const canBeUsedSecond = this.checkIfValueCanBeUsedForCalc(secondResult);
    const isNotEqualToZero = this.checkIfValueIsNotEqualToZero(secondResult);

    if (canBeUsedFirst && canBeUsedSecond && isNotEqualToZero) {
      const result = (Number(firstResult?.value) / Number(secondResult?.value)) * 100;

      return {
        value: Math.abs(result),
      };
    } else if (canBeUsedFirst && !isNotEqualToZero) {
      return {
        value: null,
        hasCalculationError: true,
      };
    } else {
      return {
        value: null,
      };
    }
  };

  protected getValueList = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult[] => {
    return argList.reduce((list, formula) => {
      switch (formula.type) {
        case EFormulaType.Const: {
          const value = this.getConstantValue(formula as TFormulaConstant);
          list.push(value);
          break;
        }

        case EFormulaType.Attribute: {
          const attributeGroupId = this.checklistsStore.getGroupIdByAttrId(
            (formula as TFormulaAttribute).attrId,
            groupId
          );

          const value = this.getAttrValue(attributeGroupId, formula as TFormulaAttribute);

          list.push(value);
          break;
        }

        case EFormulaType.Property: {
          const attributeGroupId = this.checklistsStore.getGroupIdByAttrId(
            (formula as TFormulaAttribute).attrId,
            groupId
          );

          const value = this.getPropertyValue(attributeGroupId, formula as TFormulaProperty);
          list.push(value);
          break;
        }

        case EFormulaType.Function: {
          const isCalcFn = this.checkIfFormulaIsCalculation(
            (formula as TFormulaFunction).functionType
          );

          const value = isCalcFn
            ? this.calculateValueFunction(groupId, formula as TFormulaFunction)
            : this.calculateVisibilityFunction(groupId, formula as TFormulaFunction);
          list.push(value);
          break;
        }

        default:
      }

      return list;
    }, []);
  };

  protected calculateVisibilityFunction = (
    groupId: string,
    formulaFunction: TFormulaFunction
  ): IFormulaResult => {
    switch (formulaFunction.functionType) {
      case EFormulaFunctionType.AND:
        return this.calculateAndFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.OR:
        return this.calculateORFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.XOR:
        return this.calculateXORFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.EQ:
        return this.calculateEQFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.GT:
        return this.calculateGTFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.LT:
        return this.calculateLTFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.GOE:
        return this.calculateGOEFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.LOE:
        return this.calculateLOEFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.NE:
        return this.calculateNEFunction(groupId, formulaFunction.args);

      case EFormulaFunctionType.NOT:
        return this.calculateNOTFunction(groupId, formulaFunction.args);

      default:
        return {
          value: false,
        };
    }
  };

  protected calculateAndFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    return {
      value: resultList.every(result => result?.value),
    };
  };

  protected calculateORFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    return {
      value: resultList.some(result => result?.value),
    };
  };

  protected calculateXORFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    if (resultList.every(result => !result.value)) {
      return {
        value: false,
      };
    }

    if (resultList.some(result => !result.value)) {
      return {
        value: true,
      };
    }

    if (resultList.every(result => result?.value)) {
      return {
        value: false,
      };
    }
  };

  protected calculateEQFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    return {
      value: firstResult?.value === secondResult?.value,
    };
  };

  protected calculateGTFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    const isEmptyFirstValue = this.checkIfFormulaHasEmptyValue(firstResult);
    const isEmptySecondValue = this.checkIfFormulaHasEmptyValue(secondResult);

    if (isEmptyFirstValue || isEmptySecondValue) {
      return {
        value: false,
      };
    }

    return {
      value: firstResult?.value > secondResult?.value,
    };
  };

  protected calculateLTFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    const isEmptyFirstValue = this.checkIfFormulaHasEmptyValue(firstResult);
    const isEmptySecondValue = this.checkIfFormulaHasEmptyValue(secondResult);

    if (isEmptyFirstValue || isEmptySecondValue) {
      return {
        value: false,
      };
    }

    return {
      value: firstResult?.value < secondResult?.value,
    };
  };

  protected calculateGOEFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    const isEmptyFirstValue = this.checkIfFormulaHasEmptyValue(firstResult);
    const isEmptySecondValue = this.checkIfFormulaHasEmptyValue(secondResult);

    if (isEmptyFirstValue || isEmptySecondValue) {
      return {
        value: false,
      };
    }

    return {
      value: firstResult?.value >= secondResult?.value,
    };
  };

  protected calculateLOEFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    const isEmptyFirstValue = this.checkIfFormulaHasEmptyValue(firstResult);
    const isEmptySecondValue = this.checkIfFormulaHasEmptyValue(secondResult);

    if (isEmptyFirstValue || isEmptySecondValue) {
      return {
        value: false,
      };
    }

    return {
      value: firstResult?.value <= secondResult?.value,
    };
  };

  protected calculateNEFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult, secondResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    return {
      value: firstResult?.value !== secondResult?.value,
    };
  };

  protected calculateNOTFunction = (
    groupId: string,
    argList: TFormulaFunction['args']
  ): IFormulaResult => {
    const resultList = this.getValueList(groupId, argList);
    const [firstResult] = resultList;

    if (resultList.some(result => result?.isThisArgumentHidden)) {
      return {
        value: false,
      };
    }

    return {
      value: !firstResult?.value,
    };
  };

  protected checkIfValueIsEmpty = (value: IFormulaResult['value']): boolean => {
    return value === undefined || value === '' || value === null;
  };

  protected checkIfFormulaHasEmptyValue = (result: IFormulaResult): boolean => {
    return this.checkIfValueIsEmpty(result?.value);
  };

  protected checkIfValueIsNotEqualToZero = (result: IFormulaResult): boolean => {
    return !(result?.value === 0 || result?.value === '0');
  };

  protected checkIfHasNotEditedValues = (resultList: IFormulaResult[]): boolean => {
    return resultList.every(result => result?.isNotEdited);
  };

  protected checkIfHasAllEmptyValues = (resultList: IFormulaResult[]): boolean => {
    return resultList.every(result => {
      if (result?.isConstantResult) return true;
      return this.checkIfValueIsEmpty(result?.value);
    });
  };

  protected checkIfValueCanBeUsedForCalc = (result: IFormulaResult): boolean => {
    const conditionList = [
      this.checkIfFormulaHasEmptyValue(result),
      Boolean(result?.isThisArgumentHidden),
      Boolean(result?.hasCalculationError),
    ];

    return !conditionList.some(condition => condition);
  };

  protected getValueListToCalc = (resultList: IFormulaResult[]): IFormulaResult['value'][] => {
    const valueListToCalc = resultList.reduce<IFormulaResult['value'][]>((acc, result) => {
      if (this.checkIfValueCanBeUsedForCalc(result)) {
        acc.push(result.value);
      }

      return acc;
    }, []);

    return valueListToCalc;
  };

  protected checkIfFormulaIsCalculation = (fnType: TFormulaFunction['functionType']): boolean => {
    return CALC_FUNCTION_TYPE_LIST.some(t => t === fnType);
  };
}

export default ChecklistsAttrsFormulasService;
