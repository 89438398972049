import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { Axios, TypeApiResponse } from '../../../../../../shared/utils/axios2';
import { CultureModel } from '../../../../../../../api/models/culture.model';
import { CultureZoneModel } from '../../../../../../../api/models/culture.zone.model';
import { Field } from '../../../../../../../api/models/field.model';
import { UiStore } from '../../../../../stores/ui.store';
import { clickMapEventName } from '../../../../../../map/events/click.map.event';

@provide.singleton()
export class CultureZoneStore {
  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  // @lazyInject(FieldsStore)
  // fields: FieldsStore;

  @lazyInject(UiStore)
  ui: UiStore;

  @lazyInject(Axios)
  protected axios: Axios;

  // @lazyInject(MapController)
  // mapController: MapController;

  lastValidatedGeometry: any = '';

  isCultureZoneEditing = false;

  isCultureZoneCreating = false;

  isZoneNotificationWarning = false;

  idToCultureZone: Map<string, CultureZoneModel> = new Map();

  initialIdToCultureZone: Map<string, CultureZoneModel> = new Map();

  initialGeometryOfSelectedZone: CultureZoneModel['geometry'] = null;

  selectedCultureZoneModel: CultureZoneModel = null;

  isCreateNewZoneTooltipVisible = false;

  tooltipPosition: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  idToCulture: Map<string, CultureModel> = new Map();

  field: Field;

  selectedPolygonId: number = null;

  isAcceptChangesAvailable = true;

  holePolyIdToMapId: Map<number, string> = new Map();

  mapIdToCultureList: Map<string, Array<CultureModel>> = new Map();

  startCultureState: CultureZoneModel | null = null;

  constructor() {
    makeAutoObservable(this);
    // window.addEventListener(createCultureZoneEventName, this.handleCreateNewZone);
    window.addEventListener(clickMapEventName, this.handleMapClick);
  }

  get initialCultureZonesList(): CultureZoneModel[] {
    return Array.from(this.initialIdToCultureZone.values());
  }

  clearIdToCultureZone = (): void => {
    this.idToCultureZone.clear();
  };

  setInitialIdToCultureZone = (): void => {
    this.clearInitialIdToCultureZone();

    Array.from(this.idToCultureZone).forEach(([key, value]) => {
      this.initialIdToCultureZone.set(key, _.cloneDeep(value));
    });
  };

  setSelectedCultureZoneModel = (zoneModel: CultureZoneModel): void => {
    this.selectedCultureZoneModel = zoneModel;
  };

  clearInitialIdToCultureZone = (): void => {
    this.initialIdToCultureZone.clear();
  };

  setLastValidateGeometry = (geometry: CultureZoneModel['geometry']): void => {
    this.lastValidatedGeometry = geometry;
  };

  setInitialGeometryOfSelectedZone = (geometry: CultureZoneModel['geometry']): void => {
    this.initialGeometryOfSelectedZone = geometry;
  };

  setIdToCultureZone = (id: string, zone: CultureZoneModel): void => {
    this.idToCultureZone.set(id, zone);
  };

  // culture zone editing and creating toggle

  setIsCultureZoneCreating = (value: boolean): void => {
    this.isCultureZoneCreating = value;
  };

  setIsCultureZoneEditing = (value: boolean): void => {
    this.isCultureZoneEditing = value;
  };

  // zone notification toggle
  setIsZoneNotificationWarning = (value: boolean): void => {
    this.isZoneNotificationWarning = value;
  };

  setTooltipPosition = (x: number, y: number) => {
    this.tooltipPosition = { x, y };
  };

  handleMapClick = event => {
    // todo: Handle map layer click if need
  };

  getMapIdByPolygonId = (polyId: string) => {
    const zones = this.cultureZones.filter(zone => String(zone.polyId) === String(polyId));

    return zones[0] ? zones[0].mapId : '';
  };

  setSelectedPolygonId = (id: number) => (this.selectedPolygonId = id);

  setIsCreateNewZoneTooltipVisible = (value: boolean) =>
    (this.isCreateNewZoneTooltipVisible = value);

  get cultureZones() {
    return Array.from(this.idToCultureZone.values());
  }

  get seasonBorder() {
    const season = this.seasons.getSeasonDataByYear();
    console.log('season ', season);
    console.log({
      min: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      max: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    });
    return {
      min: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      max: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    };
  }

  get cultureZonesCoordinates() {
    return this.cultureZones
      .filter(zone => !zone.isHole)
      .map(zone => {
        return zone.geometry.coordinates;
      });
  }

  denyHoleClick = () => {
    this.selectedPolygonId = null;
    this.isCreateNewZoneTooltipVisible = false;
  };

  get harvestDate() {
    return this.cultureZones[0].harvestDate;
  }

  get sowingDate() {
    return this.cultureZones[0].harvestDate;
  }

  saveCultureZones = async (): Promise<void> => {
    const zoneList = this.cultureZones.filter(zone => !zone.isHole && zone.culture.id);
    try {
      await this.axios.api.updateCultureZones({
        zones: zoneList.map(zone => {
          return {
            id: zone.id,
            cultureZoneId: zone.id,
            variety: zone.variety,
            seasonYear: Number(this.seasons.selectedSeason),
            cultureId: zone.culture.id,
            geoJson: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: zone.geometry.coordinates,
              },
            },
            sowingDate: zone.sowingDate.format('YYYY-MM-DD'),
            harvestDate: zone.harvestDate.format('YYYY-MM-DD'),
            experimentReady: zone.experimentReady,
          };
        }),
        seasonYear: Number(this.seasons.selectedSeason),
        fieldId: this.field.id,
      });
    } catch (e) {
      throw new Error('CULTURE ZONE');
    }
  };

  setCultureSort = (mapId: string, v: string) => {
    const regexp = /^[а-яёА-ЯЁa-zA-Z0-9 ]+$/iu;
    if (!regexp.test(v) && v !== '') {
      return;
    }

    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.variety = v;
  };

  setCultureArea = (mapId: string, v: string) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.area = Number(v);
  };

  setCultureHarvestDate = (mapId: string, v: any) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.harvestDate = v;
  };

  setCultureSewDate = (mapId: string, v: any) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.sowingDate = v;
  };

  setCultureExperimentReady = (mapId: string, v: boolean) => {
    const cultureZone = this.idToCultureZone.get(mapId);

    if (cultureZone) {
      cultureZone.experimentReady = v;
    }
  };

  fetchCultures = async (mapId: string, query: string) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.culture.query = query;
    let response: TypeApiResponse<'findAllCultures'>;

    try {
      response = await this.axios.api.findAllCultures(
        {
          nameFilter: query,
          fetchAttributes: true,
          attrs: {
            useInAssistance: true,
          },
          limit: 50,
          page: 0,
        },
        { omit: ['limit', 'page'] }
      );
    } catch (e) {
      console.log(e);
      return;
    }
    console.log('response ');
    this.mapIdToCultureList.delete(mapId);
    this.mapIdToCultureList.set(mapId, response.content);
  };

  getCultureOptions = (mapId: string) => {
    const list = this.mapIdToCultureList.get(mapId);
    if (!list) {
      return [];
    }
    const zone = this.idToCultureZone.get(mapId);

    if (!zone) {
      return;
    }

    return list.map(culture => {
      return {
        label: culture.name,
        value: culture.id,
        isActive: culture.id === zone.culture.id,
      };
    });
  };

  get isAvailableSave() {
    // if (!this.cultureZones[0].sowingDate || !this.cultureZones[0].harvestDate) {
    //   return false;
    // }
    // return this.cultureZones[0].sowingDate.isBefore(this.cultureZones[0].harvestDate);
    return true;
  }

  clear = () => {
    console.log('clear all KZ');
    this.clearIdToCultureZone();
    this.clearInitialIdToCultureZone();
    this.idToCulture.clear();
    this.mapIdToCultureList.clear();
    this.holePolyIdToMapId.clear();
    this.selectedCultureZoneModel = null;
    // this.mapController.map.clear();
    // this.fields.mapMode = MapMode.Listing;
    this.setIsCultureZoneEditing(false);
    this.setIsCultureZoneCreating(false);
    this.setIsZoneNotificationWarning(false);
  };

  get isZoneChanged() {
    return false;
  }

  get seasonLabel() {
    return `Сезон ${Number(this.seasons.selectedSeason) - 1}-${this.seasons.selectedSeason}`;
  }
}
