export interface ISto {
  createDate: string;
  createdBy: string;
  culture: {
    attrs: any;
    code: string;
    description: string;
    id: string;
    level: number;
    name: string;
    parentId: string;
    path: string;
    picLink?: {
      downloadUrl: string;
      fileContentType: string;
      fileName: string;
      fileSize: number;
      fileUploadDate: string;
      fileUploadUserId: string;
      id: string;
    };
  };
  cultureZones: string[];
  id: string;
  isDefault: boolean;
  latestVersion: string;
  name: string;
  organizationId: string;
  publishDate: string;
  seasonYear: number;
  status: EStoStatus;
  totalArea: number;
  versionComment: string;
  versionName: string;
  totalCultureZones: number;
}

export enum EStoStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/**
 * DTO создания СТО
 * @DTO StoCreateDto
 * @interface ICreateSto
 */
export interface ICreateSto {
  name: string;
  organizationId: string;
  cultureId: string;
  seasonYear: number;
  isDefault: boolean;
  cultureZones?: string[];
}

/**
 * DTO обновления СТО
 * @DTO IUpdateSTO
 * @interface IUpdateSto
 */
export interface IUpdateSto {
  name: string;
  status: EStoStatus;
  organizationId: string;
  culture: { id: string; name: string };
  seasonYear: number;
  isDefault: boolean;
  cultureZones?: string[];
  stoItems: IStoItem[];
}

interface IStoItem {
  stoId: string;
  techOperationTypeId: string;
  checklistId: string;
  createdBy: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  createDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  publishDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  deleteDate: string;
  isDraft: boolean;
}

/**
 * Полный DTO СТО
 *
 * @DTO StoContentDto
 * @interface IStoContent
 */
export interface IStoContent {
  id: string;
  name: string;
  organization: {
    name: string;
    representativeFullName: string;
    representativePosition: string;
    organizationId: string;
    INN: string;
    type: {
      orgTypeId: number;
      OrgType: string;
    };
  };
  culture: {
    id: string;
    name: string;
  };
  seasonYear: number;
  status: EStoStatus;
  /**
   * дата в формате YYYY-MM-DD
   */
  createDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  publishDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  deleteDate: string;
  isDefault: boolean;
  isDraft: boolean;
  cultureZones: string[];
  stoItems: IStoItem[];
}

export enum EStoAction {
  StoView = 'Sto.view',
  StoEdit = 'Sto.edit',
  StoPublish = 'Sto.publish',
}
