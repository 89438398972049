import _ from 'lodash';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { BasePolygon } from '../../../utils/MapElements';
import { MapValidationStore } from '../../stores';
import { getIntersectingLayers } from '../../../../../../map/utils/helpers';
import { MapPolygonValidationService } from '../MapPolygonValidationService';
import { EImportedFieldErrorType } from '../../../../../../../api/models/fields/getImportedField.model';

@provide.transient()
class MapValidationService {
  @lazyInject(MapValidationStore)
  validationStore: MapValidationStore;

  @lazyInject(MapPolygonValidationService)
  polygonValidationService: MapPolygonValidationService;

  checkForIntersectionsLayer = (
    listOfPolygons: BasePolygon[],
    selectedEditableLayer: BasePolygon | null,
    editedPolygonsById: BasePolygon[]
  ): {
    hasPatchedLayers: boolean;
    hasIntersections: boolean;
    errorInCurrentField: { error: boolean; errorType: EImportedFieldErrorType };
  } => {
    const allPolygons = [...listOfPolygons, ...editedPolygonsById];

    let errorInCurrentField = {
      error: false,
      errorType: null,
    };

    // Инициализируем коллекцию для уникальных айдишников полигонов
    const intersectingLayersById: Map<number, BasePolygon> = new Map<number, BasePolygon>();

    // Проверяем размер редактируемого поля если оно есть
    const areaError = this.polygonValidationService.checkPolygonArea(selectedEditableLayer);
    if (areaError) {
      intersectingLayersById.set(selectedEditableLayer.id, selectedEditableLayer);
      errorInCurrentField = {
        error: true,
        errorType: EImportedFieldErrorType.LargeArea,
      };
    }

    // Выявляем все пересечения с рекатируемыми полями
    if (editedPolygonsById.length) {
      editedPolygonsById.forEach(polygon => {
        if (!polygon) {
          return;
        }

        const tempIntersectingLayersList = getIntersectingLayers(polygon, allPolygons);
        tempIntersectingLayersList.forEach(layer => intersectingLayersById.set(layer.id, layer));
      });
    } else if (selectedEditableLayer) {
      const tempIntersectingLayersList = getIntersectingLayers(selectedEditableLayer, allPolygons);
      if (Boolean(tempIntersectingLayersList.length)) {
        errorInCurrentField = {
          error: true,
          errorType: EImportedFieldErrorType.Intersection,
        };
      }
      tempIntersectingLayersList.forEach(layer => intersectingLayersById.set(layer.id, layer));
    }

    /**
     * Получаем айдишники слоев, которые были исправлены
     * (нужно для окрашивания их в дефолтный цвет)
     */
    const listOfPatchedLayers = this.getListOfPatchedLayersId(
      Array.from(intersectingLayersById.values())
    );

    /**
     * Закидываем в стор коллекцию айдишников пересекающихся полей
     * для следующей проверки
     */
    this.validationStore.listOfPatchedPolygons = listOfPatchedLayers;
    this.validationStore.prevIncorrectPolygonsById = intersectingLayersById;

    const hasIntersections = Boolean(intersectingLayersById.size);
    const hasPatchedLayers = Boolean(listOfPatchedLayers.length);

    return { hasIntersections, hasPatchedLayers, errorInCurrentField };
  };

  getListOfPatchedLayersId = (listOfIntersectingPolygons: BasePolygon[]) => {
    return _.without(
      this.validationStore.listOfPrevIncorrectPolygons,
      ...listOfIntersectingPolygons
    );
  };
}

export default MapValidationService;
