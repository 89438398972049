import area from '@turf/area';
import {
  booleanPointInPolygon,
  difference,
  explode,
  intersect,
  polygon as turfPolygon,
} from '@turf/turf';

import { Field } from '../../../../../../../api/models/field.model';
import { BasePolygon } from '../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EditFieldsStore } from '../../stores';
import FieldsService from '../FieldsService/Fields.service';

@provide.transient()
class EditFieldsService extends FieldsService {
  @lazyInject(EditFieldsStore)
  private store: EditFieldsStore;

  // Сохраняет редактируемое поле
  public submitField(field: Field, polygon: BasePolygon) {
    this.store.isLoading = true;

    const editableFieldWithGeoJSON = {
      name: field.name,
      geoJson: polygon.toGeoJSON(),
    };

    const promise = this.axios.api.saveFieldById(
      {
        id: field.id,
        ...editableFieldWithGeoJSON,
        seasonYear: this.seasonsStore.selectedSeason,
      },
      { omit: ['id', 'seasonYear'] }
    );

    return promise
      .catch(err => {
        throw new Error(err.response.data.error);
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  public isPolygonCultureZonesValid(polygon: BasePolygon) {
    const poly = turfPolygon(polygon.getInfo().coordinates as any);

    const ALLOWED_OUTSIDE_AREA = 1; // sq.m
    const ALLOWED_CZ_OVERFLOW_AREA = 10;

    const culturesList = this.store.editableField?.cultureZones;

    if (culturesList?.length > 0) {
      let isCultureZoneOutsidePolygon: boolean;
      let isPolygonPointInsideCultureZone: boolean;
      let isPolygonIntersectsCultureZones: boolean;

      const pointsOfNewPolygon = explode(poly);
      culturesList.forEach(cultureZone => {
        const cultureZonePolygon = turfPolygon(cultureZone.geometry.coordinates);
        const cultureZoneOutside = difference(cultureZonePolygon, poly);
        const outsideArea = cultureZoneOutside === null ? 0 : area(cultureZoneOutside);

        /**
         *  Полигон культурной зоны не выступает больше погрешности
         */
        if (outsideArea > ALLOWED_OUTSIDE_AREA) {
          isCultureZoneOutsidePolygon = outsideArea > ALLOWED_OUTSIDE_AREA;
          return;
        }

        /**
         * Точки полигона внутри культурных зон
         */
        isPolygonPointInsideCultureZone = pointsOfNewPolygon.features.some(point => {
          return booleanPointInPolygon(point, cultureZonePolygon, {
            ignoreBoundary: true,
          });
        });

        const intersection = intersect(poly, cultureZonePolygon);

        if (
          intersection !== null &&
          Math.abs(area(intersection) - area(cultureZonePolygon)) > ALLOWED_CZ_OVERFLOW_AREA
        ) {
          isPolygonIntersectsCultureZones = true;
        }
      });

      if (
        isPolygonIntersectsCultureZones ||
        isPolygonPointInsideCultureZone ||
        isCultureZoneOutsidePolygon
      ) {
        console.warn('🗺 ❌ При изменении контура поля найдено невалидное состояние:', {
          isPolygonIntersectsCultureZones,
          isPolygonPointInsideCultureZone,
          isCultureZoneOutsidePolygon,
        });
      }

      return (
        !isPolygonIntersectsCultureZones &&
        !isPolygonPointInsideCultureZone &&
        !isCultureZoneOutsidePolygon
      );
    } else {
      return true;
    }
  }
}

export default EditFieldsService;
