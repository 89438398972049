import React, { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  Arrow,
  Content as ToolContent,
  IconWrapper,
  Item,
  Label as ToolLabel,
  Wrapper as ToolWrapper,
} from '../../../../../../shared/components/ToolTip/style';
import { Colors } from '../../../../../../shared/constans/colors';
import { useWarningBeforeLeavingThePage } from '../../../../../../shared/hooks/useWarningBeforeLeavingThePage';
import { ReactComponent as AddIcon } from '../../../../../../shared/static/add.svg';
import { ReactComponent as BinIcon } from '../../../../../../shared/static/bin_red.svg';
import { ReactComponent as EditIcon } from '../../../../../../shared/static/edit.svg';
import { useStore } from '../../../../../../shared/utils/IoC';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { PopupPages } from '../../../../../constants/popup.pages';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { UiStore } from '../../../../../stores/ui.store';
import { FieldsRoute } from '../../../fields.route';
import { DisplayFieldsController, FieldsUIStore, SharedFieldsStore } from '../../../mobx';

type Props = {
  closeMenu: () => void;
  setIsCardFocused: (value: boolean) => void;
  setIsDeleteModalOpen: () => void;
  onClick: (id: string) => void;
  showModal: boolean;
  filedId: string;
  dataTestId: string;
  positions: {
    x: number;
    y: number;
  };
};
export const ContextMenu = ({
  positions,
  closeMenu,
  setIsCardFocused,
  showModal,
  filedId,
  onClick,
  dataTestId,
  setIsDeleteModalOpen,
}: Props) => {
  const uiStore = useStore(UiStore);
  const fieldUiStore = useStore(FieldsUIStore);

  const navigate = useNavigate();
  const organizationsStore = useStore(OrganizationsStore);
  const sharedFieldsStore = useStore(SharedFieldsStore);
  const displayFieldsController = useStore(DisplayFieldsController);

  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();

  const handleRef = el => {
    if (!el) {
      return;
    }
    if (positions.y + el.clientHeight > window.innerHeight)
      uiStore.openTip(positions.x + 35, window.innerHeight - el.clientHeight);

    el.focus();
  };

  uiStore.setContextMenuCloseFunc(() => {
    closeMenu();
    uiStore.closeTip();
    setIsCardFocused(false);
    // setIsOpenContextMenu(false);
  });

  const closeContextMenu = () => {
    closeMenu();
    uiStore.closeTip();
    setIsCardFocused(false);
    // setIsOpenContextMenu(false);
  };

  const fieldsEditRoute = useMemo(() => {
    return generatePath(FieldsRoute.Edit, {
      orgId: organizationsStore.selectedOrganizationId,
      fieldId: filedId,
    });
  }, [filedId, organizationsStore.selectedOrganizationId]);

  const onFieldEditClick = () => {
    closeContextMenu();

    if (showModal) {
      showWarningBeforeLeavingThePage(() => {
        navigate(fieldsEditRoute);
        // Временный костыль. Убрать во время рефакторинга КЗ
        if (fieldUiStore.isSeasonsPopupOpen) {
          fieldUiStore.popupPageState = PopupPages.None;
        }
      });
      return;
    }

    // Временный костыль. Убрать во время рефакторинга КЗ
    if (fieldUiStore.isSeasonsPopupOpen) {
      fieldUiStore.popupPageState = PopupPages.None;
    }
    navigate(fieldsEditRoute);
  };

  const onAddCultureZoneClick = () => {
    closeContextMenu();
    if (filedId !== sharedFieldsStore.selectedField?.id) {
      if (showModal) {
        showWarningBeforeLeavingThePage(() => {
          fieldUiStore.showFullWeather = false;
          displayFieldsController.initialize().then(() => {
            fieldUiStore.popupPageState = PopupPages.CultureZone;
            onClick(filedId);
          });
        });
      } else {
        fieldUiStore.showFullWeather = false;
        fieldUiStore.popupPageState = PopupPages.CultureZone;
        onClick(filedId);
      }
    } else if (
      fieldUiStore.popupPageState !== PopupPages.CultureZone &&
      fieldUiStore.popupPageState !== PopupPages.Seasons
    ) {
      fieldUiStore.showFullWeather = false;
      fieldUiStore.popupPageState = PopupPages.CultureZone;
      onClick(filedId);
    } else if (fieldUiStore.isSeasonsPopupOpen) {
      fieldUiStore.popupPageState = PopupPages.CultureZone;
    }
  };

  return (
    <ToolWrapper
      ref={handleRef}
      onBlur={event => {
        event.stopPropagation();
        closeContextMenu();
      }}
      tabIndex={-1}
      data-test-id={`${dataTestId}context-menu`}
    >
      <ToolContent>
        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.editField">
          <Item onClick={onFieldEditClick}>
            <IconWrapper>
              <EditIcon />
            </IconWrapper>
            <ToolLabel>Редактировать</ToolLabel>
          </Item>
        </ControlAccessRulesWrapper>
        {filedId === sharedFieldsStore.selectedField?.id &&
        fieldUiStore.isCultureZonePopupOpen ? null : (
          <ControlAccessRulesWrapper
            mode={AccessRuleVisibility.Hide}
            actionName="field.editCultureZone"
          >
            <Item onClick={onAddCultureZoneClick}>
              <IconWrapper>
                <AddIcon />
              </IconWrapper>
              <ToolLabel>Добавить культуру</ToolLabel>
            </Item>
          </ControlAccessRulesWrapper>
        )}
        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.deleteField">
          <Item
            onClick={e => {
              e.stopPropagation();
              setIsDeleteModalOpen();
              closeContextMenu();
            }}
          >
            <IconWrapper>
              <BinIcon />
            </IconWrapper>
            <ToolLabel color={Colors.red}>Удалить</ToolLabel>
          </Item>
        </ControlAccessRulesWrapper>
      </ToolContent>
      <Arrow style={{ top: '15px' }} />
    </ToolWrapper>
  );
};
