import { useStore } from '../../../../../../../utils/IoC';
import { MapCoreController } from '../../../../../modules';
import { ReactComponent as ArrowIcon } from '../../../../../../../static/arrow.svg';

import Styled from './styles';

export const Locate = () => {
  const mapCoreController = useStore(MapCoreController);

  const onLocate = () => {
    mapCoreController.locate({ setView: true });
  };
  return (
    <Styled.Wrapper>
      <Styled.Button onClick={onLocate} data-test-id="map-user-controls-locate-user-action">
        <ArrowIcon />
      </Styled.Button>
    </Styled.Wrapper>
  );
};
