import { Chip, Tabs, Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';
import moment from 'moment';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { useStoNavigation, useStoParams, useStoActionControl } from '../../../../hooks';
import { ERequestStatus } from '../../../../../../../shared/constants/requests';
import { SpinnerLoader } from '../../../../../../../shared/components/loaders';
import { useWarningBeforeLeavingThePage } from '../../../../../../../shared/hooks';
import { Fullscreen } from '../../../../../../../shared/containers';
import { EStoTab } from '../../models/StoCommon';
import { CheckAccessStore } from '../../../../../../../authorization/stores/checkAccess.store';
import { EStoAccessAction } from '../../../../../../../shared/constants/access-rules-action';
import { StoController, StoStore } from '../../mobx';
import { StoControlButtons } from '../../common/SummaryForm/components';

import Styled from './StoContainer.styles';

const StoContainer: FC = () => {
  const store = useStore(StoStore);
  const controller = useStore(StoController);
  const accessStore = useStore(CheckAccessStore);

  const { navigateToListing, isCreationPath } = useStoNavigation();
  const { stoId } = useStoParams();
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage(store.hasChanges);
  const { isAllowToEdit } = useStoActionControl();

  useEffect(() => {
    controller.initiateSto({ stoId, isCreation: isCreationPath }).then(() => {
      store.setIsViewOnly(!isAllowToEdit);
    });

    return () => {
      store.clearStoStore();
    };
  }, [stoId]);

  useEffect(() => {
    if (accessStore?.accessRulesReady) {
      store.setIsViewOnly(!isAllowToEdit);
    }
  }, [accessStore?.accessRulesReady]);

  const changeTab = (tabId: EStoTab) => {
    if (store.hasChanges) {
      showWarningBeforeLeavingThePage(() => {
        store.clearHasChanges();
        controller.changeStoTab(tabId);
      });
    } else {
      controller.changeStoTab(tabId);
    }
  };

  const underTitleContent = useMemo(() => {
    const subtitleItemList = [];

    // Текстовка появляется только когда проваливаемся в настройку операций
    // if (store?.selectedSTO?.name) subtitleItemList.push(store?.selectedSTO?.name);

    if (store?.selectedSto?.culture?.name) subtitleItemList.push(store?.selectedSto?.culture?.name);

    // Количество полей только на вкладке операций и глубже
    // if (store?.selectedSTO?.cultureZones?.length > 0)
    //   subtitleItemList.push(`${store?.selectedSTO?.cultureZones?.length} полей`);

    if (!subtitleItemList.length) return null;

    return (
      <Styled.UnderTitleTypography data-test-id="sto-title-subtitle">
        {subtitleItemList.join(' • ')}
      </Styled.UnderTitleTypography>
    );
  }, [store.selectedSto]);

  const titleChip = useMemo(() => {
    if (!store.selectedSto) return null;

    const color = store?.selectedSto.isDraft ? 'default' : 'primary';
    const startText = store?.selectedSto.isDraft ? 'Черновик от' : 'Версия от';
    const date = moment(
      store?.selectedSto.isDraft ? store?.selectedSto.createDate : store?.selectedSto?.publishDate
    ).format('DD.MM.YYYY');

    return (
      <Chip color={color} size="medium" dataTestId="sto-title-chip">
        {startText} {date}
      </Chip>
    );
  }, [store.selectedSto]);

  if (store.requestStoStatus === ERequestStatus.Pending) {
    return <SpinnerLoader needToHideContent />;
  }

  return (
    <Styled.Wrapper>
      <Fullscreen
        backButtonText={
          <Styled.TitlePaddingWrapper>
            <Typography className="header-title" variant="h4">
              <Styled.TitleWrapper>
                <span data-test-id="sto-title">
                  {store?.selectedSto?.name ??
                    store?.StoFullscreenConfig?.titleText ??
                    'Стандарт техоперации'}
                </span>
                <Styled.ChipWrapper>{titleChip}</Styled.ChipWrapper>
              </Styled.TitleWrapper>
            </Typography>
          </Styled.TitlePaddingWrapper>
        }
        arrowText={store?.StoFullscreenConfig?.goBackText}
        backButtonHandler={navigateToListing}
        underTitleContent={underTitleContent}
        contentStyle={{
          $paddings: '4px 0 32px',
        }}
      >
        <Tabs
          stylePreset="secondary"
          content={store?.availableTabList}
          selectedTabId={store.StoTab}
          onChangeTab={changeTab}
          dataTestId="sto-tab"
          size="small"
        />
        {store?.StoFullscreenConfig?.fullscreenContent}
        <StoControlButtons />
      </Fullscreen>
    </Styled.Wrapper>
  );
};

StoContainer.displayName = 'StoContainer';

export default observer(StoContainer);
