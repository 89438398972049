import moment from 'moment';

import { Operation } from '../../../../../api/models/operations/operation.model';
import { displayModalWindow } from '../../../../modals/config';
import { ModalsStore } from '../../../../modals/store/modals.store';
import { Axios } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { ETasksModalName, tasksModalUniqueKey } from '../modals/modalsConfig';
import { OperationsStore } from '../stores/operations.store';
import { SeasonCultureStore } from '../stores/season.culture.store';
import { TasksEditStore } from '../stores/task.edit.store';
import { PreparedTask, Task, TasksStore } from '../stores/tasks.store';
import { ChecklistInstancesStore } from '../../operationsAndTasks/stores/checklist.instances.store';
import { checklistInstanceHelpers } from '../../operationsAndTasks/utils/helpers/checklists';
import {
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import { TaskViewStore } from '../stores/task.view.store';
import { FieldsService } from '../../../../shared/mobx/services/as-fields';
import { Field } from '../../../../../api/models/field.model';

import { TasksController } from './tasks.controller';

@provide.singleton()
export class TaskEditController {
  @lazyInject(Axios)
  axios: Axios;

  @lazyInject(TasksEditStore)
  taskEditStore: TasksEditStore;

  @lazyInject(TaskViewStore)
  protected taskViewStore: TaskViewStore;

  @lazyInject(OrganizationsStore)
  organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCulture: SeasonCultureStore;

  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(OperationsStore)
  protected operationsStore: OperationsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCultureStore: SeasonCultureStore;

  @lazyInject(TasksController)
  protected tasksController: TasksController;

  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  fetchField = async (id: string): Promise<Field> => {
    const { setSelectedField } = this.taskEditStore;
    const { getField } = this.fieldsService;

    const fetchedField = await getField({ fieldId: id });

    if (fetchedField) {
      setSelectedField(fetchedField);

      return fetchedField;
    }
  };

  /**
   * Активирует редактирование задачи
   */
  enableTaskEditing = (): void => {
    const { cultureId, taskForView, cultureList, operations } = this.taskViewStore;
    const { setCultureList, setOperationList } = this.taskEditStore;

    this.setTask(taskForView);

    setOperationList(operations.slice());
    setCultureList(cultureList);

    this.setSelectedFormCultureId(cultureId);
  };

  setSelectedFormCultureId = cultureId => {
    this.taskEditStore.cultureId = cultureId;
  };

  setSelectedFormOperationId = operationId => {
    this.taskEditStore.selectedOperationId = operationId;
  };

  getSeasonCultures = async (year, organizationId?) => {
    this.taskEditStore.culturesLoading = true;
    const response = await this.axios.api.getSeasonCulture(
      {
        year,
        organizationId: organizationId === 'my' ? undefined : organizationId,
      },
      { omit: ['year'] }
    );
    this.operationsStore.fieldsWithoutCulturesCount = response.fieldsWithoutCulturesCount;
    this.taskEditStore.cultureList = response;
    this.taskEditStore.culturesLoading = false;
  };

  getOperationsByCultureId = async (cultureId?) => {
    const organizationId =
      this.organizationsStore.selectedOrganizationId === 'my'
        ? ''
        : this.organizationsStore.selectedOrganizationId;
    this.taskEditStore.operationsLoading = true;
    let noCulture = false;
    let cultureIdForRequest = '';
    if (cultureId === '') {
      noCulture = true;
      cultureIdForRequest = undefined;
    } else {
      noCulture = undefined;
      cultureIdForRequest = cultureId;
    }

    const response = await this.axios.api.getOperations({
      organizationId,
      cultureId: cultureIdForRequest,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      noCulture,
      size: 10000,
    });
    this.taskEditStore.operations = response.content;
    this.taskEditStore.operationsLoading = false;
  };

  getFieldsOfOperation = async ({
    operationId,
    organizationId,
  }: {
    operationId?: string;
    organizationId?: string;
  }) => {
    this.taskEditStore.fieldsLoading = true;

    if (operationId) {
      const response = await this.axios.api.getOperationFields({
        operationId,
        organizationId: organizationId === 'my' ? '' : organizationId,
      });
      this.taskEditStore.operationFields = response;
      console.log(this.taskEditStore.operationFields);
      this.taskEditStore.fieldsLoading = false;
    }
  };

  getOrganizationUsersList = async (organizationId: string) => {
    const response = await this.axios.api.getOrganizationUsers({
      organizationID: organizationId === 'my' ? '' : organizationId,
      status: 'ACTIVE',
    });
    this.taskEditStore.usersList = response.content;
  };

  setTask = (task: Partial<Task> | PreparedTask) => {
    this.taskEditStore.task = {
      ...task,
      planStartDate: task.planStartDate ? moment(task.planStartDate) : undefined,
      planEndDate: task.planEndDate ? moment(task.planEndDate) : undefined,
    };
  };

  createTask = async (isCreatingOfMultipleTasks?: boolean): Promise<string | string[]> => {
    const {
      task,
      preparedSelectedFieldList,
      clearCollectionOfSelectedFieldId,
    } = this.taskEditStore;
    const { listOfDrawPointInst, listOfDrawMachineryInst } = this.checklistInstancesStore;
    const { isMachinery } = checklistInstanceHelpers;

    const instanceList = [...listOfDrawPointInst, ...listOfDrawMachineryInst].map(
      ({ instance }) => instance
    );

    const instancesPayload: (
      | IGetChecklistInstanceByTaskId
      | IPutChecklistInstance
    )[] = instanceList.map(combineInstance => {
      const instance = combineInstance as IGetChecklistInstanceByTaskId;

      if (isMachinery(instance.type)) {
        return instance;
      }

      return {
        ...instance,
        planCoords: instance.planCoords
          ? {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: instance.planCoords?.coordinates?.length
                  ? instance.planCoords?.coordinates.slice()
                  : instance.planCoords?.geometry?.coordinates.slice(),
              },
            }
          : undefined,
        intensity: instance?.intensity?.id,
      };
    });

    const assigneeId =
      this.organizationsStore.selectedOrganizationId === 'my'
        ? undefined
        : task.assigneeId
        ? task.assigneeId
        : (task as Task).assignee?.id;

    const createTaskResponse = isCreatingOfMultipleTasks
      ? await this.axios.api.createMultipleTasks({
          planStartDate: (task.planStartDate as moment.Moment).format('YYYY-MM-DD'),
          planEndDate: (task.planEndDate as moment.Moment).format('YYYY-MM-DD'),
          comment: task?.comment,
          status: task?.status,
          operationId: task?.operationId,
          assigneeId,
          fieldId: preparedSelectedFieldList,
        })
      : await this.axios.api.createTask_oldMethod({
          ...this.taskEditStore.task,
          planStartDate: (task.planStartDate as moment.Moment).format('YYYY-MM-DD'),
          planEndDate: (task.planEndDate as moment.Moment).format('YYYY-MM-DD'),
          executedDate: undefined,
          assigneeId,
          checkListInstances: instancesPayload,
        });

    if (!this.tasksStore.fromTasksPage) {
      this.tasksController.resetPageSettings();

      await this.tasksController.fetchTasks({
        organizationId: this.organizationsStore.selectedOrganizationId,
        seassonCultureId: this.seasonCultureStore.selectedCultureId,
        operationId: this.operationsStore.selectedOperationId,
        fieldId: this.operationsStore.selectedOperationFieldId,
        noCulture: this.seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
      });
    }

    if (isCreatingOfMultipleTasks) {
      clearCollectionOfSelectedFieldId();

      return (createTaskResponse as Task[]).flatMap(createdTask => createdTask.id);
    }

    return (createTaskResponse as any)?.id;
  };

  deleteTask = async (taskId: string) => {
    await this.axios.api.deleteTask({
      id: taskId,
    });
  };

  getStartDate = (
    operationParam: Operation,
    fromOperationChange?: boolean,
    isCheckedStartDate?: boolean
  ) => {
    let result;
    if (isCheckedStartDate) {
      const startDate =
        operationParam?.startDate ||
        this.taskEditStore.task.planStartDate ||
        this.tasksStore.selectedTask.planStartDate;
      const minDate = moment(startDate).hours(0).minutes(0).seconds(0).milliseconds(0);
      const endDate =
        operationParam?.endDate ||
        this.taskEditStore.task.planEndDate ||
        this.tasksStore.selectedTask.planEndDate;
      const maxDate = moment(endDate).hours(23).minutes(59).seconds(59).milliseconds(999);

      if (moment().isBetween(minDate, maxDate)) {
        return moment();
      }

      if (moment().isBefore(minDate) || moment().isSame(minDate)) {
        return minDate;
      }

      if (moment().isAfter(maxDate) || moment().isSame(maxDate)) {
        return maxDate;
      }

      return moment();
    }

    if (
      !fromOperationChange &&
      (this.taskEditStore.task.planStartDate || this.tasksStore.selectedTask.planStartDate)
    ) {
      result = moment(
        this.taskEditStore.task.planStartDate || this.tasksStore.selectedTask.planStartDate
      );
    } else if (operationParam) {
      const currentDate = moment();
      const startDate = moment(operationParam.startDate);
      const endDate = moment(operationParam.endDate).add(1, 'days');
      result = currentDate >= startDate && currentDate < endDate ? currentDate : null;
    }

    return result;
  };

  getModalTaskCompletedSuccessfully = (closeCallback): void => {
    const { setModal } = this.modalsStore;

    const successHandler = (): void => closeCallback();

    setModal(
      tasksModalUniqueKey,
      displayModalWindow(
        tasksModalUniqueKey,
        ETasksModalName.TaskCompletedSuccessfully,
        successHandler
      )
    );
  };

  getModalWarningBeforeChangeInstance = (closeCallback): void => {
    const { setModal } = this.modalsStore;

    const successHandler = (): void => {
      closeCallback();
      // Обнуляем усоловия при  которых отображается модалка если выходим из редактирования/создания задачи
      this.checklistInstancesStore.setHasPositionToInstanceChanged(false);
      this.clearTouchedFields();
    };

    setModal(
      tasksModalUniqueKey,
      displayModalWindow(
        tasksModalUniqueKey,
        ETasksModalName.WarningBeforeChangeInstance,
        successHandler
      )
    );
  };

  getModalTaskCreationError = (): void => {
    const { setModal } = this.modalsStore;

    setModal(
      tasksModalUniqueKey,
      displayModalWindow(tasksModalUniqueKey, ETasksModalName.TaskCreationError)
    );
  };

  addFiledToTouchedFields = (fieldName: string) => this.taskEditStore.touchedFields.add(fieldName);
  removeFiledToTouchedFields = (fieldName: string) =>
    this.taskEditStore.touchedFields.delete(fieldName);
  clearTouchedFields = () => {
    this.taskEditStore.touchedFields = new Set<string>();
  };

  isCultureSelectDisabled = (): boolean =>
    Boolean(this.taskEditStore.task.id) || this.taskEditStore.culturesLoading;

  isOperationSelectDisabled = (): boolean =>
    Boolean(this.taskEditStore.task.id) ||
    !this.taskEditStore.cultureId ||
    this.taskEditStore.culturesLoading ||
    this.taskEditStore.operationsLoading;

  isFiledSelectDisabled = (): boolean =>
    Boolean(this.taskEditStore.task.id) ||
    !this.taskEditStore.cultureId ||
    this.taskEditStore.culturesLoading ||
    !(this.taskEditStore.task.operationInfo?.id || this.taskEditStore.task.operationId) ||
    this.taskEditStore.operationsLoading ||
    this.taskEditStore.fieldsLoading;

  isAssigneeSelectDisabled = (): boolean => this.organizationsStore.selectedOrganizationId === 'my';

  changeMultipleFieldList = (fieldIdList: string[]): void => {
    const { task, setSelectedFieldList } = this.taskEditStore;

    this.setTask({
      ...task,
      fieldId: fieldIdList?.[0],
      field: {
        id: fieldIdList?.[0],
      },
    });

    setSelectedFieldList(fieldIdList);
  };

  clearStore = () => {
    this.taskEditStore.assigneeId = '';
    this.taskEditStore.cultureId = '';
    this.taskEditStore.cultureList = null;
    this.taskEditStore.operationFields = [];
    this.taskEditStore.operations = [];
    this.taskEditStore.selectedFieldId = '';
    this.taskEditStore.selectedOperationId = '';
    this.taskEditStore.task = {};
    this.taskEditStore.usersList = [];
    this.taskEditStore.presetDateFromTaskList = null;
    this.taskEditStore.touchedFields = new Set();
  };
}
