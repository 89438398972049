import { observer } from 'mobx-react';
import { FC, useRef } from 'react';

import { useStore } from '../../../shared/utils/IoC';
import { CultureZoneStore } from '../PopupSlider/components/CultureZone/stores/culture.zone.store';
import { CreateZoneToolTip } from '../PopupSlider/components/CultureZone/components/CreateZoneToolTip/CreateZoneToolTip';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import EditCultureZoneController from '../PopupSlider/components/CultureZone/controllers/EditCultureZone.controller';

export const HoleTooltip: FC = observer(() => {
  const store = useStore(CultureZoneStore);
  const { continueHoleClick } = useStore(EditCultureZoneController);
  const { isCreateNewZoneTooltipVisible, tooltipPosition, denyHoleClick } = store;

  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, denyHoleClick);

  return (
    isCreateNewZoneTooltipVisible && (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          top: tooltipPosition.y,
          left: tooltipPosition.x,
          zIndex: 500,
        }}
      >
        <CreateZoneToolTip acceptAction={continueHoleClick} denyAction={denyHoleClick} />
      </div>
    )
  );
});
