import styled, { css } from 'styled-components';

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  padding-bottom: 12px;

  border-bottom: 1px solid #e9e6f0;
`;

const AddPointLabel = styled.div`
  margin-top: 24px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3fb65f;
  cursor: pointer;
`;

const InstanceListWrapper = styled.div`
  margin-top: 20px;
`;

const MapWrapper = styled.div<{ $marginTop?: string; $isHidden?: boolean }>`
  width: 100%;
  height: 415px;

  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: ${$marginTop};
    `}

  ${({ $isHidden }) =>
    $isHidden
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}
`;

const Wrapper = styled.section`
  width: 912px;

  margin: 0;
  padding: 24px;

  border-radius: 16px;

  background-color: #ffffff;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

const StyledInspectionPoints = {
  Title,
  Header,
  AddPointLabel,
  InstanceListWrapper,
  MapWrapper,
  Wrapper,
  TitleWrapper,
};

export default StyledInspectionPoints;
