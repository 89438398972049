import L from 'leaflet';
import { point as getTurfPoint } from '@turf/helpers';
import { booleanPointInPolygon as checkIfPointInPolygon } from '@turf/turf';
import { Feature } from 'geojson';

import { provide } from '../../../../../utils/IoC';

@provide.transient()
class MapMarkerValidationService {
  // валидация маркера
  checkIfMouseInsidePolygon = (event: L.LeafletMouseEvent, geojson: Feature) => {
    const point = getTurfPoint([event.latlng.lng, event.latlng.lat]);

    return checkIfPointInPolygon(point, geojson as any);
  };
}

export default MapMarkerValidationService;
