import moment from 'moment/moment';

import { ITableFiltersBuilderFilterConfig as IFilterConfig } from '../../../models/configs';
import {
  ITableFiltersBuilderFilter as IFilter,
  ITableFiltersBuilderValue as IValue,
  ITableFiltersBuilderTag as ITag,
} from '../../../models/data';
import { TableFiltersBuilderStore as Store } from '../../stores';
import { getFormattedDateRangeLabel } from '../../../utils/helpers/dates';
import { lazyInject, provide } from '../../../../../utils/IoC';

@provide.singleton()
class TableFiltersBuilderValuesService<F = any> {
  @lazyInject(Store)
  protected store: Store<F>;

  getDefaultValueList = (filterConfigList: IFilterConfig<F>[]): IValue<F>[] => {
    const defaultValueList = filterConfigList?.reduce<IValue<F>[]>((acc, config) => {
      if (config?.defaultValue) {
        acc.push({ ...config.defaultValue, isDefault: true });
      }

      return acc;
    }, []);

    return defaultValueList;
  };

  addToStoreDefaultValueList = (builderId: string, filterConfigList: IFilterConfig<F>[]): void => {
    const defaultValueList = this.getDefaultValueList(filterConfigList);

    this.store.setDefaultValueList(builderId, defaultValueList, {
      isClearPreviousList: true,
    });
  };

  applyDefaultValues = (builderId: string): void => {
    const defaultValueList = this.store.getDefaultValueList(builderId);

    this.store.setAppliedValueList(builderId, defaultValueList, {
      isClearPreviousList: true,
    });
  };

  getAppliedFilters = (builderId: string): F | null => {
    const appliedValueList = this.store.getAppliedValueList(builderId);
    const filterList = this.store.getFilterList(builderId);

    if (!filterList.length) {
      return null;
    }

    const appliedFilters = appliedValueList.reduce<F>((filters, value) => {
      if (value.type === 'select') {
        const formattedValue = value.selectOptionList.map(option => option.value) as any;

        filters[value.filterId] = formattedValue;
      }

      if (value.type === 'date-range') {
        const formattedValue = moment(value.dateValue).format('YYYY-MM-DD') as any;

        filters[value.filterId] = formattedValue;
      }

      if (value.type === 'boolean') {
        const formattedValue = value.booleanValue as any;

        filters[value.filterId] = formattedValue;
      }

      return filters;
    }, {} as F);

    return appliedFilters;
  };

  createSelectTag = (filter: IFilter<F>, value: IValue<F>): ITag<F> | ITag<F>[] => {
    if (filter?.selectOptions?.isDisplayAllTagsAsOne && value.selectOptionList.length) {
      const label = `${filter.name}: ${value.selectOptionList.length}`;

      return {
        builderId: filter.builderId,
        id: filter.id as string,
        filter,
        label,
      };
    } else {
      return value.selectOptionList.map(option => ({
        builderId: filter.builderId,
        id: option.value as string,
        filter,
        label: option.label,
      }));
    }
  };

  createRangeDateTag = (filter: IFilter<F>, value: IValue<F>): ITag<F> => {
    const relatedValue = this.store.getAppliedValue(
      filter.builderId,
      filter?.dateRangeOptions?.relatedEndDateFilterId
    );

    const label = getFormattedDateRangeLabel(value.dateValue, relatedValue?.dateValue);

    const tag: ITag<F> = {
      builderId: filter.builderId,
      id: filter.id as string,
      filter,
      label,
    };

    if (value?.isDefault) {
      tag.isDisableRemoving = true;
    }

    return tag;
  };

  createBooleanTag = (filter: IFilter<F>): ITag<F> => {
    const label = filter.name;

    return {
      builderId: filter.builderId,
      id: filter.id as string,
      filter,
      label,
    };
  };

  createTag = (builderId: string, value: IValue<F>): ITag<F> | ITag<F>[] => {
    const filter = this.store.getFilter(builderId, value.filterId);

    if (!filter) {
      return [];
    }

    switch (value.type) {
      case 'select':
        return this.createSelectTag(filter, value);
      case 'date-range':
        return this.createRangeDateTag(filter, value);
      case 'boolean':
        return this.createBooleanTag(filter);
      default:
    }
  };

  createTagList = (builderId: string): ITag<F>[] => {
    const appliedValueList = this.store.getAppliedValueList(builderId, {
      isVisibleOnly: true,
    });

    const clearedList = this.getClearedValueList(appliedValueList);

    return clearedList.flatMap(value => this.createTag(builderId, value));
  };

  public hasAppliedValueList = (builderId: string): boolean => {
    const appliedValueList = this.store.getAppliedValueList(builderId, {
      isVisibleOnly: true,
    });

    const clearedValueList = this.getClearedValueList(appliedValueList);

    return Boolean(clearedValueList.length);
  };

  protected getClearedValueList = (valueList: IValue<F>[]): IValue<F>[] => {
    return valueList.filter(value => {
      /**
       * Если это boolean фильтр, у которого выбрано 'false',
       * то не отображаем данный тег.
       */
      const isBooleanValue = value.type === 'boolean';
      const isNotSelected = !value?.booleanValue;

      return !(isBooleanValue && isNotSelected);
    });
  };
}

export default TableFiltersBuilderValuesService;
