import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  FieldsService,
  SeasonsService,
} from '../../../../../../../../shared/mobx/services/as-fields';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { OperationCulture } from '../../../../../../../../../api/models/operations/operation.culture';
import {
  createCultureZoneSelectOptionList,
  createOperationCultureSelectOptionList,
} from '../../../../../../../../shared/utils/helpers/selectOptions';
import { CultureZone } from '../../../../../../../../../api/models/field.model';
import { TableFiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { ISTOFilters as IFilters } from '../../../models';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { ETableFiltersBuilderId } from '../../../../../../../constants/configs/TableFiltersBuilderId';

@provide.transient()
class STOFiltersOptionsService {
  @lazyInject(SeasonsService)
  protected seasonsService: SeasonsService;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  private organizationsStore: OrganizationsStore;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IFilters>;

  public createOptionsList = async (): Promise<
    [ISelectOption<OperationCulture>[], ISelectOption<CultureZone>[]]
  > => {
    const optionsList = await Promise.all([
      this.createCultureOptionList(),
      this.createCultureZoneOptionList(),
    ]);

    return optionsList;
  };

  public addCultureOptionList = (optionList: ISelectOption<OperationCulture>[]): void => {
    this.tableFiltersBuilderController.addSelectOptionList(
      ETableFiltersBuilderId.Sto,
      'cultureIds',
      optionList,
      { isNeedToCompare: true }
    );
  };

  public addCultureZoneOptionList = (optionList: ISelectOption<CultureZone>[]): void => {
    this.tableFiltersBuilderController.addSelectOptionList(
      ETableFiltersBuilderId.Sto,
      'cultureZones',
      optionList,
      { isNeedToCompare: true }
    );
  };

  protected createCultureOptionList = async (): Promise<ISelectOption<OperationCulture>[]> => {
    const cultureList = await this.fetchCultureList();

    return createOperationCultureSelectOptionList(cultureList);
  };

  protected fetchCultureList = async (): Promise<OperationCulture[]> => {
    const response = await this.seasonsService.getCultureList({
      year: Number(this.seasonsStore.selectedSeason),
      organizationId: this.organizationsStore.selectedOrganizationId,
    });

    if (response?.cultureAndVarietyList) {
      return response.cultureAndVarietyList;
    }

    return [];
  };

  protected createCultureZoneOptionList = async (): Promise<ISelectOption<CultureZone>[]> => {
    const zoneList = await this.fetchCultureZoneList();

    return createCultureZoneSelectOptionList(zoneList);
  };

  protected fetchCultureZoneList = async (): Promise<CultureZone[]> => {
    const response = await this.fieldsService.getCultureZoneList({
      seasonYear: Number(this.seasonsStore.selectedSeason),
      organizationId: this.organizationsStore.selectedOrganizationId,
      withGeometry: false,
      sort: 'f.name',
    });

    if (response?.content) {
      const optionList = response.content;

      return optionList;
    }

    return [];
  };
}

export default STOFiltersOptionsService;
