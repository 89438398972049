import { observer } from 'mobx-react';

import { EFieldFill } from '../../../../../../../../../dashboard/constants/FieldFill.enum';
import { useStore } from '../../../../../../../../utils/IoC';
import { IndicesStore } from '../../mobx';
import { Dropdown } from '../../../../../../../../components/inputs/Dropdown';

import Styled from './FillSelector.styles';

const OPTION_LIST: { label: string; value: EFieldFill }[] = [
  { label: 'Индекс NDVI', value: EFieldFill.Ndvi },
  { label: 'Спутниковый снимок', value: EFieldFill.None },
];

const FillSelector = () => {
  const { setCurrentFillMode, currentFillMode } = useStore(IndicesStore);

  const defaultValue = OPTION_LIST.find(item => item.value === currentFillMode);

  const config = {
    field: {
      onChange: (value: string) => {
        setCurrentFillMode(value as EFieldFill);
      },
      placeholder: 'Заполнение',
      isDisabled: false,
      defaultValue,

      type: {},
    },
    body: {
      optionList: OPTION_LIST,
    },
    other: {
      dataTestId: 'fill-selector',
    },
  };

  return (
    <Styled.Wrapper>
      <Dropdown config={config} />
    </Styled.Wrapper>
  );
};

export default observer(FillSelector);
