import { observer } from 'mobx-react';
import { Typography } from '@farmlink/farmik-ui';
import moment from 'moment';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore as store } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './History.styles';

const SOURCE_MAP: Record<string, { text: string; icon: React.ReactNode }> = {
  WEB: { text: 'Через web-браузер', icon: <Styled.DesktopIcon /> },
  MOBILE: { text: 'C мобильного устройства', icon: <Styled.MobileIcon /> },
};

const History: React.FC = ({ children }) => {
  const getDataTestId = useDataTestIdV2('checklists__point-history');

  const { selectedInstanceEventList: eventList } = useStore(store);

  return (
    <Styled.List {...getDataTestId()}>
      {eventList?.map((event, i) => {
        const data = SOURCE_MAP[event.source];
        const isLast = i === eventList.length - 1;

        return (
          <Styled.Item key={`${event.creationDate}_${i}`} {...getDataTestId('record')}>
            <Styled.Icon {...getDataTestId('record__icon')}>{data.icon}</Styled.Icon>

            <Typography variant="body" dataTestId={getDataTestId('record__text')['data-test-id']}>
              {data.text ?? 'С неопознанного устройства'}
            </Typography>

            <Styled.Date {...getDataTestId('record__date-wrapper')}>
              <Typography
                variant="body"
                color="secondaryDark"
                dataTestId={getDataTestId('record__date')['data-test-id']}
              >
                {moment(event.creationDate).format('DD MMMM YYYY в HH:mm')}
              </Typography>
            </Styled.Date>

            {isLast && (
              <Styled.LastElementWrapper {...getDataTestId('btn-wrapper')}>
                {children}
              </Styled.LastElementWrapper>
            )}
          </Styled.Item>
        );
      })}
    </Styled.List>
  );
};

export default observer(History);
