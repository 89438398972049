import React, { FC, useCallback, useRef, useEffect, useState } from 'react';
import { Icon } from '@farmlink/farmik-ui';
import { isMacOs } from 'react-device-detect';

import { IndexModel } from '../../../../api/models/indices.model';

import { Scroll, StyledSwipeBtn } from './style';

type Props = {
  scrollPosition?: 'default' | 'right';
  selectedFieldId?: string;
  indices?: IndexModel[];
};

const SCROLL_STEP = 100;

export const SwiperSlider: FC<Props> = ({
  children,
  scrollPosition = 'default',
  selectedFieldId,
  indices,
}) => {
  const scroll = useRef<HTMLDivElement>();
  const [scrollPos, setScrollPos] = useState(0);
  const [hasScroll, setHasScroll] = useState<boolean>();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    scrollToEnd();
  }, [selectedFieldId, indices]);

  useEffect(() => {
    const element = scroll?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      // 15 пикселей добавляется из-за того что в таймлайне не правильно считается offset
      setOffset(element.scrollWidth - element.offsetWidth + 30);

      if (scroll.current?.scrollWidth > scroll.current?.clientWidth) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [scroll]);

  const scrollToEnd = useCallback(() => {
    const position = scrollPosition === 'right' ? 0 : offset;
    setScrollPos(position);
    scroll.current.scrollTo(position, 0);
  }, []);

  const scrollHandle = useCallback(
    (e?: any, direction?: 'left' | 'right' | 'scroll' | 'wheel') => {
      let position = 0;

      if (direction === 'scroll') {
        setScrollPos(e.target.scrollLeft);
        return;
      }

      if (direction === 'left') {
        if (scrollPosition === 'right') {
          position = scrollPos <= -offset ? -offset : scrollPos - SCROLL_STEP;
        } else {
          position = scrollPos - SCROLL_STEP <= 0 ? 0 : scrollPos - SCROLL_STEP;
        }
      } else if (scrollPosition === 'right') {
        position = scrollPos >= 0 ? 0 : scrollPos + SCROLL_STEP;
      } else {
        position = scrollPos - offset >= 0 ? offset : scrollPos + SCROLL_STEP;
      }

      if (direction === 'wheel') {
        if (scrollPosition === 'right') {
          position =
            scrollPos - e.deltaY <= -offset
              ? -offset
              : scrollPos - e.deltaY >= 0
              ? 0
              : scrollPos - e.deltaY;
        } else {
          position =
            scrollPos - e.deltaY <= 0
              ? 0
              : scrollPos - e.deltaY >= offset
              ? offset
              : scrollPos - e.deltaY;
        }

        setScrollPos(position);
      }

      setScrollPos(position);
      scroll.current.scrollTo(position, 0);
    },
    [scroll, offset, scrollPos]
  );

  return (
    <>
      <StyledSwipeBtn
        onClick={e => scrollHandle(e, 'left')}
        $position="left"
        $hasScroll={hasScroll}
      >
        <Icon icon={'arrow_left'} size={'small'} />
      </StyledSwipeBtn>
      <Scroll
        isRight={scrollPosition === 'right'}
        $hasScroll={hasScroll}
        ref={scroll}
        onScroll={e => {
          e.stopPropagation();
          if (isMacOs) {
            scrollHandle(e, 'scroll');
          }
        }}
        onWheel={e => {
          e.stopPropagation();
          if (!isMacOs) {
            scrollHandle(e, 'wheel');
          }
        }}
      >
        {children}
      </Scroll>
      <StyledSwipeBtn
        onClick={e => scrollHandle(e, 'right')}
        $position="right"
        $hasScroll={hasScroll}
      >
        <Icon icon={'arrow_right'} size={'small'} />
      </StyledSwipeBtn>
    </>
  );
};

SwiperSlider.displayName = 'SwiperSlider';
