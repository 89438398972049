import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import ValidateCultureZoneService from '../services/ValidateCultureZoneService';
import EditCultureZoneService from '../services/EditCultureZoneService';

import AbstractCultureZoneController from './AbstractCultureZone.controller';

@provide.singleton()
class EditCultureZoneController extends AbstractCultureZoneController {
  @lazyInject(ValidateCultureZoneService)
  protected validateCZService: ValidateCultureZoneService;

  @lazyInject(EditCultureZoneService)
  protected editCZService: EditCultureZoneService;

  validateCultureZone = (geoJson, leafletId, options?: { isPreventUndo?: boolean }) => {
    const { needUndoChanges, error } = this.validateCZService.validateEditCultureZone(
      geoJson,
      leafletId,
      options
    );
    if (needUndoChanges) this.undoChanges();
    return error;
  };

  undoChanges = () => {
    this.editCZService.undoLastEditChange();
  };

  acceptCultureZoneEditing = (): void => {
    console.log('acceptCultureZoneEditing new');
    this.acceptEditingCZ();
  };

  cancelCultureZoneEditing = (): void => {
    this.cancelEditingCZ();
  };

  continueHoleClick = () => {
    const { holePolyIdToMapId, selectedPolygonId, idToCultureZone } = this.cultureZoneStore;
    console.log('continueHoleClick');
    const holeZoneMapId = holePolyIdToMapId.get(selectedPolygonId);
    if (!holeZoneMapId) {
      return;
    }
    const holeZone = idToCultureZone.get(holeZoneMapId);
    if (!holeZone) {
      return;
    }
    holeZone.isHole = false;
    this.renderCultureZones();

    this.cultureZoneStore.denyHoleClick();
  };

  deleteZone = (mapId: string) => {
    console.log('deleteZone new');
    const { idToCultureZone, selectedCultureZoneModel, mapIdToCultureList } = this.cultureZoneStore;
    if (!idToCultureZone.has(mapId) || selectedCultureZoneModel) {
      return;
    }
    idToCultureZone.delete(mapId);
    mapIdToCultureList.delete(mapId);
    this.createHoles();
    this.renderCultureZones();
  };
}

export default EditCultureZoneController;
