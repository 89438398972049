import _ from 'lodash';
import moment from 'moment';

import { EChecklistType, IGetChecklist } from '../../../../../api/models/checklist/checklist.model';
import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
  IGetChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import { IGetIntensity } from '../../../../../api/models/intensity/intensity.model';
import { ISelectOption } from '../../../../../types/selectOption';
import { displayModalWindow } from '../../../../modals/config';
import { ModalsStore } from '../../../../modals/store/modals.store';
import { Axios, TypeApiRequest } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { TasksEditStore } from '../../operations/stores/task.edit.store';
import { TasksStore } from '../../operations/stores/tasks.store';
import {
  checklistModalUniqueKey,
  EChecklistModalName,
} from '../modules/fullscreen/checklist/modals/modalsConfig';
import { ChecklistsService as OldChecklistsService } from '../services/checklists/checklists.service';
import { ChecklistFileUploaderStore } from '../stores/checklist.fileUploader.store';
import { ChecklistInstancesStore, EChecklistMode } from '../stores/checklist.instances.store';
import { DEFAULT_INTENSITY_OPTION } from '../utils/checklist.instances';
import {
  checklistInstanceHelpers,
  drawChecklistInstanceHelpers,
} from '../utils/helpers/checklists';
import {
  ChecklistsService,
  FieldsService,
  TasksService,
} from '../../../../shared/mobx/services/as-fields';
import useInspectionPointsActionsHook from '../../tasks/components/InspectionPoints/hooks/useInspectionPointsActions/useInspectionPointsActions.hook';
import { ChecklistsAttrsDisplayService as AttrsDisplayService } from '../../tasks/modules/Checklists/mobx/services';
import { ERequestStatus } from '../../../../shared/constants/requests';
import { EChecklistInstanceEventType } from '../../../../../api/models/checklist/instance/events/checklist.instance.event.model';
import { ChecklistsStore } from '../../tasks/modules/Checklists/mobx/stores';
import { TaskStore } from '../../tasks/mobx/stores';
import { createChecklistSelectOptionList } from '../../../../shared/utils/helpers/selectOptions';

@provide.singleton()
export class ChecklistInstancesController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(AttrsDisplayService)
  protected attrsDisplayService: AttrsDisplayService;

  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  /** TODO: Необходимо выпилить даннуб логику */
  @lazyInject(OldChecklistsService)
  protected oldChecklistsService: OldChecklistsService;

  @lazyInject(ChecklistsService)
  protected checklistsService: ChecklistsService;

  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  @lazyInject(TasksEditStore)
  protected tasksEditStore: TasksEditStore;

  @lazyInject(ChecklistFileUploaderStore)
  protected checklistFileUploaderStore: ChecklistFileUploaderStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  getTypeForChecklist = (instance: IGetChecklistInstance): EChecklistType => {
    if (!instance) {
      return;
    }

    switch (instance.type) {
      case ECheckListInstanceType.PlannedPoint:
        return EChecklistType.Point;
      case ECheckListInstanceType.ActualPoint:
        return EChecklistType.Point;
      case ECheckListInstanceType.Field:
        return EChecklistType.Field;
      case ECheckListInstanceType.Machinery:
        return EChecklistType.Machinery;
      default:
    }
  };

  fetchInstanceData = async (
    organizationId: string,
    selectedInstId: string
  ): Promise<{ checklist: IGetChecklist; instance: IGetChecklistInstance }> => {
    if (!selectedInstId) {
      return;
    }

    // Избавляемся от выбранных элементов при смене точки
    this.clearStoreAfterChangedInstance();

    // Загружаем данные точки
    const instance = await this.fetchInstanceById(selectedInstId);

    if (!instance) {
      return;
    }

    // Загружаем список чек-листов для выбора в селекте.
    if (this.checklistsStore.mode === EChecklistMode.Edit) {
      // Пукт 2 задачи H15-4340 (см. конфу).
      if (instance.type === ECheckListInstanceType.Machinery) {
        await this.addChecklistOptionList({
          organizationId,
          type: this.getTypeForChecklist(instance),
        });
      } else if (this.taskStore.selectedTask?.intensityRequired) {
        if (instance?.intensity?.id) {
          await this.addChecklistOptionList({
            organizationId,
            type: this.getTypeForChecklist(instance),
            intensityId: instance?.intensity?.id,
          });
        }
      } else {
        await this.addChecklistOptionList({
          organizationId,
          type: this.getTypeForChecklist(instance),
        });
      }
    }

    this.checklistInstancesStore.setSelectedInstance(instance);

    if (!instance?.checkListId) {
      return;
    }

    // Если у точки есть фенофаза, то делаем ее выбранной.
    if (instance?.intensity) {
      this.checklistInstancesStore.setSelectedIntensity(instance.intensity);
    }

    // Если у точки заполнен чек-лист, то получаем и его
    const fetchedChecklist = await this.fetchChecklistById(instance.checkListId);

    if (!fetchedChecklist) {
      return;
    }

    this.checklistsStore.setSelectedChecklist(fetchedChecklist);
    this.checklistInstancesStore.setDefaultChecklist(fetchedChecklist);

    return { checklist: fetchedChecklist, instance };
  };

  fetchInstanceById = async (instanceId: string): Promise<IGetChecklistInstance> => {
    const fetchedInstance = await this.oldChecklistsService.fetchInstanceById(instanceId);

    if (!fetchedInstance) {
      return;
    }

    this.checklistInstancesStore.setExtendedInstance(fetchedInstance);

    return fetchedInstance;
  };

  protected fetchChecklistListByKeys = async (
    payload: TypeApiRequest<'getChecklistListByKeys'>
  ): Promise<IGetChecklist[]> => {
    const formattedOrgId = payload.organizationId === 'my' ? '' : payload.organizationId;

    const formattedPayload: TypeApiRequest<'getChecklistListByKeys'> = {
      organizationId: formattedOrgId,
      type: payload.type,
      operationTypeId: this.taskStore.selectedTask?.operationInfo?.operationTypeInfo?.id,
      cultureId: this.taskStore?.selectedTask?.operationInfo?.cultureId,
      intensityId: payload?.intensityId,
    };

    const fetchedChecklistList = await this.checklistsService.getChecklistListByKeys(
      formattedPayload
    );

    if (!fetchedChecklistList) {
      return [];
    }

    return fetchedChecklistList;
  };

  protected createChecklistOptionList = async (
    payload: TypeApiRequest<'getChecklistListByKeys'>
  ): Promise<ISelectOption<IGetChecklist>[]> => {
    const checklistList = await this.fetchChecklistListByKeys(payload);

    const checklistOptionList = createChecklistSelectOptionList(checklistList);

    this.checklistInstancesStore.setChecklistOptionList(checklistOptionList);

    return checklistOptionList;
  };

  addChecklistOptionList = async (
    payload: TypeApiRequest<'getChecklistListByKeys'>
  ): Promise<void> => {
    const checklistOptionList = await this.createChecklistOptionList(payload);

    if (checklistOptionList.length === 1) {
      const checklist = checklistOptionList[0].initialModel;

      this.checklistsStore.setSelectedChecklist(checklist);
    }
  };

  selectChecklist = (checklistId: string): void => {
    this.clearStoreAfterChecklistChanges();
    this.checklistInstancesStore.clearDefaultChecklist();

    if (checklistId) {
      const checklist = this.checklistInstancesStore.getChecklistOption(checklistId)?.initialModel;

      if (!checklist) return;

      this.checklistsStore.setSelectedChecklist(checklist);
    } else {
      this.checklistsStore.clearSelectedChecklist();
    }
  };

  fetchChecklistById = async (checklistId: string): Promise<IGetChecklist> => {
    const fetchedChecklist = await this.oldChecklistsService.fetchChecklistById(checklistId);

    if (!fetchedChecklist) return;

    this.checklistsStore.setSelectedChecklist(fetchedChecklist);

    return fetchedChecklist;
  };

  fetchIntensityByTaskId = async (taskId: string): Promise<IGetIntensity[]> => {
    const payload: TypeApiRequest<'getIntensityByTaskId'> = {
      taskId,
      checkListType: this.getTypeForChecklist(this.checklistInstancesStore.selectedInstance),
    };

    try {
      const fetchedIntensityList = await this.axios.api.getIntensityByTaskId(payload, {
        omit: ['taskId'],
      });

      fetchedIntensityList.forEach(intensity =>
        this.checklistInstancesStore.setIdToIntensity(intensity.id, intensity)
      );

      return fetchedIntensityList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchEventList = async (id: string) => {
    const { fetchChecklistInstanceEventList } = this.oldChecklistsService;
    const { setSelectedInstanceEventList } = this.checklistInstancesStore;

    const eventList = await fetchChecklistInstanceEventList({
      id,
      typeEvent: EChecklistInstanceEventType.UpdateChecklist,
    });

    const sortedEventList = eventList.sort(
      (eventA, eventB) => moment(eventA.creationDate).unix() - moment(eventB.creationDate).unix()
    );

    setSelectedInstanceEventList(sortedEventList);
  };

  deleteInstance = async (
    id: string,
    options?: {
      isChangesCanBeUndone?: boolean;
      displayPointList?: ReturnType<typeof useInspectionPointsActionsHook>['displayPointList'];
    }
  ): Promise<boolean> => {
    const { listOfDrawPointInst, setIdToDrawInst, delDrawInst } = this.checklistInstancesStore;

    const { deleteChecklistInstance } = this.oldChecklistsService;

    const { createChangedDrawInstList } = drawChecklistInstanceHelpers;

    if (!options?.isChangesCanBeUndone) {
      const isSuccess = await deleteChecklistInstance({ id });

      // Если удаление не прошло, то блокируем дальнейшее выполнение метода
      if (!isSuccess) {
        return;
      }
    }

    delDrawInst(id);

    const drawInstListWithoutDeleted = [...listOfDrawPointInst].filter(
      drawInst => drawInst.id !== id
    );

    const changedDrawInstList = createChangedDrawInstList(drawInstListWithoutDeleted);

    setIdToDrawInst(changedDrawInstList);

    options?.displayPointList?.(changedDrawInstList);
  };

  saveInstances = async (
    creationTaskId?: string,
    isAddInstancesToStore?: boolean,
    selectedTaskId?: string
  ): Promise<IGetChecklistInstanceByTaskId[]> => {
    const {
      listOfDrawPointInst,
      listOfDrawMachineryInst,
      setIdToDrawInst,
    } = this.checklistInstancesStore;

    const { temporaryTask } = this.tasksStore;
    const { task: taskEdit } = this.tasksEditStore;

    const { isMachinery } = checklistInstanceHelpers;
    const { createDrawInstListCol } = drawChecklistInstanceHelpers;

    const taskId = selectedTaskId || temporaryTask?.id || taskEdit?.id;

    if (!taskId) {
      return;
    }

    const instanceList = [...listOfDrawPointInst, ...listOfDrawMachineryInst].map(
      ({ instance }) => instance
    );

    const payload: (IGetChecklistInstanceByTaskId | IPutChecklistInstance)[] = instanceList.map(
      combineInstance => {
        const instance = combineInstance as IGetChecklistInstanceByTaskId;

        if (isMachinery(instance.type)) {
          return instance;
        }

        return {
          ...instance,
          taskId: this.checklistInstancesStore.taskId
            ? this.checklistInstancesStore.taskId
            : creationTaskId,
          planCoords: instance.planCoords
            ? {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: instance.planCoords?.coordinates?.length
                    ? instance.planCoords?.coordinates.slice()
                    : instance.planCoords?.geometry?.coordinates.slice(),
                },
              }
            : undefined,
          intensity: instance?.intensity?.id,
        };
      }
    );

    try {
      const res = await this.axios.api.saveChecklistInstanceList(payload, {
        query: {
          taskId,
        },
      });

      // Если мы получили ответ с ошибкой, то заканчиваем обработку данного метода
      if (!_.isArray(res)) {
        return res;
      }

      if (isAddInstancesToStore) {
        const { pointInstList, machineryInstList } = createDrawInstListCol(res);

        setIdToDrawInst(pointInstList);
        setIdToDrawInst(machineryInstList);
      }

      return res;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  /**
   * @param {string} taskId
   * @param {ReturnType<typeof useInspectionPointsActionsHook>['displayPointList']} [displayPointList]
   * @return {*}  {Promise<IDrawChecklistInstance[]>}
   */
  fetchInstanceList = async (
    taskId: string,
    displayPointList?: ReturnType<typeof useInspectionPointsActionsHook>['displayPointList'],
    isMergeMachineryDisplay?: boolean
  ): Promise<IDrawChecklistInstance[]> => {
    const { setIdToDrawInst } = this.checklistInstancesStore;
    const { fetchChecklistInstanceList } = this.oldChecklistsService;
    const { createDrawInstListCol } = drawChecklistInstanceHelpers;

    const typeList = [
      ECheckListInstanceType.ActualPoint,
      ECheckListInstanceType.PlannedPoint,
      ECheckListInstanceType.Machinery,
      ECheckListInstanceType.Field,
    ];

    const res = await fetchChecklistInstanceList({ taskId, size: 2000, types: typeList });

    // Если мы получили ответ с ошибкой, то заканчиваем обработку данного метода
    if (!_.isArray(res)) {
      return;
    }

    const { pointInstList, machineryInstList } = createDrawInstListCol(
      res,
      isMergeMachineryDisplay
    );

    setIdToDrawInst(pointInstList);

    if (!isMergeMachineryDisplay) {
      setIdToDrawInst(machineryInstList);
    }

    displayPointList?.(pointInstList, { isCleanDisplay: true });

    return pointInstList;
  };

  createMachineryInst = async (taskId: string): Promise<IGetChecklistInstanceByTaskId> => {
    const { getTheBiggestDrawInstPosNum, setDrawInst } = this.checklistInstancesStore;

    const { createDrawInst } = drawChecklistInstanceHelpers;

    const machineryInst: IPutChecklistInstance = {
      taskId,
      type: ECheckListInstanceType.Machinery,
      isActive: true,
      createOrUpdateDate: new Date().toISOString(),
    };

    try {
      const createdInst = await this.axios.api.createChecklistInstance(machineryInst);

      if (!createdInst) {
        return;
      }

      const positionNumber = getTheBiggestDrawInstPosNum() + 1;
      setDrawInst(createDrawInst(createdInst, positionNumber));

      return createdInst;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  // Other methods

  get isFetching(): boolean {
    return this.oldChecklistsService.isLoading;
  }

  get isFetchingInstanceList(): boolean {
    return this.oldChecklistsService.instanceListRequestStatus === ERequestStatus.Pending;
  }

  get intensityOptionList(): ISelectOption[] {
    return [
      // DEFAULT_INTENSITY_OPTION,
      ...this.checklistInstancesStore.intensityList.map<ISelectOption>(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ];
  }

  get intensityDefaultValue(): ISelectOption {
    const { selectedIntensity } = this.checklistInstancesStore;

    if (selectedIntensity) {
      return { label: selectedIntensity.name, value: selectedIntensity.id };
    }

    // return DEFAULT_INTENSITY_OPTION;
  }

  selectIntensity = async (payload: TypeApiRequest<'getChecklistListByKeys'>): Promise<void> => {
    const {
      intensityList,
      setSelectedIntensity,
      clearSelectedIntensity,
    } = this.checklistInstancesStore;

    if (payload.intensityId === DEFAULT_INTENSITY_OPTION.value || payload.intensityId === '') {
      clearSelectedIntensity();
      this.clearStoreAfterChangeIntensity();
      return;
    }

    setSelectedIntensity(intensityList.find(({ id }) => id === payload.intensityId));
    this.clearStoreAfterChangeIntensity();

    await this.addChecklistOptionList(payload);
  };

  getModalWarningBeforeChangeInstance = (successHandler: () => void): void => {
    const { setModal } = this.modalsStore;

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeChangeInstance,
        successHandler
      )
    );
  };

  warnBeforeChangingIntensity = (
    payload: Pick<TypeApiRequest<'getChecklistListByKeys'>, 'organizationId' | 'intensityId'>
  ) => {
    const { setModal } = this.modalsStore;

    const { setIntensityModalResult, clearIntensityModalResult } = this.checklistInstancesStore;

    clearIntensityModalResult();

    const successHandler = async (): Promise<void> => {
      await this.selectIntensity(payload);

      setIntensityModalResult(true);
    };

    const denyHandler = () => {
      setIntensityModalResult(false);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeChangeIntensity,
        successHandler,
        denyHandler
      )
    );
  };

  warnBeforeLeavingThePage = (onSuccess?: () => void) => {
    const { setModal } = this.modalsStore;

    const { setFullScreenMode } = this.tasksStore;

    const successHandler = () => {
      if (onSuccess) onSuccess();
      setFullScreenMode(null);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeLeavingThePage,
        successHandler
      )
    );
  };

  clearStoreAfterChangedInstance = (): void => {
    this.clearStoreAfterChangeIntensity();

    this.checklistInstancesStore.clearSelectedInstance();
    this.checklistInstancesStore.clearSelectedIntensity();
    this.checklistInstancesStore.clearDefaultChecklist();

    this.checklistInstancesStore.clearIdOfUnsavedAttr();

    this.checklistFileUploaderStore.clearUploadKeyToFiles();
  };

  clearStoreAfterChangeIntensity = (): void => {
    this.clearStoreAfterChecklistChanges();

    this.checklistInstancesStore.clearChecklistOptionsByValue();
    this.checklistInstancesStore.clearIdOfUnsavedAttr();
    this.checklistInstancesStore.clearDefaultChecklist();

    this.checklistFileUploaderStore.clearUploadKeyToFiles();
  };

  clearStoreAfterChecklistChanges = (): void => {
    this.checklistsStore.clearSelectedChecklist();
    this.checklistsStore.clearAttrsToDrawByIdByGroupId();
    this.checklistsStore.clearNestedInstancesToDrawByIdByAttrId();
    this.checklistsStore.clearAttrIdWithError();
    this.checklistsStore.clearLastEditedAttrId();
    this.checklistsStore.clearIsAttrIdWithErrorTargeted();
  };

  clearInstances = (): void => {
    const { clearIdToDrawPointInst, clearIdToDrawMachineryInst } = this.checklistInstancesStore;

    clearIdToDrawPointInst();
    clearIdToDrawMachineryInst();
  };
}
