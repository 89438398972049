import area from '@turf/area';
import { difference, multiPolygon, polygon } from '@turf/turf';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { MapPolygonController } from '../../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { CULTURE_POLYGON_OPTIONS } from '../../../../../modules/fields/utils/constants/PolygonOptions.constant';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { normalizeCoordsForTurf } from '../helpers';
import { CultureZoneStore } from '../stores/culture.zone.store';

@provide.transient()
class DrawerCultureZoneService {
  @lazyInject(CultureZoneStore)
  cultureZoneStore: CultureZoneStore;

  @lazyInject(SeasonsStore)
  seasonsStore: SeasonsStore;

  @lazyInject(MapPolygonController)
  mapPolygonController: MapPolygonController;

  getHolesPolygons = () => {
    console.log('getHolesPolygons new');
    this.removeHoles();

    const normalizedCoords = normalizeCoordsForTurf(
      this.cultureZoneStore.field.geometry.coordinates[0]
    );

    let fieldPolygon = polygon(normalizedCoords) as any;

    if (!Boolean(this.cultureZoneStore.cultureZonesCoordinates.length)) {
      return [this.getHolePolygonObj('none', fieldPolygon, [])];
    }

    this.cultureZoneStore.cultureZonesCoordinates.forEach(zone => {
      const shitPoly = polygon(normalizeCoordsForTurf(zone));
      if (fieldPolygon) {
        fieldPolygon = difference(fieldPolygon, shitPoly);
      }
    });

    if (!fieldPolygon) {
      return;
    }

    if (fieldPolygon.geometry.type === 'MultiPolygon') {
      const polygons = fieldPolygon.geometry.coordinates.map(coords =>
        this.getHolePolygonObj('MultiPolygon', fieldPolygon, coords)
      );
      return polygons;
    } else {
      return [this.getHolePolygonObj('Polygon', fieldPolygon, [])];
    }
  };

  createCultureZoneBorder = () => {
    console.log('createCultureZoneBorder new');
    this.cultureZoneStore.holePolyIdToMapId.clear();

    const polyObj = this.mapPolygonController.display({
      element: null,
      coordinates: this.cultureZoneStore.field.geometry.coordinates[0],
      options: {
        isAllowToSelectPolygon: true,
        style: {
          ...CULTURE_POLYGON_OPTIONS.cultureZoneBorder,
          pmIgnore: false,
        },
      },
    });

    this.cultureZoneStore.field.polyId = polyObj?.polygon.id;
  };

  createHole = zone => {
    console.log('createHole new');
    const { polygon: holePolygon } = this.mapPolygonController.display({
      element: null,
      coordinates: zone.geometry.coordinates,
      options: {
        style: {
          ...CULTURE_POLYGON_OPTIONS.cultureZoneHole,
          pmIgnore: false,
        },
      },
    });
    return holePolygon;
  };

  createCultureZone = zone => {
    console.log('createCultureZone new');
    const { polygon: cultureZonePolygon } = this.mapPolygonController.display({
      element: null,
      coordinates: zone.geometry.coordinates,
      options: {
        isAllowToSelectPolygon: true,
        style: {
          ...CULTURE_POLYGON_OPTIONS.cultureZone(zone?.culture?.attrs?.assistanceColorLegend),
          pmIgnore: false,
        },
      },
    });
    return cultureZonePolygon;
  };
  removeHoles = () => {
    console.log('removeHoles new');
    this.cultureZoneStore.cultureZones.forEach(zone => {
      if (!zone.isHole) {
        return;
      }
      this.cultureZoneStore.idToCultureZone.delete(zone.mapId);
    });
  };

  getHolePolygonObj = (type, fieldPolygon, coords) => {
    const season = this.seasonsStore.getSeasonDataByYear();
    const mapId = uuidv4();
    switch (type) {
      case 'none':
        return {
          culture: {
            attrs: {
              assistanceColorLegend: '',
            },
            id: '',
            name: '',
            query: '',
          },
          geometry: this.cultureZoneStore.field.geometry,
          isHole: false,
          id: '',
          harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
          variety: '',
          mapId,
          polyId: '',
          area: area(fieldPolygon) / 10000,
          sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
        };
        break;
      case 'MultiPolygon':
        return {
          culture: {
            attrs: {
              assistanceColorLegend: '',
            },
            id: '',
            name: '',
            query: '',
          },
          geometry: {
            coordinates: coords,
            type: 'Polygon',
          },
          isHole: true,
          id: '',
          harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
          variety: '',
          mapId,
          polyId: '',
          area:
            area(
              fieldPolygon.geometry.type === 'Polygon'
                ? polygon(fieldPolygon.geometry.coordinates)
                : multiPolygon(fieldPolygon.geometry.coordinates)
            ) / 10000,
          sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
        };
        break;
      case 'Polygon':
        return {
          culture: {
            attrs: {
              assistanceColorLegend: '',
            },
            id: '',
            name: '',
            query: '',
          },
          geometry: fieldPolygon.geometry,
          isHole: true,
          id: '',
          harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
          variety: '',
          mapId,
          polyId: '',
          area:
            area(
              fieldPolygon.geometry.type === 'Polygon'
                ? polygon(fieldPolygon.geometry.coordinates)
                : multiPolygon(fieldPolygon.geometry.coordinates)
            ) / 10000,
          sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
        };
        break;
      default:
        return null;
    }
  };
}

export default DrawerCultureZoneService;
