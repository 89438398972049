import { Button, DropdownButton, TButtonMultiAction, useModal } from '@farmlink/farmik-ui';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { ButtonWrapper } from '../../../style';
import { CREATE_SEASON_MODAL_ID } from '../../../../seasons/modals/CreateSeasonModal/CreateSeasonModal.config';
import { FieldsRoute } from '../../../fields.route';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { useWarningBeforeLeavingThePage } from '../../../../../../shared/hooks/useWarningBeforeLeavingThePage';
import { EFieldsImportModalName } from '../../../utils/constants/import';
import alertSvg from '../../../static/multipleButtonIcons/alert-black-16x16.svg';
import plusSvg from '../../../static/multipleButtonIcons/plus-black-16x16.svg';
import downloadSvg from '../../../static/multipleButtonIcons/download-black-16x16.svg';
import { importedFieldsSessionStorageHelpers } from '../../../utils/helpers/import';
import { FieldsUIStore, ImportFieldsController } from '../../../mobx';

export const FooterContainer = () => {
  const seasons = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const fieldsUiStore = useStore(FieldsUIStore);

  const fieldsImportController = useStore(ImportFieldsController);

  const hasSelectedSeason = Boolean(seasons.selectedSeason);
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();
  const navigate = useNavigate();
  const { openModalByModalId } = useModal();

  const importedFieldsToken = importedFieldsSessionStorageHelpers.getToken(
    organizationsStore.selectedOrganizationId,
    seasons.selectedSeason
  );

  const fieldsImportRoute = useMemo<string>(() => {
    return generatePath(FieldsRoute.Import, {
      orgId: organizationsStore.selectedOrganizationId,
    });
  }, [organizationsStore.selectedOrganizationId]);

  const handleClickOnFieldCreation = (): void => {
    const routePath = generatePath(FieldsRoute.Add, {
      orgId: organizationsStore.selectedOrganizationId,
    });
    if (fieldsUiStore.isCultureZonePopupOpen) {
      showWarningBeforeLeavingThePage(() => {
        navigate(routePath);
      });
    } else {
      navigate(routePath);
    }
  };

  const handleClickOnUploadFields = (): void => {
    if (fieldsUiStore.isCultureZonePopupOpen) {
      showWarningBeforeLeavingThePage(() => {
        openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: false });
      });
    } else {
      openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: false });
    }
  };

  const buttonMultiActionList: TButtonMultiAction[] = [
    {
      title: 'Продолжить с черновиком',
      description: 'Вернитесь к работе с загруженным файлом',
      onClick: async (): Promise<void> => {
        if (importedFieldsToken) {
          const isSuccess = await fieldsImportController.fetchImportedList(importedFieldsToken);

          if (isSuccess) {
            openModalByModalId(EFieldsImportModalName.Loader);

            navigate(fieldsImportRoute);
          } else {
            openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: true });
          }
        }
      },
      icon: alertSvg,
      isHidden: !importedFieldsToken,
    },
    {
      title: 'Добавить поля',
      description: 'Соедините точки на карте для создания поля',
      onClick: () => {
        handleClickOnFieldCreation();
      },
      icon: plusSvg,
    },
    {
      title: 'Загрузить файлы',
      description: 'Добавьте контуры полей в форматах KML, geoJSON, shape',
      onClick: () => {
        handleClickOnUploadFields();
      },
      icon: downloadSvg,
    },
  ];

  return (
    <>
      {hasSelectedSeason ? (
        <ControlAccessRulesWrapper
          mode={AccessRuleVisibility.Hide}
          actionName="field.createFieldOrSeason"
        >
          <DropdownButton
            onClick={handleClickOnFieldCreation}
            title={'Добавить поле'}
            disabled={fieldsUiStore.isCultureZonePopupOpen}
            multiActionList={buttonMultiActionList}
            style={{ color: 'primary' }}
            dataTestId={'add-field-button'}
          />
        </ControlAccessRulesWrapper>
      ) : (
        <ControlAccessRulesWrapper
          mode={AccessRuleVisibility.Hide}
          actionName="field.createFieldOrSeason"
        >
          <ButtonWrapper>
            <Button
              color={'primary'}
              type={'button'}
              onClick={() => openModalByModalId(CREATE_SEASON_MODAL_ID)}
              dataTestId={'add-season-button'}
            >
              Добавить сезон
            </Button>
          </ButtonWrapper>
        </ControlAccessRulesWrapper>
      )}
    </>
  );
};
