import L, { LatLngBounds, Layer } from 'leaflet';

import type { Field } from '../../../../../../../../api/models/field.model';
import { IndexModel } from '../../../../../../../../api/models/indices.model';
import {
  MapCoreController,
  MapImageOverlayController,
} from '../../../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { ProfileStore } from '../../../../../../modules/profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { FieldFillStore } from '../../../../mobx';
import { IFillStrategy } from '../../../../models';
import type { TIndexType } from '../../models/FetchArgs/FetchArgs.model';
import IndicesService from '../services/Indices.service';
import IndicesStore from '../stores/Indices.store';

@provide.transient()
class IndicesFillStrategy implements IFillStrategy {
  @lazyInject(IndicesStore)
  private store: IndicesStore;

  @lazyInject(FieldFillStore)
  private fillStore: FieldFillStore;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  private organizationStore: OrganizationsStore;

  @lazyInject(ProfileStore)
  private profileStore: ProfileStore;

  @lazyInject(MapCoreController)
  private mapCoreController: MapCoreController;

  @lazyInject(MapImageOverlayController)
  private mapImageOverlayController: MapImageOverlayController;

  @lazyInject(IndicesService)
  private indicesService: IndicesService;

  private readonly indexBounds: LatLngBounds | null = null;

  // Так как фетчинг индексов асинхронный, то следует привести инициализацию стратегий (CultureFillStrategy, IndicesFillStrategy) к асинхронному варианту
  constructor(indexName: TIndexType, fieldId: string, activeLayer: Layer) {
    if (!this.isLayerValid(activeLayer)) {
      console.warn(
        `Невозможно заполнить поле[${fieldId}] индексом. Переданный полигон не содержит свойство "initialBounds" `
      );
      return;
    }

    /**
     * Берем именно начальные границы полигона/слоя (сетается в самом полигоне/слое при его инициализации).
     * Это необходимо из-за того, что границы могут быть изменены в процессе редактирования, и метод getBounds()
     * вернет последнее значение границ
     */
    this.indexBounds = activeLayer.initialBounds;
    this.fillStore.fieldFillValue = indexName;

    this.store.fetchArgs = { indexName, fieldId };

    this.fetchIndices();

    activeLayer?.closeTooltip();
  }

  public reset(): void {
    this.removePrevIndex();
  }

  public fetchIndices(): void {
    const partialArgs = this.store.fetchArgs;
    const response = this.indicesService.fetchIndices(partialArgs);

    response.then(indices => {
      const completedIndices = indices.filter(item => item.status === 'COMPLETED');

      this.store.indices = completedIndices;
      this.fillIndex(completedIndices[0]);
    });
  }

  /**
   * Заливает выбранный полигон(this.activeLayer) новым индексом.
   * Перед заливкой удаляет с карты предыдущий индекс
   * @param index - индекс для заливки
   */
  public fillIndex(index: IndexModel): void {
    this.removePrevIndex();

    if (!index || !this.indexBounds) {
      return;
    }

    const imageOverlay = this.mapImageOverlayController.display(
      this.buildVisImageUrl(index.visImage),
      this.indexBounds
    );

    this.store.displayedOverlay = imageOverlay;
    this.store.selectedIndex = index;
  }

  private removePrevIndex(): void {
    const { displayedOverlay } = this.store;
    if (!displayedOverlay) {
      return;
    }

    this.mapImageOverlayController.remove(displayedOverlay.id);
    this.store.displayedOverlay = null;
  }

  private isLayerValid(layer: L.Layer): layer is L.Layer & { initialBounds: LatLngBounds } {
    // @ts-ignore
    return layer?.initialBounds;
  }

  private buildVisImageUrl(url: string): string {
    const { fieldId } = this.store.fetchArgs;
    const orgId = this.organizationStore.selectedOrganizationId;
    const userId = this.profileStore.user?.id;
    const seasonYear = this.seasonsStore.selectedSeason;

    const orgIdQueryArg = orgId === 'my' || !orgId ? '' : `&organizationId=${orgId}`;

    return `${url}?fieldId=${fieldId}&seasonYear=${seasonYear}&userId=${userId}${orgIdQueryArg}`;
  }
}

export default IndicesFillStrategy;
