import booleanIntersects from '@turf/boolean-intersects';
import { PathOptions } from 'leaflet';
import { intersect, buffer } from '@turf/turf';

import { IMapPolygonError } from '../../models';
import isBigAreaInHectares from '../helpers/isBigAreaInHectares';
import { isBigAreaError } from '../helpers/polygonErrors';
import { BasePolygon } from '../MapElements';

class PolygonValidator {
  private isTouchedPolygonsList: Map<number, BasePolygon> = new Map<number, BasePolygon>();

  constructor(private polygon: BasePolygon) {}

  public getTouchedList(): BasePolygon[] {
    return Array.from(this.isTouchedPolygonsList.values());
  }

  public getErrors(): IMapPolygonError[] {
    return Array.from(this.polygon.errors.values());
  }

  public getPolygonErrors() {
    return this.polygon.errors;
  }

  public getTouchedListErrors() {
    const result = new Map<number, IMapPolygonError[]>();

    this.isTouchedPolygonsList.forEach(polygon => {
      if (polygon.hasErrors) {
        const errors = Array.from(polygon.errors.values());
        result.set(polygon.id, errors);
      }
    });

    return result;
  }

  public changeStyles(invalidStyle: PathOptions): this {
    const touchedPolygons = this.getTouchedList();

    touchedPolygons.forEach(polygon => {
      polygon.toggleErrorStyle(invalidStyle);
    });

    return this;
  }

  public checkIntersections(
    polygonsList: BasePolygon[],
    skipIdsCollection = new Set<number>()
  ): this {
    this.touchPolygon(this.polygon);

    const bufferedPolygon = buffer(this.polygon.toTurf(), -20, { units: 'centimeters' });

    polygonsList.forEach(polygon => {
      if (skipIdsCollection.has(polygon.id)) {
        return;
      }

      if (polygon.id === this.polygon.id) {
        return;
      }

      const isIntersect = intersect(bufferedPolygon, polygon.toTurf());
      const isIntersectBefore = polygon.intersections.has(this.polygon.id);

      if (isIntersect && !isIntersectBefore) {
        this.polygon.addIntersection(polygon);
        polygon.addIntersection(this.polygon);

        this.touchPolygon(polygon);
      }

      if (!isIntersect && isIntersectBefore) {
        this.polygon.deleteIntersection(polygon.id);
        polygon.deleteIntersection(this.polygon.id);

        this.touchPolygon(polygon);
      }
    });

    return this;
  }

  public checkIsAreaTooBig(error?: IMapPolygonError): this {
    const isTooBig = isBigAreaInHectares(this.polygon);

    const err = error ?? isBigAreaError();
    const isAlreadyHasError = this.polygon.errors.has(err.type);

    if (isTooBig && !isAlreadyHasError) {
      this.polygon.errors.set(err.type, err);
      this.touchPolygon(this.polygon);
    }

    if (!isTooBig && isAlreadyHasError) {
      this.polygon.errors.delete(err.type);
      this.touchPolygon(this.polygon);
    }

    return this;
  }

  private touchPolygon(polygon: BasePolygon) {
    this.isTouchedPolygonsList.set(polygon.id, polygon);
  }
}

export default PolygonValidator;
