import { NewScrollbar } from '@farmlink/farmik-ui';
import { FC, PropsWithChildren, useRef, useState } from 'react';

const ScrollableContainer: FC<
  PropsWithChildren<{ hoverDuration?: number; maxHeight?: string; dataTestId?: string }>
> = ({ hoverDuration = 500, maxHeight = 'auto', dataTestId = 'default', children }) => {
  const [isHovering, setIsHovering] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovering(true);
    }, hoverDuration);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    setIsHovering(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={isHovering ? 'scrollable' : ''}
      style={{ maxHeight, height: maxHeight }}
      data-test-id={`${dataTestId}-scrollable-container`}
      data-is-scrollable={isHovering}
    >
      <NewScrollbar scrollbarRightPosition={0} scrollbarPaddingOffset={4} isDisabled={!isHovering}>
        {children}
      </NewScrollbar>
    </div>
  );
};

export default ScrollableContainer;
