import { Layer } from 'leaflet';

import {
  MapCoreController,
  MapLayerGroupStore,
} from '../../../../../../../shared/features/map/modules';
import { FieldLayerGroup } from '../../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { FieldsStore } from '../../../../../../modules/fields/mobx';
import { FieldFillStore } from '../../../../mobx';
import { IFillStrategy } from '../../../../models';

@provide.transient()
class CultureZoneFillStrategy implements IFillStrategy {
  @lazyInject(FieldFillStore)
  private fillStore: FieldFillStore;

  @lazyInject(FieldsStore)
  private fieldsStore: FieldsStore;

  @lazyInject(MapLayerGroupStore)
  private mapLayerGroupStore: MapLayerGroupStore<FieldLayerGroup>;

  @lazyInject(MapCoreController)
  protected mapCoreController: MapCoreController;

  constructor(private layer?: Layer) {
    this.fillStore.fieldFillValue = EFieldFill.Cultures;

    if (this.isLayerValid(this.layer)) {
      this.layer.showCultureZones();
      this.layer.openTooltip();

      return;
    }

    this.getAllFieldLayerGroups().forEach(layerItem => {
      layerItem.showCultureZones();
      layerItem.openTooltip();
    });
  }

  public reset(): void {
    if (this.isLayerValid(this.layer)) {
      this.layer.hideCultureZones();

      return;
    }

    this.getAllFieldLayerGroups().forEach(layerGroup => {
      layerGroup.hideCultureZones();
    });
  }

  private getAllFieldLayerGroups(): FieldLayerGroup[] {
    return this.mapCoreController.getAllMapLayers().filter(layer => {
      return layer instanceof FieldLayerGroup;
    }) as FieldLayerGroup[];
  }

  private isLayerValid(layer: Layer): layer is FieldLayerGroup {
    return layer instanceof FieldLayerGroup;
  }
}

export default CultureZoneFillStrategy;
