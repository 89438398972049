import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { LatLng } from 'leaflet';
import { cloneDeep } from 'lodash';
import { distance } from '@turf/turf';
import { ENotificationType, useNotificator } from '@farmlink/farmik-ui';

import { SharedFieldsStore } from '../../../../../dashboard/modules/fields/mobx';
import { useMapEvents } from '../../../../../shared/features/map/hooks';
import { BasePolygon } from '../../../../../shared/features/map/utils/MapElements';
import { useStore } from '../../../../../shared/utils/IoC';
import { validateCoordinates } from '../../../../../shared/utils/helpers/validateCoordinates';
import {
  EMapValidationError,
  FOCUSED_MARKER_CLASS,
} from '../../../../../shared/constants/map/map.constants';
import EditCultureZoneController from '../../../../../dashboard/components/PopupSlider/components/CultureZone/controllers/EditCultureZone.controller';
import useMapValidationHook from '../../../../../shared/features/map/hooks/useMapValidation/useMapValidation.hook';

interface IProps {
  editCultureZoneController: EditCultureZoneController;
  selectedPolygon: BasePolygon;
  polygonsList: BasePolygon[];
}

const usePointCoordinatesUpdate = ({
  editCultureZoneController,
  selectedPolygon,
  polygonsList,
}: IProps) => {
  const sharedFieldsStore = useStore(SharedFieldsStore);

  const { validateLayers } = useMapValidationHook();

  const [selectedVertex, setSelectedVertex] = useState<HTMLElement>(null);
  const [currentPointPosition, setCurrentPointPosition] = useState<LatLng>(null);
  const [currentPointLayerIndex, setCurrentPointLayerIndex] = useState<[number, number]>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [newCoords, setNewCoords] = useState('');
  const [error, setError] = useState('');
  const [referenceCords, setReferenceCords] = useState(null);

  const events = useMapEvents();
  const { setNotification } = useNotificator();

  const coordinates = useMemo(
    () =>
      currentPointPosition &&
      `${Number(currentPointPosition.lat).toFixed(7)}, ${Number(currentPointPosition.lng).toFixed(
        7
      )}`,
    [currentPointPosition]
  );

  const isDisplayCoordinatesBlock =
    sharedFieldsStore.isCultureZoneEditMode ||
    sharedFieldsStore.isEditMode ||
    sharedFieldsStore.isCreateMode;

  useEffect(() => {
    setIsExpanded(false);
    setNewCoords('');
  }, [isDisplayCoordinatesBlock]);

  const onChangeCoordinates = (value: string) => {
    setError(null);
    setNewCoords(value);
  };

  const isExpandDisabled = !currentPointPosition;

  useEffect(() => {
    setNewCoords(coordinates);
  }, [coordinates, selectedPolygon]);

  useEffect(() => {
    setError('');
  }, [currentPointLayerIndex, currentPointPosition]);

  const expandChangeCord = useCallback(() => {
    setNewCoords(coordinates);
    setIsExpanded(true);

    if (!referenceCords) {
      setReferenceCords(coordinates);
    }
  }, [coordinates, referenceCords]);

  const shrinkChangeCord = useCallback(() => {
    // setNewCoords(referenceCords);
    // updateCord();
    setIsExpanded(false);

    setNewCoords('');
    setError(null);
    setReferenceCords(null);
  }, [referenceCords]);

  const sendNotification = (message: string) => {
    setNotification({
      message,
      style: { placement: 'top-right', type: ENotificationType.Warning },
    });
  };

  const updateCord = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!selectedPolygon) {
        return;
      }

      try {
        validateCoordinates(newCoords);

        const layerCords = selectedPolygon.getLatLngs();

        const parsedNewCords = newCoords.split(', ').map(item => Number(item));
        const startCords = coordinates.split(', ').map(item => Number(item));

        const distanceBetweenCords = distance(parsedNewCords, startCords, { units: 'kilometers' });

        // Проверка валидности отдалённости
        if (distanceBetweenCords >= 1) {
          throw new Error('Перенос координаты более чем на 1 км');
        }

        const newLatLng = new LatLng(parsedNewCords[0], parsedNewCords[1]);

        const previousCords = cloneDeep(layerCords);

        layerCords[currentPointLayerIndex[0]][currentPointLayerIndex[1]] = newLatLng;

        if (sharedFieldsStore.isCultureZoneEditMode) {
          const geoJson = cloneDeep(selectedPolygon).setLatLngs(layerCords).toGeoJSON();

          // Проверка валидности КЗ
          const validationError = editCultureZoneController.validateCultureZone(
            geoJson,
            selectedPolygon.id,
            {
              isPreventUndo: true,
            }
          );

          if (validationError) {
            selectedPolygon.setLatLngs(previousCords);
            setError(validationError);

            switch (validationError) {
              case EMapValidationError.OutOfField:
                return sendNotification('Культурная зона выходит за границы поля');

              default:
                return sendNotification('Имеются пересечения культурных зон');
            }
          }
        }

        selectedPolygon.setLatLngs(layerCords); // установка вертекса полигона

        // Проверка самопересечения
        if (selectedPolygon.pm.hasSelfIntersection()) {
          selectedPolygon.setLatLngs(previousCords);
          const errorMessage = 'Пересечение собственного контура';

          setError(errorMessage);

          sendNotification(errorMessage);

          return;
        }

        ((selectedPolygon as any).pm._markers[currentPointLayerIndex[0]][
          currentPointLayerIndex[1]
        ] as L.Marker).setLatLng(newLatLng); // подтягивание маркера редактирования к вертексу

        selectedPolygon.redraw();

        setIsExpanded(false);
        setCurrentPointPosition(newLatLng);

        validateLayers(polygonsList, selectedPolygon, []);
      } catch (err) {
        sendNotification(err?.message || 'Непредвиденная ошибка валидации');
        setError('unknown');
      }
    },
    [selectedPolygon, currentPointLayerIndex, coordinates, newCoords]
  );

  const clearFocusMarker = useCallback(() => {
    selectedVertex?.classList?.remove(FOCUSED_MARKER_CLASS);

    setSelectedVertex(null);
    setCurrentPointPosition(null);
    setCurrentPointLayerIndex(null);
  }, [selectedVertex]);

  const selectVertex = useCallback(
    (vertexElement: HTMLElement) => {
      if (selectedVertex) {
        selectedVertex.classList.remove(FOCUSED_MARKER_CLASS);
      }

      setSelectedVertex(vertexElement);

      vertexElement?.classList?.add(FOCUSED_MARKER_CLASS);
    },
    [selectedVertex]
  );

  const updateVertex = info => {
    if (!info.marker) {
      return;
    }

    selectVertex(info.marker.getElement());

    setCurrentPointPosition(info.marker.getLatLng());
    setCurrentPointLayerIndex(info.index);
  };

  useEffect(() => {
    events.onClickMap(() => {
      clearFocusMarker();
    });

    events.onVertexClick(updateVertex);
    events.onMarkerDrag(updateVertex);
  }, [selectedVertex]);

  return {
    isExpanded,
    isDisplayCoordinatesBlock,
    newCoords,
    onChangeCoordinates,
    coordinates,
    expandChangeCord,
    shrinkChangeCord,
    updateCord,
    error,
    isExpandDisabled,
  };
};
export default usePointCoordinatesUpdate;
