import { Field } from '../../../../../../../api/models/field.model';
import { Axios, TypeApiRequest } from '../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';
import { FieldsStore } from '../../stores';

type FieldsRequest = TypeApiRequest<'getFields'>;

/**
 * Данный сервис отвечает за фетчинг и удаление полигонов
 */
@provide.transient()
class FieldsService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(FieldsStore)
  private fieldsStore: FieldsStore;

  public async fetchFieldsList(request?: FieldsRequest): Promise<Field[] | null> {
    const random = Math.random().toString(16).substring(2, 5);
    console.time(`[${random}] Fetch Fields List`);

    this.fieldsStore.isLoading = true;

    await this.seasonsStore.waitSeasons();
    await this.profileStore.waitUser();

    const requestData = request ?? this.getDefaultRequest();
    const response = this.axios.api.getFields(requestData, {
      omit: ['organizationId'],
    });

    return response
      .then(({ content }) => {
        this.fieldsStore.setAllFields(content);

        return content;
      })
      .catch(() => null)
      .finally(() => {
        this.fieldsStore.isLoading = false;
        this.fieldsStore.fieldsLoaded = true;

        console.timeEnd(`[${random}] Fetch Fields List`);
        return null;
      });
  }

  public deleteField(fieldId: string, seasonYear: number | null) {
    return typeof seasonYear === 'number'
      ? this.axios.api.deleteFieldFromCurrentSeason({ fieldId, seasonYear })
      : this.axios.api.deleteFieldById({ fieldId });
  }

  private getDefaultRequest(): FieldsRequest {
    return {
      organizationId: this.organizationStore.getOrganisationId({ skipDefault: true }),
      seasonYear: Number(this.seasonsStore.selectedSeason),
      withCultureZones: true,
      withGeometry: true,
      sort: 'name',
    };
  }
}

export default FieldsService;
