import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapMarkersConfig } from '../../../models';
import { BasePolygon } from '../../../utils/MapElements';
import { MapMarkerStore } from '../../MapMarker';
import MapMarkerEventsService from '../../MapMarker/services/MapMarkerEvents.service';
import MapPolygonStore from '../../MapPolygon/stores/MapPolygon.store';
import MapDrawerService from '../services/MapDrawer.service';

type TDrawerServiceArgs<K extends keyof MapDrawerService> = Parameters<MapDrawerService[K]>;

@provide.transient()
class MapDrawerController {
  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  @lazyInject(MapMarkerStore)
  private markerStore: MapMarkerStore;

  @lazyInject(MapDrawerService)
  private mapDrawerService: MapDrawerService;

  @lazyInject(MapMarkerEventsService)
  private markerEventsService: MapMarkerEventsService;

  public enableDrawPolygon(...args: TDrawerServiceArgs<'enableDrawPolygon'>): void {
    this.mapDrawerService.enableDrawPolygon(...args);
  }

  public disableDrawPolygon(...args: TDrawerServiceArgs<'disableDrawPolygon'>): void {
    this.mapDrawerService.disableDrawPolygon(...args);
  }

  public enableEditPolygon(polygon: BasePolygon, options?: L.PM.EditModeOptions): void {
    this.mapDrawerService.toggleEditPolygon(polygon, 'enable', options);
  }

  public disableEditPolygon(polygon: BasePolygon, options?: L.PM.EditModeOptions): void {
    this.mapDrawerService.toggleEditPolygon(polygon, 'disable', options);
  }

  public enableDrawMarker(): void {
    this.mapDrawerService.enableDrawMarker();
  }

  public disableDrawMarker(): void {
    this.mapDrawerService.disableDrawMarker();
  }

  public setMarkerDrawConfig(config: IMapMarkersConfig) {
    this.markerStore.markersConfig = {
      ...(this.markerStore.markersConfig ?? {}),
      ...config,
    };
  }

  // Отключает рисование и удаляет нарисованные полигоны из карты
  public resetDrawPolygon(...args: TDrawerServiceArgs<'resetDrawPolygon'>) {
    this.mapDrawerService.resetDrawPolygon(...args);
  }
}

export default MapDrawerController;
