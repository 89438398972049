import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { DeleteFieldModal } from '../../../components/FieldItem/components';

const FIELDS_EDIT_MODALS_UNIQUE_KEY = 'fieldsEdit';

enum EFieldsEditModalName {
  DeleteFieldWarning = 'DELETE_FIELD_WARNING',
  IntersectionWarning = 'INTERSECTION_WARNING',
  OversizedAreaWarning = 'OVERSIZED_AREA_WARNING',
}

const deleteFieldWarningConfig: TModalConfig = {
  id: EFieldsEditModalName.DeleteFieldWarning,
  name: EFieldsEditModalName.DeleteFieldWarning,
  dataTestId: EFieldsEditModalName.DeleteFieldWarning,
  title: 'Вы уверены, что хотите удалить поле? Данные поля будут потеряны',
  type: EModalType.Custom,
  children: DeleteFieldModal,
};

const intersectionWarningConfig: TModalConfig = {
  id: EFieldsEditModalName.IntersectionWarning,
  name: EFieldsEditModalName.IntersectionWarning,
  dataTestId: EFieldsEditModalName.IntersectionWarning,
  title: 'Границы полей не могут пересекаться. Измените границы для сохранения изменений!',
  type: EModalType.Warning,
  successButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const oversizedAreaWarningConfig: TModalConfig = {
  id: EFieldsEditModalName.OversizedAreaWarning,
  name: EFieldsEditModalName.OversizedAreaWarning,
  dataTestId: EFieldsEditModalName.OversizedAreaWarning,
  title:
    'Площадь поля не может быть больше 1000 га. Уменьшите площадь поля для сохранения изменений!',
  type: EModalType.Warning,
  denyButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const fieldsEditModalConfigList = [
  intersectionWarningConfig,
  oversizedAreaWarningConfig,
  deleteFieldWarningConfig,
];

export { fieldsEditModalConfigList, FIELDS_EDIT_MODALS_UNIQUE_KEY, EFieldsEditModalName };
