import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router-dom';

import { TablePlugBuilder } from '../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { useStoParams } from '../../../../hooks';
import { FieldsRoute } from '../../../../../fields/fields.route';

import Styled from './StoListNoFieldsPlug.styled';

const StoListNoFieldsPlug: FC = () => {
  const navigate = useNavigate();

  const { orgId } = useStoParams();

  const handleButtonClick = useCallback(() => {
    navigate(generatePath(FieldsRoute.Add, { orgId }));
  }, []);

  const getDataTestId = useDataTestIdV2('sto__list__no-fields-plug');

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'field' },
        content: {
          title: 'Для работы с разделом добавьте поля и культуры',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('add-season-btn')['data-test-id']}
            >
              Добавить поля
            </Styled.Button>
          ),
        },
      }}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

StoListNoFieldsPlug.displayName = 'StoListNoFieldsPlug';

export default observer(StoListNoFieldsPlug);
