import { makeAutoObservable } from 'mobx';

import { IndexModel } from '../../../../../../../../api/models/indices.model';
import { BaseImageOverlay } from '../../../../../../../shared/features/map/utils/MapElements';
import { provide } from '../../../../../../../shared/utils/IoC';
import { TFetchArgs } from '../../models/FetchArgs/FetchArgs.model';

@provide.singleton()
class IndicesStore {
  private _isLoading = false;

  private _indices: IndexModel[] = [];

  private _selectedIndex: IndexModel | null = null;

  private _displayedOverlay: BaseImageOverlay | null = null;

  private _fetchArgs: TFetchArgs | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(index) {
    this._selectedIndex = index;
  }

  get indices() {
    return this._indices;
  }

  set indices(indicesList) {
    this._indices = indicesList;
  }

  get displayedOverlay() {
    return this._displayedOverlay;
  }

  set displayedOverlay(overlay) {
    this._displayedOverlay = overlay;
  }

  get fetchArgs() {
    return this._fetchArgs;
  }

  set fetchArgs(value) {
    this._fetchArgs = value;
  }

  get hasIndices() {
    return Boolean(this.indices.length);
  }

  setLoading = (value: boolean): void => {
    this._isLoading = value;
  };

  clear = () => {
    this._displayedOverlay = null;
    this._selectedIndex = null;
    this._indices = [];
    this._isLoading = false;
  };
}

export default IndicesStore;
