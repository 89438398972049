import { useParams } from 'react-router-dom';

import { IStoParams } from '../../models';

const useStoParams = (): IStoParams => {
  const params = useParams<keyof IStoParams>();

  return params;
};

export default useStoParams;
