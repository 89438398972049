import _ from 'lodash';
import { runInAction } from 'mobx';

import {
  TChangeImportedFieldReq,
  TGetImportedFieldListReq,
  TImportFieldsFromKmlRes,
  TImportFieldsFromShapeReq,
  TImportFieldsFromShapeRes,
} from '../../../../../../../api/api';
import { Field } from '../../../../../../../api/models/field.model';
import { EImportedFieldErrorType } from '../../../../../../../api/models/fields/getImportedField.model';
import {
  IMapLayerGroupClickPayload,
  IMapLayerGroupConfig,
} from '../../../../../../shared/features/map/models';
import { EPolygonErrorType } from '../../../../../../shared/features/map/models/PolygonErrors/PolygonErrors.model';
import { MapDrawerController } from '../../../../../../shared/features/map/modules';
import { MapEventBus } from '../../../../../../shared/features/map/modules/MapCore';
import {
  BasePolygon,
  FieldLayerGroup,
} from '../../../../../../shared/features/map/utils/MapElements';
import { PolygonValidator } from '../../../../../../shared/features/map/utils/validators';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { EFieldTooltip } from '../../../../../constants/FieldTooltip.enum';
import { PopupPages } from '../../../../../constants/popup.pages';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';
import { EFieldsMode } from '../../../constants';
import { makeAreaTooltip } from '../../../utils';
import { EFieldsImportModalName } from '../../../utils/constants/import';
import { FIELD_POLYGON_OPTIONS } from '../../../utils/constants/PolygonOptions.constant';
import { importedFieldsSessionStorageHelpers } from '../../../utils/helpers/import';
import { FieldsService, ImportFieldsService } from '../../services';
import { ImportFieldsStore } from '../../stores';
import AbstractFieldsController from '../AbstractFieldsController/AbstractFields.controller';

/**
 * TODO: Класс раздут, разбить на сервисы
 */
@provide.singleton()
class ImportFieldsController extends AbstractFieldsController {
  @lazyInject(ImportFieldsStore)
  protected store: ImportFieldsStore;

  @lazyInject(OrganizationsStore)
  private organizationStore: OrganizationsStore;

  @lazyInject(ProfileStore)
  private profileStore: ProfileStore;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  @lazyInject(FieldsService)
  private fieldsService: FieldsService;

  @lazyInject(ImportFieldsService)
  private fieldsImportService: ImportFieldsService;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  /**
   * Загружает и добавляет полигоны на карту
   */
  public async initialize(): Promise<void> {
    this.sharedFieldsStore.fieldsMode = EFieldsMode.IMPORT;

    const { getToken } = importedFieldsSessionStorageHelpers;

    await this.fieldsService.fetchFieldsList();

    if (this.store.isEmptyImportedFieldsList) {
      await this.seasonsStore.waitSeasons();
      await this.profileStore.waitUser();

      const token = getToken(
        this.organizationStore.selectedOrganizationId,
        this.seasonsStore.selectedSeason
      );

      if (!token) {
        return;
      }

      await this.fetchImportedList(token).then(() => {
        this.addFieldsToMap();
      });

      return;
    }

    await this.addFieldsToMap();
  }

  public destroy() {
    super.destroy();

    this.store.clearStore();
  }

  /**
   * Переопределяет метод абстрактного класса.
   * Выбирает полигон на карте и устанавливает ему режим редактирования
   */
  public selectField(field: Field) {
    const { editableField } = this.store;

    const selectedLayerGroup = this.getLayerGroupByField(field);
    if (!selectedLayerGroup) {
      return;
    }

    this.mapCoreController.centerOnBounds(selectedLayerGroup);

    selectedLayerGroup.setTooltipContent(`Поле ${makeAreaTooltip(field?.area ?? 0)}`);

    if (editableField) {
      const editableLayerGroup = this.getLayerGroupByField(editableField);
      const editablePolygon = editableLayerGroup.getMainPolygon();
      this.mapDrawerController.disableEditPolygon(editablePolygon);
    }

    const fieldPolygon = selectedLayerGroup.getMainPolygon();
    this.mapDrawerController.enableEditPolygon(fieldPolygon);

    runInAction(() => {
      this.store.setFocusTargetId(field.id);
      this.store.setIsFieldFocused(true);

      this.store.setEditableField({
        ...field,
        areaInHectare: fieldPolygon.getInfo().area,
      });
    });
  }

  // Переопределяет метод абстрактного класса
  protected getLayerGroupConfig(field: Field): IMapLayerGroupConfig {
    const fieldPolygonStyle = !field?.isImported
      ? FIELD_POLYGON_OPTIONS.import
      : FIELD_POLYGON_OPTIONS.display;

    const baseConfig = super.getLayerGroupConfig(field);

    baseConfig.options.isAllowToClick = field?.isImported;
    baseConfig.layerGroup = new FieldLayerGroup(field, {
      optimization: { isClusterable: false, isRenderViewport: true },
      fieldOptions: fieldPolygonStyle,
    });

    return baseConfig;
  }

  protected registerMapEvents() {
    const listener1 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);
    const listener2 = MapEventBus.on('draw.polygon.edit', this.handlePolygonEdit);

    this.mapEventsListenersList.push(listener1, listener2);
  }

  protected handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const fieldOfPolygon = this.getFieldByLayerGroup(payload.layerGroup);

    this.selectField(fieldOfPolygon);
  };

  private checkErrors = touchedList => {
    touchedList.forEach(polygon => {
      if (polygon.hasErrors) {
        [...polygon.errors.values()].forEach(error => {
          this.fieldsStore.setErrorToFieldById(polygon.fieldId, {
            error: true,
            errorType: error.type as any,
          });
        });
      } else {
        this.fieldsStore.setErrorToFieldById(polygon.fieldId, {
          error: false,
          errorType: null,
        });
      }
    });
    const errors = this.fieldsStore.fieldsList.filter(field => field.isImported && field.error);
    this.store.hasErrors = Boolean(errors.length);
  };

  private handlePolygonEdit = (polygon: BasePolygon) => {
    const fieldId = this.store.editableField.id;

    this.store.updateEditableFieldArea(polygon.getInfo().area);

    const validator = this.validate(polygon);

    this.checkErrors(validator.getTouchedList());

    // if (!errors.length) {
    //   this.fieldsStore.setErrorToFieldById(fieldId, { error: false, errorType: null });
    // }
    //
    // errors.forEach(error => {
    //   this.fieldsStore.setErrorToFieldById(fieldId, {
    //     error: true,
    //     errorType: error.type as any,
    //   });
    // });
  };

  importFields = async (apiName: string, formData: FormData): Promise<TImportFieldsFromKmlRes> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { setToken } = importedFieldsSessionStorageHelpers;

    this.store.setIsDataBeingProcessed(true);

    const res = await this.fieldsImportService
      .importFields(apiName, {
        organizationId: selectedOrganizationId === 'my' ? '' : selectedOrganizationId,
        seasonYear: Number(selectedSeason),
        file: formData,
      })
      .catch(() => null);

    if (!res) {
      this.store.setIsDataBeingProcessed(false);

      return res;
    }

    if (!res?.errorType) {
      setToken(selectedOrganizationId, selectedSeason, res?.token);
    }

    return res;
  };

  importFieldsFromShape = async (formData: FormData): Promise<TImportFieldsFromShapeRes> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { setToken } = importedFieldsSessionStorageHelpers;

    this.store.setIsDataBeingProcessed(true);

    const payload: TImportFieldsFromShapeReq = {
      organizationId: selectedOrganizationId === 'my' ? '' : selectedOrganizationId,
      seasonYear: Number(selectedSeason),
      files: formData,
    };

    const importShapeRes = await this.fieldsImportService.importFieldsFromShape(payload);

    if (!importShapeRes) {
      this.store.setIsDataBeingProcessed(false);

      return importShapeRes;
    }

    if (!importShapeRes?.errorType) {
      setToken(selectedOrganizationId, selectedSeason, importShapeRes?.token);
    }

    setToken(selectedOrganizationId, selectedSeason, importShapeRes?.token);

    return importShapeRes;
  };

  fetchImportedList = async (token: string): Promise<boolean> => {
    const importListPayload: TGetImportedFieldListReq = {
      token,
      size: 500,
    };

    const importListRes = await this.fieldsImportService.getImportedFieldList(importListPayload);

    if (!importListRes) {
      this.store.setIsDataBeingProcessed(false);

      return false;
    }

    const { content } = importListRes;

    this.store.setIdToImportedField(content);
    this.store.setIsDataBeingProcessed(false);

    return true;
  };

  deleteImportedField = async (field: Field): Promise<void> => {
    const res = await this.fieldsImportService.deleteImportedFieldById(field.id);

    if (res !== EFieldsImportModalName._Success) {
      this.store.setSaveErrorModal(res);
      return;
    }

    const layerGroup = this.getLayerGroupByField(field);
    if (layerGroup) {
      this.mapLayerGroupController.remove(layerGroup.id);
    }

    this.fieldsStore.deleteFieldById(field.id);

    if (field.id === this.store.editableField?.id) {
      this.store.clearEditableField();
    }
  };

  saveImportedFieldList = async (): Promise<EFieldsImportModalName> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { getToken, deleteToken } = importedFieldsSessionStorageHelpers;

    const token = getToken(selectedOrganizationId, selectedSeason);
    if (!token) {
      return;
    }

    // Показываем лоадер
    this.store.setSaveErrorModal(EFieldsImportModalName.Loader);

    const res = await this.fieldsImportService.saveImportedFieldList(token);

    if (res !== EFieldsImportModalName._Success) {
      this.store.setSaveErrorModal(res);
      return res;
    }

    deleteToken();
    return res;
  };

  changeFieldName = (field: Field, value: string): void => {
    const foundField = this.fieldsStore.getFieldById(field.id);
    const changedField: Field = { ...foundField, name: value };
    const changedEditableField = { ...this.store.editableField, name: value };

    this.fieldsStore.setField(changedField);
    this.store.setEditableField(changedEditableField);

    const layerGroup = this.getLayerGroupByField(field);
    layerGroup.setTooltipContent(value);
  };

  changeImportedField = async (fieldProps: TChangeImportedFieldReq): Promise<boolean> => {
    this.store.setIsWaitingForEditRes(true);
    const res = await this.fieldsImportService.changeImportedField(fieldProps?.id, fieldProps);

    this.store.setIsWaitingForEditRes(false);

    if (res === EFieldsImportModalName._Success) {
      return true;
    }

    this.store.setSaveErrorModal(res);
  };

  private addFieldsToMap = () => {
    const { listOfImportedField } = this.store;
    const { fieldsList } = this.fieldsStore;

    this.mapCoreController.clear();

    const allFieldsList = [
      ...fieldsList,
      ...listOfImportedField?.map<any>(field => ({ ...field, isImported: true })),
    ];

    const polygonsConfig = allFieldsList.map(field => {
      if (field?.errorType === EImportedFieldErrorType.InvalidGeometry) {
        return { element: field, layerGroup: null };
      }

      return this.getLayerGroupConfig(field);
    });

    const mapElementsList = this.mapLayerGroupController.displayMany(polygonsConfig);
    this.registerMapEvents();

    this.fieldsStore.attachMapElements(mapElementsList);

    this.fieldTooltipController.setTooltipContent(EFieldTooltip.Name);

    const importedFieldsList = this.fieldsStore.fieldsList.filter(field => field?.isImported);
    const sortedList = _.sortBy(importedFieldsList, ['errorType', 'area']);

    const layerGroup = this.getLayerGroupByField(sortedList[0]);
    this.mapCoreController.centerOnBounds(layerGroup);

    this.validateAllImported();

    this.UIStore.popupPageState = PopupPages.None;
  };

  private validate(polygon: BasePolygon, skipIntersectionIds = new Set<number>()) {
    const polygonValidator = new PolygonValidator(polygon);

    return polygonValidator
      .checkIntersections(this.fieldsPolygonsList, skipIntersectionIds)
      .checkIsAreaTooBig()
      .changeStyles(FIELD_POLYGON_OPTIONS.error);
  }

  // Сложность O(n2). Надо подумать над тем, как упросить валидацию при инициализации
  private validateAllImported(): void {
    const skipIdsCollection = new Set<number>();

    this.fieldsStore.fieldsList
      .filter(field => field.isImported)
      .forEach(field => {
        const layerGroup = this.getLayerGroupByField(field);
        const polygon = layerGroup?.getMainPolygon();

        if (!polygon) {
          return;
        }

        const validator = this.validate(polygon, skipIdsCollection);

        const hasIntersection = validator.getPolygonErrors().has(EPolygonErrorType.Intersection);

        if (!hasIntersection) {
          skipIdsCollection.add(polygon.id);
        }
      });
  }
}

export default ImportFieldsController;
