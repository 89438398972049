import { PolylineOptions } from 'leaflet';

import { Field } from '../../../../../../../api/models/field.model';
import { FIELD_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';
import { IFieldLayerOptions } from '../../../models';
import CultureZonePolygon from '../polygons/CultureZonePolygon';
import FieldPolygon from '../polygons/FieldPolygon';

import OptimizedLayerGroup from './OptimizedLayerGroup';

class FieldLayerGroup extends OptimizedLayerGroup {
  private fieldPolygon: FieldPolygon;
  private culturesPolygons: CultureZonePolygon[];

  private selectedStyle: PolylineOptions;

  constructor(field: Field, options?: IFieldLayerOptions) {
    // Создаем основной полигон
    const fieldPolygon = new FieldPolygon(field, options?.fieldOptions);

    // Создаем полигоны культурных зон
    const culturesPolygons = (field.cultureZones ?? []).map(zone => {
      return new CultureZonePolygon(zone, options?.cultureOptions);
    });

    // Регистрируем на карте только основной полигон. Управлять видимостью КЗ необходимо извне
    super([fieldPolygon], { pmIgnore: true, ...options });

    this.fieldPolygon = fieldPolygon;
    this.culturesPolygons = culturesPolygons;

    this.selectedStyle = options?.selectedStyle ?? FIELD_POLYGON_OPTIONS.selected;
  }

  public select(): void {
    this.fieldPolygon.setStyle(this.selectedStyle);

    this.isSelected = true;
    this.skipClustering = true;
  }

  public deselect(): void {
    if (!this.isSelected) {
      return;
    }

    this.fieldPolygon.setInitialStyle();
    this.isSelected = false;
    this.skipClustering = false;

    const tooltip = this.getTooltip();
    if (tooltip && !tooltip.isOpen()) {
      this.openTooltip();
    }
  }

  public getMainPolygon(): FieldPolygon {
    return this.fieldPolygon;
  }

  public showCultureZones(): void {
    if (!this.hasCultureZones()) {
      return;
    }

    this.culturesPolygons.forEach(polygon => {
      this.addLayer(polygon);

      if (this.isSelected) {
        polygon.bringToBack();
      }
    });
  }

  public hideCultureZones(): void {
    if (!this.hasCultureZones()) {
      return;
    }

    this.culturesPolygons.forEach(polygon => this.removeLayer(polygon));
  }

  private hasCultureZones(): boolean {
    return Boolean(this.culturesPolygons.length);
  }
}

export default FieldLayerGroup;
