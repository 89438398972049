import { PolylineOptions } from 'leaflet';

import { FIELD_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';
import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapPolygonSelectOptions, IRemoveManyOptions } from '../../../models';
import { TPolygonCoordinates } from '../../../models/PolygonElementConfig/PolygonElementConfig.model';
import { EPolygonErrorType } from '../../../models/PolygonErrors/PolygonErrors.model';
import { BasePolygon } from '../../../utils/MapElements';
import MapCoreService from '../../MapCore/services/MapCore.service';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapPolygonStore from '../stores/MapPolygon.store';

@provide.transient()
class MapPolygonService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  @lazyInject(MapCoreService)
  private mapCoreService: MapCoreService;

  public create(coords: TPolygonCoordinates, style: PolylineOptions): BasePolygon | null {
    if (!coords) {
      return null;
    }

    if (!this.coreStore.instance) {
      return null;
    }

    const polygon = new BasePolygon(coords as any, style);
    // Добавляет полигон в Leaflet.Deflate слой. Нужно для кластеризации
    // leafletPolygon.addTo(this._store.deflateLayer);
    polygon.addTo(this.coreStore.instance);

    this.polygonStore.setPolygon(polygon);

    return polygon;
  }

  /**
   * Выбор полигона. Состоит из 3 шагов
   * 1. Возвращение стандартных стилей предыдущему выбранному полигону
   * 2. Добавление полигона в переменную "selectedPolygon"
   * 3. Установка новых стилей для полигона
   */
  public select(polygon: BasePolygon, options?: IMapPolygonSelectOptions): void {
    if (!polygon) {
      return;
    }

    if (polygon.id === this.polygonStore.selectedPolygon?.id) {
      return;
    }

    this.deselectSelected();

    this.polygonStore.selectedPolygon = polygon;

    polygon.setStyle(options?.style ?? FIELD_POLYGON_OPTIONS.selected);

    if (options?.closeTooltip) {
      polygon.closeTooltip();
    }

    if (!options?.skipCenter) {
      this.mapCoreService.centerMapOnBounds(polygon);
    }
  }

  deselectSelected = () => {
    const { selectedPolygon, prevSelectedStyle } = this.polygonStore;
    if (!selectedPolygon) {
      return;
    }

    selectedPolygon.setStyle(prevSelectedStyle);

    const tooltip = selectedPolygon.getTooltip();
    if (tooltip && !tooltip.isOpen()) {
      selectedPolygon.openTooltip();
    }

    this.polygonStore.clearSelectedPolygon();
  };

  /**
   *  Удаляет полигон из Leaflet.Deflate слоя (вместе с тултипами), а также со стора
   *  @param options.revalidateIntersections = Ревалидирует пересечения удаляемого полигона
   */
  removeById = (polyId: number, options?: { revalidateIntersections?: boolean }) => {
    const polygonToDelete = this.polygonStore.getPolygon(polyId);
    if (!polygonToDelete) {
      return;
    }

    polygonToDelete.unbindTooltip();
    polygonToDelete.remove();

    if (options?.revalidateIntersections) {
      polygonToDelete.intersections.forEach(polygon => {
        polygon.intersections.delete(polygonToDelete.id);

        if (!polygon.intersections.size) {
          polygon.errors.delete(EPolygonErrorType.Intersection);
        }

        if (!polygon.hasErrors) {
          polygon.setInitialStyle();
        }
      });
    }

    this.polygonStore.deletePolygon(polyId);

    if (polyId === this.polygonStore.selectedPolygon?.id) {
      this.polygonStore.clearSelectedPolygon();
    }
  };

  removeManyByIds = (idList: number[], options?: IRemoveManyOptions): void => {
    if (options?.isRemoveAll) {
      this.polygonStore.polygonsList.forEach(({ id }) => this.removeById(id));
      this.polygonStore.clearSelectedPolygon();
      return;
    }

    idList.forEach(id => this.removeById(id));
  };
}

export default MapPolygonService;
