import { Button, useModal } from '@farmlink/farmik-ui';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { observer } from 'mobx-react';

import { ButtonWrapper } from '../../add/style';
import { ButtonLoader } from '../../../../../../shared/components/ButtonLoader/ButtonLoader';
import { useStore } from '../../../../../../shared/utils/IoC';
import { ImportFieldsController, ImportFieldsStore } from '../../../mobx';
import { FieldsRoute } from '../../../fields.route';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { EFieldsImportModalName } from '../../../utils/constants/import';

export const FooterContainer = observer(() => {
  const importFieldsController = useStore(ImportFieldsController);
  const organizationsStore = useStore(OrganizationsStore);
  const fieldsImportStore = useStore(ImportFieldsStore);
  const { closeModal } = useModal();
  const { isWaitingForEditRes, hasErrors } = fieldsImportStore;

  const { saveImportedFieldList } = importFieldsController;
  const navigate = useNavigate();

  const fieldsListingRoute = useMemo<string>(() => {
    return generatePath(FieldsRoute.Listing, {
      orgId: organizationsStore.selectedOrganizationId,
    });
  }, [organizationsStore.selectedOrganizationId]);

  const handleClose = (): void => {
    importFieldsController.destroy();
    navigate(fieldsListingRoute);
  };

  const handleSave = async (): Promise<void> => {
    const res = await saveImportedFieldList();

    if (res === EFieldsImportModalName._Success) {
      closeModal();
      navigate(fieldsListingRoute);
    }
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          type={'button'}
          color={'default'}
          onClick={handleClose}
          dataTestId={'fields-import-cancel'}
        >
          Отменить
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonLoader disabled={hasErrors} isLoading={isWaitingForEditRes} onClick={handleSave}>
          Сохранить
        </ButtonLoader>
      </ButtonWrapper>
    </>
  );
});
