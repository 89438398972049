import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { Field } from '../../../../../../api/models/field.model';
import { GetFieldByIdReq } from '../../../../../../api/endpoints/fields/get.field.by.id';

@provide.singleton()
class FieldsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getField = async (payload: GetFieldByIdReq): Promise<Field> => {
    const { getFieldById } = this.axiosService.api;

    try {
      const field = await getFieldById(payload);

      return field;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getCultureZoneList = async (
    payload: TypeApiRequest<'getCultureZoneList'>
  ): Promise<TypeApiResponse<'getCultureZoneList'>> => {
    try {
      const response = await this.axiosService.api.getCultureZoneList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default FieldsService;
