import L from 'leaflet';

import { createCultureZoneEvent } from '../../../../../../map/events/create.culture.zone.event';
import { lazyInject, provide } from '../../../../../utils/IoC';
import MapEventBus from '../../MapCore/services/MapEventBus.service';
import { BasePolygon } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapPolygonEventsService from '../../MapPolygon/services/MapPolygonEvents.service';
import MapPolygonStore from '../../MapPolygon/stores/MapPolygon.store';
import { FIELD_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';
import polygonOptions from '../../../../../../dashboard/modules/fields/utils/constants/DrawPolygonOptions.constant';

/**
 * Данный сервис регистрирует функции которые отвечают за рисование и редактирование на карте.
 */
@provide.transient()
class MapDrawerEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapPolygonStore)
  protected polygonStore: MapPolygonStore;

  @lazyInject(MapPolygonEventsService)
  private polygonEventsService: MapPolygonEventsService;

  // Глобальные события рисования
  registerGlobalEvents = () => {
    if (!this.coreStore.instance) {
      return;
    }

    this.coreStore.instance.on('pm:create', this.handleDrawCreate);
    this.coreStore.instance.on('pm:drawstart', this.handleDrawStart);
    this.coreStore.instance.on('pm:drawend', this.handleDrawEnd);
  };

  registerEditPolygonEvents = (polygon: BasePolygon) => {
    polygon.on('pm:edit', this.handleEditPolygon);

    polygon.on('pm:vertexadded', this.handleAddedVertex);
    polygon.on('pm:vertexclick', this.handleClickVertex);
    polygon.on('pm:markerdragend', this.handleMarkerDragEnd);
    polygon.on('pm:markerdragstart', this.handleMarkerDragStart);
  };

  unregisterEditPolygonEvents = (polygon: BasePolygon) => {
    polygon.off('pm:edit');
    polygon.off('pm:vertexadded');
    polygon.off('pm:vertexclick');
  };

  /**
   * Вызывается во время вызова функции instance.pm.enableDraw()
   */
  private handleDrawStart: L.PM.DrawStartEventHandler = ({ workingLayer }) => {
    MapEventBus.emit('draw.start');

    workingLayer.on('pm:vertexadded', this.handleAddedVertex);
  };

  /**
   * Вызывается во время вызова функции instance.pm.disableDraw()
   */
  private handleDrawEnd: L.PM.DrawStartEventHandler = () => {
    MapEventBus.emit('draw.end');
  };

  /**
   * Вызывается во время редактирования конкретного полигона
   */
  private handleEditPolygon: L.PM.EditEventHandler = event => {
    // @ts-ignore
    const polygonFromEvent = event.layer as BasePolygon;

    if (polygonFromEvent) {
      MapEventBus.emit('draw.polygon.edit', polygonFromEvent);
    }
  };

  /**
   * Вызывается после завершения рисования фигуры
   */
  private handleDrawCreate: L.PM.CreateEventHandler = event => {
    if (!event?.layer) {
      return;
    }

    /**
     * На данный момент рисование реализовано только для фигуры "Polygon".
     * В будущем хотелось бы добавить поддержку рисования маркеров. На данный момент маркеры рисуются с использованием instance.on('click')
     * Основная проблема с поддержкой маркеров это валидация границ внутри которых должен быть нарисован маркер. В geoman нет такой проверки
     */
    if (event.shape === 'Polygon') {
      const drawnLayer = event.layer as L.Polygon;
      const { coordinates } = drawnLayer.toGeoJSON().geometry;

      const polygon = new BasePolygon(coordinates as any, {
        pmIgnore: false,
        ...event.layer.options,
      });

      this.coreStore.instance.addLayer(polygon);
      this.coreStore.instance.removeLayer(event.layer);

      this.polygonStore.setPolygon(polygon);

      polygon?.bringToFront();

      // установка режима редактирования для нового полигона. Стоит ли делать это тут?
      this.registerEditPolygonEvents(polygon);
      polygon.pm.enable({
        allowSelfIntersection: false,
      });

      MapEventBus.emit('draw.polygon.create', polygon);
    }
  };

  // Вызывается в момент добавления точек Vertex
  private handleAddedVertex: L.PM.VertexAddedEventHandler = event => {
    if (event.shape === 'Polygon') {
      MapEventBus.emit('draw.polygon.vertex.add', event.marker);
    }
  };

  // Вызывается после клика на Vertex
  private handleClickVertex: L.PM.VertexClickEventHandler = event => {
    if (event.shape === 'Polygon') {
      MapEventBus.emit('draw.polygon.vertex.click', {
        // @ts-ignore
        marker: event?.markerEvent?.target || event?.marker,
        index: (event.indexPath as unknown) as [number, number],
      });
    }
  };

  private handleMarkerDragStart: L.PM.VertexClickEventHandler = event => {
    this.coreStore.instance.pm.disableDraw();
  };

  // Вызывается на сдвиг маркера
  private handleMarkerDragEnd: L.PM.VertexClickEventHandler = event => {
    setTimeout(() => {
      this.coreStore.instance.pm.enableDraw('Polygon', polygonOptions);
    });
    if (event.shape === 'Polygon') {
      const polygonFromEvent = event.layer as BasePolygon;
      MapEventBus.emit('draw.polygon.marker.drag', {
        // @ts-ignore
        marker: event?.markerEvent?.target || event?.marker,
        index: (event.indexPath as unknown) as [number, number],
      });

      const ev = (event as unknown) as { intersectionReset: boolean };
      if (polygonFromEvent?.hasErrors && ev?.intersectionReset) {
        setTimeout(() => {
          polygonFromEvent.setStyle(FIELD_POLYGON_OPTIONS.error);
        });
      }
    }
  };

  private handleDrawCultureCreate = event => {
    const { layer } = event;
    const geoJson = layer.toGeoJSON();
    layer.remove();
    createCultureZoneEvent(geoJson.geometry.coordinates[0]);
  };
}

export default MapDrawerEventsService;
