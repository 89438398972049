import { useCallback } from 'react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ImportFieldsController } from '../../../../../../mobx/controllers';
import { EFieldsUploadErrorType } from '../../../../../../utils/constants/import';
import { getApiNameByFileExtension } from '../../../../../../utils/helpers/import';
import { EFieldsImportContainerRenderModalName } from '../../FieldsImportModalContainer';

import { TUseImportFields, TUseImportFieldsReturn } from './useImportFields.types';

const useImportFields: TUseImportFields = setRenderModalName => {
  const fieldsImportController = useStore(ImportFieldsController);
  const { importFields, fetchImportedList } = fieldsImportController;

  const handleImportFields = useCallback<TUseImportFieldsReturn['importFields']>(
    async (fileExtension, fileList) => {
      const formData = new FormData();
      formData.append('file', fileList[0]);

      const apiName = getApiNameByFileExtension(fileExtension);

      if (!apiName) {
        return;
      }

      setRenderModalName(EFieldsImportContainerRenderModalName.Loader);

      const importFieldsRes = await importFields(apiName, formData);

      if (!importFieldsRes) {
        setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);

        return;
      }

      const { token, errorType } = importFieldsRes;

      if (errorType) {
        if (errorType === EFieldsUploadErrorType.FieldsCount) {
          setRenderModalName(EFieldsImportContainerRenderModalName.TooManyFields);

          return;
        }

        setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);

        return;
      }

      const isSuccess = await fetchImportedList(token);

      if (isSuccess) {
        setRenderModalName(EFieldsImportContainerRenderModalName.Success);
      } else {
        setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);
      }
    },
    []
  );

  return {
    importFields: handleImportFields,
  };
};

export default useImportFields;
