import styled, { css } from 'styled-components';

const ControlPanelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1001;
  width: auto;
  max-width: 100%;
`;

const ControlPanelItem = styled.div<{ fullWidth?: boolean; isMarginRight?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  padding-right: ${({ fullWidth }) => (fullWidth ? '16px' : '0')};
  overflow: ${({ fullWidth }) => (fullWidth ? 'hidden' : 'visible')};
  ${({ isMarginRight }) =>
    isMarginRight &&
    css`
      margin-right: 16px;
    `}
  display: flex;
`;

export default { ControlPanelItem, ControlPanelWrapper };
