import { ReactComponent as NoSeasonsIconSvg } from '../../../static/map-add-entity-mock.svg';
import { NoSeasonsDescription, NoSeasonsTitle, NoSeasonsWrapper } from '../style';

export const NoSeasons = () => {
  return (
    <NoSeasonsWrapper data-test-id={'fields-list-no-seasons'}>
      <NoSeasonsIconSvg />
      <NoSeasonsTitle>У вас ещё нет сезонов</NoSeasonsTitle>
      <NoSeasonsDescription>
        Добавьте сезон, чтобы получить возможность добавлять поля
      </NoSeasonsDescription>
    </NoSeasonsWrapper>
  );
};
