import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../shared/utils/IoC';
import { EFieldFill } from '../../constants/FieldFill.enum';
import { FieldsStore } from '../../modules/fields/mobx';
import { AvailableCultures } from '../AvailableCultures';
import { FieldFillStore } from '../FieldFillControls/mobx';
import { StaticLegend } from '../StaticLegend';

export const LegendControls = observer(() => {
  // Сторы
  const { fieldFillValue } = useStore(FieldFillStore);
  const fieldsStore = useStore(FieldsStore);

  const isSelectedCultureFill = fieldFillValue === EFieldFill.Cultures;
  const isSelectedNdviOrMsaviFill = [EFieldFill.Msavi, EFieldFill.Ndvi].includes(fieldFillValue);

  if (isSelectedNdviOrMsaviFill && fieldsStore.hasFields) {
    return <StaticLegend />;
  }

  if (isSelectedCultureFill) {
    return <AvailableCultures />;
  }

  return null;
});
