import { memo, useCallback, MouseEvent, forwardRef } from 'react';

import { MapLayerGroupStore, MapPolygonStore } from '../../../../../shared/features/map/modules';
import { useStore } from '../../../../../shared/utils/IoC';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { Input } from '../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import { ReactComponent as MapSvg } from '../../static/creation-map-mock.svg';
import { getErrorNameByType } from '../../utils/helpers/import';

import { ReactComponent as MapBlackSvg } from './assets/images/map-icon-black-16x16.svg';
import Styled from './EditableFieldItem.styles';
import { TEditableFieldItemProps } from './EditableFieldItem.types';

const EditableFieldItem = forwardRef<HTMLInputElement, TEditableFieldItemProps>(
  ({ field, focusTargetId, isSelected, onDelete, onNameChange, onCardClick }, ref) => {
    const { icon, name, area, error, errorType } = field;

    const mapLayerGroupStore = useStore(MapLayerGroupStore);
    const mapPolygonsStore = useStore(MapPolygonStore);

    const hasLayerGroup = mapLayerGroupStore.getLayerGroup(field.polyId);
    const hasPolygon = mapPolygonsStore.getPolygon(field.polyId);

    const handleNameChange = useCallback(
      (value: string) => {
        onNameChange?.(field, value);
      },
      [field]
    );

    const handleDeleteClick = useCallback(
      (event: MouseEvent<HTMLParagraphElement>) => {
        event.stopPropagation();

        onDelete?.(field);
      },
      [field]
    );

    const handleCardClick = useCallback(() => {
      /**
       * Если поле не отрисовано на карте, например из-за неправильной геометрии,
       * то не отслеживаем клик по нему
       */
      if (hasLayerGroup || hasPolygon) {
        onCardClick?.(field?.id);
      }
    }, [field]);

    return (
      <Styled.Wrapper onClick={handleCardClick} $isSelected={isSelected}>
        {error || isSelected ? <Styled.Status $isSelected={Boolean(isSelected && !error)} /> : null}

        <Styled.ContentWrapper>
          {icon ? (
            <Styled.MapImage src={icon?.downloadUrl} />
          ) : (
            <Styled.MapIcon>{isSelected ? <MapBlackSvg /> : <MapSvg />}</Styled.MapIcon>
          )}

          <Styled.RightColumn>
            <Input
              isDisabled={!hasLayerGroup}
              onChange={handleNameChange}
              value={name}
              ref={focusTargetId === field.id ? ref : null}
              id={`field-card-id-${field.id}`}
            />

            <Styled.BottomSectionWrapper>
              <Styled.AreaLabel $isError={error}>
                {error
                  ? getErrorNameByType(errorType)
                  : `Площадь: ${toFixedWithCeilBackEnd(area)} га`}
              </Styled.AreaLabel>

              <Styled.Delete
                onClick={handleDeleteClick}
                $isSelectedWithoutError={isSelected && !error}
              >
                Удалить
              </Styled.Delete>
            </Styled.BottomSectionWrapper>
          </Styled.RightColumn>
        </Styled.ContentWrapper>
      </Styled.Wrapper>
    );
  }
);

EditableFieldItem.displayName = 'EditableFieldItem';

export default memo(EditableFieldItem);
