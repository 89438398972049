import { PolylineOptions } from 'leaflet';

import { Field } from '../../../../../../../api/models/field.model';
import { FIELD_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';

import BasePolygon from './BasePolygon';

class FieldPolygon extends BasePolygon {
  public fieldId: string = null;

  constructor(field: Field, options?: PolylineOptions) {
    const { coordinates } = field.geometry;

    super(coordinates, options ?? FIELD_POLYGON_OPTIONS.display);
    this.fieldId = field.id;
  }
}

export default FieldPolygon;
