import L, { ImageOverlayOptions, LatLngBoundsExpression } from 'leaflet';

class BaseImageOverlay extends L.ImageOverlay {
  public id: number;

  constructor(imageUrl: string, bounds: LatLngBoundsExpression, options?: ImageOverlayOptions) {
    super(imageUrl, bounds, options);

    this.id = L.Util.stamp(this);
  }
}

export default BaseImageOverlay;
