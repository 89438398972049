import styled from 'styled-components';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 16px;
  z-index: 1000;
  pointer-events: none;
`;

const Topline = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const RightAside = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  transform: translateY(-40px);
`;

const RightAsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Styled = {
  Overlay,
  Topline,
  RightAside,
  RightAsideWrapper,
};

export default Styled;
