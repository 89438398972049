import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { MapEventBus } from '../../MapCore';
import { MapMarkerValidationService } from '../../../mobx/services/MapMarkerValidationService';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapMarkerStore from '../stores/MapMarker.store';

import MapMarkerService from './MapMarker.service';

@provide.transient()
class MapMarkerEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapMarkerStore)
  private markerStore: MapMarkerStore;

  @lazyInject(MapMarkerService)
  private service: MapMarkerService;

  @lazyInject(MapMarkerValidationService)
  private validationService: MapMarkerValidationService;

  public register() {
    if (!this.coreStore.instance) {
      return;
    }

    this.coreStore.instance.on('click', this.handleCreateMarker);
  }

  public unregister() {
    if (!this.coreStore.instance) {
      return;
    }

    this.coreStore.instance.off('click', this.handleCreateMarker);
  }

  /**
   *  Устанавливает маркер на карту.
   *  Если в конфиге маркера есть полигон, то проверяет что маркер внутри полигона
   */
  public handleCreateMarker = (event: L.LeafletMouseEvent) => {
    const polygonToDrawInside = this.markerStore.markersConfig.bounds;

    if (!polygonToDrawInside) {
      const newMarker = this.service.createMarkerOnClick(event);
      if (newMarker) {
        MapEventBus.emit('map.marker.createOnClick', newMarker);
      }
      return;
    }

    const isInsidePolygon = this.validationService.checkIfMouseInsidePolygon(
      event,
      polygonToDrawInside.toGeoJSON()
    );

    if (isInsidePolygon) {
      const newMarker = this.service.createMarkerOnClick(event);
      if (newMarker) {
        MapEventBus.emit('map.marker.createOnClick', newMarker);
      }
    }
  };
}

export default MapMarkerEventsService;
