import * as turf from '@turf/turf';

import { provide } from '../../../../../utils/IoC';
import { CultureZone, Field } from '../../../../../../../api/models/field.model';

@provide.transient()
class MapAdditionsService {
  cordsToPoint = (cords: number[]) => {
    return turf.point(cords);
  };

  isPointInZone = (
    zone: Field | CultureZone,
    point: turf.helpers.Feature<turf.helpers.Point>
  ): boolean => {
    const turfPolygon = turf.polygon(zone.geometry.coordinates);

    return turf.booleanPointInPolygon(point, turfPolygon);
  };
}

export default MapAdditionsService;
