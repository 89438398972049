import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { BorderRadius, FontSizes } from '../../../../../../../shared/constans/sizes';

const TimeLineWrapper = styled.div`
  background: ${Colors.generalWhite};
  border-radius: ${BorderRadius.default};
  height: 40px;
  width: 100%;
`;

const TimelineInfoMessage = styled.div`
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: ${Colors.darkGrey};

  & span {
    margin: 0 8px;
  }
`;

const TimelineItemsWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 32px;
  max-width: 100%;
`;

const TimelineItem = styled.div<{ active: boolean }>`
  ${FontSizes.medium};
  color: ${({ active }) => (active ? Colors.green : Colors.darkGrey)};
  cursor: pointer;
  padding: 0 16px;
  border-right: 1px solid ${Colors.borderGray};
  white-space: nowrap;

  &:first-of-type {
    border-right: 1px solid transparent;
  }
`;

const TimelineIconItem = styled.div`
  padding: 0 16px;
  border-right: 1px solid ${Colors.borderGray};

  &:first-of-type {
    border-right: 1px solid transparent;
  }
`;

export default {
  TimelineIconItem,
  TimelineItem,
  TimelineItemsWrapper,
  TimelineInfoMessage,
  TimeLineWrapper,
};
