import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapLayerGroupSelectOptions, IRemoveManyOptions } from '../../../models';
import { BaseLayerGroup } from '../../../utils/MapElements';
import MapCoreService from '../../MapCore/services/MapCore.service';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapLayerGroupStore from '../stores/MapLayerGroup.store';

@provide.transient()
class MapLayerGroupService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapLayerGroupStore)
  private layerGroupStore: MapLayerGroupStore;

  @lazyInject(MapCoreService)
  private mapCoreService: MapCoreService;

  public create(layerGroup: BaseLayerGroup | null): BaseLayerGroup | null {
    const parentLayer = this.coreStore[layerGroup?.parentLayerKey];
    if (!layerGroup || !parentLayer) {
      return null;
    }

    parentLayer.addLayer(layerGroup);

    this.layerGroupStore.setLayerGroup(layerGroup);

    return layerGroup;
  }

  public select(layerGroup: BaseLayerGroup, options?: IMapLayerGroupSelectOptions): void {
    if (!layerGroup || layerGroup.isSelected) {
      return;
    }

    this.deselectSelected();

    layerGroup.select();
    this.layerGroupStore.selectedLayerGroup = layerGroup;

    if (!options?.skipCenter) {
      this.mapCoreService.centerMapOnBounds(layerGroup);
    }
  }

  public deselectSelected(): void {
    this.layerGroupStore.selectedLayerGroup?.deselect();
    this.layerGroupStore.selectedLayerGroup = null;
  }

  public removeById(id: number): void {
    const layerToDelete = this.layerGroupStore.getLayerGroup(id);
    if (!layerToDelete) {
      return;
    }

    const deleteFrom = this.coreStore[layerToDelete.parentLayerKey];
    deleteFrom?.removeLayer(layerToDelete);

    this.layerGroupStore.deleteLayerGroup(id);
  }

  public removeManyByIds(idList: number[], options?: IRemoveManyOptions): void {
    if (options?.isRemoveAll) {
      this.layerGroupStore.layerGroupsList.forEach(({ id }) => this.removeById(id));
      return;
    }

    idList.forEach(id => this.removeById(id));
  }
}

export default MapLayerGroupService;
