import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { BasePolygon } from '../../../utils/MapElements';

@provide.singleton()
class MapValidationStore {
  private _collectionOfTempIncorrectPolygonId: Set<number> = new Set<number>();

  private _listOfPatchedPolygons: BasePolygon[] = [];

  private _prevIncorrectPolygonsById: Map<number, BasePolygon> = new Map<number, BasePolygon>();

  incorrectLayersId: Set<number> = new Set<number>();

  constructor() {
    makeAutoObservable(this);
  }

  get listOfTempIncorrectLayerId() {
    return Array.from(this._collectionOfTempIncorrectPolygonId);
  }

  setCollectionOfTempIncorrectPolygonId = (collection: Set<number>): void => {
    this._collectionOfTempIncorrectPolygonId = collection;
  };

  clearCollectionOfTempIncorrectPolygonId = (): void => {
    this._collectionOfTempIncorrectPolygonId.clear();
  };

  get listOfPatchedPolygons() {
    return this._listOfPatchedPolygons;
  }

  set listOfPatchedPolygons(list) {
    this._listOfPatchedPolygons = list;
  }

  clearListOfPatchedPolygonId = (): void => {
    this._listOfPatchedPolygons = [];
  };

  get listOfPrevIncorrectPolygons() {
    return Array.from(this._prevIncorrectPolygonsById.values());
  }

  set prevIncorrectPolygonsById(collection: Map<number, BasePolygon>) {
    this._prevIncorrectPolygonsById = collection;
  }

  clearCollectionOfPrevIncorrectPolygonId = (): void => {
    this._prevIncorrectPolygonsById.clear();
  };

  hasErrorsLayers = () => {
    return Boolean(this._prevIncorrectPolygonsById.size);
  };

  clearStore = () => {
    this.clearCollectionOfPrevIncorrectPolygonId();
    this.clearCollectionOfTempIncorrectPolygonId();
    this.clearListOfPatchedPolygonId();
  };
}

export default MapValidationStore;
