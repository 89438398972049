import { difference, intersect, polygon } from '@turf/turf';
import area from '@turf/area';

import { CultureZoneStore } from '../stores/culture.zone.store';
import { EMapValidationError } from '../../../../../../shared/constants/map/map.constants';
import { normalizeCoordsForTurf } from '../helpers';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';

@provide.transient()
class ValidateCultureZoneService {
  @lazyInject(CultureZoneStore)
  cultureZoneStore: CultureZoneStore;

  validateEditCultureZone = (geoJson, leafletId, options?: { isPreventUndo?: boolean }) => {
    let needUndoChanges = false;
    const { isPreventUndo } = options ?? {};
    const geometries = geoJson.geometry.coordinates[0].map(item => {
      return [Number(item[0].toFixed(6)), Number(item[1].toFixed(6))];
      // return item[0][0][0].toFixed(6);
    });

    const zonePolygon = polygon([geometries]);
    const fieldPolygon = polygon(this.cultureZoneStore.field.geometry.coordinates);
    const zoneArea = area(zonePolygon);
    const fieldArea = area(fieldPolygon);
    const FIELD_AREA_RELATIVITY_ERROR = Math.pow(Math.log(fieldArea), 2);
    const differenceArea = difference(fieldPolygon, zonePolygon)
      ? area(difference(fieldPolygon, zonePolygon))
      : 0;
    const zoneInMap = this.cultureZoneStore.idToCultureZone.get(
      this.cultureZoneStore.selectedCultureZoneModel?.mapId
    );
    if (Math.abs(fieldArea - zoneArea - differenceArea) > FIELD_AREA_RELATIVITY_ERROR) {
      if (!isPreventUndo) needUndoChanges = true;

      return { error: EMapValidationError.OutOfField, needUndoChanges };
    }
    const filteredList = this.cultureZoneStore.cultureZones.filter(
      zone => String(zone.polyId) !== String(leafletId) && !zone.isHole
    );
    for (let i = 0; i < filteredList.length; i++) {
      const zone = filteredList[i];
      const cultureZonePoly = polygon(normalizeCoordsForTurf(zone.geometry.coordinates));
      const intersectionArea = intersect(zonePolygon, cultureZonePoly)
        ? area(intersect(zonePolygon, cultureZonePoly))
        : 0;
      if (intersectionArea > FIELD_AREA_RELATIVITY_ERROR) {
        if (!isPreventUndo) needUndoChanges = true;

        return { error: EMapValidationError.OutOfZone, needUndoChanges };
      }
    }
    if (!zoneInMap) {
      if (!isPreventUndo) needUndoChanges = true;
      return { error: EMapValidationError.OutOfMap, needUndoChanges };
    }
    zoneInMap.geometry = geoJson.geometry;
    this.cultureZoneStore.lastValidatedGeometry = geoJson.geometry;
    this.cultureZoneStore.isAcceptChangesAvailable = true;
    return { needUndoChanges };
  };
}

export default ValidateCultureZoneService;
