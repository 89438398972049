import { FC } from 'react';

import { IMapGlobalConfig } from '../../models';

import Styled from './Overlay.styles';
import { Indices } from './components/';
import { Locate, Zoom } from './components/Controls';

interface IOverlayProps {
  globalConfig: IMapGlobalConfig;
}

const Overlay: FC<IOverlayProps> = ({ globalConfig }) => {
  const isDisplayIndices = globalConfig?.fieldIndicesOptions;

  return (
    <Styled.Overlay>
      {isDisplayIndices && (
        <Styled.Topline>
          <Indices />
        </Styled.Topline>
      )}

      <Styled.RightAside>
        <Styled.RightAsideWrapper>
          <Zoom />
          <Locate />
        </Styled.RightAsideWrapper>
      </Styled.RightAside>
    </Styled.Overlay>
  );
};

export default Overlay;
