import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const FIELDS_CREATE_MODALS_UNIQUE_KEY = 'fieldsCreate';

enum EFieldsCreateModalName {
  UnfinishedContourWarning = 'UNFINISHED_CONTOUR_WARNING',
  IntersectionWarning = 'INTERSECTION_WARNING',
  OversizedAreaWarning = 'OVERSIZED_AREA_WARNING',
  WarningBeforeLeavingThePage = 'WARNING_BEFORE_LEAVING_THE_PAGE',
}

const unfinishedContourWarningConfig: TModalConfig = {
  id: EFieldsCreateModalName.UnfinishedContourWarning,
  name: EFieldsCreateModalName.UnfinishedContourWarning,
  dataTestId: EFieldsCreateModalName.UnfinishedContourWarning,
  title: `Закончите контур, чтобы сохранить поле`,
  type: EModalType.Warning,
  denyButton: {
    title: 'Вернуться и закончить контур',
    color: 'primary',
  },
};

const intersectionWarningConfig: TModalConfig = {
  id: EFieldsCreateModalName.IntersectionWarning,
  name: EFieldsCreateModalName.IntersectionWarning,
  dataTestId: EFieldsCreateModalName.IntersectionWarning,
  title: 'Границы полей не могут пересекаться. Измените границы для сохранения изменений!',
  type: EModalType.Warning,
  successButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const oversizedAreaWarningConfig: TModalConfig = {
  id: EFieldsCreateModalName.OversizedAreaWarning,
  name: EFieldsCreateModalName.OversizedAreaWarning,
  dataTestId: EFieldsCreateModalName.OversizedAreaWarning,
  title:
    'Площадь поля не может быть больше 1000га. Уменьшите площадь поля для сохранения изменений!',
  type: EModalType.Warning,
  denyButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const fieldsCreateModalConfigList = [
  intersectionWarningConfig,
  oversizedAreaWarningConfig,
  unfinishedContourWarningConfig,
];

export { fieldsCreateModalConfigList, FIELDS_CREATE_MODALS_UNIQUE_KEY, EFieldsCreateModalName };
