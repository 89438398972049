import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { StoListPlugContainer } from '../StoListPlugContainer';
import { SeasonsStore } from '../../../../stores/seasons.store';

import {
  StoList,
  StoListNoDataPlug as NoDataPlug,
  StoListNoSeasonPlug as NoSeasonPlug,
  StoListNoFieldsPlug as NoFieldsPlug,
} from './components';
import { StoListStore } from './mobx/stores';
import { StoListController } from './mobx/controllers';

const StoListContainer: FC = () => {
  const { selectedSeason } = useStore(SeasonsStore);
  const { stoList } = useStore(StoListStore);
  const { hasFields, clearStoListStore } = useStore(StoListController);

  useEffect(() => {
    return () => clearStoListStore();
  }, []);

  const AvailableNoSeasonPlug = useMemo(() => {
    if (!selectedSeason) {
      return <NoSeasonPlug />;
    }
  }, [selectedSeason]);

  const AvailableNoFieldsPlug = useMemo(() => {
    if (!hasFields) {
      return <NoFieldsPlug />;
    }
  }, [hasFields]);

  const AvailableNoDataPlug = useMemo(() => {
    if (!stoList.length) {
      return <NoDataPlug />;
    }
  }, [stoList.length]);

  const AvailablePlug = AvailableNoSeasonPlug || AvailableNoFieldsPlug || AvailableNoDataPlug;

  return AvailablePlug ? <StoListPlugContainer>{AvailablePlug}</StoListPlugContainer> : <StoList />;
};

StoListContainer.displayName = 'StoListContainer';

export default observer(StoListContainer);
