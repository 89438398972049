import { FC } from 'react';
import { observer } from 'mobx-react';

import { OperationsUIStore } from '../../stores/operations.ui.store';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { OperationFieldsListItem } from '../OperationFieldsListItem';

import { FieldsList } from './style';

type Props = {
  fields: Array<OperationField>;
};

export const OperationFieldsList: FC<Props> = observer(({ fields }) => {
  const uiStore = useStore(OperationsUIStore);

  return (
    <FieldsList data-test-id={'operation-item-fields-list'}>
      {fields.map(field => (
        <OperationFieldsListItem
          field={field}
          isActive={field.fieldId === uiStore.selectedMarkId}
        />
      ))}
    </FieldsList>
  );
});
